import { Route, useLocation } from 'react-router'
import AttachmentOverlay from '../AttachmentOverlay'
import DocumentPanel from '../DocumentPanel'
import Helmet from 'react-helmet'
import React, { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'
import SplitPane from 'react-split-pane'
import css from './style.module.scss'
import useWindowSize from '../../utils/useWindowSize'

export type ID = string | null
interface DataTableContextInterface {
  activeTableId: ID
  setActiveTableId: Dispatch<SetStateAction<ID>>
}
export const DataTableContext = createContext<DataTableContextInterface>(null!)

interface DocumentPageWrapperProps {
  counterparty?: any
  dataPointPanel: ReactNode
  dealIsFinalized?: boolean
  documents: any
  headerComponent: ReactNode
  loading: boolean
  name?: string
  noChecklist?: boolean
  withDocumentSelector?: boolean
}

export default function DocumentPageWrapper({
  counterparty,
  dataPointPanel,
  dealIsFinalized,
  documents,
  headerComponent,
  loading,
  name,
  noChecklist,
  withDocumentSelector
}: DocumentPageWrapperProps) {
  const [activeTableId, setActiveTableId] = useState<ID>(null)
  const { pathname } = useLocation()
  const documentPanel = (
    <DocumentPanel
      counterparty={counterparty}
      dealIsFinalized={dealIsFinalized}
      documents={documents}
      loading={loading}
      parentName={name}
      withSelector={!!withDocumentSelector}
    />
  )
  const windowSize = useWindowSize()

  const getDefaultSize = () => {
    const currentWindowWidth = windowSize?.width
    const DEFAULT_SCREEN_WIDTH_FOR_SMALL_DEVICES = 400
    const DEFAULT_SCREEN_WIDTH = 600
    const isSmallWidthScreen = currentWindowWidth <= 1450

    if (isSmallWidthScreen && currentWindowWidth !== DEFAULT_SCREEN_WIDTH_FOR_SMALL_DEVICES) {
      return DEFAULT_SCREEN_WIDTH_FOR_SMALL_DEVICES
    }

    return DEFAULT_SCREEN_WIDTH
  }

  const customerAndAssigneeView = (
    <SplitPane defaultSize={getDefaultSize()} minSize={360} onChange={size => localStorage.setItem('documentPagePanelWidth', String(size))}>
      {dataPointPanel}
      {documentPanel}
    </SplitPane>
  )

  return (
    <>
      {name && (
        <Helmet>
          <title>{`${name} | Klarity`}</title>
        </Helmet>
      )}
      <div className={css.documentPage} style={{ zIndex: pathname.includes('/attachments/') ? 6000 : 1 }}>
        {headerComponent}
        <div className={css.documentPageInner}>
          <DataTableContext.Provider value={{ activeTableId, setActiveTableId }}>
            {noChecklist ? documentPanel : customerAndAssigneeView}
            {/* Below is the attachment preview overlay. It was moved here so that it can have a z-index not bound to one of the SplitPane panels */}
            <Route path={[`/deals/:dealId/attachments/:attachmentId`, `/documents/:documentId/attachments/:attachmentId`]}>
              <AttachmentOverlay />
            </Route>
          </DataTableContext.Provider>
        </div>
      </div>
    </>
  )
}
