import { debounce } from 'lodash'
import DataTableIcon from './DataTableIcon'
import DateInput from './DateInput'
import Dropdown from './Dropdown'
import FreeText from './FreeText'
import NumberInput from './NumberInput'
import Percentage from './Percentage'
import React, { KeyboardEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import type { DataPoint } from '../../graphql/codegen/schemas'

const FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE = 'This action cannot be performed as it would update a finalized deal'

export default function DatapointInput({
  dataPoint,
  value,
  setValue,
  isFocused,
  setFocused = () => null,
  editDataPointValueMutation,
  isOptionMulti,
  isExpanded,
  annotations,
  openModal,
  dealIsFinalized
}: {
  annotations: any
  dataPoint: DataPoint
  dealIsFinalized: boolean
  editDataPointValueMutation: any
  isExpanded?: boolean
  isFocused: any
  isOptionMulti: boolean
  openModal: any
  setFocused: any
  setValue: any
  value: any
}) {
  // const [isDisabled, setIsDisabled] = useState(dealIsFinalized); //converting a bool to a bool isn't necessary but setting up for the future in case there can be multiple disable triggers
  const DELAY_TO_SAVE_INPUT_VALUES = 3000
  const FINALIZED_DEAL_TOOLTIP_DELAY = 500
  const [initialValue, setInitialValue] = useState(value)
  const [shouldSaveUsingDebounce, setShouldSaveUsingDebounce] = useState(false)
  const { field_type, name, value_format } = dataPoint.data_point_field || {}

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSave(value)
    }
  }

  // removes commas from numbers represented as strings
  const getFormattedNumber = (value: string | number | null | undefined): string | number | null | undefined => {
    if (!value) return value
    return Number(String(value)?.replace(/,/g, ''))
  }

  const handleSaveDebouncedValues = useCallback(
    (currentValue: string) => {
      if (currentValue !== '' && initialValue !== null && initialValue !== undefined && (currentValue !== undefined || currentValue !== null)) {
        editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, value: currentValue } })
        setInitialValue(currentValue)
        return
      }

      if (currentValue === null) {
        setInitialValue(currentValue)
        editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, value: currentValue } })
      }
    },
    [editDataPointValueMutation, dataPoint.id, initialValue]
  )

  const handleSave = (value: string) => {
    editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, value } })
  }
  const handleListSave = (values: string[]) => {
    editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, values } })
  }

  const handleChange_OMSDD = (values: string[]) => {
    // @ts-ignore
    if (values?.length < dataPoint?.value_list?.length) {
      const removedOption =
        dataPoint?.value_list?.filter((item: any) => {
          return !values?.includes(item)
        }) || []
      const itemAnnotations =
        annotations?.filter((annotation: any) => {
          return annotation?.label_name === removedOption[0]
        }) || []
      if (itemAnnotations?.length > 0) {
        if (removedOption[0]) {
          openModal(removedOption[0], itemAnnotations?.length)
        } else {
          console.error('DatapointInput.tsx error: Option not found')
        }
      } else {
        editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, values } })
      } // delete option like normal
    } else {
      editDataPointValueMutation({ variables: { dataPointId: dataPoint.id, values } })
    } // add option like normal
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDebouncedInputValue = useCallback(debounce(handleSaveDebouncedValues, DELAY_TO_SAVE_INPUT_VALUES), [])

  // useEffect(() => { setIsDisabled(dealIsFinalized); }, [dealIsFinalized])

  useEffect(() => {
    setInitialValue(value)
  }, [value])

  useEffect(() => {
    // everytime the user is interacting with an input value
    // this piece of code should "auto save" the value on our database
    // using debounce and a delay defined in the variable DELAY_TO_SAVE_INPUT_VALUE
    const isFormattedValueEqualInitialValue = getFormattedNumber(initialValue) === getFormattedNumber(value)
    if (!shouldSaveUsingDebounce || isFormattedValueEqualInitialValue || (initialValue === '' && !!value)) return
    saveDebouncedInputValue(value)
  }, [value, saveDebouncedInputValue, initialValue, shouldSaveUsingDebounce])

  switch (field_type) {
    case 'TEXT':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'DURATION':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <FreeText
            isDisabled={dealIsFinalized}
            isFocused={isFocused}
            onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
              setShouldSaveUsingDebounce(true)
              setValue(e.currentTarget.value || null)
            }}
            onKeyDown={handleKeyDown}
            placeholder={name}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    case 'BOOLEAN':
    case 'DROP_DOWN':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Dropdown
            data_point_field={dataPoint.data_point_field}
            isDisabled={dealIsFinalized}
            isFocused={isFocused}
            onChange={v => {
              setShouldSaveUsingDebounce(false)
              setValue(v?.label || '')
              handleSave(v?.label || '')
            }}
            setFocused={setFocused}
            value={value ? { value: value.toLowerCase().replace(' ', '_'), label: value } : null}
          />
        </WithTooltip>
      )

    case 'MULTI_SELECT_DROP_DOWN':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Dropdown
            data_point_field={dataPoint.data_point_field}
            isDisabled={dealIsFinalized}
            isExpanded={isExpanded}
            isFocused={isFocused}
            isMulti
            onChange={(v: any) => {
              setShouldSaveUsingDebounce(false)
              if (isOptionMulti) {
                handleChange_OMSDD(v?.map((v: any) => v.label) || [])
              } else {
                setValue(v)
                handleListSave(v?.map((v: any) => v.label) || [])
              }
            }}
            setFocused={setFocused}
            value={dataPoint.value_list?.map((v: any) => ({ label: v, value: v }))}
          />
        </WithTooltip>
      )

    case 'PERCENTAGE':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <Percentage
            isDisabled={dealIsFinalized}
            isFocused={isFocused}
            name={name}
            onChange={e => {
              setShouldSaveUsingDebounce(true)
              setValue(e.currentTarget.value || null)
            }}
            onKeyDown={handleKeyDown}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    case 'FLOAT':
    case 'NUMBER':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <NumberInput
            isDisabled={dealIsFinalized}
            isFloat={field_type === 'FLOAT'}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              if (e?.currentTarget?.value === 'INVALID_VALUE') {
                setShouldSaveUsingDebounce(false)
                return
              }
              setShouldSaveUsingDebounce(true)
              setValue(e?.currentTarget?.value ? e?.currentTarget?.value : null)
            }}
            onKeyDown={handleKeyDown}
            placeholder={name}
            value={value}
          />
        </WithTooltip>
      )
    case 'DATA_TABLE':
      return <DataTableIcon dataPoint={dataPoint} />
    case 'DATE':
      return (
        <WithTooltip content={dealIsFinalized ? FINALIZED_DEAL_MESSAGE_ACTION_MESSAGE : ''} delay={FINALIZED_DEAL_TOOLTIP_DELAY}>
          <DateInput
            displayFormat={value_format}
            isDisabled={dealIsFinalized}
            onChange={v => {
              setShouldSaveUsingDebounce(false)
              setFocused(false)
              setValue(v || '')
              handleSave(v || '')
            }}
            setFocused={setFocused}
            value={value}
          />
        </WithTooltip>
      )

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field_type: ${field_type}`)
      }
      return null
  }
}
