import { FiChevronDown, FiChevronUp, FiDownload, FiZoomIn, FiZoomOut } from 'react-icons/fi'
import { GrDocumentPdf, GrDocumentVideo } from 'react-icons/gr'
import { downloadDocument } from '../../utils/documentApiUtils'
import Button from '../Button'
import Loader from '../Loader'
import MoreMenu from '../MoreMenu'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useGetAnnotations from '../../hooks/useGetAnnotations'
import useQString from '../../hooks/useQString'
import useUserAccess, { useIsAnnotator } from '../../hooks/useUserAccess'

interface Props {
  activeDocumentId: string | null
  currentPage: number
  // Maybe<string> similiar
  documentFormat: string
  name?: string
  setCurrentPage: Dispatch<SetStateAction<number>>
  setShowAllTags: Dispatch<SetStateAction<boolean>>
  showAllTags: boolean
  toggleDocumentFormat: () => void
  totalPages: number
  v2: boolean
  zoom: (factor: number) => void
}

export default function Toolbar({
  activeDocumentId,
  currentPage,
  documentFormat,
  name,
  setCurrentPage,
  setShowAllTags,
  showAllTags,
  toggleDocumentFormat,
  totalPages,
  v2,
  zoom
}: Props) {
  const [dlLoading, setDlLoading] = useState(false)
  const isAnnotator = useIsAnnotator()
  const hasDownloadAccess = useUserAccess({ feature: 'Document', permission: 'DOWNLOAD' })
  const { page } = useQString()

  useEffect(() => {
    const viewer = window.PdfViewer?.viewer
    if (viewer && page) {
      // prevent crashing on initial load
      // this jumps to the page specified in url params (?documentTab=...blah&page=10)
      viewer.scrollPageIntoView({ pageNumber: Number(page) })
    }
    setCurrentPage(Number(page) || 1)
  }, [page, setCurrentPage, totalPages])

  return (
    <ul className={css.toolbar}>
      {isAnnotator && (
        <>
          <li style={{ margin: '0 auto 0 8px' }}>
            <MissingAnnotations />
          </li>
          <WithTooltip content={documentFormat === 'PDF' ? 'Show Original Document' : 'Show Scanned Document'}>
            <li
              className={css.toolbarButton}
              onClick={e => {
                e.stopPropagation()
                toggleDocumentFormat()
              }}
              style={{ marginRight: '16px' }}
            >
              {documentFormat === 'PDF' ? <GrDocumentPdf /> : <GrDocumentVideo />}
            </li>
          </WithTooltip>
        </>
      )}
      {activeDocumentId && name && hasDownloadAccess && (
        <WithTooltip content={dlLoading ? 'Downloading File…' : 'Download File'}>
          <li
            className={css.toolbarButton}
            onClick={async e => {
              e.stopPropagation()
              if (!dlLoading) {
                setDlLoading(true)
                await downloadDocument(activeDocumentId, name)
                setDlLoading(false)
              }
            }}
          >
            {dlLoading ? <Loader size="s" /> : <FiDownload />}
          </li>
        </WithTooltip>
      )}
      {v2 && (
        <>
          {!!totalPages && (
            <li className={css.toolbarPages}>
              <PageNavigation currentPage={currentPage} totalPages={totalPages} />
            </li>
          )}
          <WithTooltip content={'Zoom In'}>
            <li
              className={css.toolbarButton}
              onClick={e => {
                e.stopPropagation()
                zoom(1.1)
              }}
            >
              <FiZoomIn />
            </li>
          </WithTooltip>
          <WithTooltip content={'Zoom Out'}>
            <li
              className={css.toolbarButton}
              onClick={e => {
                e.stopPropagation()
                zoom(0.9)
              }}
            >
              <FiZoomOut />
            </li>
          </WithTooltip>
          <WithTooltip content={'More Controls'}>
            <li className={css.buttonWrapper}>
              <MoreMenu
                menuItems={[
                  { label: `${showAllTags ? 'Hide' : 'Show'} all tags`, onClick: () => setShowAllTags(prev => !prev) },
                  { label: documentFormat === 'PDF' ? 'Show original document' : 'Show annotated document', onClick: toggleDocumentFormat }
                ]}
                withIcon
              />
            </li>
          </WithTooltip>
        </>
      )}
    </ul>
  )
}

function MissingAnnotations() {
  const [currentItem, setCurrentItem] = useState(0)
  const annotations = useGetAnnotations()

  const missingAnnotations = useMemo(() => {
    if (!annotations) {
      return []
    } else {
      return annotations?.filter(anno => anno?.labelType === 'MISSING_ANNO')
    }
  }, [annotations])

  const uniquePageNumbers = useMemo(() => {
    const missing = missingAnnotations?.map(anno => {
      return anno?.position?.pageNumber
    })
    // @ts-ignore
    const uniquePageNumbers = [...new Set(missing)]
    return uniquePageNumbers?.sort((a, b) => {
      if (!a || !b) {
        return 0
      } else {
        return a - b
      }
    })
  }, [missingAnnotations])

  const handleClick = () => {
    const viewer = window.PdfViewer?.viewer
    const pageNumber = uniquePageNumbers?.[currentItem]
    if (viewer && pageNumber) {
      viewer.scrollPageIntoView({ pageNumber })
    }

    const nextItem = currentItem + 1
    setCurrentItem(uniquePageNumbers && currentItem === uniquePageNumbers?.length - 1 ? 0 : nextItem)
  }

  return (
    <>
      {uniquePageNumbers?.length > 0 && (
        <div className={css.missingAnnotations}>
          <p>{`Missing Annotations: ${missingAnnotations?.length}`}</p>
          <Button onClick={handleClick} size={'s'}>
            Jump to approximate page
          </Button>
        </div>
      )}
    </>
  )
}

function PageNavigation({ currentPage, totalPages }: any) {
  const [inputValue, setInputValue] = useState(currentPage)

  const handleClick = (direction: string) => {
    const viewer = window.PdfViewer?.viewer
    if (direction === 'up') {
      viewer?.scrollPageIntoView({ pageNumber: currentPage === 1 ? 1 : currentPage - 1 })
    } else {
      viewer?.scrollPageIntoView({ pageNumber: currentPage === totalPages ? totalPages : currentPage + 1 })
    }
  }

  const handleChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') updatePageView(e)
  }

  const updatePageView = (e: any) => {
    if (e.target.value >= 1 && e.target.value <= totalPages) {
      const viewer = window.PdfViewer?.viewer
      viewer?.scrollPageIntoView({ pageNumber: Number(e.target.value) })
    } else {
      setInputValue(currentPage)
    }
  }

  useEffect(() => {
    setInputValue(currentPage)
  }, [currentPage])

  if (!currentPage) return null

  return (
    <>
      <div>
        {/* Styling handled in .toolbarPages div:first-child */}
        <p>{`Page: `}</p>
        <input onBlur={updatePageView} onChange={handleChange} onKeyDown={handleKeyDown} value={inputValue} />
        <p>{`of ${totalPages}`}</p>
      </div>

      <WithTooltip content={'Page up'}>
        <div
          className={css.toolbarButton}
          onClick={() => {
            handleClick('up')
          }}
        >
          <FiChevronUp />
        </div>
      </WithTooltip>
      <WithTooltip content={'Page down'}>
        <div
          className={css.toolbarButton}
          onClick={() => {
            handleClick('down')
          }}
        >
          <FiChevronDown />
        </div>
      </WithTooltip>
    </>
  )
}
