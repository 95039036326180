import { useCciMainContext } from '../../../../CCI_Main'
import BooleanInputs from './CreateInputs/BooleanInputs'
import Button from '../../../../../../components/Button'
import CollisionResolutionInput from './CreateInputs/CollisionResolutionInput'
import DefaultValueInput from './CreateInputs/DefaultValueInput'
import DescriptionInput from './CreateInputs/DescriptionInput'
import FieldOptions from './CreateInputs/FieldOptions'
import FieldTypeInput from './CreateInputs/FieldTypeInput'
import GroupInput from './CreateInputs/GroupInput'
import NameInput from './CreateInputs/NameInput'
import NeutralTagInput from './CreateInputs/NeutralTagInput'
import React, { useEffect, useState } from 'react'
import SourceInput from './CreateInputs/SourceInput'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'
import useUserAccess from '../../../../../../hooks/useUserAccess'

export default function CreateField() {
  const { openModal } = useCciMainContext()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)
  const booleanOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'N/A', label: 'N/A' }
  ]
  const hasChecklistCreateAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'CREATE_FIELD' })

  const handleData = (type: string, value: any) => {
    // console.info('handleData firing, type:', type, 'value:', value)
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`CreateField handleData error: Must provide a valid option`)
          return
        case 'options':
          return setValues((prev: any) => {
            return { ...prev, options: [...value] }
          })
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(values, setIsDisabled, setDisabledMessage)
  }, [values])

  // order in group somehow, maybe dropdown to select which item from that group to come after?
  // Note from Simon: By default, this field will be added to the end of the group. User the edit_data_point_field mutation to reorder the data point field

  return (
    <>
      <div className={css.editField}>
        <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{`Create Field`}</h3>
        <NameInput handleData={handleData} value={values?.name} />
        <DescriptionInput handleData={handleData} value={values?.description} />
        <GroupInput handleData={handleData} value={values?.group} />
        <SourceInput handleData={handleData} hasFullAccess={hasChecklistCreateAccess} />
        {values?.source === 'INTERNAL' && <NeutralTagInput handleData={handleData} values={values} />}
        <FieldTypeInput handleData={handleData} value={values?.field_type} />
        <FieldOptions
          defaultValue={values?.default_value || ''}
          fieldType={values?.field_type || ''}
          handleData={handleData}
          tagType={values?.tag_type}
          valueFormat={values?.value_format}
        />
        <DefaultValueInput
          defaultValue={values?.default_value || ''}
          fieldType={values?.field_type || ''}
          handleData={handleData}
          options={values?.field_type === 'BOOLEAN' ? booleanOptions : values?.options || undefined}
          valueFormat={values?.value_format || undefined}
        />
        {values?.source === 'INTERNAL' && <CollisionResolutionInput collisionType={values?.collision_type} handleData={handleData} />}
        <BooleanInputs handleData={handleData} />
      </div>
      <div className={css.modalButtonRow}>
        {/* <Button onClick={()=>{console.info('values', values)}} variant={'secondary'}>Console log values</Button> */}
        <WithTooltip content={disabledMessage}>
          <Button
            disabled={isDisabled}
            onClick={() => {
              openModal({ menuOption: 'Review New Field', content: { dataPointName: values?.name, values } })
            }}
          >
            {`Review New Field`}
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}

const validateInputs = (values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  if (!values?.name) {
    errors.push(`Name`)
  }
  if (!values?.group) {
    errors.push(`Group`)
  }
  if (!values?.source) {
    errors.push(`Source`)
  }
  if (values?.source === 'INTERNAL' && !values?.collision_type) {
    errors.push(`Collision Type is required for INTERNAL Sources`)
  }
  if (values?.tag_type !== 'option_level' && values?.source === 'INTERNAL' && !values?.neutral_tag) {
    errors.push(`Neutral Tag is required for INTERNAL Sources`)
  }
  if (values?.tag_type === 'option_level' && values?.source === 'INTERNAL') {
    const missingTags =
      values?.options?.filter((item: any) => {
        if (!item?.neutral_tag) {
          return true
        } else {
          return false
        }
      }) || []
    if (missingTags.length > 0) {
      errors.push(`Each option must be assigned a Neutral Tag`)
    }
  }
  if (!values?.field_type) {
    errors.push(`Field Type`)
  }
  if ((values?.field_type === 'DROP_DOWN' || values?.field_type === 'MULTI_SELECT_DROP_DOWN') && (!values?.options?.length || values?.options?.length < 1)) {
    errors.push(`Dropdown and Multi-Dropdowns must have at least one option`)
  }
  if (values?.field_type === 'DATE' && !values?.value_format) {
    errors.push(`Value Format is required when Field Type is DATE`)
  }

  // no duplicate options
  if (values?.options?.length) {
    const formattedValues =
      values?.options?.map((item: any) => {
        return item?.option
      }) || []

    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === '@@_DELETE_THIS_VALUE') {
          return false
        }

        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const duplicates = formattedValues?.filter((newValue?: any) => {
          if (item === newValue) {
            return true
          } else {
            return false
          }
        })
        if (duplicates?.length > 1) {
          return true
        } else {
          return false
        }
      }) || []
    if (duplicateTags.length > 0) {
      errors.push(`Options must be unique`)
    }
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}
