import { useHistory } from 'react-router'
import React, { useEffect } from 'react'
import oktaClient from '../../utils/oktaClient'

export default function Logout() {
  const history = useHistory()

  const handleLogout = () => {
    deleteAuthCookies()
    deleteLocalStorageAuthItems()
    setTimeout(() => {
      history.push('/login')
    }, 1000)
  }

  // eslint-disable-next-line
  useEffect(() => { handleLogout(); }, [])

  return (
    <div id={'root-loader'}>
      <div />
    </div>
  ) // Full screen loading overlay
}

const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const deleteAuthCookies = () => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookieName = cookies[i].split('=')[0]
    if (cookieName?.includes('okta-oauth-state') || cookieName?.includes('okta-oauth-nonce') || cookieName?.includes('okta-oauth-redirect-params')) {
      deleteCookie(cookieName)
    }
  }
}

export const deleteLocalStorageAuthItems = async () => {
  await oktaClient.tokenManager.clear()
  localStorage.removeItem('okta-token-storage')
  localStorage.removeItem('okta-cache-storage')
  localStorage.removeItem('cid') // Remove selected customer id
}
