import { FiTrash2 } from 'react-icons/fi'
import { useCciMainContext } from '../../../../CCI_Main'
import Button from '../../../../../../components/Button'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import css from '../../style.module.scss'

export default function CCI_LeftPanel_IntegrationCard({ integration }: any) {
  const { openModal, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()

  // this is a hack to get this out the door for cloudflare. Currently there can only be one integration
  // it automatically sets the first (only) option as the selected item for Edit
  useEffect(() => {
    setSelectedItem(integration)
    setActiveComponent('Edit Integration')
    // eslint-disable-next-line
    }, [])

  return (
    <div
      className={clsx(css.field, selectedItem?.node?.id === integration?.node?.id && css.isFieldActive)}
      onClick={() => {
        setSelectedItem(integration)
        setActiveComponent('Edit Integration')
      }}
    >
      <div className={clsx(css.labelRow, css.noSelect)}>
        <div style={{ display: 'flex' }}>
          <h4>{`Integration Type:`}</h4>
          <p>{`${integration?.node?.integration_type}`}</p>
        </div>
        <ul className={css.actions}>
          <li>
            <Button
              icon={<FiTrash2 />}
              onClick={() => {
                openModal({ menuOption: 'Delete Integration' })
              }}
            />
          </li>
        </ul>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`Token Type:`}</h4>
        <p>{`${integration?.node?.token_type}`}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`API URL:`}</h4>
        <p>{`${integration?.node?.api_url}`}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`User Name:`}</h4>
        <p>{`${integration?.node?.user_name}`}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`Domain:`}</h4>
        <p>{`${integration?.node?.domain}`}</p>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`Organization Id:`}</h4>
        <p>{`${integration?.node?.organization_id}`}</p>
      </div>
    </div>
  )
}
