import './scss/index.scss'
import { API_ROOT, OKTA_LOGIN_REDIRECT_PATH } from './utils/apiUtils'
import { CurrentUserDocument } from './graphql/codegen/hooks'
import { getAccessToken, getCurrentUserInApolloFormat } from './utils/sessionApiUtils'
import Admin from './pages/Admin'
import App from './app'
import React from 'react'
import ReactDOM from 'react-dom'
import apolloClient from './utils/apolloClient'
import axios from 'axios'
import oktaClient from './utils/oktaClient'

function removeLoader() {
  window.requestAnimationFrame(() => {
    const loader = document.getElementById('root-loader')
    if (!loader) {
      return
    }
    loader.addEventListener('transitionend', () => {
      loader.remove()
    })
    loader.classList.add('-remove')
  })
}

async function loadApp() {
  const root = document.getElementById('revrec-app-root')
  // Check for okta redirect, bootstrap accessToken and redirect before rendering app
  const { pathname } = window.location
  if (pathname === OKTA_LOGIN_REDIRECT_PATH) {
    try {
      const tokenObj = await oktaClient.token.parseFromUrl()

      if (tokenObj?.tokens) {
        // This will allow us to call oktaClient later to fetch user email
        await oktaClient.tokenManager.setTokens(tokenObj.tokens)

        const from = window.localStorage.getItem('from')
        window.localStorage.removeItem('from')

        // Redirect to previous route or dashboard
        return window.location.replace(from || '/')
      }
    } catch (e) {
      console.error(e)
    }
  }

  const currentCustomer = localStorage.getItem('cid')
  const accessToken = await getAccessToken()
  if (!currentCustomer && accessToken) {
    const res = await axios.get(`${API_ROOT}/user/customers`, { headers: { Authorization: `Bearer: ${accessToken}` } })
    const { customers, is_super_admin } = res.data

    if (is_super_admin) {
      ReactDOM.render(<Admin />, root, removeLoader)
      return
    } else {
      localStorage.setItem('cid', customers[0].id)
    }
  }

  // Bootstrap current user in Apollo cache
  const currentUserInApolloFormat = await getCurrentUserInApolloFormat()
  apolloClient.writeQuery({ query: CurrentUserDocument, data: { current_user: currentUserInApolloFormat } })

  ReactDOM.render(<App apolloClient={apolloClient} />, root, removeLoader)
}

loadApp()
