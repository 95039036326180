import { Box, InputAdornment, TextField } from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import SearchIcon from '@mui/icons-material/Search'

// types

interface SearchBarProps {
  isDisabled?: boolean
  placeholder?: string
  setFilter: Dispatch<SetStateAction<string>>
  value?: string
}

// components

export const SearchBar: FC<SearchBarProps> = ({
  isDisabled = false,
  placeholder = isDisabled ? 'Loading checklist items, please wait…' : 'Search checklist items…',
  value,
  setFilter
}) => (
  <Box sx={{ p: 2 }}>
    <TextField
      InputProps={{
        autoComplete: 'off',
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        sx: { fontSize: 13 }
      }}
      disabled={isDisabled}
      fullWidth
      onChange={({ currentTarget: { value } }) => setFilter(value)}
      placeholder={placeholder}
      size="small"
      type="search"
      value={value}
    />
  </Box>
)
