import { AdvancedDashboardDocument, useDocumentTypesQuery, usePreAnnotationFormMutation } from '../../graphql/codegen/hooks'
import { FiInfo } from 'react-icons/fi'
import { Formik } from 'formik'
import { getAssignedToMeFilters } from '../../hooks/useAssignedToMe'
import { useHistory, useParams } from 'react-router'
import Button from '../Button'
import DocumentPayload from '../DocumentPayload'
import React, { ReactElement, ReactNode } from 'react' // TODO - React is required in this file - investigate why
import SelectInput from '../SelectInput'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// @@refactor- this component needs classes set up, way too much inline styling making it difficult to read

export default function PreAnnotationChecklist() {
  const history = useHistory()
  const { documentId } = useParams<{ documentId: string }>()
  const currentUser = useCurrentUser()

  const [preAnnotationFormMutation, { loading }] = usePreAnnotationFormMutation({
    context: { queryName: `PreAnnotationChecklist.tsx preAnnotationFormMutation` },
    onCompleted: ({ pre_annotation_form }) => {
      const mutationSuccessful = pre_annotation_form?.__typename === 'PreAnnotationFormSuccess' // @ts-ignore
      const isDeleted = pre_annotation_form?.document?.is_deleted || undefined // @ts-ignore
      const internalAssignee = pre_annotation_form?.document?.internal_assignee?.id || undefined
      if (mutationSuccessful && (isDeleted || internalAssignee?.id !== currentUser?.id)) {
        history.push('/')
      }
    },
    refetchQueries: [
      {
        query: AdvancedDashboardDocument,
        variables: getAssignedToMeFilters({ currentUser }),
        context: { queryName: `PreAnnotationChecklist.tsx AdvancedDashboardDocument` }
      }
    ]
  })

  return (
    <div className={css.panel} style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <Formik
          initialValues={{ isAttachment: false, isSignature: null, signatureBoundingBox: null, documentTypeId: null }}
          onSubmit={async values => {
            const { isAttachment, isSignature } = values
            let shouldSubmit = true
            // eslint-disable-next-line
            if (isAttachment === true || isSignature === false) { shouldSubmit = await confirm('Are you sure? This will discard the document from the document list.'); }
            if (shouldSubmit) {
              preAnnotationFormMutation({ variables: { documentId, ...values } })
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            const { documentTypeId, isAttachment, isSignature } = values
            const canSubmit = isAttachment === true || isSignature === false || documentTypeId
            return (
              <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ padding: 16 }}>
                  <h2>{`Pre-annotation checklist`}</h2>
                  <p style={{ marginTop: 8 }}>{`Before annotating this document, please complete the following review.`}</p>
                  <DocumentPayload documentId={documentId} />
                </div>
                <ul style={{ padding: 16 }}>
                  <Item
                    description="Should this document just be included in the review process, or should it be marked as an attachment?"
                    icon={<FiInfo />}
                    label="Attachment"
                  >
                    <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                      <input
                        checked={isAttachment === false}
                        id="isNoAttachment"
                        name="isAttachment"
                        onChange={() => setFieldValue('isAttachment', false)}
                        type="radio"
                        value="isNoAttachment"
                      />
                      <label htmlFor="isNoAttachment" style={{ marginLeft: 8 }}>{`Document is not an attachment`}</label>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                      <input
                        checked={isAttachment === true}
                        id="isAttachment"
                        name="isAttachment"
                        onChange={() => setFieldValue('isAttachment', true)}
                        type="radio"
                        value="isAttachment"
                      />
                      <label htmlFor="isAttachment" style={{ marginLeft: 8 }}>{`Discard as attachment`}</label>
                    </div>
                  </Item>
                  <div
                    style={{
                      maxHeight: isAttachment === false ? 260 : 0,
                      overflow: isAttachment === false ? 'visible' : 'hidden',
                      transition: 'all 200ms ease-in-out',
                      opacity: isAttachment === false ? 1 : 0
                    }}
                  >
                    <Item label="Signature">
                      <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                        <input
                          checked={isSignature === true}
                          id="isSignature"
                          name="isSignature"
                          onChange={() => setFieldValue('isSignature', true)}
                          type="radio"
                          value="isSignature"
                        />
                        <label htmlFor="isSignature" style={{ marginLeft: 8 }}>{`Signature is present`}</label>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                        <input
                          checked={isSignature === false}
                          id="isNoSignature"
                          name="isSignature"
                          onChange={() => setFieldValue('isSignature', false)}
                          type="radio"
                          value="isNoSignature"
                        />
                        <label htmlFor="isNoSignature" style={{ marginLeft: 8 }}>{`Discard as unsigned`}</label>
                      </div>
                    </Item>
                    <div
                      style={{
                        maxHeight: isSignature ? 260 : 0,
                        overflow: isSignature ? 'visible' : 'hidden',
                        transition: 'all 200ms ease-in-out',
                        opacity: isSignature ? 1 : 0
                      }}
                    >
                      <Item label="Document type">
                        <DocTypeSelector
                          onChange={(v: { value: string } | null) => {
                            setFieldValue('documentTypeId', v?.value || null)
                          }}
                        />
                      </Item>
                    </div>
                  </div>
                </ul>
                <div style={{ padding: '16px' }}>
                  <Button disabled={!canSubmit} loading={loading} style={{ width: '100%' }} variant={(isAttachment || !isSignature) && 'danger'}>
                    {isAttachment ? 'Discard as attachment' : !isSignature ? 'Discard as unsigned' : 'Submit'}
                  </Button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

const Item = ({ children, description, icon, label }: { children: ReactNode; description?: string; icon?: ReactElement; label: string }) => {
  return (
    <li className={css.preAnnotationItem}>
      <div className={css.preAnnotationTitleContainer}>
        <p className={css.preAnnotationItemLabel}>{label}</p>
        {description && (
          <WithTooltip content={description}>
            <>{icon}</>
          </WithTooltip>
        )}
      </div>
      <div className={css.preAnnotationItemContent}>{children}</div>
    </li>
  )
}

Item.defaultProps = {
  icon: <FiInfo />
}

const DocTypeSelector = ({ isError, onChange }: { isError?: boolean; onChange: any }) => {
  const { data: docTypesData, loading: docTypesLoading } = useDocumentTypesQuery({ context: { queryName: `PreAnnotationChecklist.tsx useDocumentTypesQuery` } })
  const docTypeOptions = docTypesData?.document_types?.edges?.map(edge => {
    const { id, name } = edge?.node || {}
    return { label: name, value: id }
  })

  return (
    <SelectInput
      isClearable
      isError={isError}
      isLoading={docTypesLoading}
      menuPlacement="top"
      onChange={onChange}
      options={docTypeOptions}
      placeholder="Select document type"
    />
  )
}
