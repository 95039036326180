import { CciDealDataPointFieldsDocument, useCreateDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { getFieldTypeLabel } from '../../../../../utils/cci'
import { useCciMainContext } from '../../../CCI_Main'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function ReviewCreation({ closeModal, values }: { closeModal?: any; values?: any }) {
  const { setActiveComponent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [createDataPointFieldMutation, { error: createDPFError, loading: createDPFLoading }] = useCreateDataPointFieldMutation({
    context: { queryName: `ReviewCreation.tsx createDataPointFieldMutation` },
    onCompleted: response => {
      setActiveComponent('')
      setMessageTitle(response.create_data_point_field!.data_point_field!.name)
      setModalSuccess('Field successfully created.')
      setSelectedItem('')
    },
    refetchQueries: [{ query: CciDealDataPointFieldsDocument }]
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (createDPFLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [createDPFLoading])

  useEffect(() => {
    if (createDPFError) {
      setMessageTitle('Error creating field:')
      setModalError(createDPFError)
    }
    // eslint-disable-next-line
    }, [createDPFError])

  const handleSubmit = () => {
    const localValues = { ...values }
    const options = formatOptions()

    delete localValues.options
    delete localValues.tag_type

    // console.info('submitted values:', {options, ...localValues})
    createDataPointFieldMutation({ variables: { options, ...localValues } })
  }

  const formatOptions = () => {
    if (!values?.options?.length) {
      return undefined
    }

    // strip option level neutral tags if tag type is field level
    if (values?.tag_type !== 'option_level') {
      return JSON.stringify(
        values?.options?.map((item: any) => {
          return { option: item?.option }
        })
      )
    } else {
      return JSON.stringify(values?.options)
    }
  }

  return (
    <>
      {values?.name && (
        <div className={css.reviewDiv}>
          <h4>{`Name:`}</h4>
          <p>{values?.name}</p>
        </div>
      )}
      {values?.description && (
        <div className={css.reviewDiv}>
          <h4>{`Description:`}</h4>
          <p>{values?.description}</p>
        </div>
      )}
      {values?.group && (
        <div className={css.reviewDiv}>
          <h4>{`Group:`}</h4>
          <p>{values?.group}</p>
        </div>
      )}
      {values?.source && (
        <div className={css.reviewDiv}>
          <h4>{`Source:`}</h4>
          <p>{values?.source}</p>
        </div>
      )}
      {values?.neutral_tag && (
        <div className={css.reviewDiv}>
          <h4>{`Neutral Tag:`}</h4>
          <p>{values?.neutral_tag}</p>
        </div>
      )}
      {values?.field_type && (
        <div className={css.reviewDiv}>
          <h4>{`Field Type:`}</h4>
          <p>{getFieldTypeLabel(values.field_type)}</p>
        </div>
      )}
      {values?.options && (
        <div className={css.reviewDiv}>
          <h4>{`Options:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.options?.map((item: any, index: number) => {
              return (
                <div className={css.reviewOptions} key={item?.option + index}>
                  <p style={{ width: '100%' }}>{item?.option}</p>
                  {item?.neutral_tag && <p style={{ width: '100%' }}>{`Neutral Tag: ${item?.neutral_tag}`}</p>}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {values?.value_format && (
        <div className={css.reviewDiv}>
          <h4>{`Value Format:`}</h4>
          <p>{values?.value_format}</p>
        </div>
      )}
      {values?.default_value && (
        <div className={css.reviewDiv}>
          <h4>{`Default Value:`}</h4>
          <p>{values?.default_value}</p>
        </div>
      )}
      {values?.collision_type && (
        <div className={css.reviewDiv}>
          <h4>{`Collision Type:`}</h4>
          <p>{values?.collision_type}</p>
        </div>
      )}
      {values?.resolution_strategy && (
        <div className={css.reviewDiv}>
          <h4>{`Resolution Strategy:`}</h4>
          <p>{values?.resolution_strategy}</p>
        </div>
      )}
      <div className={css.reviewDiv}>
        <h4>{`Display If Empty:`}</h4>
        <p>{values?.display_if_empty ? 'True' : 'False'}</p>
      </div>
      <div className={css.reviewDiv}>
        <h4>{`Display On Document:`}</h4>
        <p>{values?.display_on_document ? 'True' : 'False'}</p>
      </div>
      <div className={css.reviewDiv}>
        <h4>{`Display Accounting Impact:`}</h4>
        <p>{values?.display_accounting_impact ? 'True' : 'False'}</p>
      </div>
      <div className={css.reviewDiv}>
        <h4>{`Default Field On Dashboard:`}</h4>
        <p>{values?.default_field_on_dashboard ? 'True' : 'False'}</p>
      </div>

      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
