import { getFieldTypeLabel } from '../../../../../utils/cci'
import { useCciMainContext } from '../../../CCI_Main'
import { useEditDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function ReviewEdits({
  closeModal,
  dataPointId,
  dataPointName,
  selectedItem,
  values
}: {
  closeModal: () => void
  dataPointId?: string
  dataPointName?: string
  selectedItem?: any
  values?: any
}) {
  const { setActiveComponent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [editDataPointFieldMutation, { error: editDPFError, loading: editDPFLoading }] = useEditDataPointFieldMutation({
    context: { queryName: `ReviewEdits.tsx editDataPointFieldMutation` },
    onCompleted: response => {
      setActiveComponent('')
      setMessageTitle(response.edit_data_point_field!.data_point_field!.name)
      setModalSuccess('Field successfully updated.')
      setSelectedItem('')
    }
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (editDPFLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [editDPFLoading])

  useEffect(() => {
    if (editDPFError) {
      setMessageTitle('Error updating field:')
      setModalError(editDPFError)
    }
    // eslint-disable-next-line
    }, [editDPFError])

  const handleSubmit = () => {
    const localValues = { ...values }

    const options = formatOptions()
    delete localValues.options
    delete localValues.deleted_options

    const resolution_strategy = validateResolutionStrategy()
    delete localValues.resolution_strategy

    const booleans = formatBooleanInputs()
    delete localValues.display_if_empty
    delete localValues.display_on_document
    delete localValues.display_accounting_impact
    delete localValues.default_field_on_dashboard

    // console.info('submitted values:', {data_point_field_id: dataPointId, options, resolution_strategy, ...localValues, ...booleans})
    editDataPointFieldMutation({ variables: { data_point_field_id: dataPointId, options, resolution_strategy, ...localValues, ...booleans } })
  }

  const formatOptions = () => {
    // remove deleted new options
    const formattedOptions =
      values?.options?.filter((item: any) => {
        if (!item?.currentValue && item?.newValue === '@@_DELETE_THIS_ITEM') {
          return false
        } else {
          return true
        }
      }) || []
    const formattedDeletedOptions =
      values?.deleted_options?.filter((item: any) => {
        if (!item?.currentValue && item?.newValue === '@@_DELETE_THIS_ITEM') {
          return false
        } else {
          return true
        }
      }) || []

    const combinedOptions = [...formattedOptions, ...formattedDeletedOptions]

    return combinedOptions?.length ? JSON.stringify(combinedOptions) : undefined
  }

  const formatBooleanInputs = () => {
    const localObject: any = {}
    // push if not null. if null dont push
    if (selectedItem?.display_if_empty !== values?.display_if_empty) {
      localObject.display_if_empty = values?.display_if_empty
    }
    if (selectedItem?.display_on_document !== values?.display_on_document) {
      localObject.display_on_document = values?.display_on_document
    }
    if (selectedItem?.display_accounting_impact !== values?.display_accounting_impact) {
      localObject.display_accounting_impact = values?.display_accounting_impact
    }
    if (selectedItem?.default_field_on_dashboard !== values?.default_field_on_dashboard) {
      localObject.default_field_on_dashboard = values?.default_field_on_dashboard
    }
    return localObject
  }

  const validateResolutionStrategy = () => {
    const currentStrat = selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.resolution_strategy : ''
    if (currentStrat?.length > 0 && values?.resolution_strategy?.length === currentStrat?.length) {
      for (let i = 0; i < currentStrat?.length; i++) {
        if (currentStrat[i] !== values?.resolution_strategy[i]) {
          return currentStrat // return new strat if the values dont match
        }
      }
      return null // return null if the arrays are the same- this tells the backend to not update anything
    } else {
      return values?.resolution_strategy
    }
  }

  return (
    <>
      <h5 style={{ textAlign: 'center', marginBottom: '32px' }}>{dataPointName}</h5>

      {values?.name && (
        <div className={css.reviewDiv}>
          <h4>{`Name:`}</h4>
          <p>{values?.name}</p>
        </div>
      )}
      {values?.description && (
        <div className={css.reviewDiv}>
          <h4>{`Description:`}</h4>
          <p>{values?.description}</p>
        </div>
      )}
      {values?.group && (
        <div className={css.reviewDiv}>
          <h4>{`Group:`}</h4>
          <p>{values?.group}</p>
        </div>
      )}
      {values?.source && (
        <div className={css.reviewDiv}>
          <h4>{`Source:`}</h4>
          <p>{values?.source}</p>
        </div>
      )}
      {values?.neutral_tag && (
        <div className={css.reviewDiv}>
          <h4>{`Neutral Tag:`}</h4>
          <p>{values?.neutral_tag}</p>
        </div>
      )}
      {values?.field_type && (
        <div className={css.reviewDiv}>
          <h4>{`Field Type:`}</h4>
          <p>{getFieldTypeLabel(values.field_type)}</p>
        </div>
      )}
      {values?.options && (
        <div className={css.reviewDiv}>
          <h4>{`Options:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.options?.map((item: any, index: number) => {
              if (!item?.newValue) {
                return null
              }
              return (
                <div className={css.reviewOptions} key={item?.currentValue + item?.newValue + index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    {item?.currentValue ? (
                      <>
                        {item?.newValue !== '@@_DELETE_THIS_ITEM' && (
                          <>
                            <p style={{ width: '100%' }}>{`${item?.currentValue} has been renamed to: ${item?.newValue}`}</p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <p style={{ width: '100%' }}>{`New Option: ${item?.newValue}`}</p>
                        {item?.neutralTag && <p style={{ width: '100%' }}>{`Neutral Tag: ${item?.neutralTag}`}</p>}
                      </>
                    )}
                  </div>
                </div>
              )
            })}
            {values?.deleted_options?.length > 0 && (
              <>
                <h5 style={{ textAlign: 'left' }}>{`Deleted Options:`}</h5>
                {values?.deleted_options?.map((item: any, index: number) => {
                  return (
                    <p key={item?.option + index} style={{ width: '100%', textAlign: 'right' }}>
                      {item?.currentValue}
                    </p>
                  )
                })}
              </>
            )}
          </div>
        </div>
      )}
      {selectedItem?.option_to_neutral_tag_mapping && values?.deleted_options?.length > 0 && (
        <h6 style={{ textAlign: 'center' }}>{`Warning: Annotations may be permanently deleted when deleting options with a neutral tag.`}</h6>
      )}
      {values?.value_format && values?.value_format !== '@@_DELETE_THIS_ITEM' && (
        <div className={css.reviewDiv}>
          <h4>{`Value Format:`}</h4>
          <p>{values?.value_format}</p>
        </div>
      )}
      {values?.default_value && (
        <div className={css.reviewDiv}>
          <h4>{`Default Value:`}</h4>
          <p>{values?.default_value}</p>
        </div>
      )}
      {values?.collision_type && (
        <div className={css.reviewDiv}>
          <h4>{`Collision Type:`}</h4>
          <p>{values?.collision_type}</p>
        </div>
      )}
      {values?.resolution_strategy && (
        <div className={css.reviewDiv}>
          <h4>{`Resolution Strategy:`}</h4>
          <p>{values?.resolution_strategy}</p>
        </div>
      )}
      {(values?.display_if_empty === true || values?.display_if_empty === false) && (
        <div className={css.reviewDiv}>
          <h4>{`Display If Empty:`}</h4>
          <p>{values?.display_if_empty ? 'True' : 'False'}</p>
        </div>
      )}
      {(values?.display_on_document === true || values?.display_on_document === false) && (
        <div className={css.reviewDiv}>
          <h4>{`Display On Document:`}</h4>
          <p>{values?.display_on_document ? 'True' : 'False'}</p>
        </div>
      )}
      {(values?.display_accounting_impact === true || values?.display_accounting_impact === false) && (
        <div className={css.reviewDiv}>
          <h4>{`Display Accounting Impact:`}</h4>
          <p>{values?.display_accounting_impact ? 'True' : 'False'}</p>
        </div>
      )}
      {(values?.default_field_on_dashboard === true || values?.default_field_on_dashboard === false) && (
        <div className={css.reviewDiv}>
          <h4>{`Default Field On Dashboard:`}</h4>
          <p>{values?.default_field_on_dashboard ? 'True' : 'False'}</p>
        </div>
      )}

      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
