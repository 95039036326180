import { Column, DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { FiGlobe } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { ModalContext } from '../../app'
import { formatDataPointId } from '../../utils/fieldUtils'
import { formatDate } from '../../utils/datetimeUtils'
import { generateDashboardColumns, generateSortDirection, generateSortFunction } from '../../utils/dashboardColumnUtils'
import { hideFileExtension } from '../../utils/stringUtils'
import {
  useAdvancedDashboardLazyQuery,
  useBulkEditDocumentAssigneeMutation,
  useBulkEditInternalAssigneeMutation,
  useDocumentCountLazyQuery
} from '../../graphql/codegen/hooks'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import AddDocToDeal from '../ModalOptions/AddDocToDeal'
import BulkEditAssigneeButton from '../../containers/BulkEditAssigneeButton'
import BulkEditStatusButton from '../../containers/BulkEditStatusButton'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import ConvertDocumentLevel from '../ModalOptions/ConvertDocumentLevel'
import DeleteDocument from '../ModalOptions/DeleteDocument'
import Modal from '../Modal'
import MoreMenu from '../MoreMenu'
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import Table from '../Table'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useTablePagination from '../../hooks/useTablePagination'
import useUserAccess, { useIsAnnotator, useIsSuperAnnotator } from '../../hooks/useUserAccess'
// import BulkAddDocumentsToDealButton from '../../containers/BulkAddDocumentsToDealButton';

// functions

const getAccessor = (value: string) => {
  switch (value) {
    case '_id':
      return 'document_id'
    default:
      return value
  }
}

// components

export default function DocumentList({
  dispatch,
  selectedColumns,
  setClearAll,
  setFilteredCount,
  setTotalCount
}: {
  dispatch: Dispatch<DocumentQueryAction>
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
  totalCount?: number
}) {
  const isAnnotator = useIsAnnotator()
  const isSuperAnnotator = useIsSuperAnnotator()
  const isKlarityEmployee = useIsKlarityEmployee()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()

  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })
  // const hasDealUpdateAccess = useUserAccess({feature: 'Deal', permission: 'UPDATE'});
  const canChangeDocumentCounterparty = useUserAccess({ feature: 'Document', permission: 'UPDATE_COUNTERPARTY' })
  const canDeleteDocument = useUserAccess({ feature: 'Document', permission: 'DELETE' })
  const hasAccountLevelDocumentAccess = useUserAccess({ feature: 'Document', permission: 'ACCOUNT_LEVEL' })

  const [advancedDashboardLazyQuery, { data: dataAdvanced, loading: loadingAdvanced }] = useAdvancedDashboardLazyQuery({
    context: { queryName: `DocumentList.tsx getDashboardData` },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
    // these fetch policies force the first query of the page to be from the network, then to prefer the cache afterwards.
    // this is needed in order to have the search re-fire when the search button is pressed.
  })
  const [documentCountLazyQuery, { data: dataCount, loading: loadingCount }] = useDocumentCountLazyQuery({
    variables: { ...gqlFilters },
    context: { queryName: `DocumentList.tsx useDocumentCountQuery` },
    fetchPolicy: 'network-only'
  })

  const loading = loadingAdvanced || loadingCount

  useEffect(() => {
    if (pageLength) {
      advancedDashboardLazyQuery({ variables: { ...gqlFilters, size: pageLength, offset } })
    }

    // eslint-disable-next-line
  }, [pageLength, gqlFilters, offset])
  useEffect(() => {
    // ONLY recount if the search changed!
    documentCountLazyQuery({ variables: gqlFilters })
  }, [gqlFilters, documentCountLazyQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataAdvanced) return []
    const dashboardData = dataAdvanced.fetch_advanced_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      const data_points = row.data_points.reduce((acc: any, curr: any) => {
        const id = formatDataPointId(curr)
        acc[id] = curr
        return acc
      }, {})
      return { ...row, ...data_points, created_at: row.created_at, counterPartyId: JSON.parse(row?.counter_party?.value)?.id, state: row.status }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataAdvanced])

  // Take selectedColumns from reducer, format for react table
  // The table response does not return which values have been sorted by, so we add it into the column data here.
  const dynamicColumns = useMemo(
    () =>
      (selectedColumns || []).map(c => {
        if (!c) return null

        let sortFunction = null
        let sortDirection: -1 | 0 | 1 = 0

        if (c.data_point_field_id) {
          sortDirection = generateSortDirection(sortConfig, 'sort_data_points', c.data_point_field_id)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_data_points', selectedColumns, c.data_point_field_id)
        } else if (c.value === 'created_at' || c.value === 'unresolved_comments_count') {
          sortDirection = generateSortDirection(sortConfig, `sort_${c.value}`)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, `sort_${c.value}`, selectedColumns)
        } else if (c.value === 'status' || c.value === 'state') {
          sortDirection = generateSortDirection(sortConfig, 'sort_state')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_state', selectedColumns)
        } else if (c.value === 'assignee') {
          sortDirection = generateSortDirection(sortConfig, 'sort_assignee_user_name')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_assignee_user_name', selectedColumns)
        }

        return {
          ...c,
          Header: c.label,
          accessor: getAccessor(c.value),
          sortFunction,
          sortDirection,
          draggable: true,
          Cell: ({ row, value }: any) => {
            if (!value) return null
            let displayValue = value?.value || null

            // format value for special columns
            if (c.data_type === 'MULTI_SELECT_DROP_DOWN' && value && value.value) {
              // Response is a stringified array with single quotes, so this parses it into a list
              try {
                const withQuotesReplaced = value.value.split("'").join('"')
                displayValue = JSON.parse(withQuotesReplaced).join(', ')
              } catch (error) {
                console.error(error)
              }
            } else if (c.value === 'assignee') {
              displayValue = value.value?.user_name
            } else if (c.value === 'all_assignees') {
              displayValue = value.reduce((acc: string, current: any, idx: number) => {
                if (idx !== 0) {
                  acc += ', '
                }
                acc += current.value.user_name
                return acc
              }, '')
            } else if (value?.value && c.value === 'integration_type') {
              const userName = row?.original?.created_by?.value?.user_name
              const uploadDate = row?.original?.created_at ? formatDate(row?.original?.created_at?.value) : undefined
              const uploadedBy = userName?.includes('klaritylaw.com') ? (isKlarityEmployee ? userName : 'Klarity') : userName
              const tooltipContent = (
                <>
                  {value?.value === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
                  {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
                </>
              )
              displayValue = <WithTooltip content={tooltipContent}>{value?.value}</WithTooltip>
            }

            return displayValue || null // || null is extremely important. If a Cell return is a falsey value that is not null it will crash the frontend.
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns, history, sortConfig]
  )

  const getSelectionActionsTemplate = () => {
    // Only show option to update status if no deal access (our flag for a document-only customer)
    // if (hasDealUpdateAccess) {
    //   return (props: any) => {
    //     return <BulkAddDocumentsToDealButton selectedFlatRows={props.selectedFlatRows} />;
    //   };
    // } else
    if (!hasDealAccess || isRapid7LegalTeam || isSuperAnnotator) {
      // eslint-disable-next-line react/display-name
      return (props: any) => {
        return (
          props?.selectedFlatRows?.length > 0 && (
            <>
              {!hasDealAccess || isRapid7LegalTeam ? (
                <>
                  <BulkEditStatusButton resourceName="document" selectedFlatRows={props?.selectedFlatRows} />
                  <BulkEditAssigneeButton
                    bulkEditAssigneeMutation={useBulkEditDocumentAssigneeMutation}
                    entityName="Assignee"
                    resourceName="document"
                    selectedFlatRows={props?.selectedFlatRows}
                  />
                </>
              ) : isSuperAnnotator ? (
                <BulkEditAssigneeButton
                  bulkEditAssigneeMutation={useBulkEditInternalAssigneeMutation}
                  entityName="Assignee"
                  resourceName="document"
                  selectedFlatRows={props?.selectedFlatRows}
                />
              ) : null}
            </>
          )
        )
      }
    }
  }

  // #region Modal state/functions
  const { openModal, openPreview } = useContext(ModalContext)
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<{
    counterpartyId?: string
    documentAlias?: string
    documentId: string
    documentName: string
    documentTypeId?: string
    isAccountLevel?: boolean
  }>()

  const openLocalModal = (
    menuOption: string,
    documentId: string,
    documentName: string,
    documentAlias?: string,
    counterpartyId?: string,
    isAccountLevel?: boolean
  ) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ documentId, documentName, documentAlias, counterpartyId, isAccountLevel })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(undefined)
      setModalLoading(true)
      setLoadingMessage('')
    }
  }

  const getModalOptions = ({ counterpartyId, counterpartyName, documentAlias, documentId, documentName, is_account_level }: any) => {
    const modalOptions: any = [
      // { label: 'Mark as', onClick: () => { }},
      // { label: 'Update Document Type', onClick: () => { }},
      { label: 'Add to Deal…', onClick: () => openLocalModal('Add to Deal', documentId, documentName, documentAlias, counterpartyId, is_account_level) },
      { label: 'Rename Document', onClick: () => openLocalModal('Rename Document', documentId, documentName, documentAlias, counterpartyId) },
      canChangeDocumentCounterparty && {
        label: `Change Document's Customer`,
        onClick: () => {
          openModal('ChangeCounterparty', `Change Document's Customer`, {
            itemType: 'Document',
            itemName: documentAlias || documentName,
            documentId,
            counterpartyId,
            counterpartyName
          })
        }
      },
      hasAccountLevelDocumentAccess &&
        is_account_level && {
          label: 'Remove Document from Customer Level',
          onClick: () => openLocalModal('Change Document Level', documentId, documentName, documentAlias, counterpartyId, is_account_level)
        },
      hasAccountLevelDocumentAccess &&
        !is_account_level && {
          label: 'Convert Document to Customer Level',
          onClick: () => openLocalModal('Change Document Level', documentId, documentName, documentAlias, counterpartyId, is_account_level)
        },
      canDeleteDocument && {
        label: 'Delete Document',
        onClick: () => openLocalModal('Delete Document', documentId, documentName, documentAlias, counterpartyId)
      }
      // { label: 'Remove from Deal…', onClick: () => { }},
    ]
    return [...modalOptions]
  }
  // #endregion

  const annotatorStaticColumns = !isAnnotator
    ? []
    : generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
        {
          headerName: 'Annotation Status',
          accessor: 'internal_state',
          disableSort: true,
          Cell: ({ value }: any) => {
            if (!value) {
              return null
            } else {
              return value.value || null
            }
          }
        },
        {
          headerName: 'Annotation Assignee',
          accessor: 'internal_assignee',
          disableSort: true,
          Cell: ({ value }: any) => {
            if (!value || !value?.value) {
              return 'Unassigned'
            } else {
              return value?.value?.user_name ? value.value.user_name : 'Error: missing user_name'
            }
          }
        }
      ])

  // these columns will always be shown and cannot be reordered.
  const staticColumns = [
    ...generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
      {
        headerName: 'Document',
        accessor: 'document',
        sortStringName: 'document_name',
        Cell: ({ row, value }: any) => {
          if (!value) return null
          const documentId = value?.id
          const documentName = hideFileExtension(value?.value_original || value?.value)
          const documentAlias = hideFileExtension(value?.value_alias)
          const counterpartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id
          const counterpartyName = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.name
          const hasAnnotatorAccess = isAnnotator && row.original?.extraction_status !== 'PROCESSING'
          const isProcessed = row.original?.processed !== false
          const is_account_level = !!row?.original?.is_counter_party_level
          const menuItems = [
            { label: 'Preview', onClick: () => openPreview(documentId, true, documentAlias || documentName) },
            ...(hasAnnotatorAccess || isProcessed
              ? getModalOptions({
                  documentId,
                  documentName: value?.value,
                  documentAlias: value?.value_alias,
                  counterpartyId,
                  is_account_level,
                  counterpartyName
                })
              : [])
          ]

          return (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-16px' }} title={documentAlias ? `Original Name: ${documentName}` : documentName}>
              <MoreMenu isFixed menuItems={menuItems} offset={[8, 8]} placement="auto" withIcon />
              {is_account_level && (
                <WithTooltip className={css.accountLevelTooltip} content="Customer Level Document">
                  <FiGlobe />
                </WithTooltip>
              )}
              {hasAnnotatorAccess || isProcessed ? <Link to={`/documents/${value?.id}`}>{documentAlias || documentName}</Link> : documentAlias || documentName}
            </div>
          )
        }
      },
      {
        headerName: 'Customer',
        accessor: 'counter_party',
        sortStringName: 'counter_party_name',
        Cell: ({ value }: any) => {
          const parsedCounterParty = value?.value && JSON.parse(value.value)

          return !parsedCounterParty?.name || parsedCounterParty.name === 'UNASSIGNED' ? '' : parsedCounterParty.name
        }
      }
    ]),
    ...annotatorStaticColumns
  ]

  return (
    <>
      <Table
        {...paginationControls}
        columns={[...staticColumns, ...dynamicColumns]}
        dispatch={dispatch}
        dummyDataActive={loading || !dataCount}
        loading={loading || !dataCount}
        pageLength={pageLength}
        rowCount={rowCount}
        selectionActionsTemplate={getSelectionActionsTemplate()}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          {modalOption !== 'Rename Document' && modalOption !== 'Change Document Level' && modalOption !== 'Delete Document' && (
            <h4>{modalContent?.documentAlias || modalContent?.documentName}</h4>
          )}
          {modalOption === 'Add to Deal' && (
            <AddDocToDeal
              closeModal={closeModal}
              counterpartyId={modalContent?.counterpartyId}
              documentId={modalContent?.documentId}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Rename Document' && (
            <RenameFile
              closeModal={closeModal}
              documentId={modalContent?.documentId}
              fileAlias={modalContent?.documentAlias}
              fileName={modalContent?.documentName}
              fileType={'Document'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Delete Document' && (
            <DeleteDocument
              closeModal={closeModal}
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              document_id={modalContent?.documentId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          )}
          {modalOption === 'Change Document Level' && ( // @ts-ignore
            <ConvertDocumentLevel
              closeModal={closeModal}
              counterpartyId={modalContent?.counterpartyId}
              // @ts-ignore
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              // @ts-ignore
              document_id={modalContent?.documentId}
              isAccountLevel={modalContent?.isAccountLevel}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
              setModalTitle={setModalTitle}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
