import { ArrowUp } from 'react-feather'
import React, { ReactNode, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useStickyColumnWidths from '../../../hooks/useStickyColumnWidths'

interface Props {
  children: ReactNode
  draggable?: boolean
  handleColumnSwap: any
  hasCheckboxRow: boolean
  id: string
  idx: number
  isResizing: boolean
  loading?: boolean
  setActiveDragColumnIdxs: any
  sortDirection?: any
  sortFunction?: () => void
  width?: number | string
}

export default function ColumnHeader({
  children,
  draggable,
  handleColumnSwap,
  hasCheckboxRow,
  id,
  idx,
  isResizing,
  loading,
  setActiveDragColumnIdxs,
  sortDirection,
  sortFunction,
  width
}: Props) {
  const [isDragOver, setIsDragOver] = useState(false)
  useStickyColumnWidths({ id, width, isResizing })

  return (
    <>
      {idx === 0 && hasCheckboxRow ? (
        children // this fixes an issue where the top left checkbox styling was off due to being wrapped in these spans. hasCheckbox row was needed because when the row was not there it messed up the first column header styling
      ) : (
        <span
          className={clsx(css.columnHeader, {
            [css.filterable]: !!sortFunction,
            [css.filterIsActive]: sortDirection === 1 || sortDirection === -1,
            [css.isDragOver]: isDragOver
          })}
          draggable={loading ? false : draggable}
          onClick={sortFunction}
          onDragEnd={handleColumnSwap}
          onDragLeave={() => {
            setIsDragOver(false)
          }}
          onDragOver={() => {
            if (draggable && isDragOver === false) {
              setIsDragOver(true)
              setActiveDragColumnIdxs((prev: string[]) => [prev[0], idx])
            }
          }}
          onDragStart={() => {
            setActiveDragColumnIdxs([idx, null])
          }}
        >
          <span>{children}</span>
          {sortFunction && (
            <span className={clsx(css.filterIcon, { [css.desc]: sortDirection === 1, [css.asc]: sortDirection === -1 })}>
              <ArrowUp />
              <ArrowUp />
            </span>
          )}
        </span>
      )}
    </>
  )
}
