import { ApolloError } from '@apollo/client'
import { BiGitCompare } from 'react-icons/bi'
import { Column } from '../../../reducers/dashboardQueryReducer'
import { Info } from 'react-feather'
import { formatFieldList } from '../../../utils/fieldUtils'
import {
  // useDashboardExportLazyQuery,
  useDataCompletionLazyQuery,
  useDealsDashboardFieldsQuery
} from '../../../graphql/codegen/hooks'
import { useFormik } from 'formik'
import Button from '../../Button'
import Modal from '../../Modal'
import React, { createContext, useContext, useMemo } from 'react'
import SelectInput from '../../SelectInput'
import Tippy from '@tippyjs/react'
import WithTooltip from '../../WithTooltip'
import useGetExcludedFields from '../../../hooks/useGetExcludedFields'
// import useDashboardQString from '../../../hooks/useDashboardQString'
import { DashboardExportQuery } from '../../../graphql/codegen/operations'
import { Opening, useOpening } from '@klarity/use-opening'

// types

type DashboardTab = `Deals`

type DashboardType = `DEAL`

interface DataCompletenessCheckProps {
  dashboardTab: DashboardTab
  dashboardType: DashboardType
  selectedColumns: Column[]
}

interface DataCompletenessCheckStateProps {
  data?: DashboardExportQuery
  error?: ApolloError
  loading: boolean
}

interface Errors {
  comparisonField?: string
  comparisonFieldValuesBlob?: string
  isExportAllFields?: string
}

interface LocalContextInterface {
  dashboardTab: DashboardTab
  dashboardType: DashboardType
  opening: Opening
  selectedColumns: Column[]
}

interface Values {
  comparisonField: { label: string; value: string } | null
  comparisonFieldValuesBlob: string
  isExportAllFields: boolean
}

// context

const LocalContext = createContext<LocalContextInterface | null>(null)

// hooks

const useLocalContext = () => {
  const context = useContext(LocalContext)

  if (context === null) {
    throw new Error()
  }

  return context
}

// components

const DataCompletenessCheckForm = () => {
  // const { gqlFilters: { filters } } = useDashboardQString()
  const [dataCompletion, { data, error, loading }] = useDataCompletionLazyQuery({ fetchPolicy: `no-cache` })
  const { data: dealsDashboardFieldsData } = useDealsDashboardFieldsQuery()
  const {
    dashboardTab
    // dashboardType, selectedColumns
  } = useLocalContext()

  const excludedFields = useGetExcludedFields(dashboardTab, `FieldSelector`)

  const formik = useFormik({
    initialValues: { comparisonField: null, comparisonFieldValuesBlob: ``, isExportAllFields: false },

    onSubmit: ({ comparisonField, comparisonFieldValuesBlob }: Values) =>
      dataCompletion({
        variables: {
          comparisonFieldId: comparisonField!.value.match(/(?:.*__)?(.*)/)![1],
          comparisonFieldValues: comparisonFieldValuesBlob.trim().split(/\r?\n/)
        }
      }),

    validate: (values: Values) => {
      const comparisonFieldValuesBlobTrimmed = values.comparisonFieldValuesBlob.trim()
      const errors: Errors = {}

      if (!values.comparisonField!.value.match(/(?:.*__)?(.*)/)?.[1]) errors.comparisonField = `Required.`

      if (!comparisonFieldValuesBlobTrimmed || !comparisonFieldValuesBlobTrimmed.split(/\r?\n/).length) errors.comparisonFieldValuesBlob = `Required.`

      return errors
    },

    validateOnBlur: false,
    validateOnChange: false
  })

  const { errors, getFieldProps, handleSubmit, setFieldValue, values } = formik

  // const chosenDataPointFieldIds = useMemo(() => selectedColumns.map(selectedColumn => selectedColumn.data_point_field_id), [selectedColumns])

  const options = useMemo(
    () =>
      dealsDashboardFieldsData
        ? Object.values(formatFieldList(dealsDashboardFieldsData, excludedFields))
            .filter(field => field.data_type === `TEXT`)
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
    [dealsDashboardFieldsData, excludedFields]
  )

  if (data || error || loading) return <DataCompletenessCheckState data={data} error={error} loading={loading} />

  return (
    <form onSubmit={handleSubmit}>
      <p style={{ margin: '-4px 0 36px', textAlign: 'center' }}>Download a spreadsheet verifying Klarity’s integration of your data.</p>

      <div style={{ margin: `24px 0` }}>
        <label style={{ alignItems: `baseline`, display: `flex`, gap: 16, justifyContent: `space-between`, whiteSpace: `nowrap` }}>
          <span>
            <span>Comparison Field</span>

            <Tippy content="Select the field containing the unique ID for each item.">
              <Info style={{ height: 16, marginLeft: 6, position: 'relative', top: 2, width: 16 }} />
            </Tippy>
          </span>

          <span style={{ minWidth: 300 }}>
            <SelectInput
              onChange={(labelValue: { label: string; value: string }) => setFieldValue(`comparisonField`, labelValue)}
              options={options}
              placeholder="Select…"
              value={values.comparisonField}
            />
          </span>
        </label>
      </div>

      {errors.comparisonField && (
        <div className="error" style={{ marginTop: -18, textAlign: `right` }}>
          {errors.comparisonField}
        </div>
      )}

      <div style={{ margin: `24px 0` }}>
        <label style={{ alignItems: `baseline`, display: `flex`, gap: 16, justifyContent: `space-between` }}>
          <span style={{ whiteSpace: `nowrap` }}>
            <span>Comparison Values</span>

            <Tippy content="Paste the space-separated unique IDs of the items to be checked.">
              <Info style={{ height: 16, marginLeft: 6, position: 'relative', top: 2, width: 16 }} />
            </Tippy>
          </span>

          <textarea {...getFieldProps(`comparisonFieldValuesBlob`)} spellCheck={false} style={{ fontFamily: `Open Sans`, minWidth: 300, padding: 6 }} />
        </label>
      </div>

      {errors.comparisonFieldValuesBlob && (
        <div className="error" style={{ marginTop: -18, textAlign: `right` }}>
          {errors.comparisonFieldValuesBlob}
        </div>
      )}

      {/* <div style={{ alignItems: `baseline`, display: `flex`, gap: 16, justifyContent: `space-between`, margin: `12px 0 30px` }}>
        <span>What other fields would you like to export?</span>

        <span style={{ display: `flex` }}>
          <label style={{ alignItems: `baseline`, display: `flex`, justifyContent: `space-between` }}>
            <input {...getFieldProps(`isExportAllFields`)} checked={!values.isExportAllFields} onChange={()=>setFieldValue(`isExportAllFields`, false)} type="radio" />

            <span style={{ marginLeft: 6 }}>Selected</span>
          </label>

          <label style={{ marginLeft: 12 }}>
            <input {...getFieldProps(`isExportAllFields`)} checked={values.isExportAllFields} onChange={()=>setFieldValue(`isExportAllFields`, true)} type="radio" />

            <span style={{ marginLeft: 6 }}>All</span>
          </label>
        </span>
      </div> */}

      <div style={{ display: `flex`, flexDirection: `row-reverse`, marginTop: 30 }}>
        <Button htmlType="submit">Check</Button>
      </div>
    </form>
  )
}

const DataCompletenessCheckModal = () => {
  const { opening } = useLocalContext()

  return (
    <Modal isOpen={opening.isOpen} onRequestClose={opening.close} title="Data Completeness Check">
      <DataCompletenessCheckForm />
    </Modal>
  )
}

const DataCompletenessCheckState = ({ data, error, loading }: DataCompletenessCheckStateProps) => {
  const { opening } = useLocalContext()

  return (
    <>
      <div style={{ textAlign: `center` }}>
        {data ? <p>Submitted.</p> : loading ? <p>Submitting…</p> : ``}

        {data && <p style={{ marginTop: 16 }}>You will be notified by email when your spreadsheet is ready.</p>}

        {error && <p>Error{error.message ? `: ${error.message}` : `.`}</p>}
      </div>

      <Button disabled={loading} onClick={opening.close} style={{ margin: `32px auto 0` }} variant="primary">
        Close
      </Button>
    </>
  )
}

export const DataCompletenessCheck = ({ dashboardTab, dashboardType, selectedColumns }: DataCompletenessCheckProps) => {
  const opening = useOpening()

  const context = useMemo(() => ({ dashboardTab, dashboardType, opening, selectedColumns }), [dashboardTab, dashboardType, opening, selectedColumns])

  return (
    <LocalContext.Provider value={context}>
      {opening.isOpen && <DataCompletenessCheckModal />}

      <WithTooltip content="Data completeness check">
        <Button icon={<BiGitCompare />} onClick={opening.toggle} style={{ marginRight: 4 }} variant="primary" />
      </WithTooltip>
    </LocalContext.Provider>
  )
}
