import { useCciMainContext } from '../../CCI_Main'
import React, { useEffect } from 'react'
import TabSelector from './TabSelector/TabSelector'
import clsx from 'clsx'
// import CCI_RightPanel_Controls from './RightPanel_controls/CCI_RightPanel_Controls';
import CCI_RightPanel_Content from './CCI_RightPanel_Content'
import css from './style.module.scss'

export default function CCI_RightPanel() {
  const { setActiveComponent, tab } = useCciMainContext()

  // switch to default component when changing tabs.
  // or just set it to '' and render in a default at '' for each tab?
  useEffect(() => {
    switch (tab) {
      case 'checklist':
      case 'integrations':
      case 'roles':
      case 'users':
        setActiveComponent('')
        break
    }
    // eslint-disable-next-line
  }, [tab])

  return (
    <div className={css.documentPanel}>
      {/* <CCI_RightPanel_Controls /> */}
      {/* fake div below to add drop shadow effect to navbar */}
      <div className={clsx(css.documentSelector, css.dropShadow)}>
        <div className={clsx(css.inner, css.dropShadow)} style={{ height: '1px' }} />
      </div>
      <div className={clsx(css.documentWrapper, css.withSelector)}>
        <CCI_RightPanel_Content />
      </div>
      <TabSelector tab={tab} />
    </div>
  )
}
