import {
  NotesDataDocument,
  NotesTabCountDocument,
  useCommentsDataQuery,
  useCreateDocumentNoteMutation,
  useDeleteDocumentNoteMutation,
  useNotesDataQuery
} from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import NoteSection from '../NoteSection'
import React, { Dispatch, SetStateAction, useEffect } from 'react'

// types

interface DocumentNotesTabProps {
  setActiveTab: Dispatch<SetStateAction<string>>
}

// components

const DocumentNotesTab = ({ setActiveTab }: DocumentNotesTabProps) => {
  const { documentId } = useParams<{ documentId: string }>()

  const { data: commentsData } = useCommentsDataQuery({ variables: { resourceId: documentId } })
  const [createDocumentNoteMutation, documentNoteState] = useCreateDocumentNoteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: NotesDataDocument, variables: { resourceId: documentId } },
      { query: NotesTabCountDocument, variables: { resourceId: documentId } }
    ]
  })
  const [deleteDocumentNoteMutation] = useDeleteDocumentNoteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: NotesDataDocument, variables: { resourceId: documentId } },
      { query: NotesTabCountDocument, variables: { resourceId: documentId } }
    ]
  })
  const { data: notesData } = useNotesDataQuery({ variables: { resourceId: documentId } })

  useEffect(() => {
    setActiveTab(`notes`)

    return () => setActiveTab(`default`)
  }, [setActiveTab])

  const handleCreate = (text: string) => createDocumentNoteMutation({ variables: { documentId, text } })
  const handleDelete = (noteId: string) => deleteDocumentNoteMutation({ variables: { documentId, noteId } })

  const comments = commentsData?.comments_data?.map(comment => ({ ...comment, parentDataPoint: comment?.data_point }))
  const isLoading = !(commentsData && notesData)
  const notes = notesData?.notes_data

  const combined = comments && notes && [...comments, ...notes].sort((a: any, b: any) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))

  return <NoteSection commentState={documentNoteState} comments={combined} handleCreate={handleCreate} handleDelete={handleDelete} loading={isLoading} />
}

export default DocumentNotesTab
