import { useHistory, useParams } from 'react-router-dom'
import { useIsAnnotator } from '../../../hooks/useUserAccess'
import Lifecycle from '../../../components/Lifecycle'
import PageHeader from '../../../components/PageHeader'
import React from 'react'
import css from './style.module.scss'
import useIsRapid7LegalTeam from '../../../hooks/useIsRapid7LegalTeam'

export default function DealPageHeader({
  alias,
  assigneeTo,
  counter_party,
  created_at,
  dealIsFinalized,
  name,
  state
}: {
  alias?: string
  assigneeTo?: string
  counter_party: any
  created_at: any
  dealIsFinalized: boolean
  name?: string
  state: any
}) {
  const history = useHistory()
  const { dealId } = useParams<{ dealId: string }>()
  const isAnnotator = useIsAnnotator()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  return (
    <div className={css.documentPageHeader}>
      <PageHeader
        alias={alias}
        breadcrumbSource="Deals"
        contentRight={
          <div>
            {assigneeTo && (
              <div>
                <span className={css.assignedTo}> Assigned to: {assigneeTo} </span>
              </div>
            )}
            <Lifecycle created_at={created_at} dealId={dealId} state={state} />
          </div>
        }
        counterpartyId={counter_party?.id}
        dealIsFinalized={dealIsFinalized}
        hasDealAccess={true}
        name={name}
        onBack={() => {
          history.push({ pathname: isAnnotator || isRapid7LegalTeam ? '/dashboard/deals' : '/', search: '' })
        }}
        subTitle={counter_party?.name}
      />
    </div>
  )
}
