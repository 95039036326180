import { CCI_LeftPanel } from './components/LeftPanel/CCI_LeftPanel'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import CCI_RightPanel from './components/RightPanel/CCI_RightPanel'
import ComponentLoadingOverlay from '../../components/ComponentLoadingOverlay'
import DeleteField from './components/Modal_components/ChecklistTab/DeleteField'
import DeleteIntegration from './components/Modal_components/IntegrationsTab/DeleteIntegration'
import DeleteUser from './components/Modal_components/UsersTab/DeleteUser'
import EditNeutralTag from './components/Modal_components/ChecklistTab/EditNeutralTag'
import MessageModal from '../../components/ModalOptions/MessageModal'
import Modal from '../../components/Modal'
import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState } from 'react'
import ReviewCreation from './components/Modal_components/ChecklistTab/ReviewCreation'
import ReviewDocTypeEdits from './components/Modal_components/ChecklistTab/ReviewDocTypeEdits'
import ReviewEdits from './components/Modal_components/ChecklistTab/ReviewEdits'
import ReviewIntegrationEdits from './components/Modal_components/IntegrationsTab/ReviewIntegrationEdits'
import ReviewNewIntegration from './components/Modal_components/IntegrationsTab/ReviewNewIntegration'
import ReviewNewUser from './components/Modal_components/UsersTab/ReviewNewUser'
import ReviewUserEdits from './components/Modal_components/UsersTab/ReviewUserEdits'
import SplitPane from 'react-split-pane'
import css from './style.module.scss'

// types

interface CciMainContextInterface {
  activeComponent: string
  closeModal: any
  contentData: any
  leftpanelLoading: boolean
  modalContent: any
  openModal: any
  selectedItem: any
  setActiveComponent: Dispatch<SetStateAction<string>>
  setContentData: Dispatch<SetStateAction<any>>
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setLeftpanelLoading: Dispatch<SetStateAction<boolean>>
  setMessageTitle: Dispatch<SetStateAction<string>>
  setModalContent: Dispatch<SetStateAction<any>>
  setModalError: Dispatch<SetStateAction<any>>
  setModalLoading: Dispatch<SetStateAction<boolean>>
  setModalLoadingMessage: Dispatch<SetStateAction<string>>
  setModalOption: Dispatch<SetStateAction<string>>
  setModalSuccess: Dispatch<SetStateAction<any>>
  setModalTitle: Dispatch<SetStateAction<string>>
  setSelectedItem: Dispatch<SetStateAction<any>>
  tab?: string
}

// context

const CciMainContext = createContext<CciMainContextInterface | null>(null)

// hooks

export const useCciMainContext = () => {
  const context = useContext(CciMainContext)

  if (context === null) {
    throw new Error()
  }

  return context
}

// components

export default function CCI_Main() {
  const { tab } = useParams<{ tab?: string }>()
  const [selectedItem, setSelectedItem] = useState<any>('')
  const [activeComponent, setActiveComponent] = useState('')
  const [contentData, setContentData] = useState<any>('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalContent, setModalContent] = useState<any>({})
  const [modalTitle, setModalTitle] = useState('')
  const [modalOption, setModalOption] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalError, setModalError] = useState<any>('')
  const [modalSuccess, setModalSuccess] = useState<any>('')
  const [messageTitle, setMessageTitle] = useState<any>('')
  const [leftpanelLoading, setLeftpanelLoading] = useState<boolean>(true)

  const handleClose = () => {
    setIsOpen(false)
    // hides form when clicking off-modal to cancel delete action
    if (activeComponent === 'Delete Field') {
      setActiveComponent('')
      setSelectedItem('')
    }
  }

  const openModal = ({ content, menuOption }: { content?: any; menuOption: string }) => {
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent(content ? { ...content } : undefined)
    setIsOpen(true)
  }

  const closeDeleteModal = () => {
    closeModal()
    setActiveComponent('')
    setSelectedItem('')
  }

  const closeModal = useCallback(() => {
    if (!modalLoading) {
      setModalOption('')
      setModalTitle('')
      setModalContent(null)
      setModalLoading(false)
      setModalError('')
      setModalSuccess('')
      setMessageTitle('')
      setIsOpen(false)
    }
  }, [modalLoading])

  const context = useMemo(
    () => ({
      activeComponent,
      closeModal,
      contentData,
      leftpanelLoading,
      modalContent,
      openModal,
      selectedItem,
      setActiveComponent,
      setContentData,
      setIsOpen,
      setLeftpanelLoading,
      setMessageTitle,
      setModalContent,
      setModalError,
      setModalLoading,
      setModalLoadingMessage,
      setModalOption,
      setModalSuccess,
      setModalTitle,
      setSelectedItem,
      tab: tab?.toLowerCase()
    }),
    [activeComponent, closeModal, contentData, leftpanelLoading, modalContent, selectedItem, tab]
  )

  return (
    <>
      {
        <Helmet>
          <title>{`Configuration | Klarity`}</title>
        </Helmet>
      }
      <CciMainContext.Provider value={context}>
        <div className={css.documentPage}>
          <div className={css.documentPageInner}>
            <SplitPane
              defaultSize={parseInt(localStorage.getItem('CCI_PanelWidth') || '600', 10)}
              minSize={360}
              onChange={size => localStorage.setItem('CCI_PanelWidth', String(size))}
            >
              <CCI_LeftPanel />
              <CCI_RightPanel />
            </SplitPane>
          </div>
        </div>
        <Modal isOpen={isOpen} onRequestClose={handleClose} title={messageTitle ? '' : modalTitle}>
          <ComponentLoadingOverlay loading={modalLoading} message={modalLoadingMessage} />
          {modalSuccess ? (
            <MessageModal closeModal={closeModal} message={modalSuccess} messageTitle={messageTitle || modalContent?.dataPointName || ''} />
          ) : modalError ? (
            <MessageModal closeModal={closeModal} message={modalError} messageTitle={messageTitle || modalContent?.dataPointName || 'Error'} />
          ) : (
            <>
              {modalOption === 'Delete Field' && (
                <DeleteField closeModal={closeDeleteModal} dataPointId={modalContent?.dataPointID} dataPointName={modalContent?.dataPointName} />
              )}
              {modalOption === 'Review Edits' && (
                <ReviewEdits
                  closeModal={closeModal}
                  dataPointId={modalContent?.dataPointID}
                  dataPointName={modalContent?.dataPointName}
                  values={modalContent?.values}
                />
              )}
              {modalOption === 'Review New Field' && <ReviewCreation closeModal={closeModal} values={modalContent?.values} />}
              {modalOption === 'Review Document Type Edits' && <ReviewDocTypeEdits />}
              {modalOption === 'Edit Neutral Tag' && <EditNeutralTag />}

              {modalOption === 'Delete Integration' && <DeleteIntegration closeModal={closeModal} />}
              {modalOption === 'Review New Integration' && <ReviewNewIntegration closeModal={closeModal} values={modalContent?.values} />}
              {modalOption === 'Review Integration Edits' && <ReviewIntegrationEdits closeModal={closeModal} values={modalContent?.values} />}

              {modalOption === 'Delete User' && <DeleteUser closeModal={closeModal} userId={modalContent?.userId} userName={modalContent?.userName} />}
              {modalOption === 'Review New User' && <ReviewNewUser closeModal={closeModal} values={modalContent?.values} />}
              {modalOption === 'Review User Edits' && (
                <ReviewUserEdits closeModal={closeModal} userName={modalContent?.userName} user_id={modalContent?.userId} values={modalContent?.values} />
              )}
            </>
          )}
        </Modal>
      </CciMainContext.Provider>
    </>
  )
}
