// enums

export enum FieldTypes {
  FLOAT = 'FLOAT',
  NUMBER = 'NUMBER'
}

export enum FieldTypeLabels {
  FLOAT = 'NUMBER (DECIMAL)',
  NUMBER = 'NUMBER (NON-DECIMAL)'
}

// constants

const FIELD_TYPE_LABEL = new Map([
  [FieldTypes.FLOAT, FieldTypeLabels.FLOAT],
  [FieldTypes.NUMBER, FieldTypeLabels.NUMBER]
])

// functions

export const getFieldTypeLabel = (selectedValue: string | undefined, currentValue?: string) =>
  selectedValue
    ? FIELD_TYPE_LABEL.has(selectedValue as FieldTypes)
      ? FIELD_TYPE_LABEL.get(selectedValue as FieldTypes)
      : selectedValue
    : currentValue
    ? FIELD_TYPE_LABEL.has(currentValue as FieldTypes)
      ? FIELD_TYPE_LABEL.get(currentValue as FieldTypes)
      : currentValue
    : 'Select Field Type'
