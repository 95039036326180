import { UnregisterCallback } from 'history'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

// https://gist.github.com/sibelius/60a4e11da1f826b8d60dc3975a1ac805
export default (when: boolean, message: string = 'Are you sure you want to leave without saving your changes?') => {
  const history = useHistory()

  const self = useRef<null | UnregisterCallback>(null)

  const onWindowOrTabClose = (event: BeforeUnloadEvent) => {
    if (!when) {
      return
    }

    if (event) {
      event.returnValue = message
    }

    return message
  }

  useEffect(() => {
    if (when) {
      self.current = history.block(message)
    } else {
      self.current = null
    }

    window.addEventListener('beforeunload', onWindowOrTabClose)

    return () => {
      if (self.current) {
        self.current()
        self.current = null
      }

      window.removeEventListener('beforeunload', onWindowOrTabClose)
    }
  }, [message, when]); // eslint-disable-line
}
