import { Row } from 'react-table'
import { useBulkEditDealStateMutation, useBulkEditDocumentStateMutation } from '../graphql/codegen/hooks'
import Button from '../components/Button'
import Modal from '../components/Modal'
import React, { useEffect, useState } from 'react'
import StatusSelector from '../components/StatusSelector'

export default function BulkEditStatusButton({ resourceName, selectedFlatRows }: { resourceName: 'document' | 'deal'; selectedFlatRows: Row<any>[] }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState('')
  const [resourceId, setResourceId] = useState('')
  const { length } = selectedFlatRows

  const [bulkEditDealStatus, { loading: bulkEditDealLoading }] = useBulkEditDealStateMutation({
    context: { queryName: 'BulkEditStatusButton.ts useBulkEditDealStateMutation' },
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setSelected('')
      setIsOpen(false)
    }
  })
  const [bulkEditDocumentStatus, { loading: bulkEditDocLoading }] = useBulkEditDocumentStateMutation({
    context: { queryName: 'BulkEditStatusButton.ts useBulkEditDocumentStateMutation' },
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setSelected('')
      setIsOpen(false)
    }
  })

  useEffect(() => {
    if (selectedFlatRows?.length > 0) {
      setResourceId(selectedFlatRows[0]?.values?.document?.id)
    }
  }, [selectedFlatRows])

  const handleUpdate = () => {
    if (resourceName === 'deal') {
      bulkEditDealStatus({ variables: { dealIds: selectedFlatRows.map((r: any) => r.values.document.id), stateId: selected } })
    }
    if (resourceName === 'document') {
      bulkEditDocumentStatus({ variables: { documentIds: selectedFlatRows.map((r: any) => r.values.document.id), stateId: selected } })
    }
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)} variant="primary">{`Edit Status`}</Button>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Edit Status">
        <div style={{ marginBottom: '8px' }}>{`Edit status for ${length} ${resourceName}${length === 1 ? '' : 's'} to:`}</div>
        <StatusSelector
          dealId={resourceName === 'deal' ? resourceId : undefined}
          documentId={resourceName === 'document' ? resourceId : undefined}
          onChange={({ value }: { value: string }) => {
            setSelected(value)
          }}
        />

        <div style={{ display: 'flex', marginTop: '16px' }}>
          <Button disabled={!selected || bulkEditDealLoading || bulkEditDocLoading} loading={bulkEditDealLoading || bulkEditDocLoading} onClick={handleUpdate}>
            {`Edit Status`}
          </Button>
          <Button onClick={() => setIsOpen(false)} variant="secondary">{`Cancel`}</Button>
        </div>
      </Modal>
    </>
  )
}
