import 'tippy.js/dist/tippy.css'
import { FiChevronLeft, FiChevronRight, FiGlobe, FiLoader, FiXSquare } from 'react-icons/fi'
import { GoPrimitiveDot } from 'react-icons/go'
import { Link } from 'react-router-dom'
import { MdOutlineDocumentScanner } from 'react-icons/md'
import { ModalContext } from '../../app'
import { hideFileExtension } from '../../utils/stringUtils'
import { useHistory } from 'react-router'
import Button from '../Button'
import FreeText from '../DatapointInput/FreeText'
import MoreMenu from '../MoreMenu'
import React, { CSSProperties, KeyboardEvent, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'

export default function InlineQueryMenu({
  clearSelectedValue,
  dealCounterpartyId,
  existingData,
  getMaxHeight,
  handleChange,
  isError,
  isMulti,
  keepOpen,
  loading,
  menuItemClass,
  mutationVars,
  queryData,
  queryFunction,
  queryItemName,
  queryName,
  queryNoResultsMessage,
  queryPlaceholder,
  querySubName,
  queryVars,
  resetOffset,
  selectedItems,
  setClearSelectedValue,
  setResetOffset,
  style
}: Props) {
  const history = useHistory()
  const menuRef = useRef<HTMLDivElement | null>(null)
  const [initialRender, setInitialRender] = useState<boolean>(true)
  const [customStyles, setCustomStyles] = useState<any>({})
  const [isVisible, setIsVisible] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [menuItems, setMenuItems] = useState<Array<Item>>([])
  const [offset, setOffset] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(true)
  const [buttonWasClicked, setButtonWasClicked] = useState(false)
  const [closeInput, setCloseInput] = useState<any>(undefined)

  useEffect(() => {
    if (!isMulti && !selectedValue) {
      setSelectedValue(existingData)
    }
    // eslint-disable-next-line
  }, [])

  // this disables the prev button and sets the offset to zero when the queryFunction query is fired from the parent component
  // an example of this is when setting the include deals/docs from all customers flag from add doc to deal to true the query re-fires and the buttons should be reset to 0.
  useEffect(() => {
    if (!initialRender && resetOffset) {
      setOffset(0)
      setPrevButtonDisabled(true)
      setResetOffset(false)
    }
    // eslint-disable-next-line
  }, [resetOffset])

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
    } else {
      if (isFocused || (!initialRender && loading)) {
        setIsVisible(true)
      } else if (!buttonWasClicked) {
        setCloseInput(
          setTimeout(function () {
            setSearchTerm('')
            setSearchValue('')
            setIsVisible(false)
            if (checkForRequiredValues(queryVars)) {
              queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm: '', offset: 0, size: 20 } }) // reset options when clicking outside of the field
            }
          }, 150) // timeout is because it was closing so fast menuItem onclicks couldnt fire, also to allow buttonWasClicked to have enough time to cancel the timeout and keep the input open
        )
      }
      if (buttonWasClicked && closeInput) {
        clearTimeout(closeInput)
        setFocused(true)
        setButtonWasClicked(false)
      }
    }
    // eslint-disable-next-line
  }, [isFocused, buttonWasClicked])

  useEffect(() => {
    if (clearSelectedValue) {
      setSelectedValue('')
      setClearSelectedValue(false)
    }
  }, [clearSelectedValue, setClearSelectedValue])

  useEffect(() => {
    if (getMaxHeight) {
      const parentHeight = getMaxHeight()
      const heightOffset = 190 - 16
      const paginationOffset = 44
      const includeDocumentsRowOffset = 69
      const calculatedHeight =
        prevButtonDisabled && nextButtonDisabled ? parentHeight - heightOffset : parentHeight - heightOffset - paginationOffset - includeDocumentsRowOffset
      setCustomStyles({ maxHeight: `${calculatedHeight}px` })
    }
    // eslint-disable-next-line
  }, [getMaxHeight, prevButtonDisabled, nextButtonDisabled])

  useEffect(() => {
    // May need to add additional map functions/if statements to support different query types
    const items: Array<Item> = []
    let totalItemCount = 0
    if (queryName === 'CCI Neutral Tags') {
      if (searchTerm) {
        items.push({
          label: `Create "${searchTerm}"`,
          onClick: () => {
            handleChange(searchTerm, searchTerm)
          }
        })
      }
      queryData?.cci_neutral_tags?.forEach((item: any) => {
        if (item?.external_name && items.length < 10) {
          items.push({
            label: item?.external_name,
            id: item?.internal_name,
            in_neutral_tagset: item?.in_neutral_tagset,
            onClick: () => {
              handleChange(item?.external_name, item?.internal_name)
            }
          })
        }
      })
      totalItemCount = queryData?.cci_neutral_tags?.length
    } else if (queryName === 'Counterparties by Name') {
      if (searchTerm) {
        if (querySubName === 'Change Counterparty') {
          items.push({
            label: `Create "${searchTerm}"`,
            onClick: () => {
              handleChange(undefined, searchTerm)
            }
          })
        } else {
          items.push({
            label: `Create "${searchTerm}"`,
            onClick: () => {
              handleChange('counterparty_name', searchTerm)
            }
          })
        }
      }
      queryData?.counter_parties_by_name?.forEach((item: any) => {
        if (item?.name) {
          if (querySubName === 'Change Counterparty') {
            items.push({
              label: item?.name,
              id: item?.id,
              onClick: () => {
                handleChange({ id: item?.id, name: item?.name }, '')
              }
            })
          } else {
            items.push({
              label: item?.name,
              id: item?.id,
              onClick: () => {
                handleChange('counterparty_name', item?.name)
                handleChange('counterparty_id', item?.id)
              }
            })
          }
        }
      })
      totalItemCount = queryData?.counter_parties_by_name?.length
    } else if (queryName === 'Deals by Counterparty') {
      if (querySubName !== 'Add doc to deal') {
        if (searchTerm) {
          items.push({
            label: `Create "${searchTerm}"`,
            onClick: () => {
              handleChange('deal_name', searchTerm)
            }
          })
        }
      }
      queryData?.deals_by_counter_party?.forEach((item: any) => {
        if (item?.name) {
          items.push({
            label: item?.alias || item?.name,
            onClick: () => {
              if (querySubName === 'Add doc to deal' && checkForRequiredValues(mutationVars)) {
                handleChange({ variables: { dealId: item?.id, documentIds: mutationVars?.required?.documentIds }, dealName: item?.name })
              } else {
                handleChange('deal_name', item?.name)

                handleChange('deal_id', item?.id)
              }
            }
          })
        }
      })
      totalItemCount = queryData?.deals_by_counter_party?.length
    } else if (queryName === 'Documents by Counterparty') {
      queryData?.documents_by_counter_party?.forEach((item: any) => {
        if (item?.name && items.length < 10 && !existingData?.includes(item?.id)) {
          items.push({
            label: hideFileExtension(item?.alias || item?.name),
            id: item?.id,
            onClick: () => {
              return null
            },
            counterparty: item?.counter_party
          })
        }
      })
      totalItemCount = queryData?.documents_by_counter_party?.length
    } else if (queryName === 'Documents by Deal') {
      queryData?.documents_by_deal?.forEach((item: any) => {
        if (item?.name && items.length < 10 && !existingData?.includes(item?.id)) {
          items.push({
            label: hideFileExtension(item?.alias || item?.name),
            id: item?.id,
            href: `/documents/${item.id}`,
            onClick: () => {
              return null
            },
            counterparty: item?.counter_party,
            isAccountLevel: item?.is_counter_party_level
          })
        }
        totalItemCount = queryData?.documents_by_deal?.length
      })
    }
    handleButtonDisableToggles(totalItemCount || 0)
    setMenuItems([...items])
    // eslint-disable-next-line
  }, [searchTerm, queryData, queryName, handleChange, history, offset, existingData, loading]);

  const checkForRequiredValues = (varObject: any) => {
    if (varObject?.required) {
      for (const key of Object.keys(varObject?.required)) {
        if (!varObject?.required[key]) {
          return false
        }
      }
      return true
    } else {
      return true
    }
  }

  const handleNext = () => {
    if (checkForRequiredValues(queryVars)) {
      const newOffset = offset + 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
    setButtonWasClicked(true)
  }

  const handlePrev = () => {
    if (checkForRequiredValues(queryVars)) {
      const newOffset = offset - 10 <= 0 ? 0 : offset - 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
    setButtonWasClicked(true)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (searchTerm || (!searchTerm && searchValue)) {
        if (checkForRequiredValues(queryVars)) {
          queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset, size: 20 } })
          setOffset(0)
          setSearchValue(searchTerm)
        }
      }
    }
  }

  const handleButtonDisableToggles = (totalItemCount: number) => {
    if (!totalItemCount || totalItemCount < 11) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
    if (offset === 0) {
      setPrevButtonDisabled(true)
    } else {
      setPrevButtonDisabled(false)
    }
  }

  return (
    <>
      <div
        className={clsx(css.menuPane, isFocused && css.isFocused)}
        ref={menuRef}
        style={style ? { ...style, zIndex: isVisible || keepOpen ? 8001 : 0 } : { zIndex: isVisible || keepOpen ? 8001 : 0 }}
      >
        <ul style={{ cursor: 'default' }}>
          <FreeText
            isError={isError}
            isFocused={isFocused}
            onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
              setSearchTerm(e.currentTarget.value)
            }}
            onKeyDown={handleKeyDown}
            placeholder={`${queryPlaceholder || 'Search…'}`}
            setFocused={setFocused}
            value={isMulti || isFocused ? searchTerm : searchTerm || selectedValue}
          />
          {(isVisible || keepOpen) && (
            <>
              <div className={menuItemClass} style={customStyles}>
                {isMulti && (
                  <SelectedItems dealCounterpartyId={dealCounterpartyId} handleChange={handleChange} queryName={queryName} selectedItems={selectedItems} />
                )}
                {loading ? (
                  <div className={css.rotate}>
                    <FiLoader />
                  </div>
                ) : isMulti ? (
                  <FilteredMenuItems
                    dealCounterpartyId={dealCounterpartyId}
                    handleChange={handleChange}
                    menuItems={menuItems}
                    queryName={queryName}
                    selectedItems={selectedItems}
                  />
                ) : (
                  <MenuOptions
                    dealCounterpartyId={dealCounterpartyId}
                    handleChange={handleChange}
                    menuItems={menuItems}
                    queryName={queryName}
                    setIsVisible={setIsVisible}
                    setSearchTerm={setSearchTerm}
                    setSelectedValue={setSelectedValue}
                  />
                )}
                {!loading && (
                  <NoResultsFound
                    menuItems={menuItems}
                    queryItemName={queryItemName}
                    queryNoResultsMessage={queryNoResultsMessage}
                    queryPlaceholder={queryPlaceholder}
                  />
                )}
              </div>
            </>
          )}
          {(isVisible || keepOpen) && !loading && (
            <PaginationButtons handleNext={handleNext} handlePrev={handlePrev} nextDisabled={nextButtonDisabled} prevDisabled={prevButtonDisabled} />
          )}
        </ul>
      </div>
    </>
  )
}

function MenuItem({
  counterparty,
  dealCounterpartyId,
  handleChange,
  href,
  id,
  in_neutral_tagset,
  isAccountLevel,
  isMulti,
  isSelected,
  label,
  onClick,
  queryName,
  setIsVisible,
  setSearchTerm,
  setSelectedValue
}: Item & {
  dealCounterpartyId?: any
  handleChange?: any
  isMulti?: boolean
  isSelected?: boolean
  queryName?: string
  setIsVisible?: (b: boolean) => void
  setSearchTerm?: any
  setSelectedValue?: any
}) {
  const { openPreview } = useContext(ModalContext)
  const handleClick = () => {
    if (isMulti && !isSelected) {
      handleChange('Add', { label, id, counterparty })
    } else {
      onClick()
      setSelectedValue(label)
      setSearchTerm('')
      // @ts-ignore
      setIsVisible(false)
    }
  }

  if (queryName === 'Documents by Deal') {
    const isForeignDocument = counterparty?.id ? counterparty?.id !== dealCounterpartyId : false
    const moreMenuItems = [{ label: 'Preview', onClick: () => openPreview(id, true, `${label}.pdf`) }]
    return (
      <div className={css.menuItem} style={{ display: 'flex', alignItems: 'center', cursor: 'unset' }}>
        {isAccountLevel && !isForeignDocument && (
          <Tippy content="Customer Level Document">
            <div className={css.accountLevelTooltip}>
              <FiGlobe />
            </div>
          </Tippy>
        )}
        {isForeignDocument && (
          <Tippy
            content={
              <>
                {`This document is from the customer:`}
                <div>{counterparty?.name}</div>
              </>
            }
          >
            {/* The div breaks the tooltip text to a new line */}
            <div className={css.accountLevelTooltip}>
              <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
            </div>
          </Tippy>
        )}
        {href ? (
          <WithTooltip content={'Open Document'}>
            <Link to={href}>{label}</Link>
          </WithTooltip>
        ) : (
          <p>{label}</p>
        )}
        <MoreMenu isFixed menuItems={moreMenuItems} offset={[8, 8]} placement="auto" style={{ margin: '0 0 0 auto' }} withIcon />
      </div>
    )
  } else if (queryName === 'Documents by Counterparty') {
    const isForeignDocument = counterparty?.id ? counterparty?.id !== dealCounterpartyId : false
    const moreMenuItems = [{ label: 'Preview', onClick: () => openPreview(id, true, `${label}.pdf`) }]
    return (
      <div
        className={isSelected ? css.menuItemSelected : css.menuItem}
        onClick={isSelected ? undefined : handleClick}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isForeignDocument && (
            <Tippy
              content={
                <>
                  {`This document is from the customer:`}
                  <div>{counterparty?.name}</div>
                </>
              }
            >
              {/* The div breaks the tooltip text to a new line */}
              <div className={css.accountLevelTooltip}>
                <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
              </div>
            </Tippy>
          )}
          {href ? (
            <WithTooltip content={'Open link'}>
              <Link to={href}>{label}</Link>
            </WithTooltip>
          ) : (
            <p>{label}</p>
          )}
        </div>
        <div
          onClick={e => {
            e.stopPropagation()
          }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <WithTooltip content={'More Options'}>
            <MoreMenu isFixed menuItems={moreMenuItems} offset={[8, 8]} placement="auto" withIcon />
          </WithTooltip>
          <WithTooltip content={'Unselect Item'}>
            <>
              {isSelected && (
                <Button
                  icon={<FiXSquare />}
                  onClick={() => {
                    handleChange('Remove', { label, id })
                  }}
                />
              )}
            </>
          </WithTooltip>
        </div>
      </div>
    )
  } else if (queryName === 'CCI Neutral Tags') {
    return (
      <div className={css.menuItem} onClick={handleClick}>
        {in_neutral_tagset ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WithTooltip content={'This tag is in the Neutral Tagset'}>
              <GoPrimitiveDot style={{ margin: '4px 8px 0 0' }} />
            </WithTooltip>
            <p>{label}</p>
          </div>
        ) : (
          <p>{label}</p>
        )}
      </div>
    )
  }

  return (
    <div className={isSelected ? css.menuItemSelected : css.menuItem} onClick={isSelected ? undefined : handleClick}>
      {href ? (
        <WithTooltip content={'Open link'}>
          <Link to={href}>{label}</Link>
        </WithTooltip>
      ) : (
        <p>{label}</p>
      )}
      {isSelected && (
        <Button
          icon={<FiXSquare />}
          onClick={() => {
            handleChange('Remove', { label, id })
          }}
        />
      )}
    </div>
  )
}

function SelectedItems({
  dealCounterpartyId,
  handleChange,
  queryName,
  selectedItems
}: {
  dealCounterpartyId?: string
  handleChange: any
  queryName?: string
  selectedItems: Array<Item>
}) {
  return (
    <>
      {selectedItems.map((item, index) => {
        return (
          <MenuItem
            key={`${item.label}, ${index}`}
            {...item}
            dealCounterpartyId={dealCounterpartyId}
            handleChange={handleChange}
            isMulti={true}
            isSelected={true}
            queryName={queryName}
          />
        )
      })}
    </>
  )
}

// Remove already selected items from the menu options
function FilteredMenuItems({
  dealCounterpartyId,
  handleChange,
  menuItems,
  queryName,
  selectedItems
}: {
  dealCounterpartyId?: string
  handleChange: any
  isMulti?: boolean
  menuItems: Array<Item>
  queryName?: string
  selectedItems: Array<Item>
}) {
  return (
    <>
      {menuItems?.length > 0 ? (
        <>
          {(() => {
            let length = selectedItems?.length
            const items = menuItems?.map((item, index) => {
              for (let i = 0; i <= selectedItems?.length; i++) {
                if (length < 10) {
                  if (selectedItems[i]?.id === item?.id) {
                    break
                  } else if (i >= selectedItems?.length) {
                    length += 1
                    return (
                      <MenuItem
                        key={`${item.label}, ${index}`}
                        {...item}
                        dealCounterpartyId={dealCounterpartyId}
                        handleChange={handleChange}
                        isMulti={true}
                        queryName={queryName}
                      />
                    )
                  }
                }
              }
              return undefined
            })
            return items
          })()}
        </>
      ) : null}
    </>
  )
}

function MenuOptions({
  dealCounterpartyId,
  handleChange,
  menuItems,
  queryName,
  setIsVisible,
  setSearchTerm,
  setSelectedValue
}: {
  dealCounterpartyId?: any
  handleChange: any
  menuItems?: Array<Item>
  queryName?: string
  setIsVisible: any
  setSearchTerm: any
  setSelectedValue: any
}) {
  return (
    <>
      {menuItems &&
        menuItems?.length > 0 &&
        menuItems.slice(0, 10)?.map((item, index) => {
          return (
            <MenuItem
              key={`${item.label}, ${index}`}
              {...item}
              dealCounterpartyId={dealCounterpartyId}
              handleChange={handleChange}
              queryName={queryName}
              setIsVisible={setIsVisible}
              setSearchTerm={setSearchTerm}
              setSelectedValue={setSelectedValue}
            />
          )
        })}
    </>
  )
}

function NoResultsFound({
  menuItems,
  queryItemName,
  queryNoResultsMessage,
  queryPlaceholder
}: {
  menuItems?: Array<Item>
  queryItemName?: string
  queryNoResultsMessage?: string
  queryPlaceholder?: string
}) {
  if (queryPlaceholder === `Create customer's first deal`) {
    return null
  }
  return (
    <>
      {menuItems?.length === 0 && (
        <li className={css.menuItem} style={{ cursor: 'default' }}>
          {queryNoResultsMessage || `No ${queryItemName || 'results'} found`}
        </li>
      )}
    </>
  )
}

function PaginationButtons({
  handleNext,
  handlePrev,
  nextDisabled,
  prevDisabled
}: {
  handleNext: any
  handlePrev: any
  nextDisabled: boolean
  prevDisabled: boolean
}) {
  return (
    <>
      {!(prevDisabled && nextDisabled) && (
        <li className={css.pagination}>
          <Button className={clsx(prevDisabled && css.isDisabled)} icon={<FiChevronLeft />} onClick={handlePrev} />
          <Button className={clsx(nextDisabled && css.isDisabled)} icon={<FiChevronRight />} onClick={handleNext} style={{ marginLeft: 'auto' }} />
        </li>
      )}
    </>
  )
}

interface Props {
  clearSelectedValue?: any
  dealCounterpartyId?: string
  existingData?: any
  getMaxHeight?: any
  handleChange?: any
  isError?: boolean
  isMulti?: boolean
  keepOpen?: boolean
  loading?: boolean
  menuItemClass?: string
  mutationVars?: any
  queryData?: any
  queryFunction: any
  queryItemName?: string
  queryName: string
  queryNoResultsMessage?: string
  queryPlaceholder?: string
  querySubName?: string
  queryVars?: any
  resetOffset?: boolean
  selectedItems?: any
  setClearSelectedValue?: any
  setResetOffset?: any
  style?: CSSProperties
}

type Item = {
  counterparty?: any
  href?: string
  id?: string
  in_neutral_tagset?: boolean
  isAccountLevel?: boolean
  label: string
  onClick: (...args: any) => void
}
