import { FiChevronDown, FiChevronUp, FiTrash2, FiXSquare } from 'react-icons/fi'
import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import NeutralTagRow from './NeutralTagRow'
import React, { KeyboardEvent, useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import SelectOrCreateNeutralTag from './SelectOrCreateNeutralTag'
import css from './style.module.scss'
import useUserAccess from '../../../../../../../hooks/useUserAccess'

export default function FieldOptions({
  currentDefaultValue,
  currentFieldType,
  currentItemId,
  currentOptions,
  currentValueFormat,
  handleData,
  newDefaultValue,
  newFieldType,
  newValueFormat
}: {
  currentDefaultValue?: string
  currentFieldType?: string
  currentItemId?: string
  currentOptions?: any
  currentValueFormat?: string
  handleData: any
  newDefaultValue?: string
  newFieldType?: string
  newValueFormat?: string
}) {
  const { selectedItem } = useCciMainContext()
  const [optionsArray, setOptionsArray] = useState<Array<any>>([])
  const [deletedOptionsArray, setDeletedOptionsArray] = useState<Array<any>>([])
  const optionsList = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN', 'DATE']
  const dropdownTypes = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN']
  // @ts-ignore
  const isDropdown = dropdownTypes?.includes(newFieldType) || (!newFieldType && dropdownTypes?.includes(currentFieldType))
  const valueFormatOptions = [
    { value: 'MMMM/DD/YYYY', label: 'MMMM/DD/YYYY' },
    { value: 'MMMM DD, YYYY', label: 'MMMM DD, YYYY' },
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'DD MMMM, YYYY', label: 'DD MMMM, YYYY' }
  ]
  const hasEditNeutralTagAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'EDIT_NEUTRAL_TAG' })

  // @ts-ignore
  const handleChange = (value?: string, index: number, isNew?: boolean) => {
    const localArray: any[] = [...optionsArray]
    const deletedArray: any[] = [...deletedOptionsArray]

    // update default value
    if (!isNew && (newDefaultValue || currentDefaultValue)) {
      if (
        localArray[index].newValue === (newDefaultValue || currentDefaultValue) ||
        (!localArray[index].newValue && localArray[index].currentValue === (newDefaultValue || currentDefaultValue))
      ) {
        if (value === null) {
          handleData('default_value', localArray[index].currentValue)
        } else {
          handleData('default_value', value)
        }
      }
    }

    if (value === '@@_DELETE_THIS_ITEM') {
      const element = localArray[index]
      localArray.splice(index, 1)
      if (element?.currentValue) {
        deletedArray.push({ currentValue: element.currentValue, newValue: value })
      }
    } else if (isNew) {
      if (value) {
        localArray.push({ currentValue: null, newValue: value })
      }
    } else {
      localArray[index].newValue = value
    }

    // delete the options array if no changes have been made
    const changedOptions = localArray?.filter(option => {
      if (option?.currentValue && !option?.newValue) {
        return false
      } else {
        return true
      }
    })

    handleData('options', deletedArray?.length > 0 || changedOptions?.length > 0 ? localArray : undefined)
    handleData('deleted_options', deletedArray?.length > 0 ? deletedArray : undefined)

    setOptionsArray([...localArray])
    setDeletedOptionsArray([...deletedArray])
  }

  const handleDateFormat = (item: any) => {
    if (currentValueFormat) {
      if (item?.value !== currentValueFormat) {
        handleData('value_format', item?.value)
      }
    } else {
      handleData('value_format', item?.value)
    }
  }

  const handleNeutralTag = (index: number, value?: string) => {
    if (!optionsArray || optionsArray?.length < 1) {
      return null
    }
    const localArray: any[] = [...optionsArray]
    localArray[index].neutralTag = value
    setOptionsArray([...localArray])
    setOptionsArray([...localArray])
    handleData('options', localArray)
  }

  useEffect(() => {
    // generate/wipe initial array
    if (newFieldType) {
      setOptionsArray([])
      setDeletedOptionsArray([])
    } else {
      setOptionsArray(
        currentOptions?.map((item: any) => {
          return { currentValue: item, newValue: null }
        })
      )
      setDeletedOptionsArray([])
    }
    handleData('options', null)
    // eslint-disable-next-line
    }, [currentItemId, currentFieldType, newFieldType, currentOptions])

  return (
    <>
      {/* Choose options for dropdown/multi dropdown, value_format for dates, and default values */}
      {/* @ts-ignore */}
      {(optionsList?.includes(newFieldType) || (!newFieldType && optionsList?.includes(currentFieldType))) && (
        <>
          {isDropdown && (
            <>
              <h4>{`Field Options:`}</h4>
              {optionsArray?.length ? (
                optionsArray?.map((option: any, index: number) => {
                  return (
                    <div className={css.inputWrapper} key={`${option}${index}`} style={{ flexWrap: 'wrap' }}>
                      <OptionInput
                        handleChange={handleChange}
                        handleData={handleData}
                        handleNeutralTag={handleNeutralTag}
                        hasEditNeutralTagAccess={hasEditNeutralTagAccess}
                        index={index}
                        isOMSDD={!!selectedItem?.option_to_neutral_tag_mapping}
                        key={`${option}${index}`}
                        neutralTag={option?.neutralTag}
                        optionsArray={optionsArray}
                        placeholder={option?.currentValue}
                        value={option?.newValue || ''}
                      />
                      {selectedItem?.option_to_neutral_tag_mapping && hasEditNeutralTagAccess && (
                        <OptionNeutralTagRow option={option} optionNeutralTagMapping={selectedItem?.option_to_neutral_tag_mapping} />
                      )}
                    </div>
                  )
                })
              ) : (
                <></>
              )}
              <OptionInput handleChange={handleChange} handleNeutralTag={handleNeutralTag} index={9999} isNew placeholder={'Add item…'} />
            </>
          )}

          {/* if type = date select value format */}
          {(newFieldType === 'DATE' || (!newFieldType && currentFieldType === 'DATE')) && (
            <>
              <div className={css.inputWrapper}>
                <h4>{`Value Format:`}</h4>
                <SelectInput
                  isClearable
                  onChange={handleDateFormat}
                  options={valueFormatOptions}
                  placeholder={newValueFormat === '@@_DELETE_THIS_ITEM' ? 'Select Date Format' : newValueFormat || currentValueFormat || 'Select Date Format'}
                  value={newValueFormat || currentValueFormat || ''}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

function OptionInput({
  handleChange,
  handleData,
  handleNeutralTag,
  hasEditNeutralTagAccess,
  index,
  isNew,
  isOMSDD,
  neutralTag,
  optionsArray,
  placeholder,
  value
}: {
  handleChange?: any
  handleData?: any
  handleNeutralTag: any
  hasEditNeutralTagAccess?: boolean
  index: number
  isNew?: boolean
  isOMSDD?: boolean
  neutralTag?: string
  optionsArray?: any
  placeholder?: string
  value?: string
}) {
  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')
  const isOnlyOption =
    optionsArray?.filter((option: any) => {
      if (option?.newValue === '@@_DELETE_THIS_ITEM') {
        return false
      } else {
        return true
      }
    }).length === 1
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
    }, [isFocused])

  const canDeleteOption = isOMSDD && !(value && !placeholder) ? hasEditNeutralTagAccess || false : true //! (value && !placeholder) checks if it is a new option because the old value will be null

  return (
    <>
      {value !== '@@_DELETE_THIS_ITEM' && value !== placeholder && (
        <div className={isNew ? css.optionInputWrapper : css.inputWrapper} style={isNew ? { marginLeft: '131px' } : { margin: '0' }}>
          <div className={css.textInput}>
            {!isNew && <ReorderButtons handleData={handleData} index={index} optionsArray={optionsArray} />}
            <FreeText
              isFocused={isFocused}
              onChange={(e: any) => {
                setAddItemValue(e?.currentTarget?.value)
              }}
              onKeyDown={handleKeyDown}
              placeholder={isNew ? 'Add item…' : placeholder}
              setFocused={setFocused}
              value={isFocused ? addItemValue || '' : value || ''}
            />
          </div>
          {!isNew && placeholder && (addItemValue || value) && (
            <Button
              className={css.cciButton}
              icon={<FiXSquare />}
              onClick={() => {
                handleChange(null, index)
                setAddItemValue('')
              }}
            />
          )}
          {!isOnlyOption && (!isNew || !placeholder) && canDeleteOption && (
            <Button
              icon={<FiTrash2 color="#fc3535" />}
              onClick={() => {
                handleChange('@@_DELETE_THIS_ITEM', index)
              }}
              style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
            />
          )}
        </div>
      )}
      {isOMSDD && value && !placeholder && <OptionNeutralTagInput handleNeutralTag={handleNeutralTag} index={index} neutralTag={neutralTag} />}
    </>
  )
}

function OptionNeutralTagInput({ handleNeutralTag, index, neutralTag }: { handleNeutralTag: any; index: number; neutralTag?: string }) {
  const [value, setValue] = useState<any>(neutralTag)
  const [clearSelectedValue, setClearSelectedValue] = useState(false)

  const handleChange = ({ label }: { label: string }) => {
    setValue(label)
  }

  useEffect(() => {
    handleNeutralTag(index, value || null)
    // eslint-disable-next-line
    }, [value])

  return (
    <>
      <div className={css.inputWrapper} style={{ marginBottom: '0', width: '100%' }}>
        <h4 style={{ marginLeft: '131px' }}>{`Neutral Tag:`}</h4>
        <SelectOrCreateNeutralTag
          clearSelectedValue={clearSelectedValue}
          neutralTag={value}
          setClearSelectedValue={setClearSelectedValue}
          setNeutralTag={handleChange}
        />
        {value && (
          <Button
            className={css.cciButton}
            icon={<FiXSquare />}
            onClick={() => {
              setValue(null)
              setClearSelectedValue(true)
            }}
          />
        )}
      </div>
    </>
  )
}

function ReorderButtons({ handleData, index, optionsArray }: { handleData?: any; index: number; optionsArray?: any }) {
  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      handleData('options', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== optionsArray?.length - 1) {
      handleData('options', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = optionsArray[fromIndex]
    optionsArray.splice(fromIndex, 1)
    optionsArray.splice(toIndex, 0, element)
    return [...optionsArray]
  }

  return (
    <div style={{ display: 'flex', width: '115px', marginRight: '16px' }}>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('up')
        }}
        style={{ visibility: index === 0 ? 'hidden' : 'unset' }}
        title="Move option up"
      >
        <FiChevronUp />
      </div>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('down')
        }}
        style={{ visibility: index === optionsArray?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </div>
    </div>
  )
}

function OptionNeutralTagRow({ option, optionNeutralTagMapping }: any) {
  const getOptionsNeutralTag = () => {
    try {
      // this is to prevent crashes if the tag mapping fails to parse
      if (optionNeutralTagMapping) {
        const tagMapping = JSON.parse(optionNeutralTagMapping)
        if (!tagMapping || !option?.currentValue) {
          return null
        } else {
          return tagMapping[option.currentValue]
        }
      } else {
        return null
      }
    } catch (error) {
      console.error('FieldOptions.tsx OptionNeutralTagRow error:', error)
    }
  }

  const optionNeutralTag = getOptionsNeutralTag()

  return <>{optionNeutralTag && <NeutralTagRow oldTag={optionNeutralTag} />}</>
}

// NOTES:

// for dropdown/multi: list of freetext inputs for each option array. This calls handleChange which formats the inputs and then updates handleData
// empty input at the bottom that adds a new item to the newOptions array
// newOptions array is cleaned up on submit in edit deal- if any newOption has a value or was deleted it will fill in nulls with currentItems. @@_DELETE_THIS_ITEM fields will not be sent
// if any value is changed the entire new options array is sent to the backend in order to replace old values.

// have a way to delete an option row- trashcan button to the right of X
// throw error on submit if type has required options but is missing. update this to show errors
