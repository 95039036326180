import { useDealAttachmentsQuery, useNotesTabCountQuery } from '../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import DataPointPanel from '../components/DataPointPanel'
import React from 'react'
import useUserAccess from '../hooks/useUserAccess'

// components

const DealDataPointPanelContainer = ({ dealIsFinalized }: { dealIsFinalized: boolean }) => {
  const { dealId } = useParams<{ dealId: string }>()

  const { data: dealAttachmentsData } = useDealAttachmentsQuery({ variables: { dealId } })
  const { data: notesTabCountData } = useNotesTabCountQuery({ variables: { resourceId: dealId } })

  const attachmentCount = dealAttachmentsData?.deal_attachments?.filter(item => !item?.is_deleted).length || 0
  const hasCollisionAccess = useUserAccess({ feature: 'Collision', permission: 'READ' })
  const noteCount = notesTabCountData?.notes_tab_count || 0

  return (
    <DataPointPanel
      attachmentCount={attachmentCount}
      dealId={dealId}
      dealIsFinalized={dealIsFinalized}
      noteCount={noteCount}
      showDataPointDetailOverlay={hasCollisionAccess}
    />
  )
}

export default DealDataPointPanelContainer
