import './Tip.css'
import { AiOutlineHighlight } from 'react-icons/ai'
import Button from '../Button'
import DataPointSelector from './DataPointSelector'
import React, { useState } from 'react'

type Tag = { label: string; value: string }
interface Props {
  documentId: string
  onConfirm: (tag: Tag) => void
  onOpen: () => void
}

export default function Tip({ documentId, onConfirm, onOpen }: Props) {
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null)
  const [loading, setLoading] = useState(false) // this isn't ideal; the loading state should come from the create mutation. for now this won't work because of the way that the pdfhighlighter renders tips and doesn't update on the loading prop change.
  const [compact, setCompact] = useState(true)

  return (
    <div className="Tip">
      {compact ? (
        <div
          className="Tip__compact"
          onClick={() => {
            onOpen()
            setCompact(false)
          }}
        >
          <AiOutlineHighlight />
        </div>
      ) : (
        <form
          className="Tip__card"
          onSubmit={e => {
            e.preventDefault()
            if (selectedTag) {
              setLoading(true)
              onConfirm(selectedTag)
            }
          }}
        >
          <h4 style={{ marginBottom: 8 }}>Assign to tag</h4>
          <div>
            <DataPointSelector
              documentId={documentId}
              onChange={(selectedTag: Tag) => {
                setSelectedTag(selectedTag)
              }}
            />
          </div>
          <Button disabled={!selectedTag || loading} loading={loading} style={{ width: '100%', marginTop: 8 }}>
            Save
          </Button>
        </form>
      )}
    </div>
  )
}
