import { ModalContext } from '../../app'
import { SearchTab } from './SearchTab/SearchTab'
import { getAnnotatorGreeting } from '../../utils/annotatorUtils'
import { useCurrentUserQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import AttachmentsTab from './AttachmentsTab'
import DealsTab from './DealsTab'
import DocumentsTab from './DocumentsTab'
import DownloadBulkJob from '../../components/ModalOptions/DownloadBulkJob'
import Helmet from 'react-helmet'
import InboxTab from './InboxTab'
import React, { FC, useContext, useEffect, useState } from 'react'
import Tabs from '../../components/Tabs'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useUserAccess, { useIsAnnotator } from '../../hooks/useUserAccess'

interface TabInterface {
  component: FC
  path: string
  title: string
}

export default function Dashboard() {
  const { openPreview } = useContext(ModalContext)
  const [tabs, setTabs] = useState<TabInterface[]>([])
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  const { download_artifact_uuid, previewId } = useParams<{ download_artifact_uuid: string; previewId: string }>()

  useEffect(() => {
    if (download_artifact_uuid) {
      setIsDownloadModalOpen(true)
    }
  }, [download_artifact_uuid])

  useEffect(() => {
    if (previewId) {
      openPreview(previewId, true)
    }
    // eslint-disable-next-line
  }, [previewId])

  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })
  const hasDocAccess = useUserAccess({ feature: 'DocumentDashboard', permission: 'READ' })
  const hasAttachmentAccess = useUserAccess({ feature: 'AttachmentsDashboard', permission: 'READ' })
  const hasTextSearchAccess = useUserAccess({ feature: 'TextSearch', permission: 'READ' })
  const isAnnotator = useIsAnnotator()
  const { data } = useCurrentUserQuery({ context: { queryName: `Dashboard.tsx useCurrentUserQuery` } })
  const { customers, first_name, roles } = data?.current_user || {}
  const roleList = roles?.edges.map(r => r?.node?.name).join(', ')
  const customer = customers?.edges[0]?.node
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  useEffect(() => {
    const localTabs = []
    if (isAnnotator) {
      localTabs.push({ title: 'Inbox', component: InboxTab, path: '/' })
      if (hasDealAccess) {
        localTabs.push({ title: 'Deals', component: DealsTab, path: '/dashboard/deals' })
      }
      if (hasDocAccess) {
        localTabs.push({ title: 'Documents', component: DocumentsTab, path: '/dashboard/documents' })
      }
    } else {
      if (isRapid7LegalTeam) {
        // Hardcoded feature for this task: https://app.asana.com/0/0/1200501099177444/f
        if (hasDealAccess) {
          localTabs.push({ title: 'Deals', component: DealsTab, path: '/dashboard/deals' })
        }
        if (hasDocAccess) {
          localTabs.push({ title: 'Documents', component: DocumentsTab, path: '/' })
        }
      } else {
        if (hasDealAccess) {
          localTabs.push({ title: 'Deals', component: DealsTab, path: '/' })
        }
        if (hasDocAccess) {
          localTabs.push({ title: 'Documents', component: DocumentsTab, path: hasDealAccess ? '/dashboard/documents' : '/' })
        }
      }
    }
    if (hasAttachmentAccess) {
      localTabs.push({ title: 'Attachments', component: AttachmentsTab, path: '/dashboard/attachments' })
    }

    if (hasTextSearchAccess) localTabs.push({ component: SearchTab, path: `/dashboard/search`, title: `Search` })

    setTabs([...localTabs])
  }, [hasAttachmentAccess, hasDealAccess, hasDocAccess, hasTextSearchAccess, isAnnotator, isRapid7LegalTeam])

  return (
    <>
      <Helmet>
        <title>{`Dashboard | Klarity`}</title>
      </Helmet>
      {isAnnotator && (
        <section style={{ padding: '24px', lineHeight: 1.8 }}>
          <h1 style={{ fontSize: 24 }}>
            {getAnnotatorGreeting()}, {first_name}
          </h1>
          <div>
            <b style={{ color: '#f0f' }}>{customer?.name}</b>
          </div>
          {roles && (
            <div>
              <b style={{ color: '#684bff' }}>{roleList}</b>
            </div>
          )}
        </section>
      )}
      <Tabs hasDealAccess={hasDealAccess} tabs={tabs} />
      <DownloadBulkJob isOpen={isDownloadModalOpen} setIsOpen={setIsDownloadModalOpen} />
    </>
  )
}
