import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import css from './style.module.scss'

export default function CCI_Dropdown({
  displayName,
  handleData,
  options,
  placeholder,
  value,
  variableName
}: {
  displayName: string
  handleData: any
  options?: any
  placeholder?: string
  value?: any
  variableName: string
}) {
  const ref = useRef<HTMLElement>()
  const [isFocused, setFocused] = useState(false)

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{displayName}</h4>
        {/* @ts-ignore */}
        <Select
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onBlur={() => setFocused(false)}
          onChange={(value: any) => {
            handleData(variableName, value?.value || null)
          }}
          onFocus={() => setFocused(true)}
          openMenuOnFocus
          options={options}
          placeholder={placeholder}
          // @ts-ignore
          ref={ref}
          styles={{
            control: provided => {
              return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }
            },
            option: provided => {
              return { ...provided, fontSize: 14 }
            },
            container: provided => {
              return { ...provided, padding: 0 }
            },
            multiValue: provided => {
              return { ...provided, maxWidth: '300px' }
            },
            placeholder: provided => {
              return { ...provided, color: '#e0e0e0' }
            },
            dropdownIndicator: () => {
              return { display: 'none' }
            },
            indicatorSeparator: () => {
              return { display: 'none' }
            },
            clearIndicator: (provided, { isFocused }) => {
              return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
            },
            menuPortal: provided => {
              return { ...provided, zIndex: 999999999 }
            }
          }}
          theme={theme => {
            return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
          }}
          value={value}
        />
      </div>
    </>
  )
}
