import { useCciMainContext } from '../../../CCI_Main'
import { useEditUserMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function ReviewUserEdits({ closeModal, user_id, userName, values }: { closeModal?: any; userName?: string; user_id: string; values?: any }) {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [editUserMutation, { error: editUserError, loading: editUserLoading }] = useEditUserMutation({
    context: { queryName: `ReviewUserEdits.tsx editUserMutation` },
    onCompleted: () => {
      setModalSuccess('User successfully edited')
    }
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (editUserLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Editing User…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [editUserLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(editUserError)}, [editUserError])

  const handleSubmit = () => {
    if (!user_id) {
      setModalError(`User id is missing`)
    } else {
      const localValues = { ...values }
      const roles_to_add = formatRoles('ToAdd')
      const roles_to_remove = formatRoles('ToRemove')
      delete localValues.roles_to_add
      delete localValues.roles_to_remove
      // console.info('submitted values:', {roles_to_add, roles_to_remove, ...localValues})
      editUserMutation({ variables: { user_id, roles_to_add, roles_to_remove, ...localValues } })
    }
  }

  const formatRoles = (optionType: string) => {
    if (optionType === 'ToAdd') {
      if (!values?.roles_to_add?.length) {
        return undefined
      }
      return (
        values?.roles_to_add?.map((role: any) => {
          return role?.value
        }) || undefined
      )
    } else if (optionType === 'ToRemove') {
      if (!values?.roles_to_remove?.length) {
        return undefined
      }
      return (
        values?.roles_to_remove?.map((role: any) => {
          return role?.value
        }) || undefined
      )
    }
  }

  return (
    <>
      <h5 style={{ textAlign: 'center', marginBottom: '32px' }}>{userName}</h5>

      {values?.first_name && (
        <div className={css.reviewDiv}>
          <h4>{`First Name:`}</h4>
          <p>{values?.first_name}</p>
        </div>
      )}
      {values?.last_name && (
        <div className={css.reviewDiv}>
          <h4>{`Last Name:`}</h4>
          <p>{values?.last_name}</p>
        </div>
      )}
      {values?.roles_to_add && (
        <div className={css.reviewDiv}>
          <h4>{`Roles to add:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.roles_to_add?.map((item: any, index: number) => {
              return (
                <div className={css.reviewOptions} key={item?.label + index}>
                  <p style={{ width: '100%' }}>{item?.label}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {values?.roles_to_remove && (
        <div className={css.reviewDiv}>
          <h4>{`Roles to remove:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.roles_to_remove?.map((item: any, index: number) => {
              return (
                <div className={css.reviewOptions} key={item?.label + index}>
                  <p style={{ width: '100%' }}>{item?.label}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}

      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
