import { API_ROOT } from './apiUtils'
import { getAccessToken } from './sessionApiUtils'
import axios from 'axios'

const axiosClient = () => {
  const defaultOptions = {
    baseURL: API_ROOT,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const instance = axios.create(defaultOptions)

  // Get auth token from okta manager, set for any request
  instance.interceptors.request.use(async function (config) {
    const accessToken = await getAccessToken()
    const customerId = localStorage.getItem('cid')
    const token = btoa(`${accessToken}__||__${customerId}`)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  })

  return instance
}

export default axiosClient()
