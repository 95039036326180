import { CurrentRuntimeEnvironment } from '../../utils/envUtils'
import { GraphQLClient } from 'graphql-request'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getAccessToken } from '../../utils/sessionApiUtils'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

const client = new GraphQLClient(`${CurrentRuntimeEnvironment.REACT_APP_API_ROOT}/graphql`)

export const baseApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    // @ts-ignore
    client,
    prepareHeaders: async headers => {
      const accessToken = await getAccessToken()

      if (accessToken) {
        headers.set('Authorization', `Bearer ${btoa(`${accessToken}__||__${localStorage.getItem('cid')}`)}`)
      }

      return headers
    }
  }),

  endpoints: () => ({})
})
