import 'rc-collapse/assets/index.css'
import { Route, Switch } from 'react-router-dom'
import { useCciMainContext } from '../../../CCI_Main'
import CCI_LeftPanel_ChecklistList from './ChecklistTab/CCI_LeftPanel_ChecklistList'
import CCI_LeftPanel_IntegrationList from './IntegrationsTab/CCI_LeftPanel_IntegrationList'
import CCI_LeftPanel_RoleList from './RolesTab/CCI_LeftPanel_RoleList'
import CCI_LeftPanel_UserList from './UsersTab/CCI_LeftPanel_UserList'
import React, { useEffect, useState } from 'react'
import useUserAccess from '../../../../../hooks/useUserAccess'

export default function CCI_LeftPanel_Content() {
  const { tab } = useCciMainContext()
  const [defaultTab, setDefaultTab] = useState<any>(undefined)
  const hasAdminAccess = useUserAccess({ feature: 'CCI', permission: 'ADMIN' })
  const hasChecklistTabAccess = useUserAccess({ feature: 'CCI', permission: 'CHECKLIST_TAB' })
  const hasIntegrationsTabAccess = useUserAccess({ feature: 'CCI', permission: 'INTEGRATIONS_TAB' })
  const hasRolesTabAccess = useUserAccess({ feature: 'CCI', permission: 'ROLES_TAB' })
  const hasUsersTabAccess = useUserAccess({ feature: 'CCI', permission: 'USERS_TAB' })

  // sets the default tab based off of the current user's permission levels. Goes through alphabetically until finding a tab they have access to.
  const getDefaultTab = () => {
    if (hasAdminAccess) {
      setDefaultTab(<CCI_LeftPanel_ChecklistList />)
    } else if (hasChecklistTabAccess) {
      setDefaultTab(<CCI_LeftPanel_ChecklistList />)
    } else if (hasIntegrationsTabAccess) {
      setDefaultTab(<CCI_LeftPanel_IntegrationList />)
    } else if (hasRolesTabAccess) {
      setDefaultTab(<CCI_LeftPanel_RoleList />)
    } else if (hasUsersTabAccess) {
      setDefaultTab(<CCI_LeftPanel_UserList />)
    } else {
      setDefaultTab(undefined)
    }
  }

  useEffect(() => {
    if (!tab || tab === undefined) {
      getDefaultTab()
    }
    // eslint-disable-next-line
  }, [tab])

  return (
    <Switch>
      <Route exact path="/config/checklist">
        {(hasAdminAccess || hasChecklistTabAccess) && <CCI_LeftPanel_ChecklistList />}
      </Route>

      <Route exact path="/config/integrations">
        {(hasAdminAccess || hasIntegrationsTabAccess) && <CCI_LeftPanel_IntegrationList />}
      </Route>

      <Route exact path="/config/roles">
        {(hasAdminAccess || hasRolesTabAccess) && <CCI_LeftPanel_RoleList />}
      </Route>

      <Route exact path="/config/users">
        {(hasAdminAccess || hasUsersTabAccess) && <CCI_LeftPanel_UserList />}
      </Route>

      <Route path="*">{defaultTab || <p>Error setting default tab</p>}</Route>
    </Switch>
  )
}
