import { FiTrash2 } from 'react-icons/fi'
import { formatDate } from '../../utils/datetimeUtils'
import Button from '../Button'
import React, { useState } from 'react'
import WithTooltip from '../WithTooltip'
import avatar from '../../assets/avatar.png'
import css from './style.module.scss'
import logo from '../../assets/logo.png'

export default function HighlightPopup({
  deleteAnnotation,
  highlight,
  isScrolledTo
}: {
  deleteAnnotation: ((highlightId: string) => void) | null
  highlight: any
  isScrolledTo: boolean
}) {
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    if (!deleteAnnotation) return null
    // eslint-disable-next-line
    const shouldDelete = await window.confirm(`Are you sure you want to delete this tag for ${highlight.comment.text}?`);
    if (shouldDelete) {
      setLoading(true)
      deleteAnnotation(highlight.id)
    }
  }

  return (
    <div className={css.highlightPopup}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 8 }}>
          <WithTooltip
            content={
              <ul>
                <li>Highlighted by {highlight.created_by ? highlight.created_by.user_name : 'Klarity'}</li>
                <li>{formatDate(highlight.created_at)}</li>
              </ul>
            }
          >
            <img
              alt={highlight.created_by ? 'user avatar' : 'Klarity logo'}
              src={highlight.created_by ? avatar : logo}
              style={{ width: 24, height: 24, borderRadius: '50%' }}
            />
          </WithTooltip>
        </span>
        <b>{highlight.comment.text}</b>
        <span style={{ display: isScrolledTo ? 'inline-block' : 'none', visibility: isScrolledTo ? 'visible' : 'hidden', marginLeft: 8, color: 'red' }}>
          {deleteAnnotation && <Button icon={<FiTrash2 color="#fc3535" />} loading={loading} onClick={handleDelete} />}
        </span>
      </div>
    </div>
  )
}
