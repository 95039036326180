import Button from '../../Button'
import React, { ReactNode } from 'react'
import css from './style.module.scss'

export default function MessageModal({
  closeModal,
  message,
  messageNode,
  messageTitle
}: {
  closeModal: any
  message?: any
  messageNode?: ReactNode
  messageTitle: string
}) {
  return (
    <div className={css.messageModal}>
      <h2>{messageTitle}</h2>
      {message && (
        <>
          {message?.errors?.length > 0 ? (
            message?.errors?.map((error: any, index: any) => {
              return <p key={`${error?.message}${index}`}>{error?.message}</p>
            })
          ) : message?.message ? (
            <p>{message?.message}</p>
          ) : (
            <p>{message}</p>
          )}
        </>
      )}
      {messageNode && <p>{messageNode}</p>}
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal}>{`Close`}</Button>
      </div>
    </div>
  )
}
