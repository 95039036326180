import { Link } from 'react-router-dom'
import React, { ReactNode, useEffect, useRef } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useUserAccess from '../../../../../hooks/useUserAccess'

// types

interface TabSelectorProps {
  isNew?: boolean
  tab?: string
}

// components

export default function TabSelector({ isNew, tab }: TabSelectorProps) {
  const hasAdminAccess = useUserAccess({ feature: 'CCI', permission: 'ADMIN' })
  const hasChecklistTabAccess = useUserAccess({ feature: 'CCI', permission: 'CHECKLIST_TAB' })
  const hasIntegrationsTabAccess = useUserAccess({ feature: 'CCI', permission: 'INTEGRATIONS_TAB' })
  const hasAccessControlTabAccess = useUserAccess({ feature: 'CCI', permission: 'ROLES_TAB' })
  const hasUsersTabAccess = useUserAccess({ feature: 'CCI', permission: 'USERS_TAB' })

  return (
    <div
      className={clsx(css.documentSelector, { [css.isNew]: isNew })}
      style={{ '--cci-panel-width': `${localStorage.getItem('CCI_PanelWidth') || 600}px` } as React.CSSProperties}
    >
      <div className={css.inner}>
        <div className={css.horizontalTabList}>
          {(hasAdminAccess || hasChecklistTabAccess) && (
            <ScrollingTabLink isActive={tab === 'checklist' || tab === undefined}>
              <Link className={clsx(css.item, { [css.active]: tab === 'checklist' || tab === undefined })} to={`/config/checklist`}>
                <span>{`Checklist`}</span>
              </Link>
            </ScrollingTabLink>
          )}
          {(hasAdminAccess || hasIntegrationsTabAccess) && (
            <ScrollingTabLink isActive={tab === 'integrations'}>
              <Link className={clsx(css.item, { [css.active]: tab === 'integrations' })} to={`/config/integrations`}>
                <span>{`Integrations`}</span>
              </Link>
            </ScrollingTabLink>
          )}
          {(hasAdminAccess || hasAccessControlTabAccess) && (
            <ScrollingTabLink isActive={tab === 'roles'}>
              <Link className={clsx(css.item, { [css.active]: tab === 'roles' })} to={`/config/roles`}>
                <span>{`Roles`}</span>
              </Link>
            </ScrollingTabLink>
          )}
          {(hasAdminAccess || hasUsersTabAccess) && (
            <ScrollingTabLink isActive={tab === 'users'}>
              <Link className={clsx(css.item, { [css.active]: tab === 'users' })} to={`/config/users`}>
                <span>{`Users`}</span>
              </Link>
            </ScrollingTabLink>
          )}
        </div>
      </div>
    </div>
  )
}

function ScrollingTabLink({ children, isActive }: { children: ReactNode; isActive: boolean }) {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView()
    }
  }, [isActive])
  return (
    <div className={clsx(css.scrollingTab, { [css.scrollingTabActive]: isActive })} ref={ref}>
      {children}
    </div>
  )
}
