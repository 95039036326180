import { DataPoint } from '../../graphql/codegen/schemas'
import { FiAlertTriangle } from 'react-icons/fi'
import { useIsAnnotator } from '../../hooks/useUserAccess'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import React from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function DataPointUpdateFailure({ dataPoint }: { dataPoint: DataPoint }) {
  const isAnnotator = useIsAnnotator()
  const isKlarityEmployee = useIsKlarityEmployee()
  if (!isAnnotator && !isKlarityEmployee) {
    return null
  }

  return (
    <>
      {dataPoint?.annotator_needs_review_type?.map((item: any, index: any) => {
        return (
          <p className={clsx(css.collisionAlert, css.warning)} key={`${item?.id},${index}`} style={{ margin: '-8px 0 9px 8px' }}>
            <FiAlertTriangle />
            {`Needs Review: ${item}`}
          </p>
        )
      })}
    </>
  )
}
