import { useAddDocumentsToDealMutation, useCounterPartyDealsLazyQuery } from '../../../graphql/codegen/hooks'
import Button from '../../Button'
import InlineQueryMenu from '../../InlineQueryMenu'
import RadioButton from '../../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function AddDocToDeal({
  closeModal,
  counterpartyId,
  documentId,
  loading,
  parentComponent,
  setLoading,
  setLoadingMessage,
  setSelectedDeal
}: {
  closeModal: any
  counterpartyId?: any
  documentId?: string
  loading: boolean
  parentComponent?: string
  setLoading: any
  setLoadingMessage: any
  setSelectedDeal?: any
}) {
  const [searchAllCustomers, setSearchAllCustomers] = useState(false)
  const [resetOffset, setResetOffset] = useState(false)
  const [queryVars, setQueryVars] = useState<any>('')
  const [mutationVars, setMutationVars] = useState<any>('')
  const [mutationSuccess, setMutationSuccess] = useState<boolean>(false)

  const [getCounterPartyDeals, { data: counterPartyDeals, loading: counterPartyDealsLoading }] = useCounterPartyDealsLazyQuery({
    context: { queryName: `DocumentList/ModalComponents.tsx getCounterPartyDeals` },
    fetchPolicy: 'no-cache' // Need to always get fresh search results from backend. Query is manually triggered by user, limited to 20 results max, and returns minor amount of data.
  })
  const [addDocumentsToDealMutation, { loading: addDocumentLoading }] = useAddDocumentsToDealMutation({
    context: { queryName: `DocumentList/ModalComponents.tsx addDocumentsToDealMutation` },
    onCompleted: () => {
      setLoadingMessage('')
      setMutationSuccess(true)
    }
  })

  useEffect(() => {
    if (counterpartyId) {
      setResetOffset(true)
      setMutationVars({ required: { documentIds: [documentId] }, optional: {} })
      setQueryVars({
        required: {},
        optional: {
          counterpartyId: searchAllCustomers ? undefined : counterpartyId,
          filters: parentComponent === 'ConvertDocumentLevel' ? undefined : JSON.stringify({ exclude_deals_by_document_ids: [documentId] })
        }
      })
      getCounterPartyDeals({
        variables: {
          counterpartyId: searchAllCustomers ? undefined : counterpartyId,
          filters: parentComponent === 'ConvertDocumentLevel' ? undefined : JSON.stringify({ exclude_deals_by_document_ids: [documentId] }),
          size: 20
        }
      })
    }
    // eslint-disable-next-line
    }, [searchAllCustomers])

  useEffect(() => {
    let localLoading = false
    if (addDocumentLoading) {
      localLoading = true
    }
    setLoading(localLoading)
    // eslint-disable-next-line
    }, [addDocumentLoading, setLoading])

  const handleClose = () => {
    if (!loading) {
      setQueryVars('')
      setMutationVars('')
      setMutationSuccess(false)
      closeModal()
    }
  }

  const handleAdd = (vars: any) => {
    if (parentComponent === 'ConvertDocumentLevel') {
      setSelectedDeal(vars)
    } else {
      setLoadingMessage('Adding Document…')
      delete vars.dealName
      addDocumentsToDealMutation(vars)
    }
  }

  const handleChange = (e: any) => {
    setSearchAllCustomers(e?.target?.value === 'true')
  }

  return (
    <>
      {mutationSuccess ? (
        <p>{`Successfully added document.`}</p>
      ) : (
        queryVars && (
          <>
            {parentComponent !== 'ConvertDocumentLevel' && (
              <div className={css.radioRow}>
                <p>{`Include deals from other customers?`}</p>
                <div>
                  <RadioButton
                    handleChange={handleChange}
                    id={'searchAllCustomers_true'}
                    isSelected={!!searchAllCustomers}
                    label={'Yes'}
                    name={'searchAllCustomers'}
                    style={{ marginRight: '16px' }}
                    value={true}
                  />
                  <RadioButton
                    handleChange={handleChange}
                    id={'searchAllCustomers_false'}
                    isSelected={!searchAllCustomers}
                    label={'No'}
                    name={'searchAllCustomers'}
                    value={'false'}
                  />
                </div>
              </div>
            )}
            <InlineQueryMenu
              handleChange={handleAdd}
              keepOpen
              loading={counterPartyDealsLoading}
              mutationVars={mutationVars}
              queryData={counterPartyDeals}
              queryFunction={getCounterPartyDeals}
              queryItemName={'documents'}
              queryName={'Deals by Counterparty'}
              queryNoResultsMessage={'No deals found. Deals that the document already belongs to are not shown in this list.'}
              queryPlaceholder={'Input a name and press enter to search'}
              querySubName={'Add doc to deal'}
              queryVars={queryVars}
              resetOffset={resetOffset}
              setResetOffset={setResetOffset}
            />
          </>
        )
      )}
      {!parentComponent && (
        <div className={css.modalButtonRow}>
          <Button onClick={handleClose} variant={'secondary'}>
            {mutationSuccess ? 'Close' : 'Cancel'}
          </Button>
        </div>
      )}
    </>
  )
}
