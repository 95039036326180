import { Column } from '../../reducers/dashboardQueryReducer'
import { FiX } from 'react-icons/fi'
import { decodeQuery, encodeQuery } from '../../utils/dashboardQueryUtils'
import Button from '../Button'
import QueryBuilder, { FieldSelectorProps, OperatorSelectorProps, RuleGroupType } from 'react-querybuilder'
import React, { Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useState } from 'react'
import SavedQueryModal from './SavedQueryModal'
import SavedQuerySelector from './SavedQuerySelector'
import SelectInput from '../SelectInput'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import usePrevQuery from '../../hooks/usePrevQuery'
import usePrevQueryType from '../../hooks/usePrevQueryType'
import valueEditor from './valueEditor'

interface Props {
  clearAll: boolean
  fields: any // which fields to display
  filteredCount: number
  handleSearch: (x?: RuleGroupType | null) => void
  isDealsDashboard?: boolean
  query: RuleGroupType | null // optionally pass querybuilder rules to display on first render
  recordType: string
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  totalCount: number
}

export default function AdvancedSearch({
  clearAll,
  fields,
  filteredCount,
  handleSearch,
  isDealsDashboard,
  query,
  recordType,
  selectedColumns,
  setClearAll,
  totalCount
}: Props) {
  const filteredPlurality = filteredCount === 1 ? '' : 's'
  const totalPlurality = totalCount === 1 ? '' : 's'
  const [tempQuery, setTempQuery] = useState<RuleGroupType | null>(query)
  const prevQuery = usePrevQuery()
  const prevQueryType = usePrevQueryType()
  const [searchHasNotFired, setSearchHasNotFired] = useState(false)

  useEffect(() => setTempQuery(query), [query])

  const handleClearAll = useCallback(() => {
    if (prevQueryType) {
      localStorage.removeItem(prevQueryType)
    }

    setTempQuery(null)

    handleSearch()
  }, [handleSearch, prevQueryType])

  useEffect(() => {
    if (clearAll) {
      handleClearAll()

      setClearAll(false)
    }
  }, [clearAll, handleClearAll, setClearAll])

  useEffect(() => {
    if (tempQuery && !prevQuery && !window.location.search) {
      setSearchHasNotFired(true)
    }
    // eslint-disable-next-line
  }, [tempQuery])

  const renderSelector = (p: PropsWithChildren<FieldSelectorProps | OperatorSelectorProps>) => {
    const handleChange = ({ name }: { name: string }) => {
      p.handleOnChange(name)
    }

    // This is a mess, but the querybuilder defines an option with keys 'name' and 'label', React Select requires a '
    const sortedOptions = p.options
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
      .map(opt => ({ ...opt, value: opt.name }))
    const value = sortedOptions.filter((opt: any) => opt.name === p.value)

    return (
      <span className="fieldSelector">
        <SelectInput onChange={handleChange} options={sortedOptions} placeholder="select" value={value} />
      </span>
    )
  }

  const handleQueryChange = useCallback(
    (newQuery: RuleGroupType, prevTempQuery?: RuleGroupType | null) => {
      // If rule has been removed, trigger search. Checking for rule length clears the query if there are no rules
      if (newQuery?.rules?.length < (prevTempQuery?.rules?.length as number)) {
        if (newQuery.rules.length === 0 && prevQueryType) {
          localStorage.removeItem(prevQueryType)
        }
        handleSearch(newQuery.rules.length === 0 ? null : newQuery)
        setSearchHasNotFired(false)
      } else if (prevQuery && newQuery?.rules?.length > 0) {
        // this block checks if the temp query is different from the saved query and changes the search button color if it is
        const { q } = prevQuery || {}
        const decodedPrevQuery = decodeQuery(q as string) || undefined
        const encodedPrevQuery = encodeQuery(decodedPrevQuery)
        const encodedTempQuery = encodeQuery(newQuery)
        setSearchHasNotFired(encodedTempQuery !== encodedPrevQuery)
      }
      setTempQuery(newQuery)
      // eslint-disable-next-line
    }, [handleSearch, prevQueryType]
  )

  const areQueryRules = !!tempQuery?.rules?.length

  return (
    <div
      className={css.wrapper}
      onKeyUp={e => {
        if (e.key === 'Enter') handleSearch(tempQuery)
      }}
    >
      <QueryBuilder
        controlElements={{
          fieldSelector: renderSelector,
          operatorSelector: renderSelector,
          // eslint-disable-next-line react/display-name
          addRuleAction: actionProps => {
            return (
              <div className={css.buttonRow}>
                <div>
                  <Button noWrap onClick={actionProps?.handleOnClick} variant="tertiary">
                    {areQueryRules ? '+ Add Search Rule' : 'Advanced Search'}
                  </Button>

                  <SavedQuerySelector isDealsDashboard={isDealsDashboard} />

                  {areQueryRules && !!totalCount && (
                    <div className={css.buttonRowResults}>{`${totalCount} ${recordType}${totalPlurality}${
                      totalCount !== filteredCount ? `, ${filteredCount} result${filteredPlurality}` : ''
                    }`}</div>
                  )}
                </div>

                {areQueryRules && (
                  <div>
                    <div style={{ marginRight: 12 }}>
                      <SavedQueryModal isDealsDashboard={isDealsDashboard || false} selectedColumns={selectedColumns} tempQuery={tempQuery} />
                    </div>

                    <Button onClick={handleClearAll} variant="tertiary">
                      Clear All
                    </Button>

                    {searchHasNotFired ? (
                      <WithTooltip content="This search has not been fired yet">
                        <Button
                          onClick={() => {
                            setSearchHasNotFired(false)
                            handleSearch(tempQuery)
                          }}
                          variant="primary"
                        >{`Search`}</Button>
                      </WithTooltip>
                    ) : (
                      <WithTooltip content="Fire search again">
                        <Button
                          onClick={() => {
                            setSearchHasNotFired(false)
                            handleSearch(tempQuery)
                          }}
                          variant="secondary"
                        >{`Search`}</Button>
                      </WithTooltip>
                    )}
                  </div>
                )}
              </div>
            )
          },
          addGroupAction: () => null, // no thanks, just one level here
          // eslint-disable-next-line react/display-name
          removeRuleAction: actionProps => {
            return (
              <Button onClick={actionProps?.handleOnClick} variant="secondary">
                <FiX />
              </Button>
            )
          },
          valueEditor
        }} // this is for typescript - query won't accept null
        // @ts-ignore
        fields={fields && Object.values(fields)?.filter(field => field?.filter_allowed !== false)}
        onQueryChange={query => handleQueryChange(query, tempQuery)}
        query={tempQuery || undefined}
        resetOnFieldChange
        resetOnOperatorChange // resets the input values, which causes errors with different types
        showCombinatorsBetweenRules // same as above
      />
    </div>
  )
}
