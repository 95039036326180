import { EditDocumentTypes } from './EditDocumentTypes'
import { EditField } from './EditField'
import { useCciDocumentTypesLazyQuery, useCciGroupsLazyQuery } from '../../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../../CCI_Main'
import CreateField from './CreateField'
import React, { FC, createContext, useContext, useEffect, useMemo, useState } from 'react'
import ViewField from './ViewField'
import useUserAccess from '../../../../../../hooks/useUserAccess'

// types

interface CciChecklistContextInterface {
  docTypesLoading: boolean
  documentTypes?: any
  groupOptions?: any
  groupsLoading: boolean
}

// context

export const CciChecklistContext = createContext<CciChecklistContextInterface | null>(null)

// hooks

export const useCciChecklistContext = () => {
  const context = useContext(CciChecklistContext)

  if (context === null) {
    throw new Error()
  }

  return context
}

// components

export const CCI_RightPanel_ChecklistTab_Main: FC = () => {
  const { activeComponent } = useCciMainContext()
  const [groupOptions, setGroupOptions] = useState<any>(false)
  const [documentTypes, setDocumentTypes] = useState<any>(false)
  const hasEditAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'EDIT_FIELD' })
  const hasLimitedEditAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'EDIT_FIELD_LIMITED' })

  const [getGroups, { data: groupData, loading: groupsLoading }] = useCciGroupsLazyQuery({
    context: { queryName: 'CCI_RightPanel_ChecklistTab_Main.tsx getGroups' }
  })
  const [getDocumentTypes, { data: docTypesData, loading: docTypesLoading }] = useCciDocumentTypesLazyQuery({
    context: { queryName: `CCI_RightPanel_ChecklistTab_Main.tsx getDocumentTypes` }
  })

  useEffect(() => {
    if (hasEditAccess || hasLimitedEditAccess) {
      getGroups()
      getDocumentTypes()
    }
  }, [hasEditAccess, hasLimitedEditAccess, getGroups, getDocumentTypes])

  useEffect(() => {
    setGroupOptions(
      groupData?.cci_groups?.map(item => {
        return { value: item, label: item }
      })
    )
  }, [groupData])

  // onCompleted for getDocumentTypes refused to work, so went with this useEffect instead.
  useEffect(() => {
    setDocumentTypes(
      docTypesData?.cci_document_types?.map((item: any) => {
        return { label: item?.name, value: item?.name }
      })
    )
  }, [docTypesData])

  const context = useMemo(
    () => ({ docTypesLoading, documentTypes, groupOptions, groupsLoading }),
    [docTypesLoading, documentTypes, groupOptions, groupsLoading]
  )

  return (
    <CciChecklistContext.Provider value={context}>
      {(activeComponent === '' || activeComponent === 'Delete Field' || activeComponent === 'Edit Field') && (
        <>{hasEditAccess || hasLimitedEditAccess ? <EditField hasFullAccess={hasEditAccess} /> : <ViewField />}</>
      )}
      {activeComponent === 'Create Field' && <CreateField />}
      {activeComponent === 'Edit Document Types' && <EditDocumentTypes />}
    </CciChecklistContext.Provider>
  )
}
