import { Alert, Box, IconButton, Snackbar, Tooltip } from '@mui/material'
import { DataPointDataTable } from '../../../graphql/codegen/schemas'
import { ExportToCsv } from 'export-to-csv'
import { Opening, useOpening } from '@klarity/use-opening'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { toast } from 'react-toastify'
import { useDataPointsQuery, useUpdateDataPointDataTableRowsMutation } from '../../../graphql/codegen/hooks'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DataGrid, { TextEditor } from 'react-data-grid'
import DownloadIcon from '@mui/icons-material/Download'
import Loader from '../../Loader'
import React, { Dispatch, FC, SetStateAction, SyntheticEvent, useMemo, useState } from 'react'
import copy from 'copy-to-clipboard'
import css from './style.module.scss'

// types

interface DataTableProps {
  dealIsFinalized: boolean
  setActiveTableId: Dispatch<SetStateAction<string | null>>
  tableId: string
}

interface Row {
  [k: string]: number | string
  id: number
}

// functions

const copyDataTable = (dataTable: DataPointDataTable, snackbarOpening: Opening) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  copy([columns, ...rows].map(row => row.map(cell => cell).join('\t')).join('\n'), { format: 'text/plain' })

  snackbarOpening.open()
}

const downloadDataTableCsvFile = (dataTable: DataPointDataTable) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  const options = {
    filename: 'table',
    headers: columns.map(column => `"${column}"`),
    showLabels: true
  }

  new ExportToCsv(options).generateCsv(rows)
}

const getDataTableColumnsAndRows = (dataTable: DataPointDataTable): [string[], string[][]] => {
  const columns = dataTable.columns as string[]
  const rows = (JSON.parse(dataTable.rows!) as string[]).map(row => Object.values(row))

  return [columns, rows]
}

// components

export const DataTable: FC<DataTableProps> = ({ dealIsFinalized, setActiveTableId, tableId }) => {
  const MINIMUM_HEIGHT = 218
  const copyOpening = useOpening()
  const [rows, setRows] = useState<Row[]>([])
  const [absoluteWidth, setAbsoluteWidth] = useState<number>(200)
  const [absoluteHeight, setAbsoluteHeight] = useState<number>(MINIMUM_HEIGHT)
  const [absoluteTop, setAbsoluteTop] = useState<number>(0)
  const GO_TO_NORTH_DIRECTION = 'n'

  const { data, loading } = useDataPointsQuery({
    context: { queryName: `DataTable.tsx useDataPointsQuery` },
    variables: { id: tableId },
    onCompleted: ({ data_points }) => {
      const { rows } = data_points?.edges[0]?.node?.value_data_table || {}
      if (rows) {
        try {
          return setRows(JSON.parse(rows))
        } catch (e) {
          console.error(e)
        }
      }
      const fallbackRows = Array(10)
        .fill(true)
        .map((_, idx) => ({ id: idx }))
      return setRows(fallbackRows)
    }
  })
  const [update] = useUpdateDataPointDataTableRowsMutation({
    context: { queryName: `DataTable.tsx update` },
    onCompleted: () => {
      toast.success('Saved changes to spreadsheet.', { autoClose: 5000, position: 'bottom-left' })
    }
  })

  const dataTable = data?.data_points?.edges[0]?.node?.value_data_table

  const columns = useMemo(() => {
    const formattedColumns = []

    for (const name of dataTable?.columns || []) {
      if (name) {
        const formattedName = name.toLowerCase().split(' ').join('_')
        formattedColumns.push({
          name: name.includes('Unnamed') ? '' : name,
          key: formattedName,
          editor: TextEditor,
          // this enables the scroll option for the cells with a lot of text
          cellClass: css.enablesTextWrapping
        })
      }
    }

    return formattedColumns
  }, [dataTable])

  const handleSave = (rows: any) => {
    update({
      variables: {
        dataPointId: tableId,
        rows: JSON.stringify(rows) // Rows will have dynamic keys based on column headers, so we have to skip building a custom gql input
      }
    })
  }

  const onResizeAbsolute = (event: SyntheticEvent, resizeCallback: ResizeCallbackData) => {
    if (!event) return null
    const { handle, size } = resizeCallback
    let newTop = absoluteTop
    const deltaHeight = size.height - absoluteHeight

    if (handle[0] === GO_TO_NORTH_DIRECTION) {
      newTop -= deltaHeight
    }

    const MAXIMUM_HEIGHT = 520
    // Todo: add higher maximum height values for bigger screens
    if (size.height <= MAXIMUM_HEIGHT && size.height >= MINIMUM_HEIGHT) {
      setAbsoluteWidth(size.width)
      setAbsoluteHeight(size.height)
      setAbsoluteTop(newTop)
    }
  }

  return (
    <Resizable
      className={css.dataTableSlideout}
      handle={<div className={css.customResizeHandler} />}
      height={absoluteHeight}
      onResize={onResizeAbsolute}
      resizeHandles={[GO_TO_NORTH_DIRECTION]}
      width={absoluteWidth}
    >
      <div
        style={{
          width: absoluteWidth,
          height: absoluteHeight,
          margin: `${absoluteTop} 0 0 0`
        }}
      >
        {loading || !rows ? (
          <Loader />
        ) : (
          <>
            <Box alignItems="center" bgcolor="#f3f4f8" display="flex" flexDirection="row-reverse" justifyContent="space-between" padding="8px 4px 4px">
              <Box display="flex">
                <Tooltip arrow title="Add Row">
                  <IconButton onClick={() => setRows(prev => [...prev, { id: prev[prev.length - 1].id + 1 }])} size="small">
                    <AddIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip arrow title="Close Table">
                  <IconButton onClick={() => setActiveTableId(null)} size="small">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {Boolean(dataTable) && (
                <Box display="flex">
                  <Tooltip arrow title="Copy Table">
                    <IconButton onClick={() => copyDataTable(dataTable as DataPointDataTable, copyOpening)} size="small">
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip arrow title="Download Table">
                    <IconButton onClick={() => downloadDataTableCsvFile(dataTable as DataPointDataTable)} size="small">
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <DataGrid
              className="rdg-light"
              columns={columns}
              defaultColumnOptions={{ resizable: true }}
              onRowsChange={
                dealIsFinalized
                  ? undefined
                  : r => {
                      handleSave(r)
                      setRows(r)
                    }
              }
              rowHeight={(a: any) => {
                // this adds the row height dynamically depending on the content length
                const MAXIMUM_TEXT_CHARS = 41
                const DEFAULT_ROW_HEIGHT = 40
                const BIG_ROW_HEIGHT = 60
                if (a?.row?.item_name?.length > MAXIMUM_TEXT_CHARS) {
                  return BIG_ROW_HEIGHT
                }
                return DEFAULT_ROW_HEIGHT
              }}
              rows={rows}
              style={{ height: absoluteHeight }}
            />

            <Snackbar autoHideDuration={3000} onClose={copyOpening.close} open={copyOpening.isOpen}>
              <Alert severity="success">Table copied.</Alert>
            </Snackbar>
          </>
        )}
      </div>
    </Resizable>
  )
}
