import { formatDate } from '../../utils/datetimeUtils'
import { useCounterPartyDealsLazyQuery } from '../../graphql/codegen/hooks'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import { useParams } from 'react-router-dom'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import QueryMenu from '../QueryMenu'
import React, { ReactNode, useState } from 'react'
import RenameFile from '../RenameFile'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

interface Props {
  alias?: any
  breadcrumbSource: string
  contentRight: ReactNode
  counterpartyId: string | undefined
  dealIsFinalized?: boolean
  hasDealAccess?: boolean
  name?: any
  onBack: () => void
  sourceInfo?: any
  subTitle: string | ReactNode
}

function PageHeader({ alias, breadcrumbSource, contentRight, counterpartyId, dealIsFinalized, hasDealAccess, name, onBack, sourceInfo, subTitle }: Props) {
  const isKlarityEmployee = useIsKlarityEmployee()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [modalLoading, setModalLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')

  const [getCounterPartyDeals, { data: queryData, loading: queryLoading }] = useCounterPartyDealsLazyQuery({
    context: { queryName: `PageHeader.tsx useCounterPartyDealsLazyQuery` },
    fetchPolicy: 'no-cache' // Need to always get fresh search results from backend.
  })

  const openModal = () => {
    setIsOpen(true)
    setModalOption(`Rename ${dealId ? 'Deal' : 'Document'}`)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
    }
  }

  const uploadDate = sourceInfo?.created_at ? formatDate(sourceInfo?.created_at) : undefined
  const uploadedBy = sourceInfo?.created_by?.user_name?.includes('klaritylaw.com')
    ? isKlarityEmployee
      ? sourceInfo?.created_by?.user_name
      : 'Klarity'
    : sourceInfo?.created_by?.user_name
  const titleTooltipContent = (
    <>
      {alias || name}
      {sourceInfo?.integration_type && <div>{`Source: ${sourceInfo?.integration_type}`}</div>}
      {sourceInfo?.integration_type === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
      {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
      {!dealIsFinalized && <div>{`(Click to rename${dealId ? ' Deal' : documentId ? ' Document' : ''})`}</div>}
    </>
  )

  return (
    <>
      <div className={css.pageHeader}>
        <div className={css.contentLeft}>
          <span className={css.breadcrumbSource}>
            <span onClick={onBack}>{breadcrumbSource}</span>&nbsp;<span>/</span>&nbsp;
          </span>
          {counterpartyId && hasDealAccess ? (
            // @ts-ignore
            <div
              onClick={() => {
                getCounterPartyDeals({ variables: { counterpartyId, size: 20 } })
              }}
            >
              <WithTooltip content={"View customer's deals"}>
                <QueryMenu
                  label={String(subTitle)}
                  loading={queryLoading}
                  placement={'right'}
                  queryData={queryData}
                  queryFunction={getCounterPartyDeals}
                  queryItemName={'deals'}
                  queryPlaceholder={'Search for deal…'}
                  queryVars={{ required: { counterpartyId }, optional: {} }}
                  style={{ display: 'flex', height: '27px' }}
                  styleOffset={[20, 10]}
                />
              </WithTooltip>
            </div>
          ) : (
            subTitle && <span className={css.subTitle}>{subTitle}</span>
          )}
          <WithTooltip content={titleTooltipContent}>
            <span className={css.title} onClick={dealIsFinalized ? undefined : openModal} style={{ cursor: dealIsFinalized ? 'text' : 'pointer' }}>
              {alias || name}
            </span>
          </WithTooltip>
        </div>
        <div className={css.contentRight}>{contentRight}</div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalOption}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          <RenameFile
            closeModal={closeModal}
            dealId={dealId}
            documentId={documentId}
            fileAlias={alias}
            fileName={name}
            fileType={dealId ? 'Deal' : 'Document'}
            loading={modalLoading}
            setLoading={setModalLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </div>
      </Modal>
    </>
  )
}

export default PageHeader
