import React, { useEffect, useRef } from 'react'
import Select, { components } from 'react-select'

function Dropdown({
  data_point_field,
  isDisabled,
  isExpanded,
  isFocused,
  isMulti,
  onChange,
  setFocused,
  value
}: {
  data_point_field: any
  isDisabled?: boolean
  isExpanded?: boolean
  isFocused: boolean
  isMulti?: boolean
  onChange?: (e: any) => void
  setFocused: (arg: boolean) => void
  value: any
}) {
  const ref = useRef<HTMLElement>()
  const { name, options } = data_point_field

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  const MultiValueContainer = (props: any) => {
    // https://react-select.com/components#replaceable-components
    // setting title on the container didn't work, had to add to props.innerprops
    return <components.MultiValueContainer {...{ ...props, innerProps: { ...props?.innerProps, title: props?.data?.label } }} />
  }

  return (
    // @ts-ignore
    <Select
      components={{ MultiValueContainer }}
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      onBlur={() => setFocused(false)}
      onChange={(e: any) => {
        if (onChange) {
          onChange(e)
        }
      }}
      onFocus={() => setFocused(true)}
      openMenuOnFocus
      options={options.map((label: string) => ({ value: label.toLowerCase().replace(' ', '_'), label }))}
      placeholder={name}
      // @ts-ignore
      ref={ref}
      styles={{
        control: provided => {
          return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }
        },
        option: provided => {
          return { ...provided, fontSize: 14 }
        },
        container: provided => {
          return { ...provided, padding: 0 }
        },
        multiValue: provided => {
          return { ...provided, maxWidth: '300px' }
        },
        placeholder: provided => {
          return { ...provided, color: '#e0e0e0' }
        },
        valueContainer: (provided, state) => {
          let styleOverrides = {}
          if (state.isMulti && isExpanded) {
            styleOverrides = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
          }
          return { ...provided, ...styleOverrides }
        },
        dropdownIndicator: () => {
          return { display: 'none' }
        },
        indicatorSeparator: () => {
          return { display: 'none' }
        },
        clearIndicator: (provided, { isFocused }) => {
          return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
        },
        menuPortal: provided => {
          return { ...provided, zIndex: 999999999 }
        }
      }}
      tabSelectsValue={false}
      theme={theme => {
        return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
      }}
      value={value}
    />
  )
}

export default Dropdown
