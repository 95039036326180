import React, { useEffect, useRef } from 'react'
import Select from 'react-select'

function Dropdown({
  data_point_field,
  isError,
  isFocused,
  isMulti,
  onChange,
  setFocused,
  value
}: {
  data_point_field: any
  isError: boolean
  isFocused: boolean
  isMulti?: boolean
  onChange?: (e: any) => void
  setFocused: (arg: boolean) => void
  value: any
}) {
  const ref = useRef<HTMLElement>()
  const { name, options } = data_point_field

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  return (
    <Select
      // @ts-ignore
      isClearable
      isMulti={isMulti}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      onBlur={() => setFocused(false)}
      onChange={onChange}
      onFocus={() => setFocused(true)}
      openMenuOnFocus
      options={options.map((label: string) => ({ value: label.toLowerCase().replace(' ', '_'), label }))}
      placeholder={name}
      // @ts-ignore
      ref={ref}
      styles={{
        control: provided => {
          return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none', border: `${isError ? '1px solid red' : ''}` }
        },
        option: provided => {
          return { ...provided, fontSize: 14 }
        },
        container: provided => {
          return { ...provided, padding: 0 }
        },
        multiValue: provided => {
          return { ...provided, maxWidth: '300px' }
        },
        placeholder: provided => ({ ...provided, color: '#e0e0e0' }),
        dropdownIndicator: () => ({ display: 'none' }),
        indicatorSeparator: () => ({ display: 'none' }),
        clearIndicator: (provided, { isFocused }) => {
          return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
        },
        menuPortal: provided => ({ ...provided, zIndex: 999999999 })
      }}
      tabSelectsValue={false}
      theme={theme => {
        return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
      }}
      value={value}
    />
  )
}

export default Dropdown
