import { API_ROOT } from '../../utils/apiUtils'
import { getAccessToken } from '../../utils/sessionApiUtils'
import Button from '../../components/Button'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../components/SelectInput'
import WithTooltip from '../../components/WithTooltip'
import axios from 'axios'

interface Customer {
  id: string
  name: string
}

// NOTE: This 'page' is sometimes rendered outside the context of the app, so it cannot access Apollo state
export default function Admin() {
  const [selected, setSelected] = useState<{ cid: string; name: string } | null>(null)
  const [customerOptions, setCustomerOptions] = useState<null | Customer[]>(null)

  useEffect(() => {
    async function fetchUsers() {
      const accessToken = await getAccessToken()
      const res = await axios.get(`${API_ROOT}/user/customers`, { headers: { Authorization: `Bearer: ${accessToken}` } })
      const { customers } = res.data
      setCustomerOptions(
        customers.map(({ id, name }: Customer) => {
          return { label: name, value: id }
        })
      )
    }
    fetchUsers()
    localStorage.setItem('isAnnotator', 'true')
  }, [])

  const handleChange = ({ label, value }: { label: string; value: string }) => {
    setSelected({ name: label, cid: value })
  }
  const handleSubmit = () => {
    if (!selected) throw new Error('Admin must select customer')
    localStorage.setItem('cid', selected.cid)
    localStorage.setItem('customerName', selected.name)
    clearPrevQueriesAndColumns()
    window.location.href = '/' // Handle this outside of react-router context so that requests are re-submitted
  }

  return customerOptions ? (
    <div style={{ width: '100%', margin: 'auto', maxWidth: 600 }}>
      <h2 style={{ marginBottom: '8px' }}>{`Select Customer`}</h2>
      <SelectInput loading={!customerOptions} onChange={handleChange} options={customerOptions} selected={selected} />
      <WithTooltip content={!selected ? 'Select a customer' : ''}>
        <Button disabled={!selected} onClick={handleSubmit} style={{ width: '100%', marginTop: '16px' }}>{`Go to Dashboard`}</Button>
      </WithTooltip>
    </div>
  ) : null
}

export const clearPrevQueriesAndColumns = () => {
  localStorage.removeItem('PREVIOUS_DEAL_QUERY')
  localStorage.removeItem('PREVIOUS_DOCUMENT_QUERY')
  localStorage.removeItem('PREVIOUS_ATTACHMENT_QUERY')
  Object.keys(localStorage)?.forEach(key => {
    if (key.includes('KLARITY_SELECTED_COLUMNS')) {
      localStorage.removeItem(key)
    }
  })
}
