import { useCciMainContext } from '../../../CCI_Main'
import { useCreateIntegrationMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function ReviewNewIntegration({ closeModal, values }: { closeModal?: any; values?: any }) {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [createIntegrationMutation, { error: createIntegrationError, loading: createIntegrationLoading }] = useCreateIntegrationMutation({
    context: { queryName: `ReviewNewIntegration.tsx createIntegrationMutation` },
    onCompleted: () => {
      setModalSuccess('Integration successfully created')
    }
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (createIntegrationLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating Integration…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [createIntegrationLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(createIntegrationError)}, [createIntegrationError])

  const handleSubmit = () => {
    // console.info('submitted values:', {role_ids, ...localValues})
    createIntegrationMutation({ variables: { ...values } })
  }

  // this capitalizes the first letter of a string and lowercases the rest
  const formatLabel = (string: string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
  }

  return (
    <>
      {values?.integration_type && (
        <div className={css.reviewDiv}>
          <h4>{`Integration Type:`}</h4>
          <p>{formatLabel(values?.integration_type)}</p>
        </div>
      )}
      {values?.token_type && (
        <div className={css.reviewDiv}>
          <h4>{`Token Type:`}</h4>
          <p>{formatLabel(values?.token_type)}</p>
        </div>
      )}
      {values?.token && (
        <div className={css.reviewDiv}>
          <h4>{`Token:`}</h4>
          <p>{values?.token}</p>
        </div>
      )}
      {values?.api_url && (
        <div className={css.reviewDiv}>
          <h4>{`API URL:`}</h4>
          <p>{values?.api_url}</p>
        </div>
      )}
      {values?.user_name && (
        <div className={css.reviewDiv}>
          <h4>{`Username:`}</h4>
          <p>{values?.user_name}</p>
        </div>
      )}
      {values?.password && (
        <div className={css.reviewDiv}>
          <h4>{`Password:`}</h4>
          <p>{values?.password}</p>
        </div>
      )}
      {values?.domain && (
        <div className={css.reviewDiv}>
          <h4>{`Domain:`}</h4>
          <p>{values?.domain}</p>
        </div>
      )}
      {values?.organization_id && (
        <div className={css.reviewDiv}>
          <h4>{`Organization Id:`}</h4>
          <p>{values?.organization_id}</p>
        </div>
      )}

      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
