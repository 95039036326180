import useCurrentUser from './useCurrentUser'

export default function useUserAccess({ feature, permission }: { feature: string; permission: string }) {
  const currentUser = useCurrentUser()

  for (const r of currentUser?.roles?.edges || []) {
    for (const f of r?.node?.features?.edges || []) {
      if (!f?.node) continue
      if (f.node.name === feature && f.node.permission === permission) {
        return true
      }
    }
  }

  return false
}

// Simple wrapper around Annotator access for easier RBAC
export const useIsAnnotator = () => {
  return useUserAccess({ feature: 'Annotator', permission: 'READ' })
}

export const useIsSuperAnnotator = () => {
  return useUserAccess({ feature: 'SuperAnnotator', permission: 'READ' })
}
