import 'rc-collapse/assets/index.css'
import { Box, Button } from '@mui/material'
import { ChevronDown } from 'react-feather'
import { DataPoint } from '../../graphql/codegen/schemas'
import { DealChecklistFooterContainer, DocumentChecklistFooterContainer } from './Footer'
import { SearchBar } from './SearchBar'
import { countTruthyValuesForDataPointGroup } from '../../utils/dataPointUtils'
import { formatKey } from '../../utils/stringUtils'
import { isEmpty } from 'lodash'
import { useChecklistGroupsQuery, useDataPointsForGroupQuery } from '../../graphql/codegen/hooks'
import ChecklistSkeleton, { GroupDataPointsSkeleton } from './ChecklistSkeleton'
import Collapse, { Panel } from 'rc-collapse'
import DatapointField from '../DatapointField'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useUserAccess from '../../hooks/useUserAccess'

// types

interface ChecklistTabProps {
  dealId?: string
  dealIsFinalized: boolean
  documentId?: string
  showDataPointDetailOverlay?: boolean
}

interface GroupDataPointsProps {
  dealIsFinalized: boolean
  filter: string
  group: string
  index: number
  isDisabled: boolean
  isDocumentId: boolean
  resourceId: string
  selectedAnnotationId: string | null
  showDataPointDetailOverlay?: boolean
  updateDataPoints: (dataPoints: DataPoint[], index: number) => void
}

// components

export const ChecklistTab = ({ dealId, dealIsFinalized, documentId, showDataPointDetailOverlay }: ChecklistTabProps) => {
  const { data: checkListGroupsData, error: checklistGroupsError } = useChecklistGroupsQuery({ variables: { resourceId: dealId || documentId! } })
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const checklistGroups = useMemo(() => (checkListGroupsData ? (checkListGroupsData.checklist_groups as string[]) : null), [checkListGroupsData])
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [dataPoints, setDataPoints] = useState<{ [key: number]: DataPoint[] }>({})
  const [filter, setFilter] = useState('')
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | null>(null)
  const hasDealAccess = useUserAccess({ feature: 'Deal', permission: 'READ' })

  const setSelectedAnnotationIdFromHash = () => {
    const id = document.location.hash.slice('#highlight-'.length)

    if (checklistGroups && id) {
      setActiveKeys(checklistGroups.map(formatKey))

      setSelectedAnnotationId(id)
    } else setSelectedAnnotationId(null)
  }

  const updateDataPoints = useCallback(
    (dataPoints: DataPoint[], index: number) =>
      setDataPoints(previous => {
        const current = { ...previous }

        current[index] = dataPoints

        return current
      }),
    []
  )

  useEffect(() => {
    if (checklistGroups && filter) {
      setActiveKeys(checklistGroups.map(formatKey))
    }
  }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checklistGroups) {
      setSelectedAnnotationIdFromHash()

      window.addEventListener('hashchange', setSelectedAnnotationIdFromHash, false)

      return () => window.removeEventListener('hashchange', setSelectedAnnotationIdFromHash, false)
    }
  }, [checklistGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!checklistGroups) return <ChecklistSkeleton />
  if (checklistGroupsError) return <>Error</>

  const hasFooter = !!dealId || (!!documentId && !hasDealAccess)

  const isDisabled = Object.keys(dataPoints).length !== checklistGroups.length

  return (
    <>
      <SearchBar isDisabled={isDisabled} setFilter={setFilter} value={filter} />

      <Box display="flex" gap={1} justifyContent="flex-end" sx={{ pb: 2, px: 2 }}>
        <Button color="tertiary" onClick={() => setActiveKeys(checklistGroups.map(formatKey))} size="small" variant="outlined">
          Expand all
        </Button>

        <Button color="secondary" onClick={() => setActiveKeys([])} size="small" variant="outlined">
          Collapse all
        </Button>
      </Box>

      <div className={clsx(css.inner, !hasFooter && css.noFooter)} style={{ backgroundColor: '#fff' }}>
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {checklistGroups.map((checklistGroup, index) => (
            <GroupDataPoints
              dealIsFinalized={dealIsFinalized}
              filter={filter.toLowerCase()}
              group={checklistGroup}
              index={index}
              isDisabled={isDisabled}
              isDocumentId={Boolean(documentId)}
              key={formatKey(checklistGroup)}
              resourceId={dealId || documentId!}
              selectedAnnotationId={selectedAnnotationId}
              showDataPointDetailOverlay={showDataPointDetailOverlay}
              updateDataPoints={updateDataPoints}
            />
          ))}
        </Collapse>
      </div>

      {dealId && <DealChecklistFooterContainer dealIsFinalized={dealIsFinalized} />}

      {documentId && (!hasDealAccess || isRapid7LegalTeam) && <DocumentChecklistFooterContainer />}
    </>
  )
}

const GroupDataPoints = ({
  dealIsFinalized,
  filter,
  group,
  index,
  isDisabled,
  isDocumentId,
  resourceId,
  selectedAnnotationId,
  showDataPointDetailOverlay,
  updateDataPoints,
  ...props
}: GroupDataPointsProps) => {
  const { data: groupDataPointsData } = useDataPointsForGroupQuery({ variables: { group, resourceId } })

  const groupDataPoints = useMemo(
    () =>
      groupDataPointsData
        ? [...(groupDataPointsData.data_points_for_group as DataPoint[])]
            .filter(dataPoint => !filter || dataPoint.data_point_field!.name.toLowerCase().includes(filter))
            .sort((a, b) => (a.data_point_field!.priority > b.data_point_field!.priority ? 1 : -1))
        : null,
    [filter, groupDataPointsData]
  )

  const numComplete = useMemo(() => (groupDataPoints ? countTruthyValuesForDataPointGroup(groupDataPoints) : 0), [groupDataPoints])

  useEffect(() => {
    if (groupDataPoints) {
      updateDataPoints(groupDataPoints, index)
    }
  }, [groupDataPoints, index, updateDataPoints])

  if (!isDisabled && isEmpty(groupDataPoints)) return null

  return (
    <Panel
      {...props}
      header={
        <div className="groupHeader">
          <h3>{group}</h3>
          {groupDataPoints && isDocumentId && (
            <span>
              {numComplete} / {groupDataPoints.length} items completed
            </span>
          )}
        </div>
      }
    >
      {groupDataPoints ? (
        <ul>
          {groupDataPoints.map(dataPoint => (
            <DatapointField
              dataPoint={dataPoint}
              dealIsFinalized={dealIsFinalized}
              key={dataPoint.id}
              selectedAnnotationId={selectedAnnotationId}
              showDataPointDetailOverlay={showDataPointDetailOverlay}
            />
          ))}
        </ul>
      ) : (
        <GroupDataPointsSkeleton />
      )}
    </Panel>
  )
}
