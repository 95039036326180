import { ChevronDown } from 'react-feather'
import { formatKey } from '../../../../../../utils/stringUtils'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import { useCciUsersQuery } from '../../../../../../graphql/codegen/hooks'
import CCI_LeftPanel_UserCard from './CCI_LeftPanel_UserCard'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import Collapse, { Panel } from 'rc-collapse'
import React, { useEffect } from 'react'

export default function CCI_LeftPanel_UserList() {
  const { setContentData, setLeftpanelLoading, tab } = useCciMainContext()
  const { activeKeys, searchTerm, setActiveKeys, setSearchableData } = useCciLeftPanelContext()

  const { data: userData, loading: userDataLoading } = useCciUsersQuery({ context: { queryName: 'CCI_LeftPanel_UserList.tsx getUsers' } })

  // this handles resetting contentData from cached values when switching tabs
  useEffect(() => {
    if (tab === 'users') {
      setContentData(userData?.cci_users || '')

      setSearchableData(userData?.cci_users || '')
    }
  }, [setContentData, setSearchableData, tab, userData?.cci_users])

  useEffect(() => {
    if (!userData || userDataLoading) {
      setLeftpanelLoading(true)
    } else {
      setLeftpanelLoading(false)
    }
  }, [userData, userDataLoading, setLeftpanelLoading])

  return (
    <>
      {userDataLoading ? (
        <ChecklistSkeleton />
      ) : searchTerm ? (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {userData?.cci_users
            ?.filter(user => user?.user_name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map(user => (
              <Panel
                header={
                  <div className="groupHeader">
                    <h3>{user?.user_name}</h3>
                  </div>
                }
                key={formatKey(user?.user_name)}
              >
                <CCI_LeftPanel_UserCard user={user} />
              </Panel>
            ))}
        </Collapse>
      ) : (
        <Collapse
          activeKey={activeKeys}
          expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
          onChange={setActiveKeys}
        >
          {userData?.cci_users?.map(user => {
            return (
              <Panel
                header={
                  <div className="groupHeader">
                    <h3>{user?.user_name}</h3>
                  </div>
                }
                key={formatKey(user?.user_name)}
              >
                <CCI_LeftPanel_UserCard user={user} />
              </Panel>
            )
          })}
        </Collapse>
      )}
    </>
  )
}
