import React from 'react'
import ReactDatePicker from 'react-datepicker'
import css from './style.module.scss'

export default function DatePicker({ label, onChange, selected }: { label?: string; onChange: (datestring: Date | null) => void; selected: string | null }) {
  return (
    <div className={css.root}>
      {label && <div className={css.label}>{label}</div>}
      <ReactDatePicker onChange={onChange} selected={selected ? new Date(selected) : null} />
    </div>
  )
}
