import { useCciMainContext } from '../../../../CCI_Main'
import CreateIntegration from './CreateIntegration'
import EditIntegration from './EditIntegration'
import React from 'react'

export default function CCI_RightPanel_IntegrationsTab_Main() {
  const { activeComponent } = useCciMainContext()

  return (
    <>
      {activeComponent === '' && <EditIntegration />}
      {activeComponent === 'Create Integration' && <CreateIntegration />}
      {activeComponent === 'Edit Integration' && <EditIntegration />}
    </>
  )
}
