import { ChangeLog } from '../../../graphql/codegen/schemas'
import { Maybe } from 'graphql/jsutils/Maybe'
import { RiArrowRightSLine } from 'react-icons/ri'
import { formatTimeAgo } from '../../../utils/datetimeUtils'
import React, { useState } from 'react'
import css from './style.module.scss'
import useCurrentUser from '../../../hooks/useCurrentUser'

export default function ChangeLogItem({ changeLogItem }: { changeLogItem: Maybe<ChangeLog> }) {
  const currentUser = useCurrentUser()
  const [showValue, setShowValue] = useState(false)

  const { action_on, action_type, created_at, document_name, event, new_value, user_name } = changeLogItem || {}
  if (!changeLogItem) return null
  if (typeof event === 'string' && !['Collision Resolution', 'DataPoint'].includes(event)) return null

  const isChildDataPoint = document_name && !window.location.pathname.includes('documents')

  const author = currentUser?.user_name === user_name ? 'You' : user_name
  const action = `${action_type?.toLowerCase()}d`
  const actionOn =
    event === 'Collision Resolution' ? 'resolved value' : `${action_on?.split('_').join(' ')} ${isChildDataPoint ? `for document ${document_name}` : ''}`

  const date = `${formatTimeAgo(created_at)} ago`

  return (
    <div className={css.changeLogItem}>
      <span>{author}</span>
      <span> {action}</span>
      <span> {actionOn}</span>
      {showValue && <span> to {new_value}</span>}
      <span className={css.date}> {date}</span>
      {!showValue && (
        <span className={css.moreButton} onClick={() => setShowValue(true)}>
          {' '}
          show details
          <RiArrowRightSLine />
        </span>
      )}
    </div>
  )
}
