import { DataTable } from './DataTable'
import { DataTableContext } from '../DocumentPageWrapper'
import DocumentSelector from './DocumentSelector'
import PDFDoc from '../PDFDoc'
import PDFHighlighterDoc from '../PDFHighlighterDoc'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SummaryTab from './SummaryTab'
import Toolbar from './Toolbar'
import UploadTab from './UploadTab/UploadTab'
import clsx from 'clsx'
import css from './style.module.scss'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'
import useQString from '../../hooks/useQString'
import useUserAccess from '../../hooks/useUserAccess'

/**
 * PDF: pdf document processed with baked-in annotations
 * ORIGINAL_PDF: pdf document without comments.
 */
export type DocumentFormat = 'PDF' | 'ORIGINAL_PDF'
export interface DocumentProps {
  documentFormat: DocumentFormat
  documentId: string | null
  loading?: boolean
}
interface Props {
  counterparty?: any
  dealIsFinalized?: boolean
  documents: any
  loading: boolean
  parentName?: string
  withSelector?: boolean
}

function DocumentPanel({ counterparty, dealIsFinalized, documents, loading, parentName, withSelector }: Props) {
  const { isPreAnnot } = useIsPreAnnot()
  const documentWrapperRef = useRef<HTMLDivElement | null>(null)
  const [activeDocumentId, setActiveDocumentId] = useState<string | null>(null)
  const [documentFormat, setDocumentFormat] = useState<DocumentFormat>(isPreAnnot ? 'ORIGINAL_PDF' : 'PDF')
  const [showAllTags, setShowAllTags] = useState(false)
  const { documentTab } = useQString()
  const { activeTableId, setActiveTableId } = useContext(DataTableContext)
  // const [enableAreaSelection, setEnableAreaSelection] = useState(false);
  const hasDealUpdateAccess = useUserAccess({ feature: 'Deal', permission: 'UPDATE' })

  const { alias, name, visualizer_version } = documents[0] || {}
  const v2 = visualizer_version === 2

  useEffect(() => {
    // When receiving documents, set to view last document in the list to display recent addition.
    if (documentTab) {
      setActiveDocumentId(documentTab as string)
    } else if (documents.length) {
      setActiveDocumentId(documents[0].id)
    }
  }, [documentTab, documents])

  const scrollToPageAfterFormatChange = useRef<number>()

  const toggleDocumentFormat = () => {
    // Get current page number if PDFJSViewer exists
    // @ts-ignore
    scrollToPageAfterFormatChange.current = window.PdfViewer?.viewer.currentPageNumber
    setDocumentFormat(prevFormat => {
      return prevFormat === 'PDF' ? 'ORIGINAL_PDF' : 'PDF'
    })
  }

  const showToolbar = !['upload', 'summary'].includes(activeDocumentId as string)
  const [scale, setScale] = useState<'auto' | number>('auto')
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)

  const zoom = (factor: number) => {
    // TODO: This side effect is a hacky way to ensure that we don't have highlights that are duplicated every time the document resizes. As it stands, the popper component either stays in place or jumps to the 0,0 coordinates on the page instead of being removed, so we remove it here.
    const highlightRoot = document.getElementById('popper-root')
    if (highlightRoot) {
      highlightRoot.innerHTML = ''
    }

    setScale(prevScale => {
      let val
      if (prevScale === 'auto') {
        try {
          // Initially we set the scale to 'auto', but then we need to read the exact value in order to update it.
          const currentScale = window.PdfViewer.viewer.currentScale
          val = currentScale * factor
        } catch (error) {
          console.error(error)
          val = 1 // allow this to fail gracefully if window.PdfViewer doesn't exist, which should never be the case.
        }
      } else {
        val = prevScale * factor
      }

      return val
    })
  }

  return (
    <div className={css.documentPanel}>
      {showToolbar && (
        <Toolbar
          activeDocumentId={activeDocumentId}
          currentPage={currentPage}
          documentFormat={documentFormat}
          name={alias || name}
          setCurrentPage={setCurrentPage}
          setShowAllTags={setShowAllTags}
          showAllTags={showAllTags}
          toggleDocumentFormat={toggleDocumentFormat}
          totalPages={totalPages}
          v2={v2}
          zoom={zoom}
        />
      )}
      <div
        className={clsx(css.documentWrapper, withSelector && css.withSelector, v2 ? css.v2 : css.v1)}
        ref={documentWrapperRef}
        // this is a fix for the height of the upload tab add doc to deal height
        style={!loading && hasDealUpdateAccess && (!activeDocumentId || activeDocumentId === 'upload') ? { height: 'calc(100% - 16px - 60px)' } : {}}
      >
        {!loading && (!activeDocumentId || activeDocumentId === 'upload') ? (
          <UploadTab
            counterparty={counterparty}
            dealIsFinalized={dealIsFinalized || false}
            dealName={parentName}
            hasDealUpdateAccess={hasDealUpdateAccess}
            parentRef={documentWrapperRef}
          />
        ) : activeDocumentId === 'summary' ? (
          <SummaryTab dealIsFinalized={dealIsFinalized || false} />
        ) : v2 ? (
          <PDFHighlighterDoc
            dealIsFinalized={dealIsFinalized || false}
            documentFormat={documentFormat}
            documentId={activeDocumentId}
            onDocumentReady={() => {
              // If we stored a previous page number while switching document format, immediately scroll to that position on load
              const viewer = window.PdfViewer?.viewer
              const pageNumber = scrollToPageAfterFormatChange.current
              if (viewer && pageNumber) {
                viewer.scrollPageIntoView({ pageNumber })
              }
              setTotalPages(window.PdfViewer?.viewer?._pages?.length)
            }}
            onPageChange={setCurrentPage}
            scale={scale}
            showAllTags={showAllTags}
            showHighlights={documentFormat !== 'ORIGINAL_PDF'}
          />
        ) : (
          <PDFDoc documentFormat={documentFormat} documentId={activeDocumentId} loading={loading} />
        )}
      </div>
      {activeTableId && <DataTable dealIsFinalized={dealIsFinalized || false} setActiveTableId={setActiveTableId} tableId={activeTableId} />}
      {!loading && withSelector && (
        <DocumentSelector
          currentDocumentId={activeDocumentId}
          dealCounterparty={counterparty}
          dealIsFinalized={dealIsFinalized || false}
          documents={documents}
          hasDealUpdateAccess={hasDealUpdateAccess}
          setActiveDocumentId={setActiveDocumentId}
        />
      )}
    </div>
  )
}

export default DocumentPanel
