import './style.module.scss'
import React from 'react'

export default function RadioButton({
  handleChange,
  id,
  isSelected,
  label,
  name,
  value,
  ...rest
}: {
  [x: string]: any
  handleChange?: any
  id: any
  isSelected: boolean
  label: string
  name: string
  rest?: any
  value?: any
}) {
  return (
    <div className="RadioButton" {...rest}>
      <input checked={isSelected} id={id} name={name} onChange={handleChange} type="radio" value={value} />
      <label htmlFor={id} style={{ marginLeft: '8px' }}>
        {label}
      </label>
    </div>
  )
}
