import * as queryString from 'query-string'
import { DataCompletenessCheck } from '../../../components/ModalOptions/DataCompletenessCheck'
import { compareArrayOfStrings } from '../../../utils/stringUtils'
import { decodeQuery, encodeQuery } from '../../../utils/dashboardQueryUtils'
import { useDealsDashboardFieldsQuery } from '../../../graphql/codegen/hooks'
import { useLocation } from 'react-router'
import BulkExport from '../../../components/ModalOptions/BulkExport'
import Card from '../../../components/Card'
import ColumnControls from '../ColumnControls'
import DealList from '../../../components/DealList'
import DealSearchContainer from '../../../containers/DealSearchContainer'
import DocumentUploadModal from '../../../components/DocumentUploadModal'
import FieldSelectionModal from '../../../components/ModalOptions/FieldSelectionModal'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import UploadModal from '../../../components/UploadModal'
import css from './style.module.scss'
import dashboardQueryReducer, {
  getSavedColumns,
  initialState,
  parseTemporaryColumnsFromQuery,
  resetToDefaultColumns
} from '../../../reducers/dashboardQueryReducer'
import useDashboardQString from '../../../hooks/useDashboardQString'
import useGetExcludedFields from '../../../hooks/useGetExcludedFields'
import usePrevQuery from '../../../hooks/usePrevQuery'
import useUserAccess from '../../../hooks/useUserAccess'

export default function DealsTab() {
  const [{ selectedColumns }, dispatch] = useReducer(dashboardQueryReducer, initialState)
  const [isQueryActive, setIsQueryActive] = useState(false)
  const [isInitialRender, setIsInitialRender] = useState(true) // this is to prevent temp query columns from being overwritten with partially loaded data
  const [areColumnsLoaded, setAreColumnsLoaded] = useState(true) // this is to prevent temp query columns from being overwritten with partially loaded data
  const location = useLocation()
  const { data, loading: fieldsLoading } = useDealsDashboardFieldsQuery({ context: { queryName: 'DealTab.tsx useDealsDashboardFieldsQuery' } })
  let fieldsToExclude = useGetExcludedFields('Deals', 'FieldSelector')
  fieldsToExclude = [...fieldsToExclude, 'assignee']
  const savedColumns = getSavedColumns()
  const { queryColumns } = useDashboardQString()
  const prevQuery = usePrevQuery()
  const formattedQueryColumns = typeof queryColumns === 'string' ? queryColumns.split(',') : queryColumns
  const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)
  const hasAutoUploadAccess = useUserAccess({ feature: 'AutomatedDocument', permission: 'CREATE' })
  const hasManualUploadAccess = useUserAccess({ feature: 'ManualDocument', permission: 'CREATE' })
  const hasBulkExportAccess = useUserAccess({ feature: 'CustomerData', permission: 'EXPORT' })
  const hasDataCompletenessAccess = useUserAccess({ feature: 'DataCompleteness', permission: 'EXPORT' })

  const [totalCount, setTotalCount] = useState(0)
  const [filteredCount, setFilteredCount] = useState(0)
  const [clearAll, setClearAll] = useState(false)

  const setDefaultFields = useCallback(() => {
    resetToDefaultColumns(data, [...fieldsToExclude, 'assignee'], dispatch)
  }, [data, fieldsToExclude])

  // update saved query if there is a search string, otherwise check if one exists to determine if isQueryActive is true
  useEffect(() => {
    if (location.search) {
      localStorage.setItem('PREVIOUS_DEAL_QUERY', location.search)
      setIsQueryActive(true)
    } else if (localStorage.getItem('PREVIOUS_DEAL_QUERY')) {
      setIsQueryActive(true)
    } else {
      setIsQueryActive(false)
    } // eslint-disable-next-line
  }, [location.key]); //listening for changes to the key catches when history changes but url does not- like when clear all is pressed while viewing a prev query

  // this useEffect either loads temporary columns from the search or prev query, and if not found loads saved columns or default columns if none are saved.
  useEffect(() => {
    if (!isInitialRender && !fieldsLoading) {
      const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)
      if (temporaryColumns) {
        dispatch({ type: 'SET_TEMPORARY_COLUMNS', selectedColumns: temporaryColumns })
        setAreColumnsLoaded(true)
      } else if (savedColumns) {
        dispatch({ type: 'SET_TEMPORARY_COLUMNS', selectedColumns: savedColumns })
        setAreColumnsLoaded(true)
      } else {
        setDefaultFields()
      }
    } else if (isInitialRender) {
      setIsInitialRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsLoading, JSON.stringify(queryColumns)])

  // this useEffect updates the stored previous query if new columns are selected or reordered so that those changes are persisted
  useEffect(() => {
    if (prevQuery && !fieldsLoading) {
      // this is to check that tempColumns arnt still loading
      const tempColumnIds = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)?.map(column => {
        return column.value
      })
      const newColumnIds = selectedColumns?.map(column => {
        return column.value
      })

      // check for differences between prev and current (COLUMNS ONLY- adding/removing/updating rules is handled by hitting the search button)
      const arraysAreEqual = compareArrayOfStrings(tempColumnIds || [], newColumnIds || [])
      if (!arraysAreEqual && areColumnsLoaded) {
        // update the query with the new columns/order
        const prevQueryRules = prevQuery?.q ? decodeQuery(prevQuery?.q as string) : ''
        const cols = selectedColumns?.map(column => {
          return column.value
        })
        const updatedPrevQuery = queryString.stringify({ cols, q: encodeQuery(prevQueryRules) }, { arrayFormat: 'comma' })
        localStorage.setItem('PREVIOUS_DEAL_QUERY', updatedPrevQuery)
      }
    }
    // eslint-disable-next-line
  }, [selectedColumns])

  return (
    <Card>
      <div className={css.tableHeader}>
        <DealSearchContainer
          clearAll={clearAll}
          filteredCount={filteredCount}
          selectedColumns={selectedColumns}
          setClearAll={setClearAll}
          totalCount={totalCount}
        />

        <div className={css.modalButtonWrapper}>
          <ColumnControls
            dispatch={dispatch}
            fieldsLoading={fieldsLoading}
            isQueryActive={isQueryActive}
            savedColumns={savedColumns}
            selectedColumns={selectedColumns}
            temporaryColumns={temporaryColumns}
          />

          {hasDataCompletenessAccess && <DataCompletenessCheck dashboardTab="Deals" dashboardType="DEAL" selectedColumns={selectedColumns} />}

          {hasBulkExportAccess && <BulkExport dashboard_type={'DEAL'} selectedColumns={selectedColumns} />}

          <FieldSelectionModal
            dashboardTab={'Deals'}
            dispatch={dispatch}
            fieldsToHide={['assignee']}
            selectedColumns={selectedColumns}
            setDefaultFields={setDefaultFields}
          />

          {hasManualUploadAccess ? <UploadModal /> : hasAutoUploadAccess ? <DocumentUploadModal /> : null}
        </div>
      </div>

      <DealList
        dispatch={dispatch}
        selectedColumns={selectedColumns}
        setClearAll={setClearAll}
        setFilteredCount={setFilteredCount}
        setTotalCount={setTotalCount}
      />
    </Card>
  )
}
