import { ModalContext } from '../../app'
import ChangeCounterparty from './ChangeCounterparty'
import MessageModal from './MessageModal'
import React, { useContext } from 'react'
import WrongCustomer from './WrongCustomer'

export default function ModalOptions() {
  const { closeModal, modalError, modalOption, modalSuccess } = useContext(ModalContext)

  return (
    <>
      {modalSuccess ? (
        <MessageModal closeModal={closeModal} message={modalSuccess?.message} messageNode={modalSuccess?.messageNode} messageTitle={modalSuccess?.title} />
      ) : modalError ? (
        <MessageModal closeModal={closeModal} message={modalError?.message} messageNode={modalError?.messageNode} messageTitle={modalError?.title} />
      ) : (
        <>
          {modalOption === 'ChangeCounterparty' && <ChangeCounterparty />}
          {modalOption === 'WrongCustomer' && <WrongCustomer />}
        </>
      )}
    </>
  )
}
