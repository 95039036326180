import * as queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import useIsRapid7LegalTeam from './useIsRapid7LegalTeam'
import useUserAccess, { useIsAnnotator } from './useUserAccess'

export default function usePrevQuery() {
  const location = useLocation()
  const isAnnotator = useIsAnnotator()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })

  const getPreviousQuery = () => {
    if (location?.pathname === '/') {
      if (isAnnotator) {
        return ''
      } else {
        return !isRapid7LegalTeam && hasDealAccess ? localStorage.getItem('PREVIOUS_DEAL_QUERY') : localStorage.getItem('PREVIOUS_DOCUMENT_QUERY')
      }
    } else if (location?.pathname?.includes('/dashboard/deals')) {
      return localStorage.getItem('PREVIOUS_DEAL_QUERY')
    } else if (location?.pathname?.includes('/dashboard/documents')) {
      return localStorage.getItem('PREVIOUS_DOCUMENT_QUERY')
    } else if (location?.pathname?.includes('/dashboard/attachments')) {
      return localStorage.getItem('PREVIOUS_ATTACHMENT_QUERY')
    } else {
      return ''
    }
  }

  const prevQuery = getPreviousQuery()

  return prevQuery ? queryString.parse(prevQuery, { arrayFormat: 'comma' }) : false
}
