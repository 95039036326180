import { useDropzone } from 'react-dropzone'
import Loader from '../Loader'
import React, { useMemo } from 'react'

interface Props {
  accept?: string
  idleMessage?: string
  isError?: boolean
  isLoading?: boolean
  maxFiles?: number
  multiple?: boolean
  onDrop: (x: any) => void
}

export default function Dropzone({ accept, idleMessage, isError, isLoading, maxFiles = 25, multiple = true, onDrop }: Props) {
  const { fileRejections, getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept || 'image/*,.pdf,.doc,.dot,.dotx,.dotm,.docx,.txt,.rtf,.xps,.odt,.xls,.xlsx,.csv',
    maxSize: 50_000_000,
    maxFiles,
    multiple
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isError ? errorStyle : {})
    }),
    [isDragActive, isError]
  )

  const rejectList = (
    <ul style={rejectStyle}>
      {fileRejections.map(({ errors, file }: any) => {
        console.error(errors)
        const e = errors[0]

        let errorMessage
        switch (e.code) {
          case 'file-invalid-type':
            errorMessage = 'File type not supported'
            break
          case 'file-too-large':
            errorMessage = 'File exceeds 50mb limit'
            break
          case 'too-many-files':
            if (multiple === false) {
              errorMessage = 'Only one file upload is allowed at a time'
            } else {
              errorMessage = `Too many files (Limit ${maxFiles})`
            }
            break
          default:
            errorMessage = 'Error'
            break
        }
        return (
          <li key={file.path}>
            <b>{errorMessage}:</b> {file.path}
          </li>
        )
      })}
    </ul>
  )

  return (
    <>
      {/* @ts-ignore */}
      <div {...getRootProps({ style })}>
        <input {...(getInputProps() as any)} />
        {isDragActive ? <p>Drop here...</p> : isLoading ? <Loader /> : <p>{idleMessage || 'Drag and drop files here, or click to select files'}</p>}
      </div>

      {!!fileRejections.length && rejectList}
    </>
  )
}

const baseStyle = {
  display: 'flex',
  fontSize: 14,
  cursor: 'pointer',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}
const activeStyle = { borderColor: '#2196f3' }
const rejectStyle = { marginTop: 8, fontSize: '13px', color: 'red' }
const errorStyle = { borderColor: 'red' }
