import { InnerLayout } from '../../new/components/InnerLayout'
import { ModalContext } from '../../app'
import { Navbar } from '../Navbar'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useLocation } from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary'
import React, { ReactNode, useContext } from 'react'
import css from './style.module.scss'

// types

interface LayoutProps {
  children: ReactNode
  isMaterialUi?: boolean
  tab?: string
}

// components

export default function Layout({ children, isMaterialUi, tab }: LayoutProps) {
  const { isModalOpen, isPreviewOpen } = useContext(ModalContext)
  const { pathname } = useLocation()

  return (
    <div className={css.layout} style={isModalOpen || isPreviewOpen ? { width: '100vw', height: '100vh', overflow: 'hidden' } : {}}>
      <SkeletonTheme color="#f3f4f8" highlightColor="#f9f9f9">
        <Navbar pathname={pathname} />

        <ErrorBoundary>{isMaterialUi ? <InnerLayout tab={tab}>{children}</InnerLayout> : children}</ErrorBoundary>
      </SkeletonTheme>
    </div>
  )
}
