import { Annotation, AnnotationPositionBbox, AnnotationPositionInput } from '../../graphql/codegen/schemas'

// TODO: make this use T_ScaledPosition type from @klarity/pdf-highlighter
export function positionToAnnotationPositionInput(position: any, content: any): AnnotationPositionInput {
  const bounding_rect = {
    x1: position.boundingRect.x1,
    x2: position.boundingRect.x2,
    y1: position.boundingRect.y1,
    y2: position.boundingRect.y2
  }

  return {
    text: content.text,
    page_number: position.pageNumber,
    page_height: position.boundingRect.height,
    page_width: position.boundingRect.width,
    bounding_rect,
    rects: position.rects.map((r: any) => {
      return {
        x1: r.x1,
        x2: r.x2,
        y1: r.y1,
        y2: r.y2
      }
    })
  }
}

type Rect = {
  height: number
  width: number
  x1: number
  x2: number
  y1: number
  y2: number
}

export type HighlighterHighlight = {
  comment: {
    text: string
  }
  content: {
    text: string
  }
  id: string
  // This key is used to separate those with missing positions; label_type: 'MISSING_ANNO' means that the annotation exists on the annodoc but cannot be located on the pdf
  labelType?: string | null

  position: {
    boundingRect: Rect
    pageNumber: number
    rects: Rect[]
  }
}

export function klarityAnnotationToHighlighterFormat(annotation: Annotation): HighlighterHighlight | null {
  const { bounding_rect, page_height, page_number, page_width, rects } = annotation?.positions?.edges[0]?.node || {}

  if (!(page_height && page_width && page_number && rects && bounding_rect)) return null

  return {
    ...annotation,
    id: annotation.id,
    labelType: annotation.label_type,
    content: {
      text: ''
    },
    comment: {
      text: annotation.label_name
    },
    position: {
      pageNumber: page_number,
      boundingRect: {
        ...bounding_rect,
        width: page_width,
        height: page_height
      },
      rects: rects.edges.map(r => {
        return {
          ...(r?.node as AnnotationPositionBbox),
          width: page_width,
          height: page_height
        }
      })
    }
  }
}

export const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length)

export const updateHash = (highlight: any) => {
  document.location.hash = `highlight-${highlight.id}`
}
