import {
  AdvancedDashboardDocument,
  useInternalWorkflowQuery,
  useMoveToNextInternalStateMutation,
  usePostAnnotationPublishMutation
} from '../../graphql/codegen/hooks'
import { getAssignedToMeFilters } from '../../hooks/useAssignedToMe'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router'
import AdditionalReviewModal from './AdditionalReviewModal'
import Button from '../Button'
import ConfirmMoveDocumentModal from './ConfirmMoveDocumentModal'
import Loader from '../Loader'
import Modal from '../Modal'
import React, { FC, useEffect, useState } from 'react'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// types

interface ButtonsProps {
  finalState?: boolean
}

// components

const Buttons: FC<ButtonsProps> = ({ finalState }) => {
  const currentUser = useCurrentUser()
  const history = useHistory()
  const { documentId } = useParams<{ documentId: string }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isMoveConfirmed, setIsMoveConfirmed] = useState(false)
  const [modalType, setModalType] = useState('')

  const key = `annotatorTodos-${documentId}${currentUser?.id || ''}`
  const refetchQueriesConfig = [{ query: AdvancedDashboardDocument, variables: getAssignedToMeFilters({ currentUser }) }]

  const [submit, { loading: submitLoading }] = useMoveToNextInternalStateMutation({
    onCompleted: ({ move_to_next_internal_state }) => {
      localStorage.removeItem(key)

      toast.success(`Successfully submitted review for ${move_to_next_internal_state?.document?.alias || move_to_next_internal_state?.document?.name}`, {
        autoClose: 5000
      })

      history.push('/')
    },
    onError: error => {
      console.error(error)

      if (error.message === 'Confirmation required') openModal('ConfirmMoveDocument')
    },
    refetchQueries: refetchQueriesConfig,
    variables: { documentId, isMoveConfirmed }
  })

  const [publish, { loading: publishLoading }] = usePostAnnotationPublishMutation({
    onCompleted: ({ post_annotation_publish }) => {
      toast.success(`Successfully published ${post_annotation_publish?.document?.alias || post_annotation_publish?.document?.name}. Reloading page…`, {
        autoClose: 5000
      })

      setTimeout(async () => window.location.reload(), 2000)
    },
    refetchQueries: refetchQueriesConfig,
    variables: { documentId }
  })

  useEffect(() => {
    if (isMoveConfirmed) submit()
  }, [isMoveConfirmed]) // eslint-disable-line

  const confirmMoveDocument = () => {
    setIsMoveConfirmed(true)
    closeModal()
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setModalType('')
  }

  const handlePublish = async () => {
    if (
      await confirm('Are you sure you want to publish? This will make this document available to the customer immediately, and you cannot undo this action.')
    ) {
      publish()
    }
  }

  const handleSubmit = async () => {
    if (await confirm('Are you sure you want to submit this document? It will go to the next annotator, and you won’t be able to make any more changes.')) {
      submit()
    }
  }

  const openModal = (type: string) => {
    setIsModalOpen(true)
    setModalType(type)
  }

  return (
    <>
      <div className={css.footer}>
        {finalState ? (
          <Button className={css.button} onClick={() => openModal('AdditionalReview')} variant="secondary">
            Request additional review
          </Button>
        ) : (
          <Button className={css.button} loading={submitLoading} onClick={handleSubmit}>
            Pass to next reviewer
          </Button>
        )}

        <Button className={css.button} loading={publishLoading} onClick={handlePublish} variant={finalState ? 'primary' : 'secondary'}>
          Publish
        </Button>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        {modalType === 'AdditionalReview' && <AdditionalReviewModal />}

        {modalType === 'ConfirmMoveDocument' && <ConfirmMoveDocumentModal onClose={closeModal} onSubmit={confirmMoveDocument} />}
      </Modal>
    </>
  )
}

export const ChecklistTabAnnotatorFooter: FC = () => {
  const { documentId } = useParams<{ documentId: string }>()

  const { data: internalWorkflowData } = useInternalWorkflowQuery({ fetchPolicy: 'network-only', variables: { documentId } })

  const { is_final_state } = internalWorkflowData?.internal_workflow || {}

  return internalWorkflowData ? <Buttons finalState={Boolean(is_final_state)} /> : <Loader />
}
