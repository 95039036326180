import { useSortable } from '@dnd-kit/sortable'
// import {CSS} from '@dnd-kit/utilities';
import React from 'react'

export default function DraggableWrapper(props: any) {
  // const Element = props.element || 'div';
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id, disabled: !props.canReorderItems })
  const style = { transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined, transition: transition || '' }
  // const style = {transform: CSS.Transform.toString(transform), transition};

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </div>
  )
}
