import { DndContext, DragOverlay, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { formatKey } from '../../../../../../utils/stringUtils'
import { isEmpty } from 'lodash'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import { useReorderChecklistItemMutation, useReorderGroupMutation } from '../../../../../../graphql/codegen/hooks'
import CCI_LeftPanel_ChecklistCard from './CCI_LeftPanel_ChecklistCard'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import React, { useEffect, useState } from 'react'
import SortablePanel from './SortablePanel'

export default function CCI_Checklist_DND({
  groups,
  isReorderingEnabled = true,
  sortedNames
}: {
  groups?: any
  isReorderingEnabled?: boolean
  sortedNames?: any
}) {
  const { setIsOpen, setModalError } = useCciMainContext()
  const { activeKeys, setActiveKeys } = useCciLeftPanelContext()
  const [disableCollapse, setDisableCollapse] = useState(false)
  const [groupIds, setGroupIds] = useState([...sortedNames])
  const [localGroups, setLocalGroups] = useState(groups)
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }))
  const [activeDPF, setActiveDPF] = useState<any>(undefined)

  const [reorderGroup, { error: reorderGroupError, loading: reorderGroupLoading }] = useReorderGroupMutation({
    context: { queryName: `CCI_Checklist_DND.tsx reorderGroupMutation` }
  })

  const [reorderChecklistItem, { error: reorderChecklistItemError, loading: reorderChecklistItemLoading }] = useReorderChecklistItemMutation({
    context: { queryName: `CCI_Checklist_DND.tsx reorderChecklistItemMutation` }
  })

  useEffect(() => setLocalGroups(groups), [groups])

  useEffect(() => {
    if (reorderGroupError) {
      setModalError(reorderGroupError)
      setIsOpen(true)
    } else if (reorderChecklistItemError) {
      setModalError(reorderChecklistItemError)
      setIsOpen(true)
    }
    // eslint-disable-next-line
  }, [reorderGroupError, reorderChecklistItemError])

  const handleDragStart = (event: any) => {
    const { active } = event
    const currentGroup = groups[active?.data?.current?.sortable?.containerId]
    let active_dpf
    currentGroup?.forEach((item: any) => {
      if (item?.id === active?.id) {
        active_dpf = item
      }
    })
    setActiveDPF(active_dpf)
    setDisableCollapse(true)
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    const oldContext = active?.data?.current?.sortable?.containerId
    const newContext = over?.data?.current?.sortable?.containerId

    if (active.id !== over.id) {
      if (oldContext === 'group_context' || newContext === 'group_context') {
        handleGroupReorder({ oldContext, newContext, active, over })
      } else {
        handleItemReorder({ oldContext, newContext, active, over })
      }
    }
    setActiveDPF(undefined)
    setTimeout(() => {
      setDisableCollapse(false)
    }, 100)
  }

  const handleGroupReorder = ({ active, newContext, oldContext, over }: any) => {
    // only allow groups inside the group_context and don't allow them to leave it
    if (!((oldContext === 'group_context' && newContext !== 'group_context') || (oldContext !== 'group_context' && newContext === 'group_context'))) {
      let oldIndex
      let newIndex
      let moveAfterName = null

      setGroupIds(prev => {
        oldIndex = prev.indexOf(active.id)
        newIndex = prev.indexOf(over.id)
        const newArray = [...arrayMove(prev, oldIndex, newIndex)]
        const newIndexAfterReorder = arrayMove(prev, oldIndex, newIndex).indexOf(active.id)
        moveAfterName = newIndexAfterReorder === 0 ? null : newArray[newIndexAfterReorder - 1]
        return arrayMove(prev, oldIndex, newIndex)
      })

      reorderGroup({ variables: { group_name: active?.id, move_after_group_name: moveAfterName } })
    }
  }

  // sortable: {containerId: "context id" index: 2 items: []}
  const handleItemReorder = ({ active, newContext, oldContext, over }: any) => {
    const oldIndex = active?.data?.current?.sortable?.index
    const newIndex = over?.data?.current?.sortable?.index

    if (oldContext !== newContext) {
      const localOldArray = [...localGroups[oldContext]]
      const localNewArray = [...localGroups[newContext]]
      // remove item from old array and hold onto it
      const removedItem = localOldArray.splice(oldIndex, 1) || [] // this is returning an array for some reason
      // insert it into new array at correct index
      localNewArray.splice(newIndex, 0, ...removedItem)
      // update both groups
      setLocalGroups((prev: any) => {
        return { ...prev, [oldContext]: [...localOldArray], [newContext]: [...localNewArray] }
      })
      // fire mutation
      if (newIndex === 0) {
        reorderChecklistItem({ variables: { data_point_field_id: active?.id, move_to_top_group: newContext } })
      } else {
        reorderChecklistItem({ variables: { data_point_field_id: active?.id, move_after_data_point_field_id: localNewArray[newIndex - 1]?.id } })
      }
    } else if (oldContext === newContext) {
      const localArray = [...localGroups[newContext]]
      const reorderedLocalArray = arrayMove(localArray, oldIndex, newIndex)
      setLocalGroups((prev: any) => {
        return { ...prev, [newContext]: arrayMove(localArray, oldIndex, newIndex) }
      })
      // fire mutation
      if (newIndex === 0) {
        reorderChecklistItem({ variables: { data_point_field_id: active?.id, move_to_top_group: newContext } })
      } else {
        reorderChecklistItem({ variables: { data_point_field_id: active?.id, move_after_data_point_field_id: reorderedLocalArray[newIndex - 1]?.id } })
      }
    }
  }

  if (isEmpty(sortedNames)) return null

  return (
    <>
      {reorderGroupLoading || reorderChecklistItemLoading ? (
        <ChecklistSkeleton />
      ) : (
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          <SortableContext id={'group_context'} items={groupIds} strategy={verticalListSortingStrategy}>
            {groupIds?.map((name: any) => (
              <SortablePanel
                activeKeys={activeKeys}
                disableCollapse={disableCollapse}
                id={name}
                isReorderingEnabled={isReorderingEnabled}
                key={formatKey(name)}
                listItems={localGroups[name]}
                setActiveKeys={setActiveKeys}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {/* set current group, then find the active DPF by ID (or pass it up some other way) */}
            {activeDPF && <CCI_LeftPanel_ChecklistCard dataPointField={activeDPF} />}
          </DragOverlay>
        </DndContext>
      )}
    </>
  )
}

// useEffect in child container- listen for changes of like currentDropContext = useState, currentDropArray = reordered array from ondragend.
// when those change fire setState of local array(s) to update
// or will this be done automatically because cache will be updated? doubt it
