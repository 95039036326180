import { Column, DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { FiAlertTriangle } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'
import { ModalContext } from '../../app'
import { formatDataPointId } from '../../utils/fieldUtils'
import { generateDashboardColumns, generateSortDirection, generateSortFunction } from '../../utils/dashboardColumnUtils'
import { useBulkEditDealAssigneeMutation, useDealCountLazyQuery, useDealsDashboardLazyQuery } from '../../graphql/codegen/hooks'
import BulkEditAssigneeButton from '../../containers/BulkEditAssigneeButton'
import BulkEditStatusButton from '../../containers/BulkEditStatusButton'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import DeleteDeal from '../ModalOptions/DeleteDeal'
import Modal from '../Modal'
import MoreMenu from '../MoreMenu'
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import Table from '../Table'
import ViewDealsDocuments from '../ModalOptions/ViewDealsDocuments'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useTablePagination from '../../hooks/useTablePagination'
import useUserAccess from '../../hooks/useUserAccess'

// functions

const getAccessor = (value: string) => {
  switch (value) {
    case '_id':
      return 'document_id'
    case 'is_legacy_str':
      return 'is_legacy'
    default:
      return value
  }
}

// components

export default function DealList({
  dispatch,
  selectedColumns,
  setClearAll,
  setFilteredCount,
  setTotalCount
}: {
  dispatch: Dispatch<DocumentQueryAction>
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
}) {
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()
  const hasDemoAuditorButtonPermissions = useUserAccess({ feature: 'DEMO__AuditorButton', permission: 'READ' })
  const canChangeDealCounterparty = useUserAccess({ feature: 'Deal', permission: 'UPDATE_COUNTERPARTY' })
  const canDeleteDeal = useUserAccess({ feature: 'Deal', permission: 'DELETE' })

  const [dealsDashboardLazyQuery, { data: dataDeals, loading: loadingDeals }] = useDealsDashboardLazyQuery({
    context: { queryName: `DealList.tsx getDashboardData` },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
    // these fetch policies force the first query of the page to be from the network, then to prefer the cache afterwards.
    // this is needed in order to have the search re-fire when the search button is pressed.
  })
  const [dealCountLazyQuery, { data: dataCount, loading: loadingCount }] = useDealCountLazyQuery({
    variables: { ...gqlFilters },
    context: { queryName: `DealList.tsx useDealCountQuery` },
    fetchPolicy: 'network-only'
  })
  const loading = loadingDeals || loadingCount

  useEffect(() => {
    if (pageLength) {
      dealsDashboardLazyQuery({ variables: { ...gqlFilters, size: pageLength, offset } })
    }
    // eslint-disable-next-line
  }, [pageLength, gqlFilters, offset])

  useEffect(() => {
    // ONLY recount if the search changed!
    dealCountLazyQuery({ variables: gqlFilters })
  }, [gqlFilters, dealCountLazyQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_deals_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_deals_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_deals_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_deals_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataDeals) return []

    const dashboardData = dataDeals.fetch_advanced_deals_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      const data_points = row.data_points.reduce((acc: any, curr: any) => {
        const id = formatDataPointId(curr)
        acc[id] = curr
        return acc
      }, {})
      return { ...row, ...data_points, created_at: row.created_at, state: row.status }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataDeals])

  // Take selectedColumns from reducer, format for react table
  // The table response does not return which values have been sorted by, so we add it into the column data here.
  const dynamicColumns = useMemo(
    () =>
      (selectedColumns || []).map(c => {
        if (!c) return null
        let sortFunction = null
        let sortDirection: -1 | 0 | 1 = 0

        if (c.data_point_field_id) {
          sortDirection = generateSortDirection(sortConfig, 'sort_data_points', c.data_point_field_id)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_data_points', selectedColumns, c.data_point_field_id)
        } else if (c.value === 'attachments_count' || c.value === 'created_at' || c.value === 'documents_count' || c.value === 'unresolved_comments_count') {
          sortDirection = generateSortDirection(sortConfig, `sort_${c.value}`)
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, `sort_${c.value}`, selectedColumns)
        } else if (c.value === 'status' || c.value === 'state') {
          sortDirection = generateSortDirection(sortConfig, 'sort_state')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_state', selectedColumns)
        } else if (c.value === 'assignee') {
          sortDirection = generateSortDirection(sortConfig, 'sort_assignee_user_name')
          sortFunction = generateSortFunction(history, queryBuilderQuery, sortConfig, 'sort_assignee_user_name', selectedColumns)
        }

        return {
          ...c,
          Header: c.label,
          accessor: getAccessor(c.value),
          sortDirection,
          sortFunction,
          draggable: true,
          Cell: ({ row, value }: { row: any; value: any }) => {
            if (!value) return null

            let displayValue = value?.value || null
            if (c.data_type === 'MULTI_SELECT_DROP_DOWN' && value.value) {
              // Response is a stringified array with single quotes, so this parses it into a list
              try {
                const withQuotesReplaced = value.value.split("'").join('"')
                displayValue = JSON.parse(withQuotesReplaced).join(', ')
              } catch (error) {
                console.error(error)
              }
            } else if (c.value === 'assignee') {
              displayValue = value.value?.user_name
            } else if (c.value === 'all_assignees') {
              displayValue = value.reduce((acc: string, current: any, idx: number) => {
                if (idx !== 0) {
                  acc += ', '
                }
                acc += current.value.user_name
                return acc
              }, '')
            }

            if (value.is_collision_resolved === false) {
              // must check for false value - right now if true it can either be true or null
              return (
                <div>
                  <Link className={css.reviewLink} to={`/deals/${row?.original?.document?.id}/checklist/${value.data_point_id}`}>
                    <div>
                      <FiAlertTriangle />
                    </div>
                    <div>{`${displayValue} conflicting values`}</div>
                  </Link>
                </div>
              )
            }
            if (value.hyperlink_url) {
              return (
                <a href={value.hyperlink_url} rel="noreferrer" target="_blank">
                  {displayValue}
                </a>
              )
            }
            return displayValue || null
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedColumns, history, sortConfig]
  )

  // #region Modal state/functions
  const { openModal } = useContext(ModalContext)
  const [modalLoading, setModalLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<null | { counterpartyId?: string; dealAlias?: string; dealId: string; dealName: string }>(null)

  const openLocalModal = (menuOption: string, dealId: string, dealName: string, dealAlias?: string, counterpartyId?: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ dealId, dealName, dealAlias, counterpartyId })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(null)
    }
  }

  const getModalOptions = ({ counterpartyId, counterpartyName, dealAlias, dealId, dealIsFinalized, dealName }: any) => {
    const modalOptions: any = [
      {
        label: `View Deal's Documents`,
        onClick: () => {
          openLocalModal(`View Deal's Documents`, dealId, dealName, dealAlias, counterpartyId)
        }
      },
      !dealIsFinalized && { label: 'Rename Deal', onClick: () => openLocalModal('Rename Deal', dealId, dealName, dealAlias, counterpartyId) },
      !dealIsFinalized &&
        canChangeDealCounterparty && {
          label: `Change Deal's Customer`,
          onClick: () => {
            openModal('ChangeCounterparty', `Change Deal's Customer`, {
              itemType: 'Deal',
              itemName: dealAlias || dealName,
              dealId,
              counterpartyId,
              counterpartyName
            })
          }
        },
      !dealIsFinalized && canDeleteDeal && { label: 'Delete Deal', onClick: () => openLocalModal('Delete Deal', dealId, dealName, dealAlias, counterpartyId) }
    ]
    return [...modalOptions]
  }
  // #endregion

  // these columns will always be shown and cannot be reordered.
  const staticColumns = [
    ...generateDashboardColumns(history, queryBuilderQuery, sortConfig, selectedColumns, [
      {
        headerName: 'Deal',
        accessor: 'document',
        sortStringName: 'document_name',
        Cell: ({ row, value }: any) => {
          if (!value) return null
          const dealId = value?.id
          const dealName = value?.value_original || value?.value
          const dealAlias = value?.value_alias
          const counterpartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id
          const counterpartyName = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.name
          const dealIsFinalized = row?.values?.state?.final
          const menuItems = getModalOptions({ dealId, dealName: value?.value, dealAlias, counterpartyId, counterpartyName, dealIsFinalized })

          if (row.original?.processed !== false) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-16px' }} title={dealAlias ? `Original Name: ${dealName}` : dealName}>
                <MoreMenu isFixed menuItems={menuItems} offset={[8, 8]} placement="auto" withIcon />
                <Link to={`/deals/${value?.id}`}>{dealAlias || dealName}</Link>
              </div>
            )
          } else {
            return dealAlias || dealName || null
          }
        }
      },
      {
        headerName: 'Customer',
        accessor: 'counter_party',
        sortStringName: 'counter_party_name',
        Cell: ({ value }: any) => {
          const parsedCounterParty = value?.value && JSON.parse(value?.value)
          const counterPartyName = parsedCounterParty?.name === 'UNASSIGNED' ? '' : parsedCounterParty?.name
          return counterPartyName || null
        }
      },
      {
        headerName: 'Assignee',
        accessor: 'assignee',
        sortStringName: 'sort_assignee_user_name',
        Cell: ({ value }: any) => {
          if (!value) return null
          const displayValue = value.value?.user_name
          return displayValue || '-'
        }
      }
    ])
  ]

  return (
    <>
      <Table
        {...paginationControls}
        columns={[...staticColumns, ...dynamicColumns]}
        dispatch={dispatch}
        dummyDataActive={loading || !dataCount}
        loading={loading || !dataCount}
        pageLength={pageLength}
        rowCount={rowCount}
        selectionActionsTemplate={(props: any) => {
          return (
            props?.selectedFlatRows?.length > 0 && (
              <>
                <BulkEditStatusButton resourceName="deal" selectedFlatRows={props?.selectedFlatRows} />
                <BulkEditAssigneeButton
                  bulkEditAssigneeMutation={useBulkEditDealAssigneeMutation}
                  entityName="Assignee"
                  resourceName="deal"
                  selectedFlatRows={props?.selectedFlatRows}
                />
                {hasDemoAuditorButtonPermissions && (
                  <BulkEditAssigneeButton
                    bulkEditAssigneeMutation={useBulkEditDealAssigneeMutation}
                    entityName="Auditor"
                    resourceName="deal"
                    selectedFlatRows={props?.selectedFlatRows}
                  />
                )}
              </>
            )
          )
        }}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          {modalOption !== 'Rename Deal' && modalOption !== 'Delete Deal' && <h4>{modalContent?.dealAlias || modalContent?.dealName}</h4>}
          {modalOption === `View Deal's Documents` && (
            <ViewDealsDocuments closeModal={closeModal} dealCounterpartyId={modalContent?.counterpartyId} dealId={modalContent?.dealId} />
          )}
          {modalOption === 'Rename Deal' && (
            <RenameFile
              closeModal={closeModal}
              dealId={modalContent?.dealId}
              fileAlias={modalContent?.dealAlias}
              fileName={modalContent?.dealName}
              fileType={'Deal'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Delete Deal' && (
            <DeleteDeal
              closeModal={closeModal}
              dealName={modalContent?.dealAlias || modalContent?.dealName}
              deal_id={modalContent?.dealId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
