import FreeText from '../DatapointInput/FreeText'
import React, { KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

export default function TypeToConfirm({ setConfirmed }: { setConfirmed?: any }) {
  const [isFocused, setFocused] = useState(false)
  const [value, setValue] = useState<string>('')
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  const handleChange = ({ currentTarget }: SyntheticEvent<HTMLTextAreaElement>) => {
    setConfirmed(Boolean(currentTarget.value.match(/^confirm$/i)))

    setValue(currentTarget.value)
  }

  return (
    <div className={css.inputRow}>
      <h4 style={{ whiteSpace: 'nowrap' }}>Type “confirm” to proceed:</h4>

      <FreeText autoFocus isFocused={isFocused} onChange={handleChange} onKeyDown={handleKeyDown} setFocused={setFocused} value={value} />
    </div>
  )
}
