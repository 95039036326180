import React, { SyntheticEvent, useEffect, useRef } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

function Percentage({
  handleBlur,
  isDisabled,
  isFocused,
  name,
  onChange,
  onKeyDown,
  setFocused,
  value
}: {
  handleBlur?: any
  isDisabled?: boolean
  isFocused?: boolean
  name?: string
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
  onKeyDown?: (e: any) => void
  setFocused?: (arg: boolean) => void
  value: string
}) {
  const ref = useRef<HTMLInputElement>(null!)
  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <div className={clsx(isDisabled && css.disabled)}>
      <input
        className={css.input}
        disabled={isDisabled}
        onBlur={() => {
          setFocused?.(false)
          handleBlur?.()
        }}
        onChange={(e: SyntheticEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e)
          }
        }}
        onFocus={() => (setFocused ? setFocused(true) : null)}
        onKeyDown={onKeyDown}
        placeholder={name}
        ref={ref}
        type="number"
        value={value}
      />
    </div>
  )
}

export default Percentage
