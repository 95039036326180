import { FiTrash2, FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import CCI_Input from './CCI_Input'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function DefaultValueInput({
  currentFieldType,
  currentItemId,
  currentOptions,
  currentValue,
  handleData,
  newFieldType,
  newOptions,
  newValue,
  valueFormat
}: {
  currentFieldType?: string
  currentItemId?: string
  currentOptions?: string[]
  currentValue?: string
  handleData: any
  newFieldType?: string
  newOptions?: string[]
  newValue?: string
  valueFormat?: string
}) {
  const [value, setValue] = useState<any>(newValue || currentValue || '')
  const booleanOptions = ['Yes', 'No', 'N/A']

  useEffect(() => {
    if (value !== null) {
      handleData('default_value', value)
    }
    // eslint-disable-next-line
    }, [value])

  useEffect(() => {
    setValue(currentValue || '')
    // eslint-disable-next-line
    }, [currentItemId, currentFieldType, newFieldType])

  useEffect(() => {
    setValue(newValue || currentValue || '')
    // eslint-disable-next-line
    }, [newValue])

  const isDropdown =
    currentFieldType === 'DROP_DOWN' ||
    currentFieldType === 'BOOLEAN' ||
    currentFieldType === 'MULTI_SELECT_DROP_DOWN' ||
    newFieldType === 'DROP_DOWN' ||
    newFieldType === 'BOOLEAN' ||
    newFieldType === 'MULTI_SELECT_DROP_DOWN'

  const isBoolean = newFieldType === 'BOOLEAN' || (!newFieldType && currentFieldType === 'BOOLEAN')

  return (
    <>
      {newFieldType || currentFieldType ? (
        <>
          <div className={css.inputWrapper} style={{ height: '37px' }}>
            <h4>{`Default Value:`}</h4>
            <div className={css.optionInputWrapper}>
              <div className={css.optionInputWrapper} style={{ flexGrow: 1, height: '37px' }}>
                <CCI_Input
                  fieldType={newFieldType || currentFieldType || ''}
                  options={isBoolean ? booleanOptions : newOptions || currentOptions}
                  placeholder={newValue === '@@_DELETE_THIS_ITEM' ? '' : newValue || currentValue || ''}
                  setValue={setValue}
                  value={value === '@@_DELETE_THIS_ITEM' ? '' : value}
                  valueFormat={valueFormat}
                />
              </div>
              {value && !isDropdown && (
                <Button
                  className={css.cciButton}
                  icon={<FiXSquare />}
                  onClick={() => {
                    setValue(currentValue || '')
                    handleData('default_value', currentValue || null)
                  }}
                />
              )}
              {currentValue && newValue !== '@@_DELETE_THIS_ITEM' && (
                <Button
                  icon={<FiTrash2 color="#fc3535" />}
                  onClick={() => {
                    setValue(null)
                    handleData('default_value', '@@_DELETE_THIS_ITEM')
                  }}
                  style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <p>error</p>
      )}
    </>
  )
}

// console.info('newFieldType', newFieldType)
// console.info('currentFieldType', currentFieldType)
// console.info('newValue', newValue)
// console.info('currentValue', currentValue)
// console.info('value', value)
