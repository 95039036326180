import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DashboardQueryFragmentFragmentDoc = gql`
  fragment DashboardQueryFragment on DashboardQuery {
    id
    name
    query_string
    is_shared
    created_at
    created_by {
      user_name
      id
      first_name
      last_name
    }
  }
`;
export const DataPointFieldFragmentFragmentDoc = gql`
  fragment DataPointFieldFragment on DataPointField {
    id
    collision_type
    description
    display_accounting_impact
    display_comment_field
    display_manual_review
    display_on_document
    external_source
    extraction_logic
    field_type
    group
    group_priority
    internal_mapping
    is_deleted
    name
    options
    priority
    required_for_push_integration
    source
    value_format
  }
`;
export const BaseDataPointFragmentDoc = gql`
  fragment BaseDataPoint on DataPoint {
    id
    data_point_field {
      ...DataPointFieldFragment
    }
    display_value
    has_accounting_impact
    hyperlink
    hyperlink_url
    is_collision_resolved
    is_reviewed_1
    is_reviewed_2
    is_reviewed_3
    is_reviewed_4
    is_reviewed_5
    value_bool
    value_float
    value_str
    value_iso_date
    value_list
    value_data_table {
      columns
      rows
      id
    }
    annotator_needs_review
    annotator_needs_review_type
  }
  ${DataPointFieldFragmentFragmentDoc}
`;
export const CommentFragmentFragmentDoc = gql`
  fragment CommentFragment on Comment {
    id
    text
    type
    last_updated_at
    last_updated_by {
      id
      first_name
      last_name
      user_name
    }
    created_at
    created_by {
      id
      first_name
      last_name
      user_name
    }
    data_point {
      id
    }
    is_pinned
    pinned_at
    pinned_by {
      user_name
      first_name
      last_name
    }
  }
`;
export const DataPointChecklistFragmentFragmentDoc = gql`
  fragment DataPointChecklistFragment on DataPoint {
    ...BaseDataPoint
    comments {
      ...CommentFragment
    }
    annotations {
      document {
        id
      }
      annotations {
        id
        label_name
      }
    }
  }
  ${BaseDataPointFragmentDoc}
  ${CommentFragmentFragmentDoc}
`;
export const DataPointDetailChecklistFragmentFragmentDoc = gql`
  fragment DataPointDetailChecklistFragment on DataPoint {
    ...DataPointChecklistFragment
    document_data_points {
      edges {
        node {
          ...DataPointChecklistFragment
          document {
            id
            name
            alias
            external_document
            document_type {
              id
              name
            }
          }
        }
      }
    }
    resolved_data_points {
      edges {
        node {
          ...DataPointChecklistFragment
          document {
            id
            name
            alias
            document_type {
              id
              name
            }
          }
        }
      }
    }
  }
  ${DataPointChecklistFragmentFragmentDoc}
`;
export const DataPointFragmentFragmentDoc = gql`
  fragment DataPointFragment on DataPoint {
    ...DataPointChecklistFragment
    change_log {
      id
      event
      action_on
      action_type
      old_value
      new_value
      user_name
      first_name
      last_name
      created_at
      document_name
    }
  }
  ${DataPointChecklistFragmentFragmentDoc}
`;
export const DataPointDetailFragmentFragmentDoc = gql`
  fragment DataPointDetailFragment on DataPoint {
    ...DataPointFragment
    document_data_points {
      edges {
        node {
          ...DataPointFragment
          document {
            id
            name
            alias
            external_document
            document_type {
              id
              name
            }
          }
        }
      }
    }
    resolved_data_points {
      edges {
        node {
          ...DataPointFragment
          document {
            id
            name
            alias
            document_type {
              id
              name
            }
          }
        }
      }
    }
  }
  ${DataPointFragmentFragmentDoc}
`;
export const DataPointNoCommentsFragmentFragmentDoc = gql`
  fragment DataPointNoCommentsFragment on DataPoint {
    ...BaseDataPoint
    annotations {
      document {
        id
      }
      annotations {
        id
        label_name
      }
    }
    change_log {
      id
      event
      action_on
      action_type
      old_value
      new_value
      user_name
      first_name
      last_name
      created_at
      document_name
    }
  }
  ${BaseDataPointFragmentDoc}
`;
export const DataPointDetailNoCommentsFragmentFragmentDoc = gql`
  fragment DataPointDetailNoCommentsFragment on DataPoint {
    ...DataPointNoCommentsFragment
    document_data_points {
      edges {
        node {
          ...DataPointNoCommentsFragment
          document {
            id
            name
            alias
            external_document
            document_type {
              id
              name
            }
          }
        }
      }
    }
    resolved_data_points {
      edges {
        node {
          ...DataPointNoCommentsFragment
          document {
            id
            name
            alias
            document_type {
              id
              name
            }
          }
        }
      }
    }
  }
  ${DataPointNoCommentsFragmentFragmentDoc}
`;
export const DealFragmentFragmentDoc = gql`
  fragment DealFragment on Deal {
    id
    name
    alias
    created_at
    assignee {
      id
      user_name
    }
    counter_party {
      id
      name
    }
    state {
      final
      id
      initial
      name
      priority
      workflow {
        id
        is_non_linear
      }
    }
    documents {
      edges {
        node {
          id
          name
          alias
          external_document
          is_legacy
          is_reviewed
          is_counter_party_level
          is_deal_level
          visualizer_version
          document_type {
            id
            name
          }
          integration_type
          created_at
          created_by {
            user_name
          }
          counter_party {
            id
            name
          }
        }
      }
    }
    notes {
      edges {
        node {
          id
          created_at
          created_by {
            id
            first_name
            last_name
            user_name
          }
          text
        }
      }
    }
  }
`;
export const AnnotationPositionBboxFragmentFragmentDoc = gql`
  fragment AnnotationPositionBBOXFragment on AnnotationPositionBBOX {
    x1
    x2
    y1
    y2
  }
`;
export const AnnotationPositionFragmentFragmentDoc = gql`
  fragment AnnotationPositionFragment on AnnotationPosition {
    bounding_rect {
      ...AnnotationPositionBBOXFragment
    }
    page_height
    page_number
    page_width
    rects {
      edges {
        node {
          ...AnnotationPositionBBOXFragment
        }
      }
    }
  }
  ${AnnotationPositionBboxFragmentFragmentDoc}
`;
export const AnnotationFragmentFragmentDoc = gql`
  fragment AnnotationFragment on Annotation {
    id
    created_at
    created_by {
      id
      user_name
    }
    internal_name
    label_name
    label_type
    positions {
      edges {
        node {
          ...AnnotationPositionFragment
        }
      }
    }
  }
  ${AnnotationPositionFragmentFragmentDoc}
`;
export const StateFragmentFragmentDoc = gql`
  fragment StateFragment on InternalState {
    id
    name
    role {
      name
      id
    }
  }
`;
export const DocumentFragmentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    id
    name
    alias
    is_counter_party_level
    is_deal_level
    is_reviewed
    visualizer_version
    internal_status
    is_deleted
    integration_type
    created_at
    created_by {
      user_name
    }
    annotations {
      edges {
        node {
          ...AnnotationFragment
        }
      }
    }
    counter_party {
      id
      name
    }
    state {
      final
      id
      initial
      name
      priority
      workflow {
        id
        is_non_linear
      }
    }
    internal_assignee {
      id
    }
    internal_state {
      ...StateFragment
    }
    internal_workflow {
      id
      name
      states {
        edges {
          node {
            ...StateFragment
          }
        }
      }
    }
    extraction_status
  }
  ${AnnotationFragmentFragmentDoc}
  ${StateFragmentFragmentDoc}
`;
export const CustomerFragmentFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    id
    name
    idp_id
    email_domain_for_idp
    type
  }
`;
export const AttachmentFragmentFragmentDoc = gql`
  fragment AttachmentFragment on Attachment {
    id
    document_id
    name
    alias
    has_pdf_preview
    created_at
    created_by {
      id
      user_name
      first_name
    }
    is_deleted
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    user_name
    first_name
    last_name
    is_super_admin
    customers {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const CreateAnnotationDocument = gql`
  mutation createAnnotation($annotation: MutateAnnotation!, $documentId: ID!) {
    create_annotation(new_annotation: $annotation, document_id: $documentId) {
      ... on CreateAnnotationRetry {
        annotated_text
        data_points {
          ...DataPointFragment
        }
      }
      ... on CreateAnnotationSuccess {
        data_points {
          ...DataPointFragment
        }
        document {
          ...DocumentFragment
        }
      }
    }
  }
  ${DataPointFragmentFragmentDoc}
  ${DocumentFragmentFragmentDoc}
`;
export type CreateAnnotationMutationFn = Apollo.MutationFunction<Types.CreateAnnotationMutation, Types.CreateAnnotationMutationVariables>;

/**
 * __useCreateAnnotationMutation__
 *
 * To run a mutation, you first call `useCreateAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnotationMutation, { data, loading, error }] = useCreateAnnotationMutation({
 *   variables: {
 *      annotation: // value for 'annotation'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useCreateAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAnnotationMutation, Types.CreateAnnotationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateAnnotationMutation, Types.CreateAnnotationMutationVariables>(CreateAnnotationDocument, options);
}
export type CreateAnnotationMutationHookResult = ReturnType<typeof useCreateAnnotationMutation>;
export type CreateAnnotationMutationResult = Apollo.MutationResult<Types.CreateAnnotationMutation>;
export type CreateAnnotationMutationOptions = Apollo.BaseMutationOptions<Types.CreateAnnotationMutation, Types.CreateAnnotationMutationVariables>;
export const RetryAnnotationDocument = gql`
  mutation retryAnnotation($documentId: ID!, $newAnnotation: MutateAnnotation!, $dataPointsInfoToUpdate: [SingleDataPointInput!]!) {
    retry_annotation(document_id: $documentId, new_annotation: $newAnnotation, data_points_info_to_update: $dataPointsInfoToUpdate) {
      ... on RetryAnnotationSuccess {
        document {
          ...DocumentFragment
        }
        data_points {
          ...DataPointFragment
        }
      }
      ... on RetryAnnotationFailure {
        errors {
          data_point_id
          error
        }
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${DataPointFragmentFragmentDoc}
`;
export type RetryAnnotationMutationFn = Apollo.MutationFunction<Types.RetryAnnotationMutation, Types.RetryAnnotationMutationVariables>;

/**
 * __useRetryAnnotationMutation__
 *
 * To run a mutation, you first call `useRetryAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAnnotationMutation, { data, loading, error }] = useRetryAnnotationMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      newAnnotation: // value for 'newAnnotation'
 *      dataPointsInfoToUpdate: // value for 'dataPointsInfoToUpdate'
 *   },
 * });
 */
export function useRetryAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RetryAnnotationMutation, Types.RetryAnnotationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RetryAnnotationMutation, Types.RetryAnnotationMutationVariables>(RetryAnnotationDocument, options);
}
export type RetryAnnotationMutationHookResult = ReturnType<typeof useRetryAnnotationMutation>;
export type RetryAnnotationMutationResult = Apollo.MutationResult<Types.RetryAnnotationMutation>;
export type RetryAnnotationMutationOptions = Apollo.BaseMutationOptions<Types.RetryAnnotationMutation, Types.RetryAnnotationMutationVariables>;
export const DeleteAnnotationDocument = gql`
  mutation deleteAnnotation($documentId: ID!, $annotationId: ID!) {
    delete_annotation(document_id: $documentId, annotation_id: $annotationId) {
      data_points {
        ...DataPointFragment
      }
      document {
        ...DocumentFragment
      }
    }
  }
  ${DataPointFragmentFragmentDoc}
  ${DocumentFragmentFragmentDoc}
`;
export type DeleteAnnotationMutationFn = Apollo.MutationFunction<Types.DeleteAnnotationMutation, Types.DeleteAnnotationMutationVariables>;

/**
 * __useDeleteAnnotationMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationMutation, { data, loading, error }] = useDeleteAnnotationMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      annotationId: // value for 'annotationId'
 *   },
 * });
 */
export function useDeleteAnnotationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAnnotationMutation, Types.DeleteAnnotationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteAnnotationMutation, Types.DeleteAnnotationMutationVariables>(DeleteAnnotationDocument, options);
}
export type DeleteAnnotationMutationHookResult = ReturnType<typeof useDeleteAnnotationMutation>;
export type DeleteAnnotationMutationResult = Apollo.MutationResult<Types.DeleteAnnotationMutation>;
export type DeleteAnnotationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAnnotationMutation, Types.DeleteAnnotationMutationVariables>;
export const PreAnnotationFormDocument = gql`
  mutation preAnnotationForm($documentId: ID!, $isAttachment: Boolean!, $isSignature: Boolean, $documentTypeId: ID) {
    pre_annotation_form(document_id: $documentId, attachment: $isAttachment, signed: $isSignature, document_type_id: $documentTypeId) {
      ... on PreAnnotationFormSuccess {
        document {
          ...DocumentFragment
        }
      }
      ... on PreAnnotationFormFailure {
        field
        error
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
`;
export type PreAnnotationFormMutationFn = Apollo.MutationFunction<Types.PreAnnotationFormMutation, Types.PreAnnotationFormMutationVariables>;

/**
 * __usePreAnnotationFormMutation__
 *
 * To run a mutation, you first call `usePreAnnotationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreAnnotationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preAnnotationFormMutation, { data, loading, error }] = usePreAnnotationFormMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isAttachment: // value for 'isAttachment'
 *      isSignature: // value for 'isSignature'
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function usePreAnnotationFormMutation(baseOptions?: Apollo.MutationHookOptions<Types.PreAnnotationFormMutation, Types.PreAnnotationFormMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PreAnnotationFormMutation, Types.PreAnnotationFormMutationVariables>(PreAnnotationFormDocument, options);
}
export type PreAnnotationFormMutationHookResult = ReturnType<typeof usePreAnnotationFormMutation>;
export type PreAnnotationFormMutationResult = Apollo.MutationResult<Types.PreAnnotationFormMutation>;
export type PreAnnotationFormMutationOptions = Apollo.BaseMutationOptions<Types.PreAnnotationFormMutation, Types.PreAnnotationFormMutationVariables>;
export const PostAnnotationPublishDocument = gql`
  mutation postAnnotationPublish($documentId: ID!) {
    post_annotation_publish(document_id: $documentId) {
      document {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
`;
export type PostAnnotationPublishMutationFn = Apollo.MutationFunction<Types.PostAnnotationPublishMutation, Types.PostAnnotationPublishMutationVariables>;

/**
 * __usePostAnnotationPublishMutation__
 *
 * To run a mutation, you first call `usePostAnnotationPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostAnnotationPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postAnnotationPublishMutation, { data, loading, error }] = usePostAnnotationPublishMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePostAnnotationPublishMutation(baseOptions?: Apollo.MutationHookOptions<Types.PostAnnotationPublishMutation, Types.PostAnnotationPublishMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PostAnnotationPublishMutation, Types.PostAnnotationPublishMutationVariables>(PostAnnotationPublishDocument, options);
}
export type PostAnnotationPublishMutationHookResult = ReturnType<typeof usePostAnnotationPublishMutation>;
export type PostAnnotationPublishMutationResult = Apollo.MutationResult<Types.PostAnnotationPublishMutation>;
export type PostAnnotationPublishMutationOptions = Apollo.BaseMutationOptions<Types.PostAnnotationPublishMutation, Types.PostAnnotationPublishMutationVariables>;
export const MoveToNextInternalStateDocument = gql`
  mutation moveToNextInternalState($documentId: ID!, $isMoveConfirmed: Boolean) {
    move_to_next_internal_state(document_id: $documentId, is_move_confirmed: $isMoveConfirmed) {
      document {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
`;
export type MoveToNextInternalStateMutationFn = Apollo.MutationFunction<Types.MoveToNextInternalStateMutation, Types.MoveToNextInternalStateMutationVariables>;

/**
 * __useMoveToNextInternalStateMutation__
 *
 * To run a mutation, you first call `useMoveToNextInternalStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToNextInternalStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToNextInternalStateMutation, { data, loading, error }] = useMoveToNextInternalStateMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isMoveConfirmed: // value for 'isMoveConfirmed'
 *   },
 * });
 */
export function useMoveToNextInternalStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveToNextInternalStateMutation, Types.MoveToNextInternalStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.MoveToNextInternalStateMutation, Types.MoveToNextInternalStateMutationVariables>(MoveToNextInternalStateDocument, options);
}
export type MoveToNextInternalStateMutationHookResult = ReturnType<typeof useMoveToNextInternalStateMutation>;
export type MoveToNextInternalStateMutationResult = Apollo.MutationResult<Types.MoveToNextInternalStateMutation>;
export type MoveToNextInternalStateMutationOptions = Apollo.BaseMutationOptions<Types.MoveToNextInternalStateMutation, Types.MoveToNextInternalStateMutationVariables>;
export const CreateAdditionalReviewDocument = gql`
  mutation createAdditionalReview($documentId: ID!, $assigneeId: ID!) {
    create_additional_review(document_id: $documentId, assignee_id: $assigneeId) {
      document {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
`;
export type CreateAdditionalReviewMutationFn = Apollo.MutationFunction<Types.CreateAdditionalReviewMutation, Types.CreateAdditionalReviewMutationVariables>;

/**
 * __useCreateAdditionalReviewMutation__
 *
 * To run a mutation, you first call `useCreateAdditionalReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdditionalReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdditionalReviewMutation, { data, loading, error }] = useCreateAdditionalReviewMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useCreateAdditionalReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateAdditionalReviewMutation, Types.CreateAdditionalReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateAdditionalReviewMutation, Types.CreateAdditionalReviewMutationVariables>(CreateAdditionalReviewDocument, options);
}
export type CreateAdditionalReviewMutationHookResult = ReturnType<typeof useCreateAdditionalReviewMutation>;
export type CreateAdditionalReviewMutationResult = Apollo.MutationResult<Types.CreateAdditionalReviewMutation>;
export type CreateAdditionalReviewMutationOptions = Apollo.BaseMutationOptions<Types.CreateAdditionalReviewMutation, Types.CreateAdditionalReviewMutationVariables>;
export const BulkEditInternalAssigneeDocument = gql`
  mutation bulkEditInternalAssignee($documentIds: [ID!]!, $userId: ID!) {
    bulk_edit_internal_assignee(document_ids: $documentIds, assignee_id: $userId) {
      advanced_dashboard {
        dashboard_data {
          id
          internal_assignee {
            value {
              id
              first_name
              user_name
            }
          }
        }
      }
      documents {
        id
        internal_assignee {
          id
          first_name
          last_name
          user_name
        }
      }
    }
  }
`;
export type BulkEditInternalAssigneeMutationFn = Apollo.MutationFunction<Types.BulkEditInternalAssigneeMutation, Types.BulkEditInternalAssigneeMutationVariables>;

/**
 * __useBulkEditInternalAssigneeMutation__
 *
 * To run a mutation, you first call `useBulkEditInternalAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditInternalAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditInternalAssigneeMutation, { data, loading, error }] = useBulkEditInternalAssigneeMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBulkEditInternalAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkEditInternalAssigneeMutation, Types.BulkEditInternalAssigneeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BulkEditInternalAssigneeMutation, Types.BulkEditInternalAssigneeMutationVariables>(BulkEditInternalAssigneeDocument, options);
}
export type BulkEditInternalAssigneeMutationHookResult = ReturnType<typeof useBulkEditInternalAssigneeMutation>;
export type BulkEditInternalAssigneeMutationResult = Apollo.MutationResult<Types.BulkEditInternalAssigneeMutation>;
export type BulkEditInternalAssigneeMutationOptions = Apollo.BaseMutationOptions<Types.BulkEditInternalAssigneeMutation, Types.BulkEditInternalAssigneeMutationVariables>;
export const DeleteAnnotationsByInternalNameDocument = gql`
  mutation deleteAnnotationsByInternalName($document_id: ID!, $internal_name: String!) {
    delete_annotations_by_internal_name(document_id: $document_id, internal_name: $internal_name) {
      document {
        id
        name
        annotations {
          edges {
            node {
              ...AnnotationFragment
            }
          }
        }
      }
      data_points {
        id
        display_value
        value_list
        annotations {
          document {
            id
          }
          annotations {
            id
            label_name
          }
        }
      }
    }
  }
  ${AnnotationFragmentFragmentDoc}
`;
export type DeleteAnnotationsByInternalNameMutationFn = Apollo.MutationFunction<Types.DeleteAnnotationsByInternalNameMutation, Types.DeleteAnnotationsByInternalNameMutationVariables>;

/**
 * __useDeleteAnnotationsByInternalNameMutation__
 *
 * To run a mutation, you first call `useDeleteAnnotationsByInternalNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnotationsByInternalNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnotationsByInternalNameMutation, { data, loading, error }] = useDeleteAnnotationsByInternalNameMutation({
 *   variables: {
 *      document_id: // value for 'document_id'
 *      internal_name: // value for 'internal_name'
 *   },
 * });
 */
export function useDeleteAnnotationsByInternalNameMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAnnotationsByInternalNameMutation, Types.DeleteAnnotationsByInternalNameMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteAnnotationsByInternalNameMutation, Types.DeleteAnnotationsByInternalNameMutationVariables>(DeleteAnnotationsByInternalNameDocument, options);
}
export type DeleteAnnotationsByInternalNameMutationHookResult = ReturnType<typeof useDeleteAnnotationsByInternalNameMutation>;
export type DeleteAnnotationsByInternalNameMutationResult = Apollo.MutationResult<Types.DeleteAnnotationsByInternalNameMutation>;
export type DeleteAnnotationsByInternalNameMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAnnotationsByInternalNameMutation, Types.DeleteAnnotationsByInternalNameMutationVariables>;
export const RenameAttachmentDocument = gql`
  mutation renameAttachment($attachment_document_id: ID, $name: String) {
    rename_attachment(attachment_document_id: $attachment_document_id, name: $name) {
      attachment_document {
        id
        name
        alias
      }
      attachment_document_meta {
        id
        value
        value_alias
      }
      deals {
        id
        name
        alias
        attachments {
          edges {
            node {
              id
              name
              alias
            }
          }
        }
      }
      documents {
        id
        name
        alias
        attachments {
          edges {
            node {
              id
              name
              alias
            }
          }
        }
      }
    }
  }
`;
export type RenameAttachmentMutationFn = Apollo.MutationFunction<Types.RenameAttachmentMutation, Types.RenameAttachmentMutationVariables>;

/**
 * __useRenameAttachmentMutation__
 *
 * To run a mutation, you first call `useRenameAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAttachmentMutation, { data, loading, error }] = useRenameAttachmentMutation({
 *   variables: {
 *      attachment_document_id: // value for 'attachment_document_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameAttachmentMutation, Types.RenameAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RenameAttachmentMutation, Types.RenameAttachmentMutationVariables>(RenameAttachmentDocument, options);
}
export type RenameAttachmentMutationHookResult = ReturnType<typeof useRenameAttachmentMutation>;
export type RenameAttachmentMutationResult = Apollo.MutationResult<Types.RenameAttachmentMutation>;
export type RenameAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.RenameAttachmentMutation, Types.RenameAttachmentMutationVariables>;
export const EditDataPointFieldDocument = gql`
  mutation editDataPointField($data_point_field_id: ID!, $name: String, $options: JSONString, $collision_type: String, $resolution_strategy: [String], $description: String, $default_value: String, $value_format: String, $field_type: String, $display_if_empty: Boolean, $display_on_document: Boolean, $display_accounting_impact: Boolean, $default_field_on_dashboard: Boolean) {
    edit_data_point_field(data_point_field_id: $data_point_field_id, name: $name, options: $options, description: $description, field_type: $field_type, collision_type: $collision_type, resolution_strategy: $resolution_strategy, default_value: $default_value, value_format: $value_format, display_if_empty: $display_if_empty, display_on_document: $display_on_document, display_accounting_impact: $display_accounting_impact, default_field_on_dashboard: $default_field_on_dashboard) {
      data_point_field {
        ...DataPointFieldFragment
        default_field_on_dashboard
        display_if_empty
        external_mapping
        inheritance_logic
        option_to_neutral_tag_mapping
      }
    }
  }
  ${DataPointFieldFragmentFragmentDoc}
`;
export type EditDataPointFieldMutationFn = Apollo.MutationFunction<Types.EditDataPointFieldMutation, Types.EditDataPointFieldMutationVariables>;

/**
 * __useEditDataPointFieldMutation__
 *
 * To run a mutation, you first call `useEditDataPointFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDataPointFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDataPointFieldMutation, { data, loading, error }] = useEditDataPointFieldMutation({
 *   variables: {
 *      data_point_field_id: // value for 'data_point_field_id'
 *      name: // value for 'name'
 *      options: // value for 'options'
 *      collision_type: // value for 'collision_type'
 *      resolution_strategy: // value for 'resolution_strategy'
 *      description: // value for 'description'
 *      default_value: // value for 'default_value'
 *      value_format: // value for 'value_format'
 *      field_type: // value for 'field_type'
 *      display_if_empty: // value for 'display_if_empty'
 *      display_on_document: // value for 'display_on_document'
 *      display_accounting_impact: // value for 'display_accounting_impact'
 *      default_field_on_dashboard: // value for 'default_field_on_dashboard'
 *   },
 * });
 */
export function useEditDataPointFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDataPointFieldMutation, Types.EditDataPointFieldMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditDataPointFieldMutation, Types.EditDataPointFieldMutationVariables>(EditDataPointFieldDocument, options);
}
export type EditDataPointFieldMutationHookResult = ReturnType<typeof useEditDataPointFieldMutation>;
export type EditDataPointFieldMutationResult = Apollo.MutationResult<Types.EditDataPointFieldMutation>;
export type EditDataPointFieldMutationOptions = Apollo.BaseMutationOptions<Types.EditDataPointFieldMutation, Types.EditDataPointFieldMutationVariables>;
export const CreateDataPointFieldDocument = gql`
  mutation createDataPointField($name: String, $group: String, $source: String, $field_type: String, $options: JSONString, $neutral_tag: String, $collision_type: String, $resolution_strategy: [String], $default_value: String, $value_format: String, $description: String, $display_if_empty: Boolean, $display_on_document: Boolean, $display_accounting_impact: Boolean, $default_field_on_dashboard: Boolean) {
    create_data_point_field(name: $name, group: $group, source: $source, field_type: $field_type, options: $options, neutral_tag: $neutral_tag, collision_type: $collision_type, resolution_strategy: $resolution_strategy, default_value: $default_value, value_format: $value_format, description: $description, display_if_empty: $display_if_empty, display_on_document: $display_on_document, display_accounting_impact: $display_accounting_impact, default_field_on_dashboard: $default_field_on_dashboard) {
      data_point_field {
        ...DataPointFieldFragment
        default_field_on_dashboard
        display_if_empty
        external_mapping
        inheritance_logic
        option_to_neutral_tag_mapping
      }
    }
  }
  ${DataPointFieldFragmentFragmentDoc}
`;
export type CreateDataPointFieldMutationFn = Apollo.MutationFunction<Types.CreateDataPointFieldMutation, Types.CreateDataPointFieldMutationVariables>;

/**
 * __useCreateDataPointFieldMutation__
 *
 * To run a mutation, you first call `useCreateDataPointFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataPointFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataPointFieldMutation, { data, loading, error }] = useCreateDataPointFieldMutation({
 *   variables: {
 *      name: // value for 'name'
 *      group: // value for 'group'
 *      source: // value for 'source'
 *      field_type: // value for 'field_type'
 *      options: // value for 'options'
 *      neutral_tag: // value for 'neutral_tag'
 *      collision_type: // value for 'collision_type'
 *      resolution_strategy: // value for 'resolution_strategy'
 *      default_value: // value for 'default_value'
 *      value_format: // value for 'value_format'
 *      description: // value for 'description'
 *      display_if_empty: // value for 'display_if_empty'
 *      display_on_document: // value for 'display_on_document'
 *      display_accounting_impact: // value for 'display_accounting_impact'
 *      default_field_on_dashboard: // value for 'default_field_on_dashboard'
 *   },
 * });
 */
export function useCreateDataPointFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDataPointFieldMutation, Types.CreateDataPointFieldMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDataPointFieldMutation, Types.CreateDataPointFieldMutationVariables>(CreateDataPointFieldDocument, options);
}
export type CreateDataPointFieldMutationHookResult = ReturnType<typeof useCreateDataPointFieldMutation>;
export type CreateDataPointFieldMutationResult = Apollo.MutationResult<Types.CreateDataPointFieldMutation>;
export type CreateDataPointFieldMutationOptions = Apollo.BaseMutationOptions<Types.CreateDataPointFieldMutation, Types.CreateDataPointFieldMutationVariables>;
export const DeleteDataPointFieldDocument = gql`
  mutation deleteDataPointField($data_point_field_id: ID) {
    delete_data_point_field(data_point_field_id: $data_point_field_id) {
      success
    }
  }
`;
export type DeleteDataPointFieldMutationFn = Apollo.MutationFunction<Types.DeleteDataPointFieldMutation, Types.DeleteDataPointFieldMutationVariables>;

/**
 * __useDeleteDataPointFieldMutation__
 *
 * To run a mutation, you first call `useDeleteDataPointFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataPointFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataPointFieldMutation, { data, loading, error }] = useDeleteDataPointFieldMutation({
 *   variables: {
 *      data_point_field_id: // value for 'data_point_field_id'
 *   },
 * });
 */
export function useDeleteDataPointFieldMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDataPointFieldMutation, Types.DeleteDataPointFieldMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDataPointFieldMutation, Types.DeleteDataPointFieldMutationVariables>(DeleteDataPointFieldDocument, options);
}
export type DeleteDataPointFieldMutationHookResult = ReturnType<typeof useDeleteDataPointFieldMutation>;
export type DeleteDataPointFieldMutationResult = Apollo.MutationResult<Types.DeleteDataPointFieldMutation>;
export type DeleteDataPointFieldMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDataPointFieldMutation, Types.DeleteDataPointFieldMutationVariables>;
export const ReorderGroupDocument = gql`
  mutation reorderGroup($group_name: String!, $move_after_group_name: String) {
    edit_group_priority(group_name: $group_name, move_after_group_name: $move_after_group_name) {
      data_point_fields {
        id
        group
        group_priority
        priority
      }
    }
  }
`;
export type ReorderGroupMutationFn = Apollo.MutationFunction<Types.ReorderGroupMutation, Types.ReorderGroupMutationVariables>;

/**
 * __useReorderGroupMutation__
 *
 * To run a mutation, you first call `useReorderGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderGroupMutation, { data, loading, error }] = useReorderGroupMutation({
 *   variables: {
 *      group_name: // value for 'group_name'
 *      move_after_group_name: // value for 'move_after_group_name'
 *   },
 * });
 */
export function useReorderGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReorderGroupMutation, Types.ReorderGroupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ReorderGroupMutation, Types.ReorderGroupMutationVariables>(ReorderGroupDocument, options);
}
export type ReorderGroupMutationHookResult = ReturnType<typeof useReorderGroupMutation>;
export type ReorderGroupMutationResult = Apollo.MutationResult<Types.ReorderGroupMutation>;
export type ReorderGroupMutationOptions = Apollo.BaseMutationOptions<Types.ReorderGroupMutation, Types.ReorderGroupMutationVariables>;
export const ReorderChecklistItemDocument = gql`
  mutation reorderChecklistItem($data_point_field_id: ID!, $move_after_data_point_field_id: ID, $move_to_top_group: String) {
    reorder_checklist_item(data_point_field_id: $data_point_field_id, move_after_data_point_field_id: $move_after_data_point_field_id, move_to_top_group: $move_to_top_group) {
      data_point_fields {
        id
        group
        group_priority
        priority
      }
    }
  }
`;
export type ReorderChecklistItemMutationFn = Apollo.MutationFunction<Types.ReorderChecklistItemMutation, Types.ReorderChecklistItemMutationVariables>;

/**
 * __useReorderChecklistItemMutation__
 *
 * To run a mutation, you first call `useReorderChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderChecklistItemMutation, { data, loading, error }] = useReorderChecklistItemMutation({
 *   variables: {
 *      data_point_field_id: // value for 'data_point_field_id'
 *      move_after_data_point_field_id: // value for 'move_after_data_point_field_id'
 *      move_to_top_group: // value for 'move_to_top_group'
 *   },
 * });
 */
export function useReorderChecklistItemMutation(baseOptions?: Apollo.MutationHookOptions<Types.ReorderChecklistItemMutation, Types.ReorderChecklistItemMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ReorderChecklistItemMutation, Types.ReorderChecklistItemMutationVariables>(ReorderChecklistItemDocument, options);
}
export type ReorderChecklistItemMutationHookResult = ReturnType<typeof useReorderChecklistItemMutation>;
export type ReorderChecklistItemMutationResult = Apollo.MutationResult<Types.ReorderChecklistItemMutation>;
export type ReorderChecklistItemMutationOptions = Apollo.BaseMutationOptions<Types.ReorderChecklistItemMutation, Types.ReorderChecklistItemMutationVariables>;
export const CreateUserDocument = gql`
  mutation createUser($user_primary_email: String!, $first_name: String!, $last_name: String!, $role_ids: [ID]!) {
    create_user(user_primary_email: $user_primary_email, first_name: $first_name, last_name: $last_name, role_ids: $role_ids) {
      user {
        id
        user_name
        first_name
        last_name
        roles {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<Types.CreateUserMutation, Types.CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user_primary_email: // value for 'user_primary_email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      role_ids: // value for 'role_ids'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserMutation, Types.CreateUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateUserMutation, Types.CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<Types.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserMutation, Types.CreateUserMutationVariables>;
export const EditUserDocument = gql`
  mutation editUser($user_id: ID!, $first_name: String, $last_name: String, $roles_to_add: [ID], $roles_to_remove: [ID]) {
    edit_user(user_id: $user_id, first_name: $first_name, last_name: $last_name, roles_to_add: $roles_to_add, roles_to_remove: $roles_to_remove) {
      user {
        id
        user_name
        first_name
        last_name
        roles {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<Types.EditUserMutation, Types.EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      roles_to_add: // value for 'roles_to_add'
 *      roles_to_remove: // value for 'roles_to_remove'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditUserMutation, Types.EditUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditUserMutation, Types.EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<Types.EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<Types.EditUserMutation, Types.EditUserMutationVariables>;
export const DeleteUserDocument = gql`
  mutation deleteUser($user_id: ID!) {
    delete_user(user_id: $user_id) {
      user {
        id
        user_name
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<Types.DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<Types.DeleteUserMutation, Types.DeleteUserMutationVariables>;
export const CreateIntegrationDocument = gql`
  mutation createIntegration($integration_type: String!, $token_type: String!, $token: String!, $api_url: String!, $user_name: String!, $password: String!, $domain: String, $organization_id: String) {
    create_integration(integration_type: $integration_type, token_type: $token_type, token: $token, api_url: $api_url, user_name: $user_name, password: $password, domain: $domain, organization_id: $organization_id) {
      integration {
        id
        integration_type
        token_type
        api_url
        user_name
        domain
        organization_id
      }
    }
  }
`;
export type CreateIntegrationMutationFn = Apollo.MutationFunction<Types.CreateIntegrationMutation, Types.CreateIntegrationMutationVariables>;

/**
 * __useCreateIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationMutation, { data, loading, error }] = useCreateIntegrationMutation({
 *   variables: {
 *      integration_type: // value for 'integration_type'
 *      token_type: // value for 'token_type'
 *      token: // value for 'token'
 *      api_url: // value for 'api_url'
 *      user_name: // value for 'user_name'
 *      password: // value for 'password'
 *      domain: // value for 'domain'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useCreateIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIntegrationMutation, Types.CreateIntegrationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateIntegrationMutation, Types.CreateIntegrationMutationVariables>(CreateIntegrationDocument, options);
}
export type CreateIntegrationMutationHookResult = ReturnType<typeof useCreateIntegrationMutation>;
export type CreateIntegrationMutationResult = Apollo.MutationResult<Types.CreateIntegrationMutation>;
export type CreateIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.CreateIntegrationMutation, Types.CreateIntegrationMutationVariables>;
export const EditIntegrationDocument = gql`
  mutation editIntegration($integration_type: String, $token_type: String, $token: String, $api_url: String, $user_name: String, $password: String, $domain: String, $organization_id: String) {
    edit_integration(integration_type: $integration_type, token_type: $token_type, token: $token, api_url: $api_url, user_name: $user_name, password: $password, domain: $domain, organization_id: $organization_id) {
      integration {
        id
        integration_type
        token_type
        api_url
        user_name
        domain
        organization_id
      }
    }
  }
`;
export type EditIntegrationMutationFn = Apollo.MutationFunction<Types.EditIntegrationMutation, Types.EditIntegrationMutationVariables>;

/**
 * __useEditIntegrationMutation__
 *
 * To run a mutation, you first call `useEditIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIntegrationMutation, { data, loading, error }] = useEditIntegrationMutation({
 *   variables: {
 *      integration_type: // value for 'integration_type'
 *      token_type: // value for 'token_type'
 *      token: // value for 'token'
 *      api_url: // value for 'api_url'
 *      user_name: // value for 'user_name'
 *      password: // value for 'password'
 *      domain: // value for 'domain'
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useEditIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditIntegrationMutation, Types.EditIntegrationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditIntegrationMutation, Types.EditIntegrationMutationVariables>(EditIntegrationDocument, options);
}
export type EditIntegrationMutationHookResult = ReturnType<typeof useEditIntegrationMutation>;
export type EditIntegrationMutationResult = Apollo.MutationResult<Types.EditIntegrationMutation>;
export type EditIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.EditIntegrationMutation, Types.EditIntegrationMutationVariables>;
export const DeleteIntegrationDocument = gql`
  mutation deleteIntegration {
    delete_integration {
      success
    }
  }
`;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<Types.DeleteIntegrationMutation, Types.DeleteIntegrationMutationVariables>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteIntegrationMutation, Types.DeleteIntegrationMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteIntegrationMutation, Types.DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, options);
}
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<Types.DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteIntegrationMutation, Types.DeleteIntegrationMutationVariables>;
export const ChangeNeutralTagDocument = gql`
  mutation changeNeutralTag($data_point_field_id: ID!, $new_neutral_tag: String!, $old_neutral_tag: String, $change_other_fields: Boolean!, $change_annotations: Boolean!) {
    change_neutral_tag(data_point_field_id: $data_point_field_id, new_neutral_tag: $new_neutral_tag, old_neutral_tag: $old_neutral_tag, change_other_fields: $change_other_fields, change_annotations: $change_annotations) {
      data_point_fields {
        ...DataPointFieldFragment
        default_field_on_dashboard
        display_if_empty
        inheritance_logic
        external_mapping
        option_to_neutral_tag_mapping
      }
    }
  }
  ${DataPointFieldFragmentFragmentDoc}
`;
export type ChangeNeutralTagMutationFn = Apollo.MutationFunction<Types.ChangeNeutralTagMutation, Types.ChangeNeutralTagMutationVariables>;

/**
 * __useChangeNeutralTagMutation__
 *
 * To run a mutation, you first call `useChangeNeutralTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNeutralTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNeutralTagMutation, { data, loading, error }] = useChangeNeutralTagMutation({
 *   variables: {
 *      data_point_field_id: // value for 'data_point_field_id'
 *      new_neutral_tag: // value for 'new_neutral_tag'
 *      old_neutral_tag: // value for 'old_neutral_tag'
 *      change_other_fields: // value for 'change_other_fields'
 *      change_annotations: // value for 'change_annotations'
 *   },
 * });
 */
export function useChangeNeutralTagMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeNeutralTagMutation, Types.ChangeNeutralTagMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ChangeNeutralTagMutation, Types.ChangeNeutralTagMutationVariables>(ChangeNeutralTagDocument, options);
}
export type ChangeNeutralTagMutationHookResult = ReturnType<typeof useChangeNeutralTagMutation>;
export type ChangeNeutralTagMutationResult = Apollo.MutationResult<Types.ChangeNeutralTagMutation>;
export type ChangeNeutralTagMutationOptions = Apollo.BaseMutationOptions<Types.ChangeNeutralTagMutation, Types.ChangeNeutralTagMutationVariables>;
export const Cci_EditDocumentTypesDocument = gql`
  mutation CCI_editDocumentTypes($doc_types_id_name_list: JSONString!) {
    bulk_edit_document_type(doc_types_id_name_list: $doc_types_id_name_list) {
      document_types {
        id
        name
      }
      data_point_fields {
        id
        name
        options
      }
    }
  }
`;
export type Cci_EditDocumentTypesMutationFn = Apollo.MutationFunction<Types.Cci_EditDocumentTypesMutation, Types.Cci_EditDocumentTypesMutationVariables>;

/**
 * __useCci_EditDocumentTypesMutation__
 *
 * To run a mutation, you first call `useCci_EditDocumentTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCci_EditDocumentTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cciEditDocumentTypesMutation, { data, loading, error }] = useCci_EditDocumentTypesMutation({
 *   variables: {
 *      doc_types_id_name_list: // value for 'doc_types_id_name_list'
 *   },
 * });
 */
export function useCci_EditDocumentTypesMutation(baseOptions?: Apollo.MutationHookOptions<Types.Cci_EditDocumentTypesMutation, Types.Cci_EditDocumentTypesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.Cci_EditDocumentTypesMutation, Types.Cci_EditDocumentTypesMutationVariables>(Cci_EditDocumentTypesDocument, options);
}
export type Cci_EditDocumentTypesMutationHookResult = ReturnType<typeof useCci_EditDocumentTypesMutation>;
export type Cci_EditDocumentTypesMutationResult = Apollo.MutationResult<Types.Cci_EditDocumentTypesMutation>;
export type Cci_EditDocumentTypesMutationOptions = Apollo.BaseMutationOptions<Types.Cci_EditDocumentTypesMutation, Types.Cci_EditDocumentTypesMutationVariables>;
export const CreateCommentDocument = gql`
  mutation createComment($dataPointId: ID, $text: String) {
    create_comment(data_point_id: $dataPointId, text: $text, comment_type: "NEEDS_REVIEW") {
      comment {
        last_updated_at
        last_updated_by {
          id
          first_name
          last_name
          user_name
          notification_preferences
        }
        data_point {
          id
        }
        id
        text
      }
    }
  }
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(CreateCommentDocument, options);
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
  mutation deleteComment($commentId: ID!) {
    delete_comment(comment_id: $commentId) {
      status
    }
  }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export const MarkCommentAsPermanentDocument = gql`
  mutation markCommentAsPermanent($commentId: ID!) {
    edit_comment(comment_id: $commentId, edit_type: "COMMENT_TYPE", comment_type: "PERMANENT") {
      comment {
        id
      }
    }
  }
`;
export type MarkCommentAsPermanentMutationFn = Apollo.MutationFunction<Types.MarkCommentAsPermanentMutation, Types.MarkCommentAsPermanentMutationVariables>;

/**
 * __useMarkCommentAsPermanentMutation__
 *
 * To run a mutation, you first call `useMarkCommentAsPermanentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCommentAsPermanentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCommentAsPermanentMutation, { data, loading, error }] = useMarkCommentAsPermanentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useMarkCommentAsPermanentMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkCommentAsPermanentMutation, Types.MarkCommentAsPermanentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.MarkCommentAsPermanentMutation, Types.MarkCommentAsPermanentMutationVariables>(MarkCommentAsPermanentDocument, options);
}
export type MarkCommentAsPermanentMutationHookResult = ReturnType<typeof useMarkCommentAsPermanentMutation>;
export type MarkCommentAsPermanentMutationResult = Apollo.MutationResult<Types.MarkCommentAsPermanentMutation>;
export type MarkCommentAsPermanentMutationOptions = Apollo.BaseMutationOptions<Types.MarkCommentAsPermanentMutation, Types.MarkCommentAsPermanentMutationVariables>;
export const MarkCommentAsResolvedDocument = gql`
  mutation markCommentAsResolved($commentId: ID!) {
    edit_comment(comment_id: $commentId, edit_type: "COMMENT_TYPE", comment_type: "RESOLVED") {
      comment {
        id
      }
    }
  }
`;
export type MarkCommentAsResolvedMutationFn = Apollo.MutationFunction<Types.MarkCommentAsResolvedMutation, Types.MarkCommentAsResolvedMutationVariables>;

/**
 * __useMarkCommentAsResolvedMutation__
 *
 * To run a mutation, you first call `useMarkCommentAsResolvedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCommentAsResolvedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCommentAsResolvedMutation, { data, loading, error }] = useMarkCommentAsResolvedMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useMarkCommentAsResolvedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkCommentAsResolvedMutation, Types.MarkCommentAsResolvedMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.MarkCommentAsResolvedMutation, Types.MarkCommentAsResolvedMutationVariables>(MarkCommentAsResolvedDocument, options);
}
export type MarkCommentAsResolvedMutationHookResult = ReturnType<typeof useMarkCommentAsResolvedMutation>;
export type MarkCommentAsResolvedMutationResult = Apollo.MutationResult<Types.MarkCommentAsResolvedMutation>;
export type MarkCommentAsResolvedMutationOptions = Apollo.BaseMutationOptions<Types.MarkCommentAsResolvedMutation, Types.MarkCommentAsResolvedMutationVariables>;
export const EditCommentTextDocument = gql`
  mutation editCommentText($commentId: ID!, $text: String!) {
    edit_comment(comment_id: $commentId, text: $text, edit_type: "TEXT") {
      comment {
        id
        text
      }
    }
  }
`;
export type EditCommentTextMutationFn = Apollo.MutationFunction<Types.EditCommentTextMutation, Types.EditCommentTextMutationVariables>;

/**
 * __useEditCommentTextMutation__
 *
 * To run a mutation, you first call `useEditCommentTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentTextMutation, { data, loading, error }] = useEditCommentTextMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useEditCommentTextMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditCommentTextMutation, Types.EditCommentTextMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditCommentTextMutation, Types.EditCommentTextMutationVariables>(EditCommentTextDocument, options);
}
export type EditCommentTextMutationHookResult = ReturnType<typeof useEditCommentTextMutation>;
export type EditCommentTextMutationResult = Apollo.MutationResult<Types.EditCommentTextMutation>;
export type EditCommentTextMutationOptions = Apollo.BaseMutationOptions<Types.EditCommentTextMutation, Types.EditCommentTextMutationVariables>;
export const PinCommentDocument = gql`
  mutation pinComment($commentId: ID!, $pin_type: String!) {
    edit_comment(comment_id: $commentId, edit_type: $pin_type) {
      comment {
        id
        text
        is_pinned
        pinned_at
      }
    }
  }
`;
export type PinCommentMutationFn = Apollo.MutationFunction<Types.PinCommentMutation, Types.PinCommentMutationVariables>;

/**
 * __usePinCommentMutation__
 *
 * To run a mutation, you first call `usePinCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinCommentMutation, { data, loading, error }] = usePinCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      pin_type: // value for 'pin_type'
 *   },
 * });
 */
export function usePinCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.PinCommentMutation, Types.PinCommentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.PinCommentMutation, Types.PinCommentMutationVariables>(PinCommentDocument, options);
}
export type PinCommentMutationHookResult = ReturnType<typeof usePinCommentMutation>;
export type PinCommentMutationResult = Apollo.MutationResult<Types.PinCommentMutation>;
export type PinCommentMutationOptions = Apollo.BaseMutationOptions<Types.PinCommentMutation, Types.PinCommentMutationVariables>;
export const CreateDashboardQueryDocument = gql`
  mutation createDashboardQuery($name: String!, $queryString: String!, $isShared: Boolean!, $isDefault: Boolean!, $dashboard: String!) {
    create_dashboard_query(name: $name, query_string: $queryString, is_shared: $isShared, is_default: $isDefault, dashboard: $dashboard) {
      dashboard_query {
        ...DashboardQueryFragment
      }
    }
  }
  ${DashboardQueryFragmentFragmentDoc}
`;
export type CreateDashboardQueryMutationFn = Apollo.MutationFunction<Types.CreateDashboardQueryMutation, Types.CreateDashboardQueryMutationVariables>;

/**
 * __useCreateDashboardQueryMutation__
 *
 * To run a mutation, you first call `useCreateDashboardQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardQueryMutation, { data, loading, error }] = useCreateDashboardQueryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      queryString: // value for 'queryString'
 *      isShared: // value for 'isShared'
 *      isDefault: // value for 'isDefault'
 *      dashboard: // value for 'dashboard'
 *   },
 * });
 */
export function useCreateDashboardQueryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDashboardQueryMutation, Types.CreateDashboardQueryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDashboardQueryMutation, Types.CreateDashboardQueryMutationVariables>(CreateDashboardQueryDocument, options);
}
export type CreateDashboardQueryMutationHookResult = ReturnType<typeof useCreateDashboardQueryMutation>;
export type CreateDashboardQueryMutationResult = Apollo.MutationResult<Types.CreateDashboardQueryMutation>;
export type CreateDashboardQueryMutationOptions = Apollo.BaseMutationOptions<Types.CreateDashboardQueryMutation, Types.CreateDashboardQueryMutationVariables>;
export const DeleteDashboardQueryDocument = gql`
  mutation deleteDashboardQuery($dashboard_query_id: ID!) {
    delete_dashboard_query(dashboard_query_id: $dashboard_query_id) {
      dashboard_query {
        id
        name
      }
    }
  }
`;
export type DeleteDashboardQueryMutationFn = Apollo.MutationFunction<Types.DeleteDashboardQueryMutation, Types.DeleteDashboardQueryMutationVariables>;

/**
 * __useDeleteDashboardQueryMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardQueryMutation, { data, loading, error }] = useDeleteDashboardQueryMutation({
 *   variables: {
 *      dashboard_query_id: // value for 'dashboard_query_id'
 *   },
 * });
 */
export function useDeleteDashboardQueryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDashboardQueryMutation, Types.DeleteDashboardQueryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDashboardQueryMutation, Types.DeleteDashboardQueryMutationVariables>(DeleteDashboardQueryDocument, options);
}
export type DeleteDashboardQueryMutationHookResult = ReturnType<typeof useDeleteDashboardQueryMutation>;
export type DeleteDashboardQueryMutationResult = Apollo.MutationResult<Types.DeleteDashboardQueryMutation>;
export type DeleteDashboardQueryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDashboardQueryMutation, Types.DeleteDashboardQueryMutationVariables>;
export const EditDataPointAccountingImpactDocument = gql`
  mutation editDataPointAccountingImpact($dataPointId: ID!, $accountingImpact: Boolean) {
    edit_data_point_value(data_point_id: $dataPointId, accounting_impact: $accountingImpact, edit_type: "ACCOUNTING_IMPACT") {
      data_points {
        has_accounting_impact
        id
      }
    }
  }
`;
export type EditDataPointAccountingImpactMutationFn = Apollo.MutationFunction<Types.EditDataPointAccountingImpactMutation, Types.EditDataPointAccountingImpactMutationVariables>;

/**
 * __useEditDataPointAccountingImpactMutation__
 *
 * To run a mutation, you first call `useEditDataPointAccountingImpactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDataPointAccountingImpactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDataPointAccountingImpactMutation, { data, loading, error }] = useEditDataPointAccountingImpactMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      accountingImpact: // value for 'accountingImpact'
 *   },
 * });
 */
export function useEditDataPointAccountingImpactMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDataPointAccountingImpactMutation, Types.EditDataPointAccountingImpactMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditDataPointAccountingImpactMutation, Types.EditDataPointAccountingImpactMutationVariables>(EditDataPointAccountingImpactDocument, options);
}
export type EditDataPointAccountingImpactMutationHookResult = ReturnType<typeof useEditDataPointAccountingImpactMutation>;
export type EditDataPointAccountingImpactMutationResult = Apollo.MutationResult<Types.EditDataPointAccountingImpactMutation>;
export type EditDataPointAccountingImpactMutationOptions = Apollo.BaseMutationOptions<Types.EditDataPointAccountingImpactMutation, Types.EditDataPointAccountingImpactMutationVariables>;
export const EditDataPointManualReviewDocument = gql`
  mutation editDataPointManualReview($dataPointId: ID!, $isReviewed: Boolean!, $priority: Int) {
    edit_data_point_value(data_point_id: $dataPointId, edit_type: "MANUAL_REVIEW", is_reviewed: $isReviewed, reviewed_state_priority: $priority) {
      data_points {
        id
        is_reviewed_1
        is_reviewed_2
        is_reviewed_3
        is_reviewed_4
        is_reviewed_5
      }
    }
  }
`;
export type EditDataPointManualReviewMutationFn = Apollo.MutationFunction<Types.EditDataPointManualReviewMutation, Types.EditDataPointManualReviewMutationVariables>;

/**
 * __useEditDataPointManualReviewMutation__
 *
 * To run a mutation, you first call `useEditDataPointManualReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDataPointManualReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDataPointManualReviewMutation, { data, loading, error }] = useEditDataPointManualReviewMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      isReviewed: // value for 'isReviewed'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useEditDataPointManualReviewMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDataPointManualReviewMutation, Types.EditDataPointManualReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditDataPointManualReviewMutation, Types.EditDataPointManualReviewMutationVariables>(EditDataPointManualReviewDocument, options);
}
export type EditDataPointManualReviewMutationHookResult = ReturnType<typeof useEditDataPointManualReviewMutation>;
export type EditDataPointManualReviewMutationResult = Apollo.MutationResult<Types.EditDataPointManualReviewMutation>;
export type EditDataPointManualReviewMutationOptions = Apollo.BaseMutationOptions<Types.EditDataPointManualReviewMutation, Types.EditDataPointManualReviewMutationVariables>;
export const EditDataPointValueDocument = gql`
  mutation editDataPointValue($dataPointId: ID!, $value: String, $values: [String]) {
    edit_data_point_value(data_point_id: $dataPointId, value: $value, values: $values, edit_type: "VALUE") {
      data_points {
        ...DataPointDetailFragment
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;
export type EditDataPointValueMutationFn = Apollo.MutationFunction<Types.EditDataPointValueMutation, Types.EditDataPointValueMutationVariables>;

/**
 * __useEditDataPointValueMutation__
 *
 * To run a mutation, you first call `useEditDataPointValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDataPointValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDataPointValueMutation, { data, loading, error }] = useEditDataPointValueMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      value: // value for 'value'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useEditDataPointValueMutation(baseOptions?: Apollo.MutationHookOptions<Types.EditDataPointValueMutation, Types.EditDataPointValueMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.EditDataPointValueMutation, Types.EditDataPointValueMutationVariables>(EditDataPointValueDocument, options);
}
export type EditDataPointValueMutationHookResult = ReturnType<typeof useEditDataPointValueMutation>;
export type EditDataPointValueMutationResult = Apollo.MutationResult<Types.EditDataPointValueMutation>;
export type EditDataPointValueMutationOptions = Apollo.BaseMutationOptions<Types.EditDataPointValueMutation, Types.EditDataPointValueMutationVariables>;
export const ResolveCollisionDocument = gql`
  mutation resolveCollision($dataPointId: ID!, $resolvedDataPointIds: [ID]) {
    resolve_collision(deal_data_point_id: $dataPointId, resolved_data_point_ids: $resolvedDataPointIds) {
      data_point {
        ...DataPointDetailFragment
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;
export type ResolveCollisionMutationFn = Apollo.MutationFunction<Types.ResolveCollisionMutation, Types.ResolveCollisionMutationVariables>;

/**
 * __useResolveCollisionMutation__
 *
 * To run a mutation, you first call `useResolveCollisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveCollisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveCollisionMutation, { data, loading, error }] = useResolveCollisionMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      resolvedDataPointIds: // value for 'resolvedDataPointIds'
 *   },
 * });
 */
export function useResolveCollisionMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResolveCollisionMutation, Types.ResolveCollisionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ResolveCollisionMutation, Types.ResolveCollisionMutationVariables>(ResolveCollisionDocument, options);
}
export type ResolveCollisionMutationHookResult = ReturnType<typeof useResolveCollisionMutation>;
export type ResolveCollisionMutationResult = Apollo.MutationResult<Types.ResolveCollisionMutation>;
export type ResolveCollisionMutationOptions = Apollo.BaseMutationOptions<Types.ResolveCollisionMutation, Types.ResolveCollisionMutationVariables>;
export const UpdateDataPointDataTableRowsDocument = gql`
  mutation updateDataPointDataTableRows($dataPointId: ID!, $rows: String!) {
    update_data_point_data_table_rows(data_point_id: $dataPointId, rows: $rows) {
      data_points {
        ...DataPointFragment
      }
    }
  }
  ${DataPointFragmentFragmentDoc}
`;
export type UpdateDataPointDataTableRowsMutationFn = Apollo.MutationFunction<Types.UpdateDataPointDataTableRowsMutation, Types.UpdateDataPointDataTableRowsMutationVariables>;

/**
 * __useUpdateDataPointDataTableRowsMutation__
 *
 * To run a mutation, you first call `useUpdateDataPointDataTableRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPointDataTableRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPointDataTableRowsMutation, { data, loading, error }] = useUpdateDataPointDataTableRowsMutation({
 *   variables: {
 *      dataPointId: // value for 'dataPointId'
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useUpdateDataPointDataTableRowsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDataPointDataTableRowsMutation, Types.UpdateDataPointDataTableRowsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateDataPointDataTableRowsMutation, Types.UpdateDataPointDataTableRowsMutationVariables>(UpdateDataPointDataTableRowsDocument, options);
}
export type UpdateDataPointDataTableRowsMutationHookResult = ReturnType<typeof useUpdateDataPointDataTableRowsMutation>;
export type UpdateDataPointDataTableRowsMutationResult = Apollo.MutationResult<Types.UpdateDataPointDataTableRowsMutation>;
export type UpdateDataPointDataTableRowsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDataPointDataTableRowsMutation, Types.UpdateDataPointDataTableRowsMutationVariables>;
export const SetDealStateDocument = gql`
  mutation setDealState($dealId: ID!, $stateId: ID!) {
    edit_deal(deal_id: $dealId, state_id: $stateId, edit_type: "STATE") {
      deal {
        id
        state {
          id
          final
          name
          workflow {
            id
            is_non_linear
          }
        }
      }
      dashboard_data {
        id
        status {
          id
          value
        }
      }
      transition_audit {
        states {
          id
          active
        }
      }
    }
  }
`;
export type SetDealStateMutationFn = Apollo.MutationFunction<Types.SetDealStateMutation, Types.SetDealStateMutationVariables>;

/**
 * __useSetDealStateMutation__
 *
 * To run a mutation, you first call `useSetDealStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealStateMutation, { data, loading, error }] = useSetDealStateMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useSetDealStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDealStateMutation, Types.SetDealStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDealStateMutation, Types.SetDealStateMutationVariables>(SetDealStateDocument, options);
}
export type SetDealStateMutationHookResult = ReturnType<typeof useSetDealStateMutation>;
export type SetDealStateMutationResult = Apollo.MutationResult<Types.SetDealStateMutation>;
export type SetDealStateMutationOptions = Apollo.BaseMutationOptions<Types.SetDealStateMutation, Types.SetDealStateMutationVariables>;
export const RenameDealDocument = gql`
  mutation renameDeal($dealId: ID!, $edit_type: String!, $name: String!) {
    edit_deal(deal_id: $dealId, edit_type: $edit_type, name: $name) {
      deal {
        id
        alias
      }
      dashboard_data {
        id
        document {
          id
          value_alias
          value_original
        }
      }
    }
  }
`;
export type RenameDealMutationFn = Apollo.MutationFunction<Types.RenameDealMutation, Types.RenameDealMutationVariables>;

/**
 * __useRenameDealMutation__
 *
 * To run a mutation, you first call `useRenameDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDealMutation, { data, loading, error }] = useRenameDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      edit_type: // value for 'edit_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameDealMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameDealMutation, Types.RenameDealMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RenameDealMutation, Types.RenameDealMutationVariables>(RenameDealDocument, options);
}
export type RenameDealMutationHookResult = ReturnType<typeof useRenameDealMutation>;
export type RenameDealMutationResult = Apollo.MutationResult<Types.RenameDealMutation>;
export type RenameDealMutationOptions = Apollo.BaseMutationOptions<Types.RenameDealMutation, Types.RenameDealMutationVariables>;
export const RenameDealAttachmentDocument = gql`
  mutation renameDealAttachment($dealId: ID!, $attachmentId: ID!, $edit_type: String!, $name: String!) {
    edit_deal_attachment(deal_id: $dealId, attachment_id: $attachmentId, edit_type: $edit_type, name: $name) {
      deal {
        id
        attachments {
          edges {
            node {
              id
              alias
            }
          }
        }
      }
    }
  }
`;
export type RenameDealAttachmentMutationFn = Apollo.MutationFunction<Types.RenameDealAttachmentMutation, Types.RenameDealAttachmentMutationVariables>;

/**
 * __useRenameDealAttachmentMutation__
 *
 * To run a mutation, you first call `useRenameDealAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDealAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDealAttachmentMutation, { data, loading, error }] = useRenameDealAttachmentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      attachmentId: // value for 'attachmentId'
 *      edit_type: // value for 'edit_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameDealAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameDealAttachmentMutation, Types.RenameDealAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RenameDealAttachmentMutation, Types.RenameDealAttachmentMutationVariables>(RenameDealAttachmentDocument, options);
}
export type RenameDealAttachmentMutationHookResult = ReturnType<typeof useRenameDealAttachmentMutation>;
export type RenameDealAttachmentMutationResult = Apollo.MutationResult<Types.RenameDealAttachmentMutation>;
export type RenameDealAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.RenameDealAttachmentMutation, Types.RenameDealAttachmentMutationVariables>;
export const CreateDealNoteDocument = gql`
  mutation createDealNote($dealId: ID!, $text: String!) {
    create_deal_note(deal_id: $dealId, text: $text) {
      deal {
        id
        notes {
          edges {
            node {
              id
              created_at
              created_by {
                id
                first_name
                last_name
                user_name
              }
              text
            }
          }
        }
      }
    }
  }
`;
export type CreateDealNoteMutationFn = Apollo.MutationFunction<Types.CreateDealNoteMutation, Types.CreateDealNoteMutationVariables>;

/**
 * __useCreateDealNoteMutation__
 *
 * To run a mutation, you first call `useCreateDealNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealNoteMutation, { data, loading, error }] = useCreateDealNoteMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateDealNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDealNoteMutation, Types.CreateDealNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDealNoteMutation, Types.CreateDealNoteMutationVariables>(CreateDealNoteDocument, options);
}
export type CreateDealNoteMutationHookResult = ReturnType<typeof useCreateDealNoteMutation>;
export type CreateDealNoteMutationResult = Apollo.MutationResult<Types.CreateDealNoteMutation>;
export type CreateDealNoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateDealNoteMutation, Types.CreateDealNoteMutationVariables>;
export const DeleteDealNoteDocument = gql`
  mutation deleteDealNote($dealId: ID!, $noteId: ID!) {
    delete_deal_note(deal_id: $dealId, note_id: $noteId) {
      deal {
        id
        notes {
          edges {
            node {
              id
              text
            }
          }
        }
      }
    }
  }
`;
export type DeleteDealNoteMutationFn = Apollo.MutationFunction<Types.DeleteDealNoteMutation, Types.DeleteDealNoteMutationVariables>;

/**
 * __useDeleteDealNoteMutation__
 *
 * To run a mutation, you first call `useDeleteDealNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealNoteMutation, { data, loading, error }] = useDeleteDealNoteMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteDealNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDealNoteMutation, Types.DeleteDealNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDealNoteMutation, Types.DeleteDealNoteMutationVariables>(DeleteDealNoteDocument, options);
}
export type DeleteDealNoteMutationHookResult = ReturnType<typeof useDeleteDealNoteMutation>;
export type DeleteDealNoteMutationResult = Apollo.MutationResult<Types.DeleteDealNoteMutation>;
export type DeleteDealNoteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDealNoteMutation, Types.DeleteDealNoteMutationVariables>;
export const DeleteDealAttachmentDocument = gql`
  mutation deleteDealAttachment($dealId: ID!, $attachmentId: ID!) {
    delete_deal_attachment(deal_id: $dealId, attachment_id: $attachmentId) {
      deal {
        id
        attachments {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeleteDealAttachmentMutationFn = Apollo.MutationFunction<Types.DeleteDealAttachmentMutation, Types.DeleteDealAttachmentMutationVariables>;

/**
 * __useDeleteDealAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteDealAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealAttachmentMutation, { data, loading, error }] = useDeleteDealAttachmentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteDealAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDealAttachmentMutation, Types.DeleteDealAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDealAttachmentMutation, Types.DeleteDealAttachmentMutationVariables>(DeleteDealAttachmentDocument, options);
}
export type DeleteDealAttachmentMutationHookResult = ReturnType<typeof useDeleteDealAttachmentMutation>;
export type DeleteDealAttachmentMutationResult = Apollo.MutationResult<Types.DeleteDealAttachmentMutation>;
export type DeleteDealAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDealAttachmentMutation, Types.DeleteDealAttachmentMutationVariables>;
export const DeleteDocumentsFromDealDocument = gql`
  mutation deleteDocumentsFromDeal($dealId: ID!, $documentIds: [ID!]) {
    edit_deal(deal_id: $dealId, document_ids: $documentIds, edit_type: "DELETE_DOCUMENTS") {
      deal {
        id
        documents {
          edges {
            node {
              id
            }
          }
        }
      }
      deal_data_points {
        ...DataPointDetailFragment
      }
      dashboard_data {
        id
        documents_count {
          internal_name
          value
        }
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;
export type DeleteDocumentsFromDealMutationFn = Apollo.MutationFunction<Types.DeleteDocumentsFromDealMutation, Types.DeleteDocumentsFromDealMutationVariables>;

/**
 * __useDeleteDocumentsFromDealMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentsFromDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentsFromDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentsFromDealMutation, { data, loading, error }] = useDeleteDocumentsFromDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDeleteDocumentsFromDealMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentsFromDealMutation, Types.DeleteDocumentsFromDealMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDocumentsFromDealMutation, Types.DeleteDocumentsFromDealMutationVariables>(DeleteDocumentsFromDealDocument, options);
}
export type DeleteDocumentsFromDealMutationHookResult = ReturnType<typeof useDeleteDocumentsFromDealMutation>;
export type DeleteDocumentsFromDealMutationResult = Apollo.MutationResult<Types.DeleteDocumentsFromDealMutation>;
export type DeleteDocumentsFromDealMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentsFromDealMutation, Types.DeleteDocumentsFromDealMutationVariables>;
export const AddDocumentsToDealDocument = gql`
  mutation addDocumentsToDeal($dealId: ID!, $documentIds: [ID!]) {
    edit_deal(deal_id: $dealId, document_ids: $documentIds, edit_type: "ADD_DOCUMENTS") {
      deal {
        id
        documents {
          edges {
            node {
              id
              name
              alias
              external_document
              is_reviewed
              is_counter_party_level
              is_deal_level
              visualizer_version
              document_type {
                id
                name
              }
              integration_type
              created_at
              created_by {
                user_name
              }
              counter_party {
                id
                name
              }
            }
          }
        }
      }
      deal_data_points {
        ...DataPointDetailFragment
      }
      dashboard_data {
        id
        documents_count {
          internal_name
          value
        }
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;
export type AddDocumentsToDealMutationFn = Apollo.MutationFunction<Types.AddDocumentsToDealMutation, Types.AddDocumentsToDealMutationVariables>;

/**
 * __useAddDocumentsToDealMutation__
 *
 * To run a mutation, you first call `useAddDocumentsToDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentsToDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentsToDealMutation, { data, loading, error }] = useAddDocumentsToDealMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useAddDocumentsToDealMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddDocumentsToDealMutation, Types.AddDocumentsToDealMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddDocumentsToDealMutation, Types.AddDocumentsToDealMutationVariables>(AddDocumentsToDealDocument, options);
}
export type AddDocumentsToDealMutationHookResult = ReturnType<typeof useAddDocumentsToDealMutation>;
export type AddDocumentsToDealMutationResult = Apollo.MutationResult<Types.AddDocumentsToDealMutation>;
export type AddDocumentsToDealMutationOptions = Apollo.BaseMutationOptions<Types.AddDocumentsToDealMutation, Types.AddDocumentsToDealMutationVariables>;
export const SetDealAssigneeDocument = gql`
  mutation setDealAssignee($dealId: ID!, $assigneeId: ID) {
    edit_deal(deal_id: $dealId, assignee_id: $assigneeId, edit_type: "ASSIGNEE") {
      deal {
        id
        assignee {
          id
          first_name
          last_name
          user_name
        }
      }
      dashboard_data {
        id
        assignee {
          value {
            id
            first_name
            last_name
            user_name
          }
        }
      }
    }
  }
`;
export type SetDealAssigneeMutationFn = Apollo.MutationFunction<Types.SetDealAssigneeMutation, Types.SetDealAssigneeMutationVariables>;

/**
 * __useSetDealAssigneeMutation__
 *
 * To run a mutation, you first call `useSetDealAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealAssigneeMutation, { data, loading, error }] = useSetDealAssigneeMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useSetDealAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDealAssigneeMutation, Types.SetDealAssigneeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDealAssigneeMutation, Types.SetDealAssigneeMutationVariables>(SetDealAssigneeDocument, options);
}
export type SetDealAssigneeMutationHookResult = ReturnType<typeof useSetDealAssigneeMutation>;
export type SetDealAssigneeMutationResult = Apollo.MutationResult<Types.SetDealAssigneeMutation>;
export type SetDealAssigneeMutationOptions = Apollo.BaseMutationOptions<Types.SetDealAssigneeMutation, Types.SetDealAssigneeMutationVariables>;
export const SetDealCounterpartyDocument = gql`
  mutation setDealCounterparty($dealId: ID!, $counter_party_id: ID, $name: String) {
    edit_deal(deal_id: $dealId, counter_party_id: $counter_party_id, name: $name, edit_type: "COUNTER_PARTY") {
      deal {
        id
        counter_party {
          id
          name
        }
      }
      dashboard_data {
        id
        counter_party {
          internal_name
          value
        }
      }
    }
  }
`;
export type SetDealCounterpartyMutationFn = Apollo.MutationFunction<Types.SetDealCounterpartyMutation, Types.SetDealCounterpartyMutationVariables>;

/**
 * __useSetDealCounterpartyMutation__
 *
 * To run a mutation, you first call `useSetDealCounterpartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDealCounterpartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDealCounterpartyMutation, { data, loading, error }] = useSetDealCounterpartyMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      counter_party_id: // value for 'counter_party_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetDealCounterpartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDealCounterpartyMutation, Types.SetDealCounterpartyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDealCounterpartyMutation, Types.SetDealCounterpartyMutationVariables>(SetDealCounterpartyDocument, options);
}
export type SetDealCounterpartyMutationHookResult = ReturnType<typeof useSetDealCounterpartyMutation>;
export type SetDealCounterpartyMutationResult = Apollo.MutationResult<Types.SetDealCounterpartyMutation>;
export type SetDealCounterpartyMutationOptions = Apollo.BaseMutationOptions<Types.SetDealCounterpartyMutation, Types.SetDealCounterpartyMutationVariables>;
export const BulkEditDealStateDocument = gql`
  mutation bulkEditDealState($dealIds: [ID!], $stateId: ID!) {
    bulk_edit_deal_state(deal_ids: $dealIds, state_id: $stateId) {
      deals {
        id
        state {
          id
          final
          name
          workflow {
            id
            is_non_linear
          }
        }
      }
      advanced_dashboard {
        dashboard_data {
          id
          status {
            id
            value
          }
        }
      }
    }
  }
`;
export type BulkEditDealStateMutationFn = Apollo.MutationFunction<Types.BulkEditDealStateMutation, Types.BulkEditDealStateMutationVariables>;

/**
 * __useBulkEditDealStateMutation__
 *
 * To run a mutation, you first call `useBulkEditDealStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDealStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDealStateMutation, { data, loading, error }] = useBulkEditDealStateMutation({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useBulkEditDealStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkEditDealStateMutation, Types.BulkEditDealStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BulkEditDealStateMutation, Types.BulkEditDealStateMutationVariables>(BulkEditDealStateDocument, options);
}
export type BulkEditDealStateMutationHookResult = ReturnType<typeof useBulkEditDealStateMutation>;
export type BulkEditDealStateMutationResult = Apollo.MutationResult<Types.BulkEditDealStateMutation>;
export type BulkEditDealStateMutationOptions = Apollo.BaseMutationOptions<Types.BulkEditDealStateMutation, Types.BulkEditDealStateMutationVariables>;
export const BulkEditDealAssigneeDocument = gql`
  mutation bulkEditDealAssignee($dealIds: [ID!], $userId: ID) {
    bulk_edit_deal_assignee(deal_ids: $dealIds, user_id: $userId) {
      advanced_dashboard {
        dashboard_data {
          id
          assignee {
            value {
              id
              first_name
              last_name
              user_name
            }
          }
        }
      }
      deals {
        id
        assignee {
          id
          first_name
          last_name
          user_name
        }
      }
    }
  }
`;
export type BulkEditDealAssigneeMutationFn = Apollo.MutationFunction<Types.BulkEditDealAssigneeMutation, Types.BulkEditDealAssigneeMutationVariables>;

/**
 * __useBulkEditDealAssigneeMutation__
 *
 * To run a mutation, you first call `useBulkEditDealAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDealAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDealAssigneeMutation, { data, loading, error }] = useBulkEditDealAssigneeMutation({
 *   variables: {
 *      dealIds: // value for 'dealIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBulkEditDealAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkEditDealAssigneeMutation, Types.BulkEditDealAssigneeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BulkEditDealAssigneeMutation, Types.BulkEditDealAssigneeMutationVariables>(BulkEditDealAssigneeDocument, options);
}
export type BulkEditDealAssigneeMutationHookResult = ReturnType<typeof useBulkEditDealAssigneeMutation>;
export type BulkEditDealAssigneeMutationResult = Apollo.MutationResult<Types.BulkEditDealAssigneeMutation>;
export type BulkEditDealAssigneeMutationOptions = Apollo.BaseMutationOptions<Types.BulkEditDealAssigneeMutation, Types.BulkEditDealAssigneeMutationVariables>;
export const ChangeDealAttachmentAsDocumentDocument = gql`
  mutation changeDealAttachmentAsDocument($dealId: ID!, $attachmentId: ID!, $isAccountLevel: Boolean!) {
    change_deal_attachment_as_document(deal_id: $dealId, attachment_id: $attachmentId, is_counter_party_level: $isAccountLevel) {
      deal {
        ...DealFragment
      }
    }
  }
  ${DealFragmentFragmentDoc}
`;
export type ChangeDealAttachmentAsDocumentMutationFn = Apollo.MutationFunction<Types.ChangeDealAttachmentAsDocumentMutation, Types.ChangeDealAttachmentAsDocumentMutationVariables>;

/**
 * __useChangeDealAttachmentAsDocumentMutation__
 *
 * To run a mutation, you first call `useChangeDealAttachmentAsDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDealAttachmentAsDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDealAttachmentAsDocumentMutation, { data, loading, error }] = useChangeDealAttachmentAsDocumentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      attachmentId: // value for 'attachmentId'
 *      isAccountLevel: // value for 'isAccountLevel'
 *   },
 * });
 */
export function useChangeDealAttachmentAsDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeDealAttachmentAsDocumentMutation, Types.ChangeDealAttachmentAsDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ChangeDealAttachmentAsDocumentMutation, Types.ChangeDealAttachmentAsDocumentMutationVariables>(ChangeDealAttachmentAsDocumentDocument, options);
}
export type ChangeDealAttachmentAsDocumentMutationHookResult = ReturnType<typeof useChangeDealAttachmentAsDocumentMutation>;
export type ChangeDealAttachmentAsDocumentMutationResult = Apollo.MutationResult<Types.ChangeDealAttachmentAsDocumentMutation>;
export type ChangeDealAttachmentAsDocumentMutationOptions = Apollo.BaseMutationOptions<Types.ChangeDealAttachmentAsDocumentMutation, Types.ChangeDealAttachmentAsDocumentMutationVariables>;
export const MoveDocumentToDealAttachmentDocument = gql`
  mutation moveDocumentToDealAttachment($dealId: ID!, $documentId: ID!) {
    move_document_to_deal_attachment(deal_id: $dealId, document_id: $documentId) {
      deal {
        ...DealFragment
      }
      deal_data_points {
        ...DataPointDetailNoCommentsFragment
      }
    }
  }
  ${DealFragmentFragmentDoc}
  ${DataPointDetailNoCommentsFragmentFragmentDoc}
`;
export type MoveDocumentToDealAttachmentMutationFn = Apollo.MutationFunction<Types.MoveDocumentToDealAttachmentMutation, Types.MoveDocumentToDealAttachmentMutationVariables>;

/**
 * __useMoveDocumentToDealAttachmentMutation__
 *
 * To run a mutation, you first call `useMoveDocumentToDealAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentToDealAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentToDealAttachmentMutation, { data, loading, error }] = useMoveDocumentToDealAttachmentMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useMoveDocumentToDealAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.MoveDocumentToDealAttachmentMutation, Types.MoveDocumentToDealAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.MoveDocumentToDealAttachmentMutation, Types.MoveDocumentToDealAttachmentMutationVariables>(MoveDocumentToDealAttachmentDocument, options);
}
export type MoveDocumentToDealAttachmentMutationHookResult = ReturnType<typeof useMoveDocumentToDealAttachmentMutation>;
export type MoveDocumentToDealAttachmentMutationResult = Apollo.MutationResult<Types.MoveDocumentToDealAttachmentMutation>;
export type MoveDocumentToDealAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.MoveDocumentToDealAttachmentMutation, Types.MoveDocumentToDealAttachmentMutationVariables>;
export const DeleteDealDocument = gql`
  mutation deleteDeal($deal_id: ID!, $delete_documents: Boolean) {
    delete_deal(deal_id: $deal_id, delete_documents: $delete_documents) {
      deal {
        id
        name
        documents {
          edges {
            node {
              id
              name
              is_deleted
            }
          }
        }
      }
    }
  }
`;
export type DeleteDealMutationFn = Apollo.MutationFunction<Types.DeleteDealMutation, Types.DeleteDealMutationVariables>;

/**
 * __useDeleteDealMutation__
 *
 * To run a mutation, you first call `useDeleteDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealMutation, { data, loading, error }] = useDeleteDealMutation({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *      delete_documents: // value for 'delete_documents'
 *   },
 * });
 */
export function useDeleteDealMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDealMutation, Types.DeleteDealMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDealMutation, Types.DeleteDealMutationVariables>(DeleteDealDocument, options);
}
export type DeleteDealMutationHookResult = ReturnType<typeof useDeleteDealMutation>;
export type DeleteDealMutationResult = Apollo.MutationResult<Types.DeleteDealMutation>;
export type DeleteDealMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDealMutation, Types.DeleteDealMutationVariables>;
export const CreateDocumentNoteDocument = gql`
  mutation createDocumentNote($documentId: ID!, $text: String!) {
    create_document_note(document_id: $documentId, text: $text) {
      document {
        notes {
          edges {
            node {
              id
              created_at
              created_by {
                id
                first_name
                last_name
                user_name
              }
              text
            }
          }
        }
      }
    }
  }
`;
export type CreateDocumentNoteMutationFn = Apollo.MutationFunction<Types.CreateDocumentNoteMutation, Types.CreateDocumentNoteMutationVariables>;

/**
 * __useCreateDocumentNoteMutation__
 *
 * To run a mutation, you first call `useCreateDocumentNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentNoteMutation, { data, loading, error }] = useCreateDocumentNoteMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateDocumentNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateDocumentNoteMutation, Types.CreateDocumentNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDocumentNoteMutation, Types.CreateDocumentNoteMutationVariables>(CreateDocumentNoteDocument, options);
}
export type CreateDocumentNoteMutationHookResult = ReturnType<typeof useCreateDocumentNoteMutation>;
export type CreateDocumentNoteMutationResult = Apollo.MutationResult<Types.CreateDocumentNoteMutation>;
export type CreateDocumentNoteMutationOptions = Apollo.BaseMutationOptions<Types.CreateDocumentNoteMutation, Types.CreateDocumentNoteMutationVariables>;
export const DeleteDocumentNoteDocument = gql`
  mutation deleteDocumentNote($documentId: ID!, $noteId: ID!) {
    delete_document_note(document_id: $documentId, note_id: $noteId) {
      document {
        notes {
          edges {
            node {
              id
              created_at
              created_by {
                id
                first_name
                last_name
                user_name
              }
              text
            }
          }
        }
      }
    }
  }
`;
export type DeleteDocumentNoteMutationFn = Apollo.MutationFunction<Types.DeleteDocumentNoteMutation, Types.DeleteDocumentNoteMutationVariables>;

/**
 * __useDeleteDocumentNoteMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentNoteMutation, { data, loading, error }] = useDeleteDocumentNoteMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useDeleteDocumentNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentNoteMutation, Types.DeleteDocumentNoteMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDocumentNoteMutation, Types.DeleteDocumentNoteMutationVariables>(DeleteDocumentNoteDocument, options);
}
export type DeleteDocumentNoteMutationHookResult = ReturnType<typeof useDeleteDocumentNoteMutation>;
export type DeleteDocumentNoteMutationResult = Apollo.MutationResult<Types.DeleteDocumentNoteMutation>;
export type DeleteDocumentNoteMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentNoteMutation, Types.DeleteDocumentNoteMutationVariables>;
export const SetDocumentStateDocument = gql`
  mutation setDocumentState($documentId: ID!, $stateId: ID!) {
    edit_document(document_id: $documentId, state_id: $stateId, edit_type: "STATE") {
      document {
        id
        state {
          id
          name
          workflow {
            id
            is_non_linear
          }
        }
      }
      dashboard_data {
        id
        status {
          id
          internal_name
          value
        }
      }
      transition_audit {
        states {
          id
          active
        }
      }
    }
  }
`;
export type SetDocumentStateMutationFn = Apollo.MutationFunction<Types.SetDocumentStateMutation, Types.SetDocumentStateMutationVariables>;

/**
 * __useSetDocumentStateMutation__
 *
 * To run a mutation, you first call `useSetDocumentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentStateMutation, { data, loading, error }] = useSetDocumentStateMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useSetDocumentStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDocumentStateMutation, Types.SetDocumentStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDocumentStateMutation, Types.SetDocumentStateMutationVariables>(SetDocumentStateDocument, options);
}
export type SetDocumentStateMutationHookResult = ReturnType<typeof useSetDocumentStateMutation>;
export type SetDocumentStateMutationResult = Apollo.MutationResult<Types.SetDocumentStateMutation>;
export type SetDocumentStateMutationOptions = Apollo.BaseMutationOptions<Types.SetDocumentStateMutation, Types.SetDocumentStateMutationVariables>;
export const SetDocumentAssigneeDocument = gql`
  mutation setDocumentAssignee($documentId: ID!, $assigneeId: ID) {
    edit_document(document_id: $documentId, assignee_id: $assigneeId, edit_type: "ASSIGNEE") {
      document {
        id
        assignee {
          id
          first_name
          last_name
          user_name
        }
      }
      dashboard_data {
        id
        assignee {
          value {
            id
            first_name
            last_name
            user_name
          }
        }
      }
    }
  }
`;
export type SetDocumentAssigneeMutationFn = Apollo.MutationFunction<Types.SetDocumentAssigneeMutation, Types.SetDocumentAssigneeMutationVariables>;

/**
 * __useSetDocumentAssigneeMutation__
 *
 * To run a mutation, you first call `useSetDocumentAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentAssigneeMutation, { data, loading, error }] = useSetDocumentAssigneeMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useSetDocumentAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDocumentAssigneeMutation, Types.SetDocumentAssigneeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDocumentAssigneeMutation, Types.SetDocumentAssigneeMutationVariables>(SetDocumentAssigneeDocument, options);
}
export type SetDocumentAssigneeMutationHookResult = ReturnType<typeof useSetDocumentAssigneeMutation>;
export type SetDocumentAssigneeMutationResult = Apollo.MutationResult<Types.SetDocumentAssigneeMutation>;
export type SetDocumentAssigneeMutationOptions = Apollo.BaseMutationOptions<Types.SetDocumentAssigneeMutation, Types.SetDocumentAssigneeMutationVariables>;
export const SetDocumentCounterpartyDocument = gql`
  mutation setDocumentCounterparty($documentId: ID!, $counter_party_id: ID, $name: String) {
    edit_document(document_id: $documentId, counter_party_id: $counter_party_id, name: $name, edit_type: "COUNTER_PARTY") {
      document {
        id
        counter_party {
          id
          name
        }
      }
      dashboard_data {
        id
        counter_party {
          internal_name
          value
        }
      }
    }
  }
`;
export type SetDocumentCounterpartyMutationFn = Apollo.MutationFunction<Types.SetDocumentCounterpartyMutation, Types.SetDocumentCounterpartyMutationVariables>;

/**
 * __useSetDocumentCounterpartyMutation__
 *
 * To run a mutation, you first call `useSetDocumentCounterpartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentCounterpartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentCounterpartyMutation, { data, loading, error }] = useSetDocumentCounterpartyMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      counter_party_id: // value for 'counter_party_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSetDocumentCounterpartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.SetDocumentCounterpartyMutation, Types.SetDocumentCounterpartyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetDocumentCounterpartyMutation, Types.SetDocumentCounterpartyMutationVariables>(SetDocumentCounterpartyDocument, options);
}
export type SetDocumentCounterpartyMutationHookResult = ReturnType<typeof useSetDocumentCounterpartyMutation>;
export type SetDocumentCounterpartyMutationResult = Apollo.MutationResult<Types.SetDocumentCounterpartyMutation>;
export type SetDocumentCounterpartyMutationOptions = Apollo.BaseMutationOptions<Types.SetDocumentCounterpartyMutation, Types.SetDocumentCounterpartyMutationVariables>;
export const UpdateDocumentTypeDocument = gql`
  mutation updateDocumentType($documentId: ID!, $documentTypeId: ID!) {
    edit_document(document_id: $documentId, document_type_id: $documentTypeId, edit_type: "DOCUMENT_TYPE") {
      document {
        id
        document_type {
          id
          name
        }
      }
    }
  }
`;
export type UpdateDocumentTypeMutationFn = Apollo.MutationFunction<Types.UpdateDocumentTypeMutation, Types.UpdateDocumentTypeMutationVariables>;

/**
 * __useUpdateDocumentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTypeMutation, { data, loading, error }] = useUpdateDocumentTypeMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      documentTypeId: // value for 'documentTypeId'
 *   },
 * });
 */
export function useUpdateDocumentTypeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateDocumentTypeMutation, Types.UpdateDocumentTypeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateDocumentTypeMutation, Types.UpdateDocumentTypeMutationVariables>(UpdateDocumentTypeDocument, options);
}
export type UpdateDocumentTypeMutationHookResult = ReturnType<typeof useUpdateDocumentTypeMutation>;
export type UpdateDocumentTypeMutationResult = Apollo.MutationResult<Types.UpdateDocumentTypeMutation>;
export type UpdateDocumentTypeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateDocumentTypeMutation, Types.UpdateDocumentTypeMutationVariables>;
export const BulkEditDocumentStateDocument = gql`
  mutation bulkEditDocumentState($documentIds: [ID!], $stateId: ID!) {
    bulk_edit_document_state(document_ids: $documentIds, state_id: $stateId) {
      documents {
        id
        state {
          id
          name
          workflow {
            id
            is_non_linear
          }
        }
      }
      advanced_dashboard {
        dashboard_data {
          id
          status {
            id
            value
          }
        }
      }
    }
  }
`;
export type BulkEditDocumentStateMutationFn = Apollo.MutationFunction<Types.BulkEditDocumentStateMutation, Types.BulkEditDocumentStateMutationVariables>;

/**
 * __useBulkEditDocumentStateMutation__
 *
 * To run a mutation, you first call `useBulkEditDocumentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDocumentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDocumentStateMutation, { data, loading, error }] = useBulkEditDocumentStateMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      stateId: // value for 'stateId'
 *   },
 * });
 */
export function useBulkEditDocumentStateMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkEditDocumentStateMutation, Types.BulkEditDocumentStateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BulkEditDocumentStateMutation, Types.BulkEditDocumentStateMutationVariables>(BulkEditDocumentStateDocument, options);
}
export type BulkEditDocumentStateMutationHookResult = ReturnType<typeof useBulkEditDocumentStateMutation>;
export type BulkEditDocumentStateMutationResult = Apollo.MutationResult<Types.BulkEditDocumentStateMutation>;
export type BulkEditDocumentStateMutationOptions = Apollo.BaseMutationOptions<Types.BulkEditDocumentStateMutation, Types.BulkEditDocumentStateMutationVariables>;
export const BulkEditDocumentAssigneeDocument = gql`
  mutation bulkEditDocumentAssignee($documentIds: [ID!], $userId: ID) {
    bulk_edit_document_assignee(document_ids: $documentIds, user_id: $userId) {
      advanced_dashboard {
        dashboard_data {
          id
          assignee {
            value {
              id
              first_name
              last_name
              user_name
            }
          }
        }
      }
      documents {
        id
        assignee {
          id
          first_name
          last_name
          user_name
        }
      }
    }
  }
`;
export type BulkEditDocumentAssigneeMutationFn = Apollo.MutationFunction<Types.BulkEditDocumentAssigneeMutation, Types.BulkEditDocumentAssigneeMutationVariables>;

/**
 * __useBulkEditDocumentAssigneeMutation__
 *
 * To run a mutation, you first call `useBulkEditDocumentAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDocumentAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDocumentAssigneeMutation, { data, loading, error }] = useBulkEditDocumentAssigneeMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useBulkEditDocumentAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<Types.BulkEditDocumentAssigneeMutation, Types.BulkEditDocumentAssigneeMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BulkEditDocumentAssigneeMutation, Types.BulkEditDocumentAssigneeMutationVariables>(BulkEditDocumentAssigneeDocument, options);
}
export type BulkEditDocumentAssigneeMutationHookResult = ReturnType<typeof useBulkEditDocumentAssigneeMutation>;
export type BulkEditDocumentAssigneeMutationResult = Apollo.MutationResult<Types.BulkEditDocumentAssigneeMutation>;
export type BulkEditDocumentAssigneeMutationOptions = Apollo.BaseMutationOptions<Types.BulkEditDocumentAssigneeMutation, Types.BulkEditDocumentAssigneeMutationVariables>;
export const MarkDocumentAsReviewedDocument = gql`
  mutation markDocumentAsReviewed($documentId: ID!, $isReviewed: Boolean!) {
    mark_document_as_reviewed(document_id: $documentId, is_reviewed: $isReviewed) {
      document {
        id
        is_reviewed
      }
    }
  }
`;
export type MarkDocumentAsReviewedMutationFn = Apollo.MutationFunction<Types.MarkDocumentAsReviewedMutation, Types.MarkDocumentAsReviewedMutationVariables>;

/**
 * __useMarkDocumentAsReviewedMutation__
 *
 * To run a mutation, you first call `useMarkDocumentAsReviewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDocumentAsReviewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDocumentAsReviewedMutation, { data, loading, error }] = useMarkDocumentAsReviewedMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isReviewed: // value for 'isReviewed'
 *   },
 * });
 */
export function useMarkDocumentAsReviewedMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkDocumentAsReviewedMutation, Types.MarkDocumentAsReviewedMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.MarkDocumentAsReviewedMutation, Types.MarkDocumentAsReviewedMutationVariables>(MarkDocumentAsReviewedDocument, options);
}
export type MarkDocumentAsReviewedMutationHookResult = ReturnType<typeof useMarkDocumentAsReviewedMutation>;
export type MarkDocumentAsReviewedMutationResult = Apollo.MutationResult<Types.MarkDocumentAsReviewedMutation>;
export type MarkDocumentAsReviewedMutationOptions = Apollo.BaseMutationOptions<Types.MarkDocumentAsReviewedMutation, Types.MarkDocumentAsReviewedMutationVariables>;
export const RenameDocumentDocument = gql`
  mutation renameDocument($documentId: ID!, $edit_type: String!, $name: String!) {
    edit_document(document_id: $documentId, edit_type: $edit_type, name: $name) {
      document {
        id
        alias
      }
      dashboard_data {
        id
        document {
          id
          value_alias
          value_original
        }
      }
    }
  }
`;
export type RenameDocumentMutationFn = Apollo.MutationFunction<Types.RenameDocumentMutation, Types.RenameDocumentMutationVariables>;

/**
 * __useRenameDocumentMutation__
 *
 * To run a mutation, you first call `useRenameDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentMutation, { data, loading, error }] = useRenameDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      edit_type: // value for 'edit_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameDocumentMutation, Types.RenameDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RenameDocumentMutation, Types.RenameDocumentMutationVariables>(RenameDocumentDocument, options);
}
export type RenameDocumentMutationHookResult = ReturnType<typeof useRenameDocumentMutation>;
export type RenameDocumentMutationResult = Apollo.MutationResult<Types.RenameDocumentMutation>;
export type RenameDocumentMutationOptions = Apollo.BaseMutationOptions<Types.RenameDocumentMutation, Types.RenameDocumentMutationVariables>;
export const RenameDocumentAttachmentDocument = gql`
  mutation renameDocumentAttachment($documentId: ID!, $attachmentId: ID!, $edit_type: String!, $name: String!) {
    edit_document_attachment(document_id: $documentId, attachment_id: $attachmentId, edit_type: $edit_type, name: $name) {
      document {
        id
        attachments {
          edges {
            node {
              id
              alias
            }
          }
        }
      }
    }
  }
`;
export type RenameDocumentAttachmentMutationFn = Apollo.MutationFunction<Types.RenameDocumentAttachmentMutation, Types.RenameDocumentAttachmentMutationVariables>;

/**
 * __useRenameDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useRenameDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentAttachmentMutation, { data, loading, error }] = useRenameDocumentAttachmentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      attachmentId: // value for 'attachmentId'
 *      edit_type: // value for 'edit_type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameDocumentAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.RenameDocumentAttachmentMutation, Types.RenameDocumentAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RenameDocumentAttachmentMutation, Types.RenameDocumentAttachmentMutationVariables>(RenameDocumentAttachmentDocument, options);
}
export type RenameDocumentAttachmentMutationHookResult = ReturnType<typeof useRenameDocumentAttachmentMutation>;
export type RenameDocumentAttachmentMutationResult = Apollo.MutationResult<Types.RenameDocumentAttachmentMutation>;
export type RenameDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.RenameDocumentAttachmentMutation, Types.RenameDocumentAttachmentMutationVariables>;
export const DeleteDocumentAttachmentDocument = gql`
  mutation deleteDocumentAttachment($documentId: ID!, $attachmentId: ID!) {
    delete_document_attachment(document_id: $documentId, attachment_id: $attachmentId) {
      document {
        id
        attachments {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeleteDocumentAttachmentMutationFn = Apollo.MutationFunction<Types.DeleteDocumentAttachmentMutation, Types.DeleteDocumentAttachmentMutationVariables>;

/**
 * __useDeleteDocumentAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentAttachmentMutation, { data, loading, error }] = useDeleteDocumentAttachmentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteDocumentAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentAttachmentMutation, Types.DeleteDocumentAttachmentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDocumentAttachmentMutation, Types.DeleteDocumentAttachmentMutationVariables>(DeleteDocumentAttachmentDocument, options);
}
export type DeleteDocumentAttachmentMutationHookResult = ReturnType<typeof useDeleteDocumentAttachmentMutation>;
export type DeleteDocumentAttachmentMutationResult = Apollo.MutationResult<Types.DeleteDocumentAttachmentMutation>;
export type DeleteDocumentAttachmentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentAttachmentMutation, Types.DeleteDocumentAttachmentMutationVariables>;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($document_id: ID!, $deal_id: ID) {
    delete_document(document_id: $document_id, deal_id: $deal_id) {
      deal {
        id
        documents {
          edges {
            node {
              id
            }
          }
        }
      }
      deal_data_points {
        ...DataPointDetailFragment
      }
      document {
        id
        name
        is_deleted
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      document_id: // value for 'document_id'
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<Types.DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteDocumentMutation, Types.DeleteDocumentMutationVariables>;
export const ChangeDocumentClassificationLevelDocument = gql`
  mutation changeDocumentClassificationLevel($classification_level: ClassificationLevel!, $deal_id: ID, $document_id: ID!) {
    change_document_classification_level(classification_level: $classification_level, deal_id: $deal_id, document_id: $document_id) {
      document {
        id
        is_counter_party_level
        is_deal_level
      }
      deals {
        id
        documents {
          edges {
            node {
              id
              is_counter_party_level
              is_deal_level
            }
          }
        }
      }
    }
  }
`;
export type ChangeDocumentClassificationLevelMutationFn = Apollo.MutationFunction<Types.ChangeDocumentClassificationLevelMutation, Types.ChangeDocumentClassificationLevelMutationVariables>;

/**
 * __useChangeDocumentClassificationLevelMutation__
 *
 * To run a mutation, you first call `useChangeDocumentClassificationLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDocumentClassificationLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDocumentClassificationLevelMutation, { data, loading, error }] = useChangeDocumentClassificationLevelMutation({
 *   variables: {
 *      classification_level: // value for 'classification_level'
 *      deal_id: // value for 'deal_id'
 *      document_id: // value for 'document_id'
 *   },
 * });
 */
export function useChangeDocumentClassificationLevelMutation(baseOptions?: Apollo.MutationHookOptions<Types.ChangeDocumentClassificationLevelMutation, Types.ChangeDocumentClassificationLevelMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ChangeDocumentClassificationLevelMutation, Types.ChangeDocumentClassificationLevelMutationVariables>(ChangeDocumentClassificationLevelDocument, options);
}
export type ChangeDocumentClassificationLevelMutationHookResult = ReturnType<typeof useChangeDocumentClassificationLevelMutation>;
export type ChangeDocumentClassificationLevelMutationResult = Apollo.MutationResult<Types.ChangeDocumentClassificationLevelMutation>;
export type ChangeDocumentClassificationLevelMutationOptions = Apollo.BaseMutationOptions<Types.ChangeDocumentClassificationLevelMutation, Types.ChangeDocumentClassificationLevelMutationVariables>;
export const AnnotationTagsetDocument = gql`
  query annotationTagset($documentId: ID!) {
    annotation_tagset(document_id: $documentId) {
      display_name
      internal_name
    }
  }
`;

/**
 * __useAnnotationTagsetQuery__
 *
 * To run a query within a React component, call `useAnnotationTagsetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotationTagsetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotationTagsetQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useAnnotationTagsetQuery(baseOptions: Apollo.QueryHookOptions<Types.AnnotationTagsetQuery, Types.AnnotationTagsetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AnnotationTagsetQuery, Types.AnnotationTagsetQueryVariables>(AnnotationTagsetDocument, options);
}
export function useAnnotationTagsetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AnnotationTagsetQuery, Types.AnnotationTagsetQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AnnotationTagsetQuery, Types.AnnotationTagsetQueryVariables>(AnnotationTagsetDocument, options);
}
export type AnnotationTagsetQueryHookResult = ReturnType<typeof useAnnotationTagsetQuery>;
export type AnnotationTagsetLazyQueryHookResult = ReturnType<typeof useAnnotationTagsetLazyQuery>;
export type AnnotationTagsetQueryResult = Apollo.QueryResult<Types.AnnotationTagsetQuery, Types.AnnotationTagsetQueryVariables>;
export const AnnotationTodosDocument = gql`
  query annotationTodos {
    annotation_todos {
      edges {
        node {
          id
          created_at
          last_updated_at
          todolist {
            edges {
              node {
                item_type
                text
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAnnotationTodosQuery__
 *
 * To run a query within a React component, call `useAnnotationTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnnotationTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnnotationTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnnotationTodosQuery(baseOptions?: Apollo.QueryHookOptions<Types.AnnotationTodosQuery, Types.AnnotationTodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AnnotationTodosQuery, Types.AnnotationTodosQueryVariables>(AnnotationTodosDocument, options);
}
export function useAnnotationTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AnnotationTodosQuery, Types.AnnotationTodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AnnotationTodosQuery, Types.AnnotationTodosQueryVariables>(AnnotationTodosDocument, options);
}
export type AnnotationTodosQueryHookResult = ReturnType<typeof useAnnotationTodosQuery>;
export type AnnotationTodosLazyQueryHookResult = ReturnType<typeof useAnnotationTodosLazyQuery>;
export type AnnotationTodosQueryResult = Apollo.QueryResult<Types.AnnotationTodosQuery, Types.AnnotationTodosQueryVariables>;
export const InternalWorkflowDocument = gql`
  query internalWorkflow($documentId: ID!) {
    internal_workflow(document_id: $documentId) {
      id
      name
      is_final_state
    }
  }
`;

/**
 * __useInternalWorkflowQuery__
 *
 * To run a query within a React component, call `useInternalWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useInternalWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInternalWorkflowQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useInternalWorkflowQuery(baseOptions: Apollo.QueryHookOptions<Types.InternalWorkflowQuery, Types.InternalWorkflowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.InternalWorkflowQuery, Types.InternalWorkflowQueryVariables>(InternalWorkflowDocument, options);
}
export function useInternalWorkflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InternalWorkflowQuery, Types.InternalWorkflowQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.InternalWorkflowQuery, Types.InternalWorkflowQueryVariables>(InternalWorkflowDocument, options);
}
export type InternalWorkflowQueryHookResult = ReturnType<typeof useInternalWorkflowQuery>;
export type InternalWorkflowLazyQueryHookResult = ReturnType<typeof useInternalWorkflowLazyQuery>;
export type InternalWorkflowQueryResult = Apollo.QueryResult<Types.InternalWorkflowQuery, Types.InternalWorkflowQueryVariables>;
export const DataPointFieldsByInternalNameDocument = gql`
  query dataPointFieldsByInternalName($internal_name: String!) {
    document_data_point_fields_by_internal_name(internal_name: $internal_name) {
      id
      name
    }
  }
`;

/**
 * __useDataPointFieldsByInternalNameQuery__
 *
 * To run a query within a React component, call `useDataPointFieldsByInternalNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointFieldsByInternalNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointFieldsByInternalNameQuery({
 *   variables: {
 *      internal_name: // value for 'internal_name'
 *   },
 * });
 */
export function useDataPointFieldsByInternalNameQuery(baseOptions: Apollo.QueryHookOptions<Types.DataPointFieldsByInternalNameQuery, Types.DataPointFieldsByInternalNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataPointFieldsByInternalNameQuery, Types.DataPointFieldsByInternalNameQueryVariables>(DataPointFieldsByInternalNameDocument, options);
}
export function useDataPointFieldsByInternalNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPointFieldsByInternalNameQuery, Types.DataPointFieldsByInternalNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataPointFieldsByInternalNameQuery, Types.DataPointFieldsByInternalNameQueryVariables>(DataPointFieldsByInternalNameDocument, options);
}
export type DataPointFieldsByInternalNameQueryHookResult = ReturnType<typeof useDataPointFieldsByInternalNameQuery>;
export type DataPointFieldsByInternalNameLazyQueryHookResult = ReturnType<typeof useDataPointFieldsByInternalNameLazyQuery>;
export type DataPointFieldsByInternalNameQueryResult = Apollo.QueryResult<Types.DataPointFieldsByInternalNameQuery, Types.DataPointFieldsByInternalNameQueryVariables>;
export const DocumentAttachmentsDocument = gql`
  query documentAttachments($documentId: ID!) {
    document_attachments(document_id: $documentId) {
      ...AttachmentFragment
    }
  }
  ${AttachmentFragmentFragmentDoc}
`;

/**
 * __useDocumentAttachmentsQuery__
 *
 * To run a query within a React component, call `useDocumentAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAttachmentsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentAttachmentsQuery, Types.DocumentAttachmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentAttachmentsQuery, Types.DocumentAttachmentsQueryVariables>(DocumentAttachmentsDocument, options);
}
export function useDocumentAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentAttachmentsQuery, Types.DocumentAttachmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentAttachmentsQuery, Types.DocumentAttachmentsQueryVariables>(DocumentAttachmentsDocument, options);
}
export type DocumentAttachmentsQueryHookResult = ReturnType<typeof useDocumentAttachmentsQuery>;
export type DocumentAttachmentsLazyQueryHookResult = ReturnType<typeof useDocumentAttachmentsLazyQuery>;
export type DocumentAttachmentsQueryResult = Apollo.QueryResult<Types.DocumentAttachmentsQuery, Types.DocumentAttachmentsQueryVariables>;
export const DealAttachmentsDocument = gql`
  query dealAttachments($dealId: ID!) {
    deal_attachments(deal_id: $dealId) {
      ...AttachmentFragment
    }
  }
  ${AttachmentFragmentFragmentDoc}
`;

/**
 * __useDealAttachmentsQuery__
 *
 * To run a query within a React component, call `useDealAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealAttachmentsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *   },
 * });
 */
export function useDealAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<Types.DealAttachmentsQuery, Types.DealAttachmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealAttachmentsQuery, Types.DealAttachmentsQueryVariables>(DealAttachmentsDocument, options);
}
export function useDealAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealAttachmentsQuery, Types.DealAttachmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealAttachmentsQuery, Types.DealAttachmentsQueryVariables>(DealAttachmentsDocument, options);
}
export type DealAttachmentsQueryHookResult = ReturnType<typeof useDealAttachmentsQuery>;
export type DealAttachmentsLazyQueryHookResult = ReturnType<typeof useDealAttachmentsLazyQuery>;
export type DealAttachmentsQueryResult = Apollo.QueryResult<Types.DealAttachmentsQuery, Types.DealAttachmentsQueryVariables>;
export const AdvancedAttachmentsDashboardDocument = gql`
  query advancedAttachmentsDashboard($filters: JSONString!, $sortConfig: [JSONString], $size: Int!, $offset: Int!) {
    fetch_advanced_attachments_dashboard(filters: $filters, sort_config: $sortConfig, offset: $offset, size: $size) {
      dashboard_data {
        id
        document {
          __typename
          id
          internal_name
          value
          value_alias
          value_original
        }
        is_counter_party_level
        is_deal_level
        counter_party {
          internal_name
          value
        }
        created_at {
          internal_name
          value
        }
        created_by {
          internal_name
          value {
            id
            user_name
          }
        }
        integration_type {
          internal_name
          value
        }
      }
      total_number_of_documents
      total_number_of_filtered_documents
    }
  }
`;

/**
 * __useAdvancedAttachmentsDashboardQuery__
 *
 * To run a query within a React component, call `useAdvancedAttachmentsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedAttachmentsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedAttachmentsDashboardQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortConfig: // value for 'sortConfig'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdvancedAttachmentsDashboardQuery(baseOptions: Apollo.QueryHookOptions<Types.AdvancedAttachmentsDashboardQuery, Types.AdvancedAttachmentsDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AdvancedAttachmentsDashboardQuery, Types.AdvancedAttachmentsDashboardQueryVariables>(AdvancedAttachmentsDashboardDocument, options);
}
export function useAdvancedAttachmentsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdvancedAttachmentsDashboardQuery, Types.AdvancedAttachmentsDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AdvancedAttachmentsDashboardQuery, Types.AdvancedAttachmentsDashboardQueryVariables>(AdvancedAttachmentsDashboardDocument, options);
}
export type AdvancedAttachmentsDashboardQueryHookResult = ReturnType<typeof useAdvancedAttachmentsDashboardQuery>;
export type AdvancedAttachmentsDashboardLazyQueryHookResult = ReturnType<typeof useAdvancedAttachmentsDashboardLazyQuery>;
export type AdvancedAttachmentsDashboardQueryResult = Apollo.QueryResult<Types.AdvancedAttachmentsDashboardQuery, Types.AdvancedAttachmentsDashboardQueryVariables>;
export const AdvancedAttachmentsDashboardFieldsDocument = gql`
  query advancedAttachmentsDashboardFields {
    fetch_advanced_attachments_dashboard_fields {
      document {
        internal_name
        display_name
        filter_type
        operators
      }
      counter_party {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
      }
      created_at {
        internal_name
        display_name
        filter_type
        operators
      }
      integration_type {
        internal_name
        display_name
        value
        filter_type
        operators
      }
    }
  }
`;

/**
 * __useAdvancedAttachmentsDashboardFieldsQuery__
 *
 * To run a query within a React component, call `useAdvancedAttachmentsDashboardFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedAttachmentsDashboardFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedAttachmentsDashboardFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvancedAttachmentsDashboardFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdvancedAttachmentsDashboardFieldsQuery, Types.AdvancedAttachmentsDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AdvancedAttachmentsDashboardFieldsQuery, Types.AdvancedAttachmentsDashboardFieldsQueryVariables>(AdvancedAttachmentsDashboardFieldsDocument, options);
}
export function useAdvancedAttachmentsDashboardFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdvancedAttachmentsDashboardFieldsQuery, Types.AdvancedAttachmentsDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AdvancedAttachmentsDashboardFieldsQuery, Types.AdvancedAttachmentsDashboardFieldsQueryVariables>(AdvancedAttachmentsDashboardFieldsDocument, options);
}
export type AdvancedAttachmentsDashboardFieldsQueryHookResult = ReturnType<typeof useAdvancedAttachmentsDashboardFieldsQuery>;
export type AdvancedAttachmentsDashboardFieldsLazyQueryHookResult = ReturnType<typeof useAdvancedAttachmentsDashboardFieldsLazyQuery>;
export type AdvancedAttachmentsDashboardFieldsQueryResult = Apollo.QueryResult<Types.AdvancedAttachmentsDashboardFieldsQuery, Types.AdvancedAttachmentsDashboardFieldsQueryVariables>;
export const AttachmentCountDocument = gql`
  query attachmentCount($filters: JSONString!, $sortConfig: [JSONString]) {
    fetch_advanced_attachments_dashboard_count(filters: $filters, sort_config: $sortConfig) {
      total_number_of_documents
      total_number_of_filtered_documents
    }
  }
`;

/**
 * __useAttachmentCountQuery__
 *
 * To run a query within a React component, call `useAttachmentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sortConfig: // value for 'sortConfig'
 *   },
 * });
 */
export function useAttachmentCountQuery(baseOptions: Apollo.QueryHookOptions<Types.AttachmentCountQuery, Types.AttachmentCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AttachmentCountQuery, Types.AttachmentCountQueryVariables>(AttachmentCountDocument, options);
}
export function useAttachmentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AttachmentCountQuery, Types.AttachmentCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AttachmentCountQuery, Types.AttachmentCountQueryVariables>(AttachmentCountDocument, options);
}
export type AttachmentCountQueryHookResult = ReturnType<typeof useAttachmentCountQuery>;
export type AttachmentCountLazyQueryHookResult = ReturnType<typeof useAttachmentCountLazyQuery>;
export type AttachmentCountQueryResult = Apollo.QueryResult<Types.AttachmentCountQuery, Types.AttachmentCountQueryVariables>;
export const DealsAndDocumentsWithAttachmentDocument = gql`
  query dealsAndDocumentsWithAttachment($attachment_document_id: ID!) {
    deals_and_documents_with_attachment(attachment_document_id: $attachment_document_id) {
      deals {
        id
        name
      }
      documents {
        id
        name
      }
    }
  }
`;

/**
 * __useDealsAndDocumentsWithAttachmentQuery__
 *
 * To run a query within a React component, call `useDealsAndDocumentsWithAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsAndDocumentsWithAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsAndDocumentsWithAttachmentQuery({
 *   variables: {
 *      attachment_document_id: // value for 'attachment_document_id'
 *   },
 * });
 */
export function useDealsAndDocumentsWithAttachmentQuery(baseOptions: Apollo.QueryHookOptions<Types.DealsAndDocumentsWithAttachmentQuery, Types.DealsAndDocumentsWithAttachmentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsAndDocumentsWithAttachmentQuery, Types.DealsAndDocumentsWithAttachmentQueryVariables>(DealsAndDocumentsWithAttachmentDocument, options);
}
export function useDealsAndDocumentsWithAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsAndDocumentsWithAttachmentQuery, Types.DealsAndDocumentsWithAttachmentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsAndDocumentsWithAttachmentQuery, Types.DealsAndDocumentsWithAttachmentQueryVariables>(DealsAndDocumentsWithAttachmentDocument, options);
}
export type DealsAndDocumentsWithAttachmentQueryHookResult = ReturnType<typeof useDealsAndDocumentsWithAttachmentQuery>;
export type DealsAndDocumentsWithAttachmentLazyQueryHookResult = ReturnType<typeof useDealsAndDocumentsWithAttachmentLazyQuery>;
export type DealsAndDocumentsWithAttachmentQueryResult = Apollo.QueryResult<Types.DealsAndDocumentsWithAttachmentQuery, Types.DealsAndDocumentsWithAttachmentQueryVariables>;
export const CciUsersDocument = gql`
  query cciUsers {
    cci_users {
      id
      first_name
      last_name
      user_name
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useCciUsersQuery__
 *
 * To run a query within a React component, call `useCciUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCciUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciUsersQuery, Types.CciUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciUsersQuery, Types.CciUsersQueryVariables>(CciUsersDocument, options);
}
export function useCciUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciUsersQuery, Types.CciUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciUsersQuery, Types.CciUsersQueryVariables>(CciUsersDocument, options);
}
export type CciUsersQueryHookResult = ReturnType<typeof useCciUsersQuery>;
export type CciUsersLazyQueryHookResult = ReturnType<typeof useCciUsersLazyQuery>;
export type CciUsersQueryResult = Apollo.QueryResult<Types.CciUsersQuery, Types.CciUsersQueryVariables>;
export const CciRolesDocument = gql`
  query cciRoles {
    cci_roles {
      id
      name
    }
  }
`;

/**
 * __useCciRolesQuery__
 *
 * To run a query within a React component, call `useCciRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCciRolesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciRolesQuery, Types.CciRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciRolesQuery, Types.CciRolesQueryVariables>(CciRolesDocument, options);
}
export function useCciRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciRolesQuery, Types.CciRolesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciRolesQuery, Types.CciRolesQueryVariables>(CciRolesDocument, options);
}
export type CciRolesQueryHookResult = ReturnType<typeof useCciRolesQuery>;
export type CciRolesLazyQueryHookResult = ReturnType<typeof useCciRolesLazyQuery>;
export type CciRolesQueryResult = Apollo.QueryResult<Types.CciRolesQuery, Types.CciRolesQueryVariables>;
export const CciRolesWithFeaturesDocument = gql`
  query cciRolesWithFeatures {
    cci_roles {
      id
      name
      features {
        edges {
          node {
            name
            permission
          }
        }
      }
    }
  }
`;

/**
 * __useCciRolesWithFeaturesQuery__
 *
 * To run a query within a React component, call `useCciRolesWithFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciRolesWithFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciRolesWithFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCciRolesWithFeaturesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables>(CciRolesWithFeaturesDocument, options);
}
export function useCciRolesWithFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables>(CciRolesWithFeaturesDocument, options);
}
export type CciRolesWithFeaturesQueryHookResult = ReturnType<typeof useCciRolesWithFeaturesQuery>;
export type CciRolesWithFeaturesLazyQueryHookResult = ReturnType<typeof useCciRolesWithFeaturesLazyQuery>;
export type CciRolesWithFeaturesQueryResult = Apollo.QueryResult<Types.CciRolesWithFeaturesQuery, Types.CciRolesWithFeaturesQueryVariables>;
export const CciDealDataPointFieldsDocument = gql`
  query cciDealDataPointFields {
    cci_deal_data_point_fields {
      ...DataPointFieldFragment
      default_field_on_dashboard
      display_if_empty
      external_mapping
      inheritance_logic
      option_to_neutral_tag_mapping
    }
  }
  ${DataPointFieldFragmentFragmentDoc}
`;

/**
 * __useCciDealDataPointFieldsQuery__
 *
 * To run a query within a React component, call `useCciDealDataPointFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciDealDataPointFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciDealDataPointFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCciDealDataPointFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciDealDataPointFieldsQuery, Types.CciDealDataPointFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciDealDataPointFieldsQuery, Types.CciDealDataPointFieldsQueryVariables>(CciDealDataPointFieldsDocument, options);
}
export function useCciDealDataPointFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciDealDataPointFieldsQuery, Types.CciDealDataPointFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciDealDataPointFieldsQuery, Types.CciDealDataPointFieldsQueryVariables>(CciDealDataPointFieldsDocument, options);
}
export type CciDealDataPointFieldsQueryHookResult = ReturnType<typeof useCciDealDataPointFieldsQuery>;
export type CciDealDataPointFieldsLazyQueryHookResult = ReturnType<typeof useCciDealDataPointFieldsLazyQuery>;
export type CciDealDataPointFieldsQueryResult = Apollo.QueryResult<Types.CciDealDataPointFieldsQuery, Types.CciDealDataPointFieldsQueryVariables>;
export const CciGroupsDocument = gql`
  query cciGroups($name: String) {
    cci_groups(name: $name)
  }
`;

/**
 * __useCciGroupsQuery__
 *
 * To run a query within a React component, call `useCciGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciGroupsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCciGroupsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciGroupsQuery, Types.CciGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciGroupsQuery, Types.CciGroupsQueryVariables>(CciGroupsDocument, options);
}
export function useCciGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciGroupsQuery, Types.CciGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciGroupsQuery, Types.CciGroupsQueryVariables>(CciGroupsDocument, options);
}
export type CciGroupsQueryHookResult = ReturnType<typeof useCciGroupsQuery>;
export type CciGroupsLazyQueryHookResult = ReturnType<typeof useCciGroupsLazyQuery>;
export type CciGroupsQueryResult = Apollo.QueryResult<Types.CciGroupsQuery, Types.CciGroupsQueryVariables>;
export const CciDocumentTypesDocument = gql`
  query cciDocumentTypes {
    cci_document_types {
      id
      name
    }
  }
`;

/**
 * __useCciDocumentTypesQuery__
 *
 * To run a query within a React component, call `useCciDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCciDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciDocumentTypesQuery, Types.CciDocumentTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciDocumentTypesQuery, Types.CciDocumentTypesQueryVariables>(CciDocumentTypesDocument, options);
}
export function useCciDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciDocumentTypesQuery, Types.CciDocumentTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciDocumentTypesQuery, Types.CciDocumentTypesQueryVariables>(CciDocumentTypesDocument, options);
}
export type CciDocumentTypesQueryHookResult = ReturnType<typeof useCciDocumentTypesQuery>;
export type CciDocumentTypesLazyQueryHookResult = ReturnType<typeof useCciDocumentTypesLazyQuery>;
export type CciDocumentTypesQueryResult = Apollo.QueryResult<Types.CciDocumentTypesQuery, Types.CciDocumentTypesQueryVariables>;
export const IntegrationsDocument = gql`
  query integrations {
    integrations {
      edges {
        node {
          id
          integration_type
          token_type
          api_url
          user_name
          domain
          organization_id
        }
      }
    }
  }
`;

/**
 * __useIntegrationsQuery__
 *
 * To run a query within a React component, call `useIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.IntegrationsQuery, Types.IntegrationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.IntegrationsQuery, Types.IntegrationsQueryVariables>(IntegrationsDocument, options);
}
export function useIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IntegrationsQuery, Types.IntegrationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.IntegrationsQuery, Types.IntegrationsQueryVariables>(IntegrationsDocument, options);
}
export type IntegrationsQueryHookResult = ReturnType<typeof useIntegrationsQuery>;
export type IntegrationsLazyQueryHookResult = ReturnType<typeof useIntegrationsLazyQuery>;
export type IntegrationsQueryResult = Apollo.QueryResult<Types.IntegrationsQuery, Types.IntegrationsQueryVariables>;
export const CciNeutralTagsDocument = gql`
  query cciNeutralTags($searchTerm: String, $offset: Int, $size: Int = 20) {
    cci_neutral_tags(name: $searchTerm, offset: $offset, size: $size) {
      internal_name
      external_name
      in_neutral_tagset
    }
  }
`;

/**
 * __useCciNeutralTagsQuery__
 *
 * To run a query within a React component, call `useCciNeutralTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCciNeutralTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCciNeutralTagsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCciNeutralTagsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CciNeutralTagsQuery, Types.CciNeutralTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CciNeutralTagsQuery, Types.CciNeutralTagsQueryVariables>(CciNeutralTagsDocument, options);
}
export function useCciNeutralTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CciNeutralTagsQuery, Types.CciNeutralTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CciNeutralTagsQuery, Types.CciNeutralTagsQueryVariables>(CciNeutralTagsDocument, options);
}
export type CciNeutralTagsQueryHookResult = ReturnType<typeof useCciNeutralTagsQuery>;
export type CciNeutralTagsLazyQueryHookResult = ReturnType<typeof useCciNeutralTagsLazyQuery>;
export type CciNeutralTagsQueryResult = Apollo.QueryResult<Types.CciNeutralTagsQuery, Types.CciNeutralTagsQueryVariables>;
export const ChecklistGroupsDocument = gql`
  query checklistGroups($resourceId: ID) {
    checklist_groups(resource_id: $resourceId)
  }
`;

/**
 * __useChecklistGroupsQuery__
 *
 * To run a query within a React component, call `useChecklistGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistGroupsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useChecklistGroupsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ChecklistGroupsQuery, Types.ChecklistGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ChecklistGroupsQuery, Types.ChecklistGroupsQueryVariables>(ChecklistGroupsDocument, options);
}
export function useChecklistGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChecklistGroupsQuery, Types.ChecklistGroupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ChecklistGroupsQuery, Types.ChecklistGroupsQueryVariables>(ChecklistGroupsDocument, options);
}
export type ChecklistGroupsQueryHookResult = ReturnType<typeof useChecklistGroupsQuery>;
export type ChecklistGroupsLazyQueryHookResult = ReturnType<typeof useChecklistGroupsLazyQuery>;
export type ChecklistGroupsQueryResult = Apollo.QueryResult<Types.ChecklistGroupsQuery, Types.ChecklistGroupsQueryVariables>;
export const CommentsDataDocument = gql`
  query commentsData($resourceId: ID!) {
    comments_data(resource_id: $resourceId) {
      ...CommentFragment
      data_point {
        data_point_field {
          id
          name
        }
        id
      }
    }
  }
  ${CommentFragmentFragmentDoc}
`;

/**
 * __useCommentsDataQuery__
 *
 * To run a query within a React component, call `useCommentsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsDataQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useCommentsDataQuery(baseOptions: Apollo.QueryHookOptions<Types.CommentsDataQuery, Types.CommentsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CommentsDataQuery, Types.CommentsDataQueryVariables>(CommentsDataDocument, options);
}
export function useCommentsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CommentsDataQuery, Types.CommentsDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CommentsDataQuery, Types.CommentsDataQueryVariables>(CommentsDataDocument, options);
}
export type CommentsDataQueryHookResult = ReturnType<typeof useCommentsDataQuery>;
export type CommentsDataLazyQueryHookResult = ReturnType<typeof useCommentsDataLazyQuery>;
export type CommentsDataQueryResult = Apollo.QueryResult<Types.CommentsDataQuery, Types.CommentsDataQueryVariables>;
export const CounterPartiesByNameDocument = gql`
  query counterPartiesByName($searchTerm: String = "", $excludeCounterPartyIds: [ID], $offset: Int, $size: Int = 20) {
    counter_parties_by_name(counter_party_name: $searchTerm, exclude_counter_party_ids: $excludeCounterPartyIds, offset: $offset, size: $size) {
      id
      name
    }
  }
`;

/**
 * __useCounterPartiesByNameQuery__
 *
 * To run a query within a React component, call `useCounterPartiesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterPartiesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterPartiesByNameQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      excludeCounterPartyIds: // value for 'excludeCounterPartyIds'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCounterPartiesByNameQuery(baseOptions?: Apollo.QueryHookOptions<Types.CounterPartiesByNameQuery, Types.CounterPartiesByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CounterPartiesByNameQuery, Types.CounterPartiesByNameQueryVariables>(CounterPartiesByNameDocument, options);
}
export function useCounterPartiesByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CounterPartiesByNameQuery, Types.CounterPartiesByNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CounterPartiesByNameQuery, Types.CounterPartiesByNameQueryVariables>(CounterPartiesByNameDocument, options);
}
export type CounterPartiesByNameQueryHookResult = ReturnType<typeof useCounterPartiesByNameQuery>;
export type CounterPartiesByNameLazyQueryHookResult = ReturnType<typeof useCounterPartiesByNameLazyQuery>;
export type CounterPartiesByNameQueryResult = Apollo.QueryResult<Types.CounterPartiesByNameQuery, Types.CounterPartiesByNameQueryVariables>;
export const CounterPartyDealsDocument = gql`
  query counterPartyDeals($counterpartyId: ID, $searchTerm: String, $filters: JSONString, $offset: Int, $size: Int = 20) {
    deals_by_counter_party(counter_party_id: $counterpartyId, deal_name: $searchTerm, filters: $filters, offset: $offset, size: $size) {
      id
      name
      alias
    }
  }
`;

/**
 * __useCounterPartyDealsQuery__
 *
 * To run a query within a React component, call `useCounterPartyDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterPartyDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterPartyDealsQuery({
 *   variables: {
 *      counterpartyId: // value for 'counterpartyId'
 *      searchTerm: // value for 'searchTerm'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCounterPartyDealsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CounterPartyDealsQuery, Types.CounterPartyDealsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CounterPartyDealsQuery, Types.CounterPartyDealsQueryVariables>(CounterPartyDealsDocument, options);
}
export function useCounterPartyDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CounterPartyDealsQuery, Types.CounterPartyDealsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CounterPartyDealsQuery, Types.CounterPartyDealsQueryVariables>(CounterPartyDealsDocument, options);
}
export type CounterPartyDealsQueryHookResult = ReturnType<typeof useCounterPartyDealsQuery>;
export type CounterPartyDealsLazyQueryHookResult = ReturnType<typeof useCounterPartyDealsLazyQuery>;
export type CounterPartyDealsQueryResult = Apollo.QueryResult<Types.CounterPartyDealsQuery, Types.CounterPartyDealsQueryVariables>;
export const CounterPartyDocumentsDocument = gql`
  query counterPartyDocuments($counterpartyId: ID, $searchTerm: String, $filters: JSONString, $offset: Int, $size: Int = 20) {
    documents_by_counter_party(counter_party_id: $counterpartyId, document_name: $searchTerm, filters: $filters, offset: $offset, size: $size) {
      id
      name
      alias
      counter_party {
        id
        name
      }
    }
  }
`;

/**
 * __useCounterPartyDocumentsQuery__
 *
 * To run a query within a React component, call `useCounterPartyDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCounterPartyDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCounterPartyDocumentsQuery({
 *   variables: {
 *      counterpartyId: // value for 'counterpartyId'
 *      searchTerm: // value for 'searchTerm'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCounterPartyDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.CounterPartyDocumentsQuery, Types.CounterPartyDocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CounterPartyDocumentsQuery, Types.CounterPartyDocumentsQueryVariables>(CounterPartyDocumentsDocument, options);
}
export function useCounterPartyDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CounterPartyDocumentsQuery, Types.CounterPartyDocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CounterPartyDocumentsQuery, Types.CounterPartyDocumentsQueryVariables>(CounterPartyDocumentsDocument, options);
}
export type CounterPartyDocumentsQueryHookResult = ReturnType<typeof useCounterPartyDocumentsQuery>;
export type CounterPartyDocumentsLazyQueryHookResult = ReturnType<typeof useCounterPartyDocumentsLazyQuery>;
export type CounterPartyDocumentsQueryResult = Apollo.QueryResult<Types.CounterPartyDocumentsQuery, Types.CounterPartyDocumentsQueryVariables>;
export const DashboardQueriesDocument = gql`
  query dashboardQueries($dashboard: String!) {
    dashboard_queries(dashboard: $dashboard) {
      edges {
        node {
          ...DashboardQueryFragment
        }
      }
    }
  }
  ${DashboardQueryFragmentFragmentDoc}
`;

/**
 * __useDashboardQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQueriesQuery({
 *   variables: {
 *      dashboard: // value for 'dashboard'
 *   },
 * });
 */
export function useDashboardQueriesQuery(baseOptions: Apollo.QueryHookOptions<Types.DashboardQueriesQuery, Types.DashboardQueriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DashboardQueriesQuery, Types.DashboardQueriesQueryVariables>(DashboardQueriesDocument, options);
}
export function useDashboardQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DashboardQueriesQuery, Types.DashboardQueriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DashboardQueriesQuery, Types.DashboardQueriesQueryVariables>(DashboardQueriesDocument, options);
}
export type DashboardQueriesQueryHookResult = ReturnType<typeof useDashboardQueriesQuery>;
export type DashboardQueriesLazyQueryHookResult = ReturnType<typeof useDashboardQueriesLazyQuery>;
export type DashboardQueriesQueryResult = Apollo.QueryResult<Types.DashboardQueriesQuery, Types.DashboardQueriesQueryVariables>;
export const DashboardExportDocument = gql`
  query dashboardExport($chosenDataPointFieldIds: [String], $dashboardType: String!, $filters: JSONString, $flowType: String!, $offset: Int, $size: Int, $sortConfig: [JSONString]) {
    fetch_dashboard_export(chosen_data_point_field_ids: $chosenDataPointFieldIds, dashboard_type: $dashboardType, filters: $filters, flow_type: $flowType, offset: $offset, size: $size, sort_config: $sortConfig) {
      export_status
      spreadsheet_export {
        file_contents
        file_name
      }
    }
  }
`;

/**
 * __useDashboardExportQuery__
 *
 * To run a query within a React component, call `useDashboardExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardExportQuery({
 *   variables: {
 *      chosenDataPointFieldIds: // value for 'chosenDataPointFieldIds'
 *      dashboardType: // value for 'dashboardType'
 *      filters: // value for 'filters'
 *      flowType: // value for 'flowType'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *      sortConfig: // value for 'sortConfig'
 *   },
 * });
 */
export function useDashboardExportQuery(baseOptions: Apollo.QueryHookOptions<Types.DashboardExportQuery, Types.DashboardExportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DashboardExportQuery, Types.DashboardExportQueryVariables>(DashboardExportDocument, options);
}
export function useDashboardExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DashboardExportQuery, Types.DashboardExportQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DashboardExportQuery, Types.DashboardExportQueryVariables>(DashboardExportDocument, options);
}
export type DashboardExportQueryHookResult = ReturnType<typeof useDashboardExportQuery>;
export type DashboardExportLazyQueryHookResult = ReturnType<typeof useDashboardExportLazyQuery>;
export type DashboardExportQueryResult = Apollo.QueryResult<Types.DashboardExportQuery, Types.DashboardExportQueryVariables>;
export const DataCompletionDocument = gql`
  query dataCompletion($comparisonFieldId: ID!, $comparisonFieldValues: [String]!) {
    fetch_integration_validation(data_point_field_id: $comparisonFieldId, list_of_external_ids: $comparisonFieldValues) {
      spreadsheet_export {
        file_contents
        file_name
      }
    }
  }
`;

/**
 * __useDataCompletionQuery__
 *
 * To run a query within a React component, call `useDataCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataCompletionQuery({
 *   variables: {
 *      comparisonFieldId: // value for 'comparisonFieldId'
 *      comparisonFieldValues: // value for 'comparisonFieldValues'
 *   },
 * });
 */
export function useDataCompletionQuery(baseOptions: Apollo.QueryHookOptions<Types.DataCompletionQuery, Types.DataCompletionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataCompletionQuery, Types.DataCompletionQueryVariables>(DataCompletionDocument, options);
}
export function useDataCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataCompletionQuery, Types.DataCompletionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataCompletionQuery, Types.DataCompletionQueryVariables>(DataCompletionDocument, options);
}
export type DataCompletionQueryHookResult = ReturnType<typeof useDataCompletionQuery>;
export type DataCompletionLazyQueryHookResult = ReturnType<typeof useDataCompletionLazyQuery>;
export type DataCompletionQueryResult = Apollo.QueryResult<Types.DataCompletionQuery, Types.DataCompletionQueryVariables>;
export const DataPointsDocument = gql`
  query dataPoints($id: ID, $dealId: ID, $documentId: ID) {
    data_points(id: $id, deal: $dealId, document: $documentId) {
      edges {
        node {
          ...DataPointDetailFragment
        }
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;

/**
 * __useDataPointsQuery__
 *
 * To run a query within a React component, call `useDataPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dealId: // value for 'dealId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDataPointsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataPointsQuery, Types.DataPointsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataPointsQuery, Types.DataPointsQueryVariables>(DataPointsDocument, options);
}
export function useDataPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPointsQuery, Types.DataPointsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataPointsQuery, Types.DataPointsQueryVariables>(DataPointsDocument, options);
}
export type DataPointsQueryHookResult = ReturnType<typeof useDataPointsQuery>;
export type DataPointsLazyQueryHookResult = ReturnType<typeof useDataPointsLazyQuery>;
export type DataPointsQueryResult = Apollo.QueryResult<Types.DataPointsQuery, Types.DataPointsQueryVariables>;
export const DataPointFieldsDocument = gql`
  query dataPointFields($id: ID!) {
    data_point_fields(id: $id) {
      edges {
        node {
          ...DataPointFieldFragment
        }
      }
    }
  }
  ${DataPointFieldFragmentFragmentDoc}
`;

/**
 * __useDataPointFieldsQuery__
 *
 * To run a query within a React component, call `useDataPointFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataPointFieldsQuery(baseOptions: Apollo.QueryHookOptions<Types.DataPointFieldsQuery, Types.DataPointFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataPointFieldsQuery, Types.DataPointFieldsQueryVariables>(DataPointFieldsDocument, options);
}
export function useDataPointFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPointFieldsQuery, Types.DataPointFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataPointFieldsQuery, Types.DataPointFieldsQueryVariables>(DataPointFieldsDocument, options);
}
export type DataPointFieldsQueryHookResult = ReturnType<typeof useDataPointFieldsQuery>;
export type DataPointFieldsLazyQueryHookResult = ReturnType<typeof useDataPointFieldsLazyQuery>;
export type DataPointFieldsQueryResult = Apollo.QueryResult<Types.DataPointFieldsQuery, Types.DataPointFieldsQueryVariables>;
export const DataPointDetailDocument = gql`
  query dataPointDetail($id: ID!) {
    data_points(id: $id) {
      edges {
        node {
          ...DataPointDetailFragment
        }
      }
    }
  }
  ${DataPointDetailFragmentFragmentDoc}
`;

/**
 * __useDataPointDetailQuery__
 *
 * To run a query within a React component, call `useDataPointDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDataPointDetailQuery(baseOptions: Apollo.QueryHookOptions<Types.DataPointDetailQuery, Types.DataPointDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataPointDetailQuery, Types.DataPointDetailQueryVariables>(DataPointDetailDocument, options);
}
export function useDataPointDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPointDetailQuery, Types.DataPointDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataPointDetailQuery, Types.DataPointDetailQueryVariables>(DataPointDetailDocument, options);
}
export type DataPointDetailQueryHookResult = ReturnType<typeof useDataPointDetailQuery>;
export type DataPointDetailLazyQueryHookResult = ReturnType<typeof useDataPointDetailLazyQuery>;
export type DataPointDetailQueryResult = Apollo.QueryResult<Types.DataPointDetailQuery, Types.DataPointDetailQueryVariables>;
export const ManualUploadFieldsDocument = gql`
  query manualUploadFields($configType: String, $documentTypeName: String) {
    manual_upload_fields(config_type: $configType, document_type_name: $documentTypeName) {
      manual_upload_fields {
        data_point_field {
          id
          name
          field_type
          options
        }
        deal_or_document
        is_optional
      }
    }
  }
`;

/**
 * __useManualUploadFieldsQuery__
 *
 * To run a query within a React component, call `useManualUploadFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualUploadFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualUploadFieldsQuery({
 *   variables: {
 *      configType: // value for 'configType'
 *      documentTypeName: // value for 'documentTypeName'
 *   },
 * });
 */
export function useManualUploadFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.ManualUploadFieldsQuery, Types.ManualUploadFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ManualUploadFieldsQuery, Types.ManualUploadFieldsQueryVariables>(ManualUploadFieldsDocument, options);
}
export function useManualUploadFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ManualUploadFieldsQuery, Types.ManualUploadFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ManualUploadFieldsQuery, Types.ManualUploadFieldsQueryVariables>(ManualUploadFieldsDocument, options);
}
export type ManualUploadFieldsQueryHookResult = ReturnType<typeof useManualUploadFieldsQuery>;
export type ManualUploadFieldsLazyQueryHookResult = ReturnType<typeof useManualUploadFieldsLazyQuery>;
export type ManualUploadFieldsQueryResult = Apollo.QueryResult<Types.ManualUploadFieldsQuery, Types.ManualUploadFieldsQueryVariables>;
export const DataPointsForGroupDocument = gql`
  query dataPointsForGroup($group: String, $resourceId: ID) {
    data_points_for_group(group: $group, resource_id: $resourceId) {
      ...DataPointDetailChecklistFragment
    }
  }
  ${DataPointDetailChecklistFragmentFragmentDoc}
`;

/**
 * __useDataPointsForGroupQuery__
 *
 * To run a query within a React component, call `useDataPointsForGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataPointsForGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataPointsForGroupQuery({
 *   variables: {
 *      group: // value for 'group'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useDataPointsForGroupQuery(baseOptions?: Apollo.QueryHookOptions<Types.DataPointsForGroupQuery, Types.DataPointsForGroupQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DataPointsForGroupQuery, Types.DataPointsForGroupQueryVariables>(DataPointsForGroupDocument, options);
}
export function useDataPointsForGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DataPointsForGroupQuery, Types.DataPointsForGroupQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DataPointsForGroupQuery, Types.DataPointsForGroupQueryVariables>(DataPointsForGroupDocument, options);
}
export type DataPointsForGroupQueryHookResult = ReturnType<typeof useDataPointsForGroupQuery>;
export type DataPointsForGroupLazyQueryHookResult = ReturnType<typeof useDataPointsForGroupLazyQuery>;
export type DataPointsForGroupQueryResult = Apollo.QueryResult<Types.DataPointsForGroupQuery, Types.DataPointsForGroupQueryVariables>;
export const DealsDocument = gql`
  query deals($id: ID) {
    deals(id: $id) {
      edges {
        node {
          ...DealFragment
        }
      }
    }
  }
  ${DealFragmentFragmentDoc}
`;

/**
 * __useDealsQuery__
 *
 * To run a query within a React component, call `useDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DealsQuery, Types.DealsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsQuery, Types.DealsQueryVariables>(DealsDocument, options);
}
export function useDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsQuery, Types.DealsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsQuery, Types.DealsQueryVariables>(DealsDocument, options);
}
export type DealsQueryHookResult = ReturnType<typeof useDealsQuery>;
export type DealsLazyQueryHookResult = ReturnType<typeof useDealsLazyQuery>;
export type DealsQueryResult = Apollo.QueryResult<Types.DealsQuery, Types.DealsQueryVariables>;
export const DealsDashboardFieldsDocument = gql`
  query dealsDashboardFields {
    fetch_advanced_deals_dashboard_fields {
      document {
        internal_name
        display_name
        filter_type
        operators
      }
      attachments_count {
        display_name
        filter_type
        internal_name
        operators
      }
      document_id {
        display_name
        filter_type
        internal_name
        operators
      }
      integration_type {
        display_name
        filter_type
        internal_name
        operators
        value
      }
      is_legacy {
        display_name
        filter_type
        internal_name
        operators
        value
      }
      counter_party {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
      }
      status {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
      }
      created_at {
        internal_name
        display_name
        filter_type
        operators
      }
      assignee {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          user_name
          first_name
          last_name
        }
      }
      all_assignees {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          user_name
          first_name
          last_name
        }
      }
      documents_count {
        internal_name
        display_name
        filter_type
        operators
      }
      unresolved_comments_count {
        internal_name
        display_name
        filter_type
        operators
      }
      data_points {
        data_point_field_id
        default_table_column
        name
        group
        data_type
        filter_type
        filter_allowed
        operators
        internal_name
        options
      }
      multi_select_data_points {
        data_point_field_id
        default_table_column
        name
        group
        data_type
        filter_type
        operators
        internal_name
        options
      }
    }
  }
`;

/**
 * __useDealsDashboardFieldsQuery__
 *
 * To run a query within a React component, call `useDealsDashboardFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsDashboardFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsDashboardFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealsDashboardFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DealsDashboardFieldsQuery, Types.DealsDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsDashboardFieldsQuery, Types.DealsDashboardFieldsQueryVariables>(DealsDashboardFieldsDocument, options);
}
export function useDealsDashboardFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsDashboardFieldsQuery, Types.DealsDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsDashboardFieldsQuery, Types.DealsDashboardFieldsQueryVariables>(DealsDashboardFieldsDocument, options);
}
export type DealsDashboardFieldsQueryHookResult = ReturnType<typeof useDealsDashboardFieldsQuery>;
export type DealsDashboardFieldsLazyQueryHookResult = ReturnType<typeof useDealsDashboardFieldsLazyQuery>;
export type DealsDashboardFieldsQueryResult = Apollo.QueryResult<Types.DealsDashboardFieldsQuery, Types.DealsDashboardFieldsQueryVariables>;
export const DealsDashboardDocument = gql`
  query dealsDashboard($filters: JSONString!, $chosenDataPointFieldIds: [String], $sortConfig: [JSONString], $size: Int, $offset: Int) {
    fetch_advanced_deals_dashboard(filters: $filters, chosen_data_point_field_ids: $chosenDataPointFieldIds, sort_config: $sortConfig, offset: $offset, size: $size) {
      dashboard_data {
        attachments_count {
          internal_name
          value
        }
        id
        document {
          id
          internal_name
          value
          value_alias
          value_original
        }
        document_id {
          internal_name
          value
        }
        integration_type {
          internal_name
          value
        }
        is_legacy {
          internal_name
          value
        }
        counter_party {
          internal_name
          value
        }
        assignee {
          internal_name
          value {
            id
            user_name
            first_name
            last_name
          }
        }
        all_assignees {
          internal_name
          value {
            id
            user_name
            first_name
            last_name
          }
        }
        created_at {
          internal_name
          value
        }
        status {
          id
          internal_name
          value
          final
        }
        processed
        comments_count
        non_standard_terms_count
        revenue_impact_count
        data_points {
          data_point_id
          data_point_field_id
          is_collision_resolved
          name
          value
          group
          data_type
          hyperlink_url
        }
        documents_count {
          internal_name
          value
        }
        unresolved_comments_count {
          internal_name
          value
        }
      }
    }
  }
`;

/**
 * __useDealsDashboardQuery__
 *
 * To run a query within a React component, call `useDealsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsDashboardQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      chosenDataPointFieldIds: // value for 'chosenDataPointFieldIds'
 *      sortConfig: // value for 'sortConfig'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDealsDashboardQuery(baseOptions: Apollo.QueryHookOptions<Types.DealsDashboardQuery, Types.DealsDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsDashboardQuery, Types.DealsDashboardQueryVariables>(DealsDashboardDocument, options);
}
export function useDealsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsDashboardQuery, Types.DealsDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsDashboardQuery, Types.DealsDashboardQueryVariables>(DealsDashboardDocument, options);
}
export type DealsDashboardQueryHookResult = ReturnType<typeof useDealsDashboardQuery>;
export type DealsDashboardLazyQueryHookResult = ReturnType<typeof useDealsDashboardLazyQuery>;
export type DealsDashboardQueryResult = Apollo.QueryResult<Types.DealsDashboardQuery, Types.DealsDashboardQueryVariables>;
export const SimpleDealListDocument = gql`
  query simpleDealList {
    deals {
      edges {
        node {
          id
          name
          alias
        }
      }
    }
  }
`;

/**
 * __useSimpleDealListQuery__
 *
 * To run a query within a React component, call `useSimpleDealListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleDealListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleDealListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimpleDealListQuery(baseOptions?: Apollo.QueryHookOptions<Types.SimpleDealListQuery, Types.SimpleDealListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SimpleDealListQuery, Types.SimpleDealListQueryVariables>(SimpleDealListDocument, options);
}
export function useSimpleDealListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SimpleDealListQuery, Types.SimpleDealListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SimpleDealListQuery, Types.SimpleDealListQueryVariables>(SimpleDealListDocument, options);
}
export type SimpleDealListQueryHookResult = ReturnType<typeof useSimpleDealListQuery>;
export type SimpleDealListLazyQueryHookResult = ReturnType<typeof useSimpleDealListLazyQuery>;
export type SimpleDealListQueryResult = Apollo.QueryResult<Types.SimpleDealListQuery, Types.SimpleDealListQueryVariables>;
export const DealCountDocument = gql`
  query dealCount($filters: JSONString!, $chosenDataPointFieldIds: [String], $sortConfig: [JSONString]) {
    fetch_advanced_deals_dashboard_count(filters: $filters, chosen_data_point_field_ids: $chosenDataPointFieldIds, sort_config: $sortConfig) {
      total_number_of_documents
      total_number_of_filtered_documents
    }
  }
`;

/**
 * __useDealCountQuery__
 *
 * To run a query within a React component, call `useDealCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      chosenDataPointFieldIds: // value for 'chosenDataPointFieldIds'
 *      sortConfig: // value for 'sortConfig'
 *   },
 * });
 */
export function useDealCountQuery(baseOptions: Apollo.QueryHookOptions<Types.DealCountQuery, Types.DealCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealCountQuery, Types.DealCountQueryVariables>(DealCountDocument, options);
}
export function useDealCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealCountQuery, Types.DealCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealCountQuery, Types.DealCountQueryVariables>(DealCountDocument, options);
}
export type DealCountQueryHookResult = ReturnType<typeof useDealCountQuery>;
export type DealCountLazyQueryHookResult = ReturnType<typeof useDealCountLazyQuery>;
export type DealCountQueryResult = Apollo.QueryResult<Types.DealCountQuery, Types.DealCountQueryVariables>;
export const DealDocumentsDocument = gql`
  query dealDocuments($dealId: ID!, $searchTerm: String, $offset: Int, $size: Int = 20) {
    documents_by_deal(deal_id: $dealId, document_name: $searchTerm, offset: $offset, size: $size) {
      id
      name
      alias
      is_counter_party_level
      counter_party {
        id
        name
      }
    }
  }
`;

/**
 * __useDealDocumentsQuery__
 *
 * To run a query within a React component, call `useDealDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealDocumentsQuery({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      searchTerm: // value for 'searchTerm'
 *      offset: // value for 'offset'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useDealDocumentsQuery(baseOptions: Apollo.QueryHookOptions<Types.DealDocumentsQuery, Types.DealDocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealDocumentsQuery, Types.DealDocumentsQueryVariables>(DealDocumentsDocument, options);
}
export function useDealDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealDocumentsQuery, Types.DealDocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealDocumentsQuery, Types.DealDocumentsQueryVariables>(DealDocumentsDocument, options);
}
export type DealDocumentsQueryHookResult = ReturnType<typeof useDealDocumentsQuery>;
export type DealDocumentsLazyQueryHookResult = ReturnType<typeof useDealDocumentsLazyQuery>;
export type DealDocumentsQueryResult = Apollo.QueryResult<Types.DealDocumentsQuery, Types.DealDocumentsQueryVariables>;
export const DealsAffectedByDocumentDeleteDocument = gql`
  query dealsAffectedByDocumentDelete($document_id: ID!) {
    deals_affected_by_document_delete(document_id: $document_id) {
      id
      name
    }
  }
`;

/**
 * __useDealsAffectedByDocumentDeleteQuery__
 *
 * To run a query within a React component, call `useDealsAffectedByDocumentDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsAffectedByDocumentDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsAffectedByDocumentDeleteQuery({
 *   variables: {
 *      document_id: // value for 'document_id'
 *   },
 * });
 */
export function useDealsAffectedByDocumentDeleteQuery(baseOptions: Apollo.QueryHookOptions<Types.DealsAffectedByDocumentDeleteQuery, Types.DealsAffectedByDocumentDeleteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsAffectedByDocumentDeleteQuery, Types.DealsAffectedByDocumentDeleteQueryVariables>(DealsAffectedByDocumentDeleteDocument, options);
}
export function useDealsAffectedByDocumentDeleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsAffectedByDocumentDeleteQuery, Types.DealsAffectedByDocumentDeleteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsAffectedByDocumentDeleteQuery, Types.DealsAffectedByDocumentDeleteQueryVariables>(DealsAffectedByDocumentDeleteDocument, options);
}
export type DealsAffectedByDocumentDeleteQueryHookResult = ReturnType<typeof useDealsAffectedByDocumentDeleteQuery>;
export type DealsAffectedByDocumentDeleteLazyQueryHookResult = ReturnType<typeof useDealsAffectedByDocumentDeleteLazyQuery>;
export type DealsAffectedByDocumentDeleteQueryResult = Apollo.QueryResult<Types.DealsAffectedByDocumentDeleteQuery, Types.DealsAffectedByDocumentDeleteQueryVariables>;
export const DealsAffectedByDocumentLevelChangeDocument = gql`
  query dealsAffectedByDocumentLevelChange($document_id: ID!) {
    deals_affected_by_document_level_change(document_id: $document_id) {
      active_deals
      completed_deals
    }
  }
`;

/**
 * __useDealsAffectedByDocumentLevelChangeQuery__
 *
 * To run a query within a React component, call `useDealsAffectedByDocumentLevelChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsAffectedByDocumentLevelChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsAffectedByDocumentLevelChangeQuery({
 *   variables: {
 *      document_id: // value for 'document_id'
 *   },
 * });
 */
export function useDealsAffectedByDocumentLevelChangeQuery(baseOptions: Apollo.QueryHookOptions<Types.DealsAffectedByDocumentLevelChangeQuery, Types.DealsAffectedByDocumentLevelChangeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealsAffectedByDocumentLevelChangeQuery, Types.DealsAffectedByDocumentLevelChangeQueryVariables>(DealsAffectedByDocumentLevelChangeDocument, options);
}
export function useDealsAffectedByDocumentLevelChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DealsAffectedByDocumentLevelChangeQuery, Types.DealsAffectedByDocumentLevelChangeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealsAffectedByDocumentLevelChangeQuery, Types.DealsAffectedByDocumentLevelChangeQueryVariables>(DealsAffectedByDocumentLevelChangeDocument, options);
}
export type DealsAffectedByDocumentLevelChangeQueryHookResult = ReturnType<typeof useDealsAffectedByDocumentLevelChangeQuery>;
export type DealsAffectedByDocumentLevelChangeLazyQueryHookResult = ReturnType<typeof useDealsAffectedByDocumentLevelChangeLazyQuery>;
export type DealsAffectedByDocumentLevelChangeQueryResult = Apollo.QueryResult<Types.DealsAffectedByDocumentLevelChangeQuery, Types.DealsAffectedByDocumentLevelChangeQueryVariables>;
export const DocumentsDocument = gql`
  query documents($documentId: ID, $counterPartyId: ID) {
    documents(id: $documentId, counter_party: $counterPartyId) {
      edges {
        node {
          ...DocumentFragment
        }
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      counterPartyId: // value for 'counterPartyId'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentsQuery, Types.DocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentsQuery, Types.DocumentsQueryVariables>(DocumentsDocument, options);
}
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentsQuery, Types.DocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentsQuery, Types.DocumentsQueryVariables>(DocumentsDocument, options);
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<Types.DocumentsQuery, Types.DocumentsQueryVariables>;
export const AdvancedDashboardDocument = gql`
  query advancedDashboard($filters: JSONString!, $chosenDataPointFieldIds: [String]!, $sortConfig: [JSONString], $size: Int!, $offset: Int!) {
    fetch_advanced_dashboard(filters: $filters, chosen_data_point_field_ids: $chosenDataPointFieldIds, sort_config: $sortConfig, offset: $offset, size: $size) {
      dashboard_data {
        id
        document {
          __typename
          id
          internal_name
          value
          value_alias
          value_original
        }
        document_id {
          internal_name
          value
        }
        is_counter_party_level
        is_deal_level
        counter_party {
          internal_name
          value
        }
        assignee {
          internal_name
          value {
            id
            user_name
            first_name
            last_name
          }
        }
        all_assignees {
          internal_name
          value {
            id
            user_name
            first_name
            last_name
          }
        }
        extraction_status
        internal_status {
          value
          internal_name
        }
        internal_assignee {
          internal_name
          value {
            id
            user_name
            first_name
            last_name
          }
        }
        internal_state {
          internal_name
          value
        }
        status {
          id
          internal_name
          value
        }
        created_at {
          internal_name
          value
        }
        created_by {
          internal_name
          value {
            id
            user_name
          }
        }
        processed
        comments_count
        non_standard_terms_count
        revenue_impact_count
        data_points {
          data_point_field_id
          name
          value
          group
          data_type
        }
        integration_type {
          internal_name
          value
        }
        unresolved_comments_count {
          internal_name
          value
        }
      }
      total_number_of_documents
      total_number_of_filtered_documents
    }
  }
`;

/**
 * __useAdvancedDashboardQuery__
 *
 * To run a query within a React component, call `useAdvancedDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedDashboardQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      chosenDataPointFieldIds: // value for 'chosenDataPointFieldIds'
 *      sortConfig: // value for 'sortConfig'
 *      size: // value for 'size'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdvancedDashboardQuery(baseOptions: Apollo.QueryHookOptions<Types.AdvancedDashboardQuery, Types.AdvancedDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AdvancedDashboardQuery, Types.AdvancedDashboardQueryVariables>(AdvancedDashboardDocument, options);
}
export function useAdvancedDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdvancedDashboardQuery, Types.AdvancedDashboardQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AdvancedDashboardQuery, Types.AdvancedDashboardQueryVariables>(AdvancedDashboardDocument, options);
}
export type AdvancedDashboardQueryHookResult = ReturnType<typeof useAdvancedDashboardQuery>;
export type AdvancedDashboardLazyQueryHookResult = ReturnType<typeof useAdvancedDashboardLazyQuery>;
export type AdvancedDashboardQueryResult = Apollo.QueryResult<Types.AdvancedDashboardQuery, Types.AdvancedDashboardQueryVariables>;
export const DocumentTransitionsDocument = gql`
  query documentTransitions($currentStateId: ID!) {
    transitions(current_state: $currentStateId) {
      edges {
        node {
          id
          name
          next_state {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentTransitionsQuery__
 *
 * To run a query within a React component, call `useDocumentTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTransitionsQuery({
 *   variables: {
 *      currentStateId: // value for 'currentStateId'
 *   },
 * });
 */
export function useDocumentTransitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentTransitionsQuery, Types.DocumentTransitionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentTransitionsQuery, Types.DocumentTransitionsQueryVariables>(DocumentTransitionsDocument, options);
}
export function useDocumentTransitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentTransitionsQuery, Types.DocumentTransitionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentTransitionsQuery, Types.DocumentTransitionsQueryVariables>(DocumentTransitionsDocument, options);
}
export type DocumentTransitionsQueryHookResult = ReturnType<typeof useDocumentTransitionsQuery>;
export type DocumentTransitionsLazyQueryHookResult = ReturnType<typeof useDocumentTransitionsLazyQuery>;
export type DocumentTransitionsQueryResult = Apollo.QueryResult<Types.DocumentTransitionsQuery, Types.DocumentTransitionsQueryVariables>;
export const AdvancedDashboardFieldsDocument = gql`
  query advancedDashboardFields {
    fetch_advanced_dashboard_fields {
      document {
        internal_name
        display_name
        filter_type
        operators
      }
      document_id {
        display_name
        filter_type
        internal_name
        operators
      }
      counter_party {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
      }
      internal_status {
        internal_name
        display_name
        value
        filter_type
        operators
      }
      status {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
      }
      assignee {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          user_name
          first_name
          last_name
        }
      }
      all_assignees {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          user_name
          first_name
          last_name
        }
      }
      created_at {
        internal_name
        display_name
        filter_type
        operators
      }
      data_points {
        data_point_field_id
        default_table_column
        name
        group
        data_type
        filter_type
        filter_allowed
        operators
        internal_name
        options
      }
      multi_select_data_points {
        data_point_field_id
        default_table_column
        name
        group
        data_type
        filter_type
        operators
        internal_name
        options
        value
      }
      integration_type {
        internal_name
        display_name
        value
        filter_type
        operators
      }
      unresolved_comments_count {
        internal_name
        display_name
        filter_type
        operators
      }
      internal_state {
        internal_name
        display_name
        filter_type
        operators
        value {
          id
          name
        }
        options {
          id
          name
        }
      }
      internal_assignee {
        internal_name
        display_name
        operators
        value {
          id
          first_name
          last_name
          user_name
        }
        options {
          id
          user_name
        }
      }
    }
  }
`;

/**
 * __useAdvancedDashboardFieldsQuery__
 *
 * To run a query within a React component, call `useAdvancedDashboardFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancedDashboardFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancedDashboardFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvancedDashboardFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdvancedDashboardFieldsQuery, Types.AdvancedDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AdvancedDashboardFieldsQuery, Types.AdvancedDashboardFieldsQueryVariables>(AdvancedDashboardFieldsDocument, options);
}
export function useAdvancedDashboardFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdvancedDashboardFieldsQuery, Types.AdvancedDashboardFieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AdvancedDashboardFieldsQuery, Types.AdvancedDashboardFieldsQueryVariables>(AdvancedDashboardFieldsDocument, options);
}
export type AdvancedDashboardFieldsQueryHookResult = ReturnType<typeof useAdvancedDashboardFieldsQuery>;
export type AdvancedDashboardFieldsLazyQueryHookResult = ReturnType<typeof useAdvancedDashboardFieldsLazyQuery>;
export type AdvancedDashboardFieldsQueryResult = Apollo.QueryResult<Types.AdvancedDashboardFieldsQuery, Types.AdvancedDashboardFieldsQueryVariables>;
export const DocumentNotesDocument = gql`
  query documentNotes($documentId: ID!) {
    documents(id: $documentId) {
      edges {
        node {
          id
          notes {
            edges {
              node {
                id
                created_at
                created_by {
                  id
                  first_name
                  last_name
                  user_name
                }
                text
              }
            }
          }
        }
      }
    }
    data_points(document: $documentId) {
      edges {
        node {
          ...DataPointFragment
        }
      }
    }
  }
  ${DataPointFragmentFragmentDoc}
`;

/**
 * __useDocumentNotesQuery__
 *
 * To run a query within a React component, call `useDocumentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNotesQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentNotesQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentNotesQuery, Types.DocumentNotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentNotesQuery, Types.DocumentNotesQueryVariables>(DocumentNotesDocument, options);
}
export function useDocumentNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentNotesQuery, Types.DocumentNotesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentNotesQuery, Types.DocumentNotesQueryVariables>(DocumentNotesDocument, options);
}
export type DocumentNotesQueryHookResult = ReturnType<typeof useDocumentNotesQuery>;
export type DocumentNotesLazyQueryHookResult = ReturnType<typeof useDocumentNotesLazyQuery>;
export type DocumentNotesQueryResult = Apollo.QueryResult<Types.DocumentNotesQuery, Types.DocumentNotesQueryVariables>;
export const DocumentTypesDocument = gql`
  query documentTypes {
    document_types {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDocumentTypesQuery__
 *
 * To run a query within a React component, call `useDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentTypesQuery, Types.DocumentTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentTypesQuery, Types.DocumentTypesQueryVariables>(DocumentTypesDocument, options);
}
export function useDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentTypesQuery, Types.DocumentTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentTypesQuery, Types.DocumentTypesQueryVariables>(DocumentTypesDocument, options);
}
export type DocumentTypesQueryHookResult = ReturnType<typeof useDocumentTypesQuery>;
export type DocumentTypesLazyQueryHookResult = ReturnType<typeof useDocumentTypesLazyQuery>;
export type DocumentTypesQueryResult = Apollo.QueryResult<Types.DocumentTypesQuery, Types.DocumentTypesQueryVariables>;
export const DocumentCountDocument = gql`
  query documentCount($filters: JSONString!, $chosenDataPointFieldIds: [String]!, $sortConfig: [JSONString]) {
    fetch_advanced_dashboard_count(filters: $filters, chosen_data_point_field_ids: $chosenDataPointFieldIds, sort_config: $sortConfig) {
      total_number_of_documents
      total_number_of_filtered_documents
    }
  }
`;

/**
 * __useDocumentCountQuery__
 *
 * To run a query within a React component, call `useDocumentCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      chosenDataPointFieldIds: // value for 'chosenDataPointFieldIds'
 *      sortConfig: // value for 'sortConfig'
 *   },
 * });
 */
export function useDocumentCountQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentCountQuery, Types.DocumentCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentCountQuery, Types.DocumentCountQueryVariables>(DocumentCountDocument, options);
}
export function useDocumentCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentCountQuery, Types.DocumentCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentCountQuery, Types.DocumentCountQueryVariables>(DocumentCountDocument, options);
}
export type DocumentCountQueryHookResult = ReturnType<typeof useDocumentCountQuery>;
export type DocumentCountLazyQueryHookResult = ReturnType<typeof useDocumentCountLazyQuery>;
export type DocumentCountQueryResult = Apollo.QueryResult<Types.DocumentCountQuery, Types.DocumentCountQueryVariables>;
export const DocumentsAffectedByDealDeleteDocument = gql`
  query documentsAffectedByDealDelete($deal_id: ID!) {
    documents_affected_by_deal_delete(deal_id: $deal_id) {
      documents_to_delete {
        id
        name
        document_type {
          name
        }
      }
      documents_to_preserve {
        id
        name
        document_type {
          name
        }
      }
    }
  }
`;

/**
 * __useDocumentsAffectedByDealDeleteQuery__
 *
 * To run a query within a React component, call `useDocumentsAffectedByDealDeleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsAffectedByDealDeleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsAffectedByDealDeleteQuery({
 *   variables: {
 *      deal_id: // value for 'deal_id'
 *   },
 * });
 */
export function useDocumentsAffectedByDealDeleteQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentsAffectedByDealDeleteQuery, Types.DocumentsAffectedByDealDeleteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentsAffectedByDealDeleteQuery, Types.DocumentsAffectedByDealDeleteQueryVariables>(DocumentsAffectedByDealDeleteDocument, options);
}
export function useDocumentsAffectedByDealDeleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentsAffectedByDealDeleteQuery, Types.DocumentsAffectedByDealDeleteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentsAffectedByDealDeleteQuery, Types.DocumentsAffectedByDealDeleteQueryVariables>(DocumentsAffectedByDealDeleteDocument, options);
}
export type DocumentsAffectedByDealDeleteQueryHookResult = ReturnType<typeof useDocumentsAffectedByDealDeleteQuery>;
export type DocumentsAffectedByDealDeleteLazyQueryHookResult = ReturnType<typeof useDocumentsAffectedByDealDeleteLazyQuery>;
export type DocumentsAffectedByDealDeleteQueryResult = Apollo.QueryResult<Types.DocumentsAffectedByDealDeleteQuery, Types.DocumentsAffectedByDealDeleteQueryVariables>;
export const DocumentNameDocument = gql`
  query documentName($document_id: ID!) {
    documents(id: $document_id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useDocumentNameQuery__
 *
 * To run a query within a React component, call `useDocumentNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNameQuery({
 *   variables: {
 *      document_id: // value for 'document_id'
 *   },
 * });
 */
export function useDocumentNameQuery(baseOptions: Apollo.QueryHookOptions<Types.DocumentNameQuery, Types.DocumentNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentNameQuery, Types.DocumentNameQueryVariables>(DocumentNameDocument, options);
}
export function useDocumentNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentNameQuery, Types.DocumentNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentNameQuery, Types.DocumentNameQueryVariables>(DocumentNameDocument, options);
}
export type DocumentNameQueryHookResult = ReturnType<typeof useDocumentNameQuery>;
export type DocumentNameLazyQueryHookResult = ReturnType<typeof useDocumentNameLazyQuery>;
export type DocumentNameQueryResult = Apollo.QueryResult<Types.DocumentNameQuery, Types.DocumentNameQueryVariables>;
export const DocumentVersionsDocument = gql`
  query documentVersions($documentId: ID) {
    documents(id: $documentId) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * __useDocumentVersionsQuery__
 *
 * To run a query within a React component, call `useDocumentVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentVersionsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentVersionsQuery(baseOptions?: Apollo.QueryHookOptions<Types.DocumentVersionsQuery, Types.DocumentVersionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DocumentVersionsQuery, Types.DocumentVersionsQueryVariables>(DocumentVersionsDocument, options);
}
export function useDocumentVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DocumentVersionsQuery, Types.DocumentVersionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DocumentVersionsQuery, Types.DocumentVersionsQueryVariables>(DocumentVersionsDocument, options);
}
export type DocumentVersionsQueryHookResult = ReturnType<typeof useDocumentVersionsQuery>;
export type DocumentVersionsLazyQueryHookResult = ReturnType<typeof useDocumentVersionsLazyQuery>;
export type DocumentVersionsQueryResult = Apollo.QueryResult<Types.DocumentVersionsQuery, Types.DocumentVersionsQueryVariables>;
export const NotesDataDocument = gql`
  query notesData($resourceId: ID!) {
    notes_data(resource_id: $resourceId) {
      created_at
      created_by {
        first_name
        id
        last_name
        user_name
      }
      id
      is_deleted
      text
    }
  }
`;

/**
 * __useNotesDataQuery__
 *
 * To run a query within a React component, call `useNotesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesDataQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useNotesDataQuery(baseOptions: Apollo.QueryHookOptions<Types.NotesDataQuery, Types.NotesDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.NotesDataQuery, Types.NotesDataQueryVariables>(NotesDataDocument, options);
}
export function useNotesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotesDataQuery, Types.NotesDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.NotesDataQuery, Types.NotesDataQueryVariables>(NotesDataDocument, options);
}
export type NotesDataQueryHookResult = ReturnType<typeof useNotesDataQuery>;
export type NotesDataLazyQueryHookResult = ReturnType<typeof useNotesDataLazyQuery>;
export type NotesDataQueryResult = Apollo.QueryResult<Types.NotesDataQuery, Types.NotesDataQueryVariables>;
export const NotesTabCountDocument = gql`
  query notesTabCount($resourceId: ID!) {
    notes_tab_count(resource_id: $resourceId)
  }
`;

/**
 * __useNotesTabCountQuery__
 *
 * To run a query within a React component, call `useNotesTabCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesTabCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesTabCountQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useNotesTabCountQuery(baseOptions: Apollo.QueryHookOptions<Types.NotesTabCountQuery, Types.NotesTabCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.NotesTabCountQuery, Types.NotesTabCountQueryVariables>(NotesTabCountDocument, options);
}
export function useNotesTabCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotesTabCountQuery, Types.NotesTabCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.NotesTabCountQuery, Types.NotesTabCountQueryVariables>(NotesTabCountDocument, options);
}
export type NotesTabCountQueryHookResult = ReturnType<typeof useNotesTabCountQuery>;
export type NotesTabCountLazyQueryHookResult = ReturnType<typeof useNotesTabCountLazyQuery>;
export type NotesTabCountQueryResult = Apollo.QueryResult<Types.NotesTabCountQuery, Types.NotesTabCountQueryVariables>;
export const TransitionsDocument = gql`
  query transitions($currentStateId: ID!) {
    transitions(current_state: $currentStateId) {
      edges {
        node {
          id
          name
          next_state {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useTransitionsQuery__
 *
 * To run a query within a React component, call `useTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransitionsQuery({
 *   variables: {
 *      currentStateId: // value for 'currentStateId'
 *   },
 * });
 */
export function useTransitionsQuery(baseOptions: Apollo.QueryHookOptions<Types.TransitionsQuery, Types.TransitionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TransitionsQuery, Types.TransitionsQueryVariables>(TransitionsDocument, options);
}
export function useTransitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransitionsQuery, Types.TransitionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TransitionsQuery, Types.TransitionsQueryVariables>(TransitionsDocument, options);
}
export type TransitionsQueryHookResult = ReturnType<typeof useTransitionsQuery>;
export type TransitionsLazyQueryHookResult = ReturnType<typeof useTransitionsLazyQuery>;
export type TransitionsQueryResult = Apollo.QueryResult<Types.TransitionsQuery, Types.TransitionsQueryVariables>;
export const StatesDocument = gql`
  query states($workflowId: ID) {
    states(workflow: $workflowId) {
      edges {
        node {
          id
          name
          initial
          final
          workflow {
            id
            is_non_linear
          }
        }
      }
    }
  }
`;

/**
 * __useStatesQuery__
 *
 * To run a query within a React component, call `useStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *   },
 * });
 */
export function useStatesQuery(baseOptions?: Apollo.QueryHookOptions<Types.StatesQuery, Types.StatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.StatesQuery, Types.StatesQueryVariables>(StatesDocument, options);
}
export function useStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StatesQuery, Types.StatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.StatesQuery, Types.StatesQueryVariables>(StatesDocument, options);
}
export type StatesQueryHookResult = ReturnType<typeof useStatesQuery>;
export type StatesLazyQueryHookResult = ReturnType<typeof useStatesLazyQuery>;
export type StatesQueryResult = Apollo.QueryResult<Types.StatesQuery, Types.StatesQueryVariables>;
export const TransitionAuditsDocument = gql`
  query transitionAudits($dealOrDocumentId: ID!) {
    transition_audit(parent_id: $dealOrDocumentId) {
      states {
        active
        final
        id
        initial
        name
        priority
      }
    }
  }
`;

/**
 * __useTransitionAuditsQuery__
 *
 * To run a query within a React component, call `useTransitionAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransitionAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransitionAuditsQuery({
 *   variables: {
 *      dealOrDocumentId: // value for 'dealOrDocumentId'
 *   },
 * });
 */
export function useTransitionAuditsQuery(baseOptions: Apollo.QueryHookOptions<Types.TransitionAuditsQuery, Types.TransitionAuditsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TransitionAuditsQuery, Types.TransitionAuditsQueryVariables>(TransitionAuditsDocument, options);
}
export function useTransitionAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TransitionAuditsQuery, Types.TransitionAuditsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TransitionAuditsQuery, Types.TransitionAuditsQueryVariables>(TransitionAuditsDocument, options);
}
export type TransitionAuditsQueryHookResult = ReturnType<typeof useTransitionAuditsQuery>;
export type TransitionAuditsLazyQueryHookResult = ReturnType<typeof useTransitionAuditsLazyQuery>;
export type TransitionAuditsQueryResult = Apollo.QueryResult<Types.TransitionAuditsQuery, Types.TransitionAuditsQueryVariables>;
export const LatestStateAuditsDocument = gql`
  query latestStateAudits($workflowId: ID!, $dealId: ID, $documentId: ID, $all_state_audits: Boolean!) {
    latest_state_audits(workflow_id: $workflowId, deal_id: $dealId, document_id: $documentId, all_state_audits: $all_state_audits) {
      state_id
      audit {
        id
        new_value
        old_value
        user_name
        first_name
        last_name
        created_at
      }
    }
  }
`;

/**
 * __useLatestStateAuditsQuery__
 *
 * To run a query within a React component, call `useLatestStateAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestStateAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestStateAuditsQuery({
 *   variables: {
 *      workflowId: // value for 'workflowId'
 *      dealId: // value for 'dealId'
 *      documentId: // value for 'documentId'
 *      all_state_audits: // value for 'all_state_audits'
 *   },
 * });
 */
export function useLatestStateAuditsQuery(baseOptions: Apollo.QueryHookOptions<Types.LatestStateAuditsQuery, Types.LatestStateAuditsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LatestStateAuditsQuery, Types.LatestStateAuditsQueryVariables>(LatestStateAuditsDocument, options);
}
export function useLatestStateAuditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestStateAuditsQuery, Types.LatestStateAuditsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LatestStateAuditsQuery, Types.LatestStateAuditsQueryVariables>(LatestStateAuditsDocument, options);
}
export type LatestStateAuditsQueryHookResult = ReturnType<typeof useLatestStateAuditsQuery>;
export type LatestStateAuditsLazyQueryHookResult = ReturnType<typeof useLatestStateAuditsLazyQuery>;
export type LatestStateAuditsQueryResult = Apollo.QueryResult<Types.LatestStateAuditsQuery, Types.LatestStateAuditsQueryVariables>;
export const UsersDocument = gql`
  query users($internal: Boolean) {
    users(internal: $internal) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      internal: // value for 'internal'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export const CurrentUserDocument = gql`
  query currentUser {
    current_user {
      id
      user_name
      first_name
      last_name
      is_super_admin
      customers {
        edges {
          node {
            id
            name
          }
        }
      }
      roles {
        edges {
          node {
            id
            name
            features {
              edges {
                node {
                  id
                  name
                  permission
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;
