import { ArrowLeft, Download } from 'react-feather'
import { BasePDFDoc } from '../PDFDoc'
import { useDocumentNameLazyQuery } from '../../graphql/codegen/hooks'
import Loader from '../Loader'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useAsync from '../../hooks/useAsync'
import useUserAccess from '../../hooks/useUserAccess'

export default function DashboardPDFOverlay({
  handleClose,
  isDocument,
  pdfDocumentId,
  pdfName
}: {
  handleClose?: any
  isDocument?: boolean
  pdfDocumentId: string
  pdfName: string
}) {
  const [loading, setLoading] = useState(false)
  const [tooltipContent, setTooltipContent] = useState('')
  const isPdf = pdfName?.includes('.pdf')
  const hasAttachmentDownloadAccess = useUserAccess({ feature: 'Attachment', permission: 'DOWNLOAD' })
  const hasDocumentDownloadAccess = useUserAccess({ feature: 'Document', permission: 'DOWNLOAD' })

  const [getPdfName, { data: pdfNameData }] = useDocumentNameLazyQuery({ context: { queryName: `DocumentList.tsx useDocumentCountQuery` } })

  const { data, error } = useAsync(async () => {
    if (!pdfDocumentId) return null
    // this works for any document or attachment, we didn't update the endpoint name when we decided to reuse it
    const res = await axiosClient.get(`attachments/${pdfDocumentId}?preview=1`, { responseType: 'blob' })
    return URL.createObjectURL(res.data)
  }, [pdfDocumentId])

  const handleDownload = async () => {
    const documentDownloadUrl = `/document/${pdfDocumentId}?type=ORIGINAL_PDF`
    const attachmentDownloadUrl = `attachments/${pdfDocumentId}`
    setLoading(true)
    const data = await axiosClient
      .get(`${isDocument ? documentDownloadUrl : attachmentDownloadUrl}`, {
        responseType: isDocument ? undefined : 'blob',
        onDownloadProgress(progressEvent) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setTooltipContent(`Downloading File: ${progress}%`)
        }
      })
      .then(res => {
        setLoading(false)
        setTooltipContent('')
        if (isDocument) {
          return res?.data?.data
        } else {
          return window.URL.createObjectURL(res?.data)
        }
      })
      .catch(err => {
        setLoading(false)
        setTooltipContent('')
        return err
      })

    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = data
    // TODO: return name w/ response. this is built on the assumption that all document names end with .docx
    a.download = pdfName ? pdfName.split('.')[0] + '.pdf' : pdfNameData?.documents?.edges[0]?.node?.name?.split('.')[0] + '.pdf'

    document.body.appendChild(a)
    a.click()
  }

  useEffect(() => {
    if (!pdfName) {
      getPdfName({ variables: { document_id: pdfDocumentId } })
    }
    // eslint-disable-next-line
  }, [pdfName])

  return (
    <div className={css.overlay}>
      <div className={css.header}>
        <WithTooltip content={'Close Preview'} style={{ cursor: 'pointer' }}>
          <span onClick={handleClose}>
            <ArrowLeft />
          </span>
        </WithTooltip>
        {pdfName || (pdfNameData?.documents?.edges[0]?.node?.name && <span>{pdfName || pdfNameData?.documents?.edges[0]?.node?.name}</span>)}
        {pdfDocumentId &&
          (pdfName || pdfNameData?.documents?.edges[0]?.node?.name) &&
          ((isDocument && hasDocumentDownloadAccess) || (!isDocument && hasAttachmentDownloadAccess)) && (
            <WithTooltip content={tooltipContent || loading ? 'Downloading File…' : 'Download File'} style={{ cursor: 'pointer' }}>
              {loading ? <Loader variant="light" /> : <Download onClick={handleDownload} />}
            </WithTooltip>
          )}
      </div>
      <div className={css.attachmentWrapper}>
        {error && <p>{`Uh oh, something went wrong. This file format might not be supported for preview.`}</p>}
        {!error && !data && <Loader variant="light" />}
        {data &&
          (isPdf || isDocument ? (
            <BasePDFDoc data={data} error={error} id={pdfDocumentId} loading={loading} />
          ) : (
            <img alt={`Preview of ${pdfName}`} src={data} />
          ))}
      </div>
    </div>
  )
}
