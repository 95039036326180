import { OverridesStyleRules } from '@mui/material/styles/overrides'

// constants

export const COMPONENTS: OverridesStyleRules = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState: { color, variant } }: any) => ({
        textTransform: 'none',

        ...(variant === 'outlined' &&
          color === 'secondary' && {
            '&': { backgroundColor: '#f3f4f8', borderColor: '#bdbec2', color: '#000' },
            '&:hover': { backgroundColor: '#004fdb', borderColor: '#bdbec2', color: '#fff' }
          }),

        ...(variant === 'outlined' &&
          color === 'tertiary' && {
            '&': { borderColor: '#bdbec2' },
            '&:hover': { backgroundColor: '#f3f4f8', borderColor: '#bdbec2' }
          })
      })
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiScopedCssBaseline: {
    styleOverrides: {
      root: {
        WebkitFontSmoothing: 'auto'
      }
    }
  }
}
