import type { DataPoint, DataPointField } from '../graphql/codegen/schemas'

/**
 * Data points can have a string value and still be considered 'empty' if it matches one of these values
 */
export const EMPTY_DATA_POINT_VALUES = [null, '', 'No', 'N/A']

export function countTruthyValues(dataPoint: DataPoint) {
  return dataPoint?.document_data_points?.edges.filter(dp => {
    let val = dp?.node?.display_value
    // Dropdowns do not have display_values
    if (dp?.node?.data_point_field?.field_type === 'MULTI_SELECT_DROP_DOWN') {
      val = dp.node.value_list?.[0] // this supposes that if an empty value is included in the value_list, no other values are permitted
    }

    return val && !EMPTY_DATA_POINT_VALUES.includes(val)
  }).length
}

export function countTruthyValuesForDataPointGroup(dataPointGroup: DataPoint[]) {
  return dataPointGroup.reduce((acc, curr) => {
    const { value_bool, value_float, value_iso_date, value_list, value_str } = curr
    if (
      value_bool || // we don't want to count false OR null
      value_float !== null || // we want to count 0 still
      value_iso_date ||
      value_str || // empty string doesn't count
      (value_list?.length && !value_list?.some(v => EMPTY_DATA_POINT_VALUES.includes(v))) //
    ) {
      acc++
    }

    return acc
  }, 0)
}

// Separate metadata and steps
export function groupDataPoints(dataPoints?: any): { [k: string]: DataPoint[] } | null {
  if (!dataPoints) return null

  const groups = dataPoints.reduce((acc: { [k: string]: any[] }, curr: any) => {
    const { group } = curr.data_point_field

    // Intentionally hidden
    if (group === 'Aggregates') {
      return acc
    }

    if (!acc[group]) {
      acc[group] = []
    }
    acc[group].push(curr)
    return acc
  }, {})

  // Order datapoints within group
  for (const k in groups) {
    const arr = groups[k]
    const sorted = arr.sort((a: any, b: any) => {
      if (a.data_point_field.priority > b.data_point_field.priority) {
        return 1
      }
      return -1
    })
    groups[k] = sorted
  }

  return groups
}

// Separate metadata and steps

export const groupDataPointFields = (dataPointFields?: any): { [k: string]: DataPointField[] } | null => {
  if (!dataPointFields) return null

  const groups = dataPointFields.reduce((acc: { [k: string]: any[] }, curr: any) => {
    const { group } = curr
    // Intentionally hidden
    if (group === 'Aggregates') {
      return acc
    }
    if (!acc[group]) {
      acc[group] = []
    }
    acc[group].push(curr)
    return acc
  }, {})

  // Order dataPointFields within group
  for (const k in groups) {
    const arr = groups[k]
    const sorted = arr.sort((a: any, b: any) => {
      if (a.priority > b.priority) {
        return 1
      }
      return -1
    })
    groups[k] = sorted
  }

  return groups
}
