import { NotesDataDocument, NotesTabCountDocument, useCommentsDataQuery, useCreateDealNoteMutation, useNotesDataQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import NoteSection from '../NoteSection'
import React, { Dispatch, SetStateAction, useEffect } from 'react'

// types

interface DealNotesTabProps {
  dealIsFinalized: boolean
  setActiveTab: Dispatch<SetStateAction<string>>
}

// components

const DealNotesTab = ({ dealIsFinalized, setActiveTab }: DealNotesTabProps) => {
  const { dealId } = useParams<{ dealId: string }>()

  const { data: commentsData } = useCommentsDataQuery({ variables: { resourceId: dealId } })
  const [createDealNoteMutation, noteState] = useCreateDealNoteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: NotesDataDocument, variables: { resourceId: dealId } },
      { query: NotesTabCountDocument, variables: { resourceId: dealId } }
    ]
  })
  const { data: notesData } = useNotesDataQuery({ variables: { resourceId: dealId } })

  useEffect(() => {
    setActiveTab(`notes`)

    return () => setActiveTab(`default`)
  }, [setActiveTab])

  const handleCreate = dealIsFinalized ? undefined : (text: string) => createDealNoteMutation({ variables: { dealId, text } })

  const comments = commentsData?.comments_data?.map(comment => ({ ...comment, parentDataPoint: comment?.data_point }))
  const isLoading = !(commentsData && notesData)
  const notes = notesData?.notes_data

  const combined = comments && notes && [...comments, ...notes].sort((a: any, b: any) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))

  return <NoteSection commentState={noteState} comments={combined} dealIsFinalized={dealIsFinalized} handleCreate={handleCreate} loading={isLoading} />
}

export default DealNotesTab
