import { useCciMainContext } from '../../../../../CCI_Main'
import Button from '../../../../../../../components/Button'
import React from 'react'
import css from './style.module.scss'

export default function NeutralTagRow({ oldTag }: { oldTag?: string }) {
  const { openModal, selectedItem } = useCciMainContext()

  const handleClick = () => {
    openModal({
      menuOption: 'Edit Neutral Tag',
      content: {
        oldTag: oldTag || (selectedItem?.external_mapping?.length === 1 ? selectedItem?.external_mapping[0] : undefined),
        fieldName: selectedItem?.name,
        fieldId: selectedItem?.id
      }
    })
  }

  return (
    <>
      <div className={css.neutralTagInputWrapper} style={oldTag ? { width: '100%' } : {}}>
        <h4 style={oldTag ? { marginLeft: '131px' } : {}}>{`Neutral Tag:`}</h4>
        <div>
          <p>{oldTag || selectedItem?.external_mapping}</p>
          <Button onClick={handleClick}>{`Edit`}</Button>
        </div>
      </div>
    </>
  )
}
