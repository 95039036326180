import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { Loader } from 'react-feather'
import { useHistory } from 'react-router'
import { usePopper } from 'react-popper'
import Button from '../Button'
import FreeText from '../DatapointInput/FreeText'
import React, { CSSProperties, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import useOnClickOutside from '../../hooks/useOnClickOutside'

export default function QueryMenu({
  label,
  placement = 'right',
  loading,
  style,
  styleOffset = [8, 8],
  queryFunction,
  queryVars,
  queryData,
  queryPlaceholder,
  queryItemName
}: Props) {
  const history = useHistory()
  const ref = useRef<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | HTMLSpanElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isFocused, setFocused] = useState(false)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 16 } },
      { name: 'offset', options: { offset: styleOffset } }
    ]
  })
  useOnClickOutside(ref, () => setIsVisible(false))

  const [menuItems, setMenuItems] = useState<Array<Item>>([])
  const [offset, setOffset] = useState<any>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
  const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(true)

  useEffect(() => {
    // May need to add additional map functions/if statements to support different query types
    const items: Array<Item> = []
    queryData?.deals_by_counter_party?.forEach((item: any, index: any) => {
      if (item?.name && index < 10) {
        items.push({
          label: item?.alias || item?.name,
          href: `/deals/${item?.id}`,
          onClick: () => {
            history.push(`/deals/${item?.id}`)
          }
        })
      }
    })
    setMenuItems([...items])

    // @ts-ignore
    if (queryData?.deals_by_counter_party?.length < 20) {
      setNextButtonDisabled(true)
    }
    if (offset === 0) {
      setPrevButtonDisabled(true)
    }
  }, [queryData, history, offset])

  const checkForRequiredValues = () => {
    if (queryVars?.required) {
      for (const key of Object.keys(queryVars?.required)) {
        // console.info(key + ": " + queryVars.required[key])
        if (!queryVars?.required[key]) {
          return false
        } // console.info(`Required var is null-`, key,`:`, queryVars.required[key])
      }
      return true // console.info('All required vars have value, return true');
    } else {
      return true
    } // console.info('No required vars, return true');
  }

  const handleNext = () => {
    if (checkForRequiredValues()) {
      const newOffset = offset + 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handlePrev = () => {
    if (checkForRequiredValues()) {
      const newOffset = offset - 10 <= 0 ? 0 : offset - 10
      queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset: newOffset, size: 20 } })
      setOffset(newOffset)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if (checkForRequiredValues()) {
        queryFunction({ variables: { ...queryVars?.required, ...queryVars?.optional, searchTerm, offset, size: 20 } })
        setOffset(0)
      }
    }
  }

  return (
    <div ref={ref} style={style}>
      <span
        className={css.subTitle}
        onClick={() => {
          setIsVisible(prev => !prev)
        }}
        ref={setReferenceElement}
      >
        {label}
      </span>
      {isVisible && (
        <div className={css.menuPane} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <ul style={{ cursor: 'default' }}>
            <FreeText
              isFocused={isFocused}
              onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
                setSearchTerm(e.currentTarget.value)
              }}
              onKeyDown={handleKeyDown}
              placeholder={queryPlaceholder || 'Search…'}
              setFocused={setFocused}
              value={searchTerm}
            />
            {loading ? (
              <div className={css.rotate}>
                <Loader />
              </div>
            ) : (
              menuItems?.map(item => {
                return <MenuItem key={item.label} {...item} setIsVisible={setIsVisible} />
              })
            )}
            {!loading && menuItems?.length === 0 && <li className={css.menuItem} style={{ cursor: 'default' }}>{`No ${queryItemName} found`}</li>}
            {!loading && (
              <li className={clsx(css.pagination, nextButtonDisabled && prevButtonDisabled && css.isDisabled)}>
                <Button className={clsx(prevButtonDisabled && css.isDisabled)} icon={<FiChevronLeft />} onClick={prevButtonDisabled ? undefined : handlePrev} />
                <Button
                  className={clsx(nextButtonDisabled && css.isDisabled)}
                  icon={<FiChevronRight />}
                  onClick={nextButtonDisabled ? undefined : handleNext}
                />
              </li>
            )}
          </ul>
          <div ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  )
}

function MenuItem({ href, label, onClick, setIsVisible }: Item & { setIsVisible: (b: boolean) => void }) {
  const handleClick = () => {
    setIsVisible(false)
    onClick()
  }

  return (
    <li className={css.menuItem} onClick={handleClick}>
      {href ? (
        <Link title={'Open link'} to={href}>
          {label}
        </Link>
      ) : (
        label
      )}
    </li>
  )
}

interface Props {
  label?: string | null
  loading?: boolean
  placement?: Placement
  queryData?: any
  queryFunction: any
  queryItemName?: string
  queryPlaceholder?: string
  queryVars?: any
  style?: CSSProperties
  styleOffset?: any
}

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type Item = { href?: string; label: string; onClick: (...args: any) => void }

// #region Former debounce logic, refactor for QueryMenu if we decide to add debouncing back in in the future

// import { debounce } from 'lodash';

// // eslint-disable-next-line react-hooks/exhaustive-deps
// const debounceTerm = useCallback(
//   debounce((nextValue: string | undefined, counterpartyId) => {
//     if (counterpartyId) {
//       getCounterPartyDeals({ variables: { counterpartyId, dealName: nextValue, offset, size: 20 } });
//     }
//   }, 500),
//   []
// );

// useEffect(() => {
//   debounceTerm(searchTerm, counterpartyId);
// }, [searchTerm, debounceTerm, counterpartyId]);

// #endregion
