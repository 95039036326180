import React from 'react'
import Skeleton from 'react-loading-skeleton'

// components

const ChecklistSkeleton = () => {
  return (
    <div style={{ padding: 16 }}>
      <Skeleton count={4} height={24} style={{ margin: '8px 0' }} />
    </div>
  )
}

export const GroupDataPointsSkeleton = () => {
  return (
    <div style={{ background: '#fff', padding: '2px 8px 0' }}>
      <Skeleton height={24} style={{ margin: '8px 0' }} />
    </div>
  )
}

export default ChecklistSkeleton
