import { useCciMainContext } from '../../../../CCI_Main'
import { validateEmail } from '../../../../../../utils/stringUtils'
import Button from '../../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import RoleInput from './RoleInput'
import TextInput from '../CCI_Inputs/TextInput'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'

export default function CreateUser({ roleOptions }: { roleOptions?: any }) {
  const { activeComponent, openModal, tab } = useCciMainContext()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)

  // wipe values on tab or component change
  useEffect(() => {
    setValues(undefined)
    // eslint-disable-next-line
    }, [tab, activeComponent])

  const handleData = (type: string, value: any) => {
    // console.info('handleData firing, type:', type, 'value:', value)
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`CreateUser handleData error: Must provide a valid option`)
          return
        case 'role_ids':
          return setValues((prev: any) => {
            return { ...prev, role_ids: [...value] }
          })
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(values, setIsDisabled, setDisabledMessage)
  }, [values])

  return (
    <>
      <div>
        <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{`Create User`}</h3>
        <TextInput
          displayName={'Email:'}
          handleData={handleData}
          placeholder={`Enter user's email`}
          value={values?.user_primary_email}
          variableName={'user_primary_email'}
        />
        <TextInput
          displayName={'First Name:'}
          handleData={handleData}
          placeholder={`Enter user's first name`}
          value={values?.first_name}
          variableName={'first_name'}
        />
        <TextInput
          displayName={'Last Name:'}
          handleData={handleData}
          placeholder={`Enter user's last name`}
          value={values?.last_name}
          variableName={'last_name'}
        />
        <RoleInput handleData={handleData} options={roleOptions} />
      </div>
      <div className={css.modalButtonRow}>
        {/* <Button onClick={()=>{console.info('values', values)}} variant={'secondary'}>Console log values</Button> */}
        <WithTooltip content={disabledMessage}>
          <Button
            disabled={isDisabled}
            onClick={() => {
              openModal({ menuOption: 'Review New User', content: { userName: values?.name, values } })
            }}
          >
            {`Review New User`}
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}

const validateInputs = (values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  if (!values?.user_primary_email) {
    errors.push(`Email`)
  } else if (!validateEmail(values?.user_primary_email)) {
    errors.push(`Email is invalid`)
  }
  if (!values?.first_name) {
    errors.push(`First Name`)
  }
  if (!values?.last_name) {
    errors.push(`Last Name`)
  }
  if (!values?.role_ids?.length || values?.roles?.length < 1) {
    errors.push(`At least one role is required`)
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}
