import 'react-toastify/dist/ReactToastify.css'
import { ApiProvider } from '@reduxjs/toolkit/query/react'
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { COMPONENTS } from '../new/theme/components'
import { Router as KlarityRouter } from './router'
import { PALETTE } from '../new/theme/palette'
import { Router } from 'react-router'
import { SHAPE } from '../new/theme/shape'
import { TYPOGRAPHY } from '../new/theme/typography'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { api } from '../new/api'
import AppSetupUtils from './AppSetupUtils'
import ComponentLoadingOverlay from '../components/ComponentLoadingOverlay'
import DashboardPDFOverlay from '../components/DashboardPDFOverlay'
import ModalOptions from '../components/ModalOptions'
import RModal from 'react-modal'
import React, { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'
import css from './style.module.scss'
import history from './history'

const theme = createTheme({
  components: COMPONENTS,
  palette: PALETTE,
  shape: SHAPE,
  typography: TYPOGRAPHY
})

export default function App({ apolloClient }: { apolloClient: ApolloClient<NormalizedCacheObject> }) {
  return (
    <ApolloProvider client={apolloClient}>
      <ApiProvider api={api}>
        <ThemeProvider theme={theme}>
          <InnerApp />
        </ThemeProvider>
      </ApiProvider>
    </ApolloProvider>
  )
}

function InnerApp() {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [previewContent, setPreviewContent] = useState<{ documentId: string; documentName: string; isDocument: boolean }>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalOption, setModalOption] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalError, setModalError] = useState<{ message?: string; messageNode?: ReactNode; title: string } | undefined>(undefined)
  const [modalSuccess, setModalSuccess] = useState<{ message?: string; messageNode?: ReactNode; title: string } | undefined>(undefined)
  const [modalContent, setModalContent] = useState<any>(undefined)

  const openPreview = (documentId: string, isDocument: boolean, documentName?: string) => {
    setIsPreviewOpen(true)
    setPreviewContent({ documentId, documentName: documentName || '', isDocument })
  }
  const closePreview = () => {
    setIsPreviewOpen(false)
    setPreviewContent({ documentId: '', documentName: '', isDocument: false })
  }

  const openModal = (option: string, title: string, modalContent: any) => {
    setIsModalOpen(true)
    setModalTitle(title)
    setModalOption(option)
    setModalContent(modalContent ? { ...modalContent } : undefined)
  }
  const closeModal = () => {
    setIsModalOpen(false)
    setModalTitle('')
    setModalOption('')
    setModalLoading(false)
    setModalLoadingMessage('')
    setModalError(undefined)
    setModalSuccess(undefined)
    setModalContent(undefined)
  }
  const handleClickOutside = () => {
    if (modalOption !== 'WrongCustomer') {
      closeModal()
    }
  }

  return (
    <Router history={history}>
      <ModalContext.Provider
        value={{
          openPreview,
          isPreviewOpen,
          openModal,
          isModalOpen,
          closeModal,
          modalOption,
          setModalOption,
          modalContent,
          setModalTitle,
          setModalLoading,
          setModalLoadingMessage,
          modalError,
          setModalError,
          modalSuccess,
          setModalSuccess
        }}
      >
        <AppSetupUtils />
        <KlarityRouter />
        <ToastContainer autoClose={false} position="bottom-right" />
        {isPreviewOpen && (
          <DashboardPDFOverlay
            handleClose={closePreview}
            isDocument={previewContent?.isDocument}
            pdfDocumentId={previewContent?.documentId || ''}
            pdfName={previewContent?.documentName || ''}
          />
        )}
        <RModal className={css.modal} isOpen={isModalOpen} onRequestClose={handleClickOutside} overlayClassName={css.overlay}>
          {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={modalLoadingMessage} />}
          {modalTitle && !modalSuccess && !modalError && <h2 className={css.modalTitle}>{modalTitle}</h2>}
          <ModalOptions />
        </RModal>
      </ModalContext.Provider>
    </Router>
  )
}

interface ModalInterface {
  closeModal: any
  isModalOpen: boolean
  isPreviewOpen: boolean
  modalContent: any
  modalError?: { message?: string; messageNode?: ReactNode; title: string } | undefined
  modalOption: string
  modalSuccess?: { message?: string; messageNode?: ReactNode; title: string } | undefined
  openModal: any
  openPreview: any
  setModalError: Dispatch<SetStateAction<{ message?: string; messageNode?: ReactNode; title: string } | undefined>>
  setModalLoading: Dispatch<SetStateAction<boolean>>
  setModalLoadingMessage: Dispatch<SetStateAction<string>>
  setModalOption: Dispatch<SetStateAction<string>>
  setModalSuccess: Dispatch<SetStateAction<{ message?: string; messageNode?: ReactNode; title: string } | undefined>>
  setModalTitle: Dispatch<SetStateAction<string>>
}

export const ModalContext = createContext<ModalInterface>(null!)
