import { CommentsDataDocument, DataPointsDocument, NotesTabCountDocument } from '../../../graphql/codegen/hooks'
import { MessageCircle } from 'react-feather'
import { createComment } from '../../../graphql/mutations/comment.graphql'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { usePopper } from 'react-popper'
import CommentSection from '../../../components/CommentSection'
import React, { ReactNode, useRef, useState } from 'react'
import clsx from 'clsx'
import css from '../style.module.scss'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import type { DataPoint } from '../../../graphql/codegen/schemas'

function PopperWrapper({ children, commentCount }: { children: ReactNode; commentCount?: number }) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)

  useOnClickOutside(ref, () => setIsVisible(false))

  const { attributes, styles } = usePopper(ref.current, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    placement: 'right-start',
    strategy: 'fixed'
  })

  return (
    <div ref={ref}>
      <div className={clsx(css.commentButtonIcon, (commentCount || isVisible) && css.withCommentsOrActive)}>
        {!!commentCount && <span style={{ fontSize: 12 }}>{commentCount}</span>}
        <MessageCircle
          onClick={() => {
            setIsVisible(true)
          }}
        />
      </div>
      {isVisible && (
        <div className={css.commentPopover} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {children}
        </div>
      )}
    </div>
  )
}

export default function CommentButton({ dataPoint, dealIsFinalized }: { dataPoint: DataPoint; dealIsFinalized: boolean }) {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [createCommentMutation, commentState] = useMutation(createComment, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: DataPointsDocument, variables: { id: dataPoint.id } },
      { query: CommentsDataDocument, variables: { resourceId: dealId || documentId! } },
      { query: NotesTabCountDocument, variables: { resourceId: dealId || documentId! } }
    ]
  })

  const handleCreate = (text: string) => createCommentMutation({ variables: { dataPointId: dataPoint.id, text } })

  // @ts-ignore
  const comments = dataPoint?.comments?.length > 0 ? [...dataPoint?.comments] : []
  const sortedComments = comments.sort((a: any, b: any) => {
    return new Date(a.created_at) > new Date(b.created_at) ? 1 : -1
  })

  return (
    <>
      {(!dealIsFinalized || sortedComments?.length > 0) && (
        <PopperWrapper commentCount={dataPoint?.comments?.length}>
          <CommentSection
            commentState={commentState}
            comments={sortedComments}
            dataPointId={dataPoint.id}
            dealIsFinalized={dealIsFinalized || false}
            handleCreate={dealIsFinalized ? undefined : handleCreate}
          />
        </PopperWrapper>
      )}
    </>
  )
}
