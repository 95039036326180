import { useDealDocumentsLazyQuery } from '../../../graphql/codegen/hooks'
import Button from '../../Button'
import InlineQueryMenu from '../../InlineQueryMenu'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

export default function ViewDealsDocuments({ closeModal, dealCounterpartyId, dealId }: { closeModal: any; dealCounterpartyId?: any; dealId?: string }) {
  const [queryVars, setQueryVars] = useState<any>('')

  const [getDealDocuments, { data: dealDocuments, loading: dealDocumentsLoading }] = useDealDocumentsLazyQuery({
    context: { queryName: 'DealList.tsx getDealDocuments' }
  })

  useEffect(() => {
    if (dealId) {
      setQueryVars({ required: { dealId }, optional: {} })
      getDealDocuments({ variables: { dealId } })
    }
    // eslint-disable-next-line
    }, [])

  return (
    <>
      <InlineQueryMenu
        dealCounterpartyId={dealCounterpartyId}
        keepOpen
        loading={dealDocumentsLoading}
        queryData={dealDocuments}
        queryFunction={getDealDocuments}
        queryItemName={'documents'}
        queryName={'Documents by Deal'}
        queryPlaceholder={'Input a name and press enter to search'}
        queryVars={queryVars}
      />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Close`}</Button>
      </div>
    </>
  )
}
