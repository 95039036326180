// TODO: This needs to be combined with DataPointInput in the pursuit of DRY code
import { Maybe } from 'graphql/jsutils/Maybe'
import DateInput from './DateInput'
import FreeText from './FreeText'
import NumberInput from './NumberInput'
import Percentage from './Percentage'
import React, { SyntheticEvent } from 'react'
import Select from 'react-select'

interface GenericDataPointInputProps {
  fieldType: any
  options?: Maybe<Maybe<string>[]>
  placeholder?: string
  setValue: (v: any) => void
  value: any
}

export default function GenericDataPointInput({ fieldType, options, placeholder, setValue, value }: GenericDataPointInputProps) {
  let currentValues: any = []
  if (Array.isArray(value)) {
    currentValues = value?.map(val => {
      return { label: val, value: val }
    })
  }

  switch (fieldType) {
    case 'TEXT':
    case 'DURATION':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return <FreeText onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => setValue(e.currentTarget.value)} placeholder={placeholder} value={value} />

    case 'DROP_DOWN':
    case 'BOOLEAN':
      if (!options) return null
      return (
        <Select
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={v => {
            setValue(v?.value || '')
          }}
          options={options.map(v => ({ label: v, value: v }))}
          styles={{
            control: provided => {
              return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }
            },
            option: provided => {
              return { ...provided, fontSize: 14 }
            },
            container: provided => {
              return { ...provided, padding: 0 }
            },
            multiValue: provided => {
              return { ...provided, maxWidth: '300px' }
            },
            placeholder: provided => {
              return { ...provided, color: '#e0e0e0' }
            },
            dropdownIndicator: () => {
              return { display: 'none' }
            },
            indicatorSeparator: () => {
              return { display: 'none' }
            },
            clearIndicator: (provided, { isFocused }) => {
              return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
            },
            menuPortal: provided => {
              return { ...provided, zIndex: 999999999 }
            }
          }}
          theme={theme => {
            return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
          }}
          value={{ label: value, value }}
        />
      )

    case 'MULTI_SELECT_DROP_DOWN':
      if (!options) return null
      return (
        <Select
          isMulti
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onChange={v => {
            setValue(
              v?.map(item => {
                return item?.value
              })
            )
          }}
          options={options.map(v => ({ label: v, value: v }))}
          styles={{
            control: provided => {
              return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }
            },
            option: provided => {
              return { ...provided, fontSize: 14 }
            },
            container: provided => {
              return { ...provided, padding: 0 }
            },
            multiValue: provided => {
              return { ...provided, maxWidth: '300px' }
            },
            placeholder: provided => {
              return { ...provided, color: '#e0e0e0' }
            },
            dropdownIndicator: () => {
              return { display: 'none' }
            },
            indicatorSeparator: () => {
              return { display: 'none' }
            },
            clearIndicator: (provided, { isFocused }) => {
              return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
            },
            menuPortal: provided => {
              return { ...provided, zIndex: 999999999 }
            }
          }}
          theme={theme => {
            return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
          }}
          value={currentValues}
        />
      )

    case 'PERCENTAGE':
      return <Percentage onChange={e => setValue(e.currentTarget.value)} value={value} />

    case 'FLOAT':
    case 'NUMBER':
      return (
        <NumberInput
          isFloat={fieldType === 'FLOAT'}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => setValue(e?.currentTarget?.value || null)}
          placeholder={placeholder}
          value={value}
        />
      )

    case 'DATE':
      return (
        <DateInput
          onChange={v => {
            setValue(v || '')
          }}
          value={value}
        />
      )

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field type: ${fieldType}`)
      }
      return null
  }
}
