import { API_ROOT } from '../../utils/apiUtils'
import { FiXSquare } from 'react-icons/fi'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Dropzone from '../Dropzone'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'

export default function BulkUpload({ closeModal, setComponentLoading }: { closeModal?: any; setComponentLoading?: any }) {
  const [documents, setDocuments] = useState<Array<File>>([])
  const [uploaded, setUploaded] = useState(false)
  const [successfulUploads, setSuccessfulUploads] = useState<any>([])
  const [failedUploads, setFailedUploads] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [disabledMessage, setDisabledMessage] = useState('')

  const wipeForm = () => {
    setDocuments([])
    setUploaded(false)
    setSuccessfulUploads([])
    setFailedUploads([])
    setLoading(false)
    setComponentLoading(false)
    setLoadingMessage('')
    setIsDisabled(true)
    setDisabledMessage('')
  }

  const handleClose = () => {
    wipeForm()
    closeModal()
  }

  const handleBulkUpload = async () => {
    if (!documents) {
      throw new Error('Must upload at least one document')
    }

    const localSuccessfulUploads: any[] = []
    const localFailedUploads: any[] = []

    setLoading(true)
    setComponentLoading(true)
    setLoadingMessage(`Uploading Documents..`)
    await Promise.all(
      documents?.map((document: File, index: number) => {
        return uploadDocument(document, index)
      })
    ).then(values => {
      values?.forEach((item?: any) => {
        if (item?.upload_success) {
          localSuccessfulUploads.push(item)
        } else {
          localFailedUploads.push(item)
        }
      })
      setSuccessfulUploads([...localSuccessfulUploads])
      setFailedUploads([...localFailedUploads])
      setDocuments(
        localFailedUploads?.map((item: any) => {
          return item?.document
        })
      )
      setUploaded(true)
      setLoading(false)
      setComponentLoading(false)
      setLoadingMessage('')
    })
  }

  const handleRemoveDocument = (index: number) => {
    setDocuments(prev => {
      const localArray = [...prev]
      localArray.splice(index, 1)
      return [...localArray]
    })
  }

  const uploadDocument = (document: File, index: number) => {
    return new Promise(resolve => {
      if (document === undefined) {
        throw Error('Document and document_type.id must be present')
      }

      const formData = new FormData()
      formData.append('document', document)

      // timeout is to prevent slamming the server with 25 uploads instantly
      setTimeout(() => {
        axiosClient
          .post(`${API_ROOT}/document/basic_manual_upload`, formData)
          .then(() => {
            resolve({ name: document?.name, upload_success: true })
          })
          .catch(err => {
            resolve({
              document,
              name: document?.name,
              upload_success: false,
              link: err?.response?.data?.link,
              error_message: err?.response?.data?.message || err
            })
          })
        // Resolving here so that can handle the error within Promise.all. Rejecting would instantly throw an error and break out of the .all
      }, 50 * index)
    })
  }

  useEffect(() => {
    if (!documents || documents?.length === 0) {
      setIsDisabled(true)
      setDisabledMessage('Select at least one document')
    } else {
      setIsDisabled(false)
      setDisabledMessage('')
    }
  }, [documents])

  return (
    <>
      {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}
      {uploaded ? (
        <>
          <h5 style={{ textAlign: 'center', marginBottom: '16px' }}>{`Upload Complete`}</h5>
          {successfulUploads?.length > 0 && (
            <div className={css.bulkUploadDocuments}>
              <h5>{`Successfully uploaded documents:`}</h5>
              {successfulUploads?.map((item: any, index: number) => {
                return <p key={item?.name + index}>{item?.name}</p>
              })}
            </div>
          )}
          {failedUploads?.length > 0 && (
            <div className={css.bulkUploadDocuments}>
              <h5>{`Failed to upload these documents:`}</h5>
              {failedUploads?.map((item: any, index: number) => {
                return (
                  <div key={item?.name + index}>
                    {item?.link ? (
                      <WithTooltip content={'Open conflicting document'}>
                        <a href={item.link}>{item?.name}</a>
                      </WithTooltip>
                    ) : (
                      <p>{item?.name}</p>
                    )}
                    <p style={{ margin: '3px 0 0 16px' }}>{`Error: ${item?.error_message || `Unknown network error`}`}</p>
                  </div>
                )
              })}
            </div>
          )}
          {successfulUploads?.length > 0 && <SuccessMessage />}
          {failedUploads?.length > 0 ? (
            <>
              <FailedDocumentsMessage successfulUploads={successfulUploads} />
              <div className={css.modalButtonRow}>
                <Button onClick={handleClose} variant={'secondary'}>{`Cancel`}</Button>
                <Button disabled={isDisabled} onClick={handleBulkUpload} title={isDisabled ? disabledMessage : ''}>
                  {'Re-Submit'}
                </Button>
              </div>
            </>
          ) : (
            <div className={css.modalButtonRow}>
              <Button onClick={handleClose} style={{ marginRight: 0 }} variant={'secondary'}>{`Close`}</Button>
            </div>
          )}
        </>
      ) : (
        <>
          {documents?.length > 0 ? (
            <>
              <h4 style={{ marginBottom: '8px' }}>{`Selected Documents:`}</h4>
              {documents?.map((document: File, index: number) => {
                return (
                  <div className={css.documentRow} key={document.name + index}>
                    <span>{document.name}</span>
                    <Button
                      icon={<FiXSquare />}
                      onClick={() => {
                        handleRemoveDocument(index)
                      }}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <h4 style={{ marginBottom: '8px' }}>{`Select Documents to upload:`}</h4>
              <Dropzone
                accept=".docx,.pdf"
                idleMessage={'Drag and drop .docx or .pdf documents here, or click to select'}
                multiple={true}
                onDrop={(acceptedFiles: File[]) => setDocuments(acceptedFiles)}
              />
            </>
          )}
          <div className={css.modalButtonRow}>
            <Button onClick={handleClose} variant={'secondary'}>{`Cancel`}</Button>
            <Button disabled={isDisabled} onClick={handleBulkUpload} title={isDisabled ? disabledMessage : ''}>
              {'Submit'}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

function SuccessMessage() {
  return (
    <div className={css.bulkSuccessMessage}>
      <p>{`Successfully uploaded documents have started pre-processing.`}</p>
      <p>{`They will be visible in the dashboard in about 30 minutes.`}</p>
      <p style={{ minWidth: '550px' }}>{`Deals will be created in roughly 24 hours after Klarity's AI extractions are complete.`}</p>
    </div>
  )
}

function FailedDocumentsMessage({ successfulUploads }: any) {
  return (
    <div className={css.bulkFailureMessage}>
      <p style={{ margin: '8px 0 0 0' }}>{`${successfulUploads?.length > 0 ? 'Some of your' : 'Your'} documents failed to upload.`}</p>
      <p style={{ margin: '8px 0' }}>{`Click the button below to attempt uploading them again.`}</p>
    </div>
  )
}
