import { FiTrash2 } from 'react-icons/fi'
import { useCciMainContext } from '../../../../CCI_Main'
import Button from '../../../../../../components/Button'
import React from 'react'
import clsx from 'clsx'
import css from '../../style.module.scss'

export default function CCI_LeftPanel_UserCard({ user }: any) {
  const { openModal, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()

  return (
    <div
      className={clsx(css.field, selectedItem?.id === user?.id && css.isFieldActive)}
      onClick={() => {
        setSelectedItem(user)
        setActiveComponent('Edit User')
      }}
    >
      <div className={clsx(css.labelRow, css.noSelect)}>
        <div style={{ display: 'flex' }}>
          <h4>{`First Name:`}</h4>
          <p>{`${user?.first_name}`}</p>
        </div>
        <ul className={css.actions}>
          <li>
            <Button
              icon={<FiTrash2 />}
              onClick={() => {
                openModal({ menuOption: 'Delete User', content: { userId: user?.id, userName: user?.user_name } })
              }}
            />
          </li>
        </ul>
      </div>
      <div style={{ display: 'flex' }}>
        <h4>{`Last Name:`}</h4>
        <p>{`${user?.last_name}`}</p>
      </div>
      {user?.roles?.edges?.length > 0 && (
        <>
          <h4>{`Roles:`}</h4>
          {user?.roles?.edges?.map((edge: any, index: number) => {
            return <p key={`${user?.user_name}${edge?.node?.name}${index}`}>{edge?.node?.name}</p>
          })}
        </>
      )}
    </div>
  )
}
