import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table'
import useAssignedToMe from '../../hooks/useAssignedToMe'
import useTablePagination from '../../hooks/useTablePagination'

export default function InboxTab() {
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const [sortConfig, setSortConfig] = useState<any[]>([{ sort_created_at: 1 }])
  const { dashboardData, documentCountData, loading } = useAssignedToMe({ sortConfig, pageLength: pageLength || 10, offset })
  const total = documentCountData?.fetch_advanced_dashboard_count?.total_number_of_filtered_documents

  useEffect(() => setRowCount(total || 0), [total])

  const tableData = useMemo(() => {
    if (!dashboardData) return []
    else {
      return dashboardData?.fetch_advanced_dashboard?.dashboard_data
    }
  }, [dashboardData])

  const getSortDirection = useCallback(
    (key: string) => {
      return sortConfig.find((a: any) => a[key])?.[key] || 0
    },
    [sortConfig]
  )

  const columns = useMemo(() => {
    const updateSortConfig = (key: string) => {
      const sortDirection = getSortDirection(key)
      setSortConfig([{ [key]: sortDirection === 1 ? -1 : ((sortDirection + 1) as -1 | 0 | 1) }])
    }

    return [
      {
        Header: 'Name',
        accessor: 'document',
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value: { value } }: any) => {
          if (!value) return null

          const { extraction_status, id } = row.original
          if (extraction_status === 'PROCESSING') {
            return (
              <span>
                {value}{' '}
                <span
                  style={{ color: '#5a5b5e', fontSize: 12, backgroundColor: 'rgba(71, 172, 255, 0.24)', padding: '0 6px', borderRadius: 8 }}
                >{`Extraction in progress`}</span>
              </span>
            )
          }
          return <Link to={`/documents/${id}?from=inbox`}>{value}</Link>
        },
        width: 400,
        sortFunction: () => updateSortConfig('sort_document_name'),
        sortDirection: getSortDirection('sort_document_name')
      },
      {
        Header: 'Annotation Status',
        accessor: 'internal_state',
        Cell: ({ value: { value } }: any) => {
          return value || null
        }
        // sortFunction: () => updateSortConfig('sort_internal_state'),
        // sortDirection: getSortDirection('sort_internal_state'),
      },
      {
        Header: 'Annotation Assignee',
        accessor: 'internal_assignee',
        Cell: ({ value: { value } }: any) => {
          if (!value) {
            return 'Unassigned'
          } else {
            return value?.user_name ? value.user_name : 'Error: missing user_name'
          }
        }
      },
      {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ value: { value } }: any) => {
          return value || null
        },
        sortFunction: () => updateSortConfig('sort_created_at'),
        sortDirection: getSortDirection('sort_created_at')
      }
    ]
  }, [getSortDirection])

  return (
    <Card>
      <Table {...paginationControls} columns={columns} loading={loading} pageLength={pageLength} rowCount={rowCount} tableData={tableData} variant="inbox" />
    </Card>
  )
}
