import { API_ROOT } from './apiUtils'
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { getAccessToken, logout } from './sessionApiUtils'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { toast } from 'react-toastify'

const httpLink = createHttpLink({ uri: API_ROOT + '/graphql' })

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessToken()
  const customerId = localStorage.getItem('cid')
  const token = btoa(`${accessToken}__||__${customerId}`)
  // console.log('token', token);

  // return the headers to the context so httpLink can read them
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } }
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const enabled = true
  if (enabled && process.env.NODE_ENV !== 'production') {
    const startTime = new Date()
    const context = operation.getContext()
    if (context?.queryName) {
      console.info(
        `${context?.queryName} FAILED at ${startTime?.getHours()}:${startTime?.getMinutes()}:${startTime?.getSeconds()}
      \nVariables:`,
        operation?.variables
      )
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ locations, message, path }) => {
      if (message === 'User must be logged in to view this resource') {
        if (window.location.pathname !== '/login' && window.location.pathname !== '/logout') {
          // this prevents infinite looping
          // This looks odd, but really it is just making sure that the apollo cache, localstorage, oktaclient, and revrec backend are all in sync.
          // This function will reset our store and redirect to /login without attempting to refetch queries unauthorized
          return logout()
        }
      }

      console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)

      if (message?.includes('not a valid ObjectId') || message?.includes('Invalid id')) return // Errors handled by rendering <ErrorPage />.

      if (message === 'Confirmation required' && path?.includes('move_to_next_internal_state')) return // Error handled in <AnnotatorTodoTab />.

      if (message === 'You do not have permission to perform this action') {
        toast.error('You do not have permission to perform this action.')
      } else if (message?.includes(`No actions other than to change the deal's state is allowed.`)) {
        toast.error('This action cannot be performed as it would update a finalized deal.')
      } else {
        toast.error('Something went wrong performing your request.')
      }
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const log_query_Link = new ApolloLink((operation, forward) => {
  const enabled = true
  if (enabled && process.env.NODE_ENV !== 'production') {
    const startTime = new Date()
    const context = operation.getContext()
    if (context?.queryName) {
      // console.info(`DevOnly QueryLogger:
      console.info(
        `${context?.queryName} firing at ${startTime?.getHours()}:${startTime?.getMinutes()}:${startTime?.getSeconds()}
      \nVariables:`,
        operation?.variables
      )
    }
  }
  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        assigned_to_me: relayStylePagination()
      }
    }
  }
})

const apolloClient = new ApolloClient({
  link: from([log_query_Link, authLink, errorLink, httpLink]),
  cache
})

export default apolloClient
