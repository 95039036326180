import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Column, DocumentQueryAction, resetToQueryColumns, showSavedColumns } from '../../../reducers/dashboardQueryReducer'
import { MdOutlineSystemSecurityUpdateGood } from 'react-icons/md'
import { array1ContainsAllOfArray2, compareArrayOfStrings } from '../../../utils/stringUtils'
import Button from '../../../components/Button'
import React, { Dispatch, useEffect, useState } from 'react'
import WithTooltip from '../../../components/WithTooltip'

export default function ColumnControls({
  dispatch,
  fieldsLoading,
  isQueryActive,
  savedColumns,
  selectedColumns,
  temporaryColumns
}: {
  dispatch: Dispatch<DocumentQueryAction>
  fieldsLoading: boolean
  isQueryActive: boolean
  savedColumns: Column[] | null
  selectedColumns: Column[]
  temporaryColumns: Column[] | null
}) {
  const [showSavedColumnButtonIsActive, setShowSavedColumnButtonIsActive] = useState(false)
  const [hideSavedColumnButtonIsActive, setHideSavedColumnButtonIsActive] = useState(false)
  const [updateSavedColumnButtonIsActive, setUpdateSavedColumnButtonIsActive] = useState(false)

  // this useEffect is checking if temporary columns do not match saved columns, and then shows buttons to show/hide or update the saved columns
  useEffect(() => {
    if (!fieldsLoading && isQueryActive) {
      const savedColumnIds = savedColumns?.map(column => {
        return column.value
      })
      const currentColumnIds = selectedColumns?.map(column => {
        return column.value
      })
      const queryColumnIds = temporaryColumns?.map(column => {
        return column.value
      })
      const arraysAreEqual = compareArrayOfStrings(savedColumnIds || [], currentColumnIds || [])

      if (!arraysAreEqual) {
        if (array1ContainsAllOfArray2(currentColumnIds || [], savedColumnIds || [])) {
          // hide show/hide saved buttons if only order has changed
          if (currentColumnIds?.length === savedColumnIds?.length) {
            setShowSavedColumnButtonIsActive(false)
            setHideSavedColumnButtonIsActive(false)
          }
          // query columns should be checked differently- if the query contains all of the saved columns then hide should not be shown
          else if (array1ContainsAllOfArray2(queryColumnIds || [], savedColumnIds || [])) {
            setShowSavedColumnButtonIsActive(false)
            setHideSavedColumnButtonIsActive(false)
          } else {
            setShowSavedColumnButtonIsActive(false)
            setHideSavedColumnButtonIsActive(true)
          }
        } else {
          setShowSavedColumnButtonIsActive(true)
          setHideSavedColumnButtonIsActive(false)
        }
      }
    }
  }, [fieldsLoading, isQueryActive, savedColumns, selectedColumns, temporaryColumns])

  // if there is any difference between current columns and saved columns show the update columns button
  useEffect(() => {
    const savedColumnIds = savedColumns?.map(column => {
      return column.value
    })
    const currentColumnIds = selectedColumns?.map(column => {
      return column.value
    })
    const arraysAreEqual = compareArrayOfStrings(savedColumnIds || [], currentColumnIds || [])
    if (arraysAreEqual) {
      setUpdateSavedColumnButtonIsActive(false)
    } else {
      setUpdateSavedColumnButtonIsActive(true)
    }
  }, [fieldsLoading, savedColumns, selectedColumns])

  // either show saved columns or show search columns. column icon to show saved, save icon to update? magnifying glass for query columns
  // only give option to include saved columns?
  // only toggle between saved or query columns, no combining?

  return (
    <>
      {showSavedColumnButtonIsActive && savedColumns?.length && (
        <WithTooltip content="Show saved columns">
          <Button
            icon={<AiOutlineEye />}
            onClick={() => {
              showSavedColumns(dispatch, savedColumns || [], selectedColumns || [])
            }}
            style={{ marginRight: '8px' }}
          />
        </WithTooltip>
      )}
      {hideSavedColumnButtonIsActive && savedColumns?.length && (
        <WithTooltip content="Hide saved columns">
          <Button
            icon={<AiOutlineEyeInvisible />}
            onClick={() => {
              resetToQueryColumns(dispatch, temporaryColumns || undefined)
            }}
            style={{ marginRight: '8px' }}
          />
        </WithTooltip>
      )}
      {updateSavedColumnButtonIsActive && (
        <WithTooltip content="Update saved columns">
          <Button
            icon={<MdOutlineSystemSecurityUpdateGood />}
            onClick={() => {
              dispatch({ type: 'SAVE_SELECTED_COLUMNS', selectedColumns })
            }}
            style={{ marginRight: '8px' }}
          />
        </WithTooltip>
      )}
    </>
  )
}
