import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default ({ id, isResizing, width }: { id: string; isResizing: boolean; width?: number | string }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (isResizing && id) {
      const key = `columnWidths__${pathname}` // append pathname to separate columns width same id on different pages
      const prev = getStickyColumnWidths(pathname)
      localStorage.setItem(
        key,
        JSON.stringify({
          ...prev,
          [id]: width
        })
      )
    }
  }, [width, id, isResizing, pathname])
}

// Helper so these can be retrieved separately
export function getStickyColumnWidths(pathname: string) {
  const key = `columnWidths__${pathname}` // append pathname to separate columns width same id on different pages
  return JSON.parse(localStorage.getItem(key) || '{}')
}
