import { getFieldTypeLabel } from '../../../../../../utils/cci'
import { useCciMainContext } from '../../../../CCI_Main'
import React from 'react'
import css from './style.module.scss'

export default function ViewField() {
  const { selectedItem } = useCciMainContext()

  return (
    <>
      {selectedItem ? (
        <div className={css.editField}>
          <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{selectedItem?.name || 'View Field'}</h3>
          {selectedItem?.description && (
            <div className={css.reviewDiv}>
              <h4 style={{ alignSelf: 'baseline' }}>{`Description:`}</h4>
              <p>{selectedItem?.description}</p>
            </div>
          )}
          {selectedItem?.group && (
            <div className={css.reviewDiv}>
              <h4>{`Group:`}</h4>
              <p>{selectedItem?.group}</p>
            </div>
          )}
          {selectedItem?.source && (
            <div className={css.reviewDiv}>
              <h4>{`Source:`}</h4>
              <p>{selectedItem?.source}</p>
            </div>
          )}
          {/* {selectedItem?.neutral_tag && <div className={css.reviewDiv}><h4>{`Neutral Tag:`}</h4><p>{selectedItem?.neutral_tag}</p></div>} */}
          {selectedItem?.field_type && (
            <div className={css.reviewDiv}>
              <h4>{`Field Type:`}</h4>
              <p>{getFieldTypeLabel(selectedItem.field_type)}</p>
            </div>
          )}
          {selectedItem?.options?.length > 0 && (
            <div className={css.reviewDiv}>
              <h4>{`Options:`}</h4>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {selectedItem?.options?.map((option: any, index: number) => {
                  return (
                    <div className={css.reviewOptions} key={option + index}>
                      <p style={{ width: '100%' }}>{option}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {selectedItem?.value_format && (
            <div className={css.reviewDiv}>
              <h4>{`Value Format:`}</h4>
              <p>{selectedItem?.value_format}</p>
            </div>
          )}
          {selectedItem?.default_value && (
            <div className={css.reviewDiv}>
              <h4>{`Default Value:`}</h4>
              <p>{selectedItem?.default_value}</p>
            </div>
          )}
          {selectedItem?.collision_type && (
            <div className={css.reviewDiv}>
              <h4>{`Collision Type:`}</h4>
              <p>{selectedItem?.collision_type}</p>
            </div>
          )}
          {selectedItem?.resolution_strategy && (
            <div className={css.reviewDiv}>
              <h4>{`Resolution Strategy:`}</h4>
              <p>{selectedItem?.resolution_strategy}</p>
            </div>
          )}
          <div className={css.reviewDiv}>
            <h4>{`Display If Empty:`}</h4>
            <p>{selectedItem?.display_if_empty ? 'True' : 'False'}</p>
          </div>
          <div className={css.reviewDiv}>
            <h4>{`Display On Document:`}</h4>
            <p>{selectedItem?.display_on_document ? 'True' : 'False'}</p>
          </div>
          <div className={css.reviewDiv}>
            <h4>{`Display Accounting Impact:`}</h4>
            <p>{selectedItem?.display_accounting_impact ? 'True' : 'False'}</p>
          </div>
          <div className={css.reviewDiv}>
            <h4>{`Default Field On Dashboard:`}</h4>
            <p>{selectedItem?.default_field_on_dashboard ? 'True' : 'False'}</p>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{`Select an item from the checklist to view`}</p>
      )}
    </>
  )
}
