import { AdminOnlyRoute, AuthRoute, ProtectedRoute } from '../utils/authRouteUtils'
import { CciAccessControlPage } from '../new/pages/CciAccessControl'
import { Route, Switch } from 'react-router-dom'
import Admin from '../pages/Admin'
import Analytics from '../pages/Analytics'
import CCI_Main from '../pages/CCI'
import ContactUs from '../pages/ContactUs'
import Dashboard from '../pages/Dashboard'
import DealPage from '../pages/DealPage'
import DocumentPage from '../pages/DocumentPage'
import Layout from '../components/Layout'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import NotFoundPage from '../pages/NotFoundPage'
import React from 'react'
import useUserAccess from '../hooks/useUserAccess'

// components

export function Router() {
  const hasAttachmentAccess = useUserAccess({ feature: 'AttachmentsDashboard', permission: 'READ' })
  const hasCCIAccess = useUserAccess({ feature: 'CCI', permission: 'READ' })

  return (
    <Switch>
      <Route exact path="/logout">
        <Logout />
      </Route>
      {/* Redirect to '/' if user is logged in */}
      <AuthRoute exact path="/login">
        <Login />
      </AuthRoute>
      <AuthRoute exact path="/authorization-code/callback">
        <Login />
      </AuthRoute>

      {/* Redirect to '/login' without current user */}
      <ProtectedRoute exact path="/analytics">
        <Layout>
          <Analytics />
        </Layout>
      </ProtectedRoute>
      {/* The route '/' is used for deals for deal-level customers, documents for document-level customers.
        Dashboard/documents is only for deal customers that also have access to the documents list. */}
      <ProtectedRoute exact path={['/', '/dashboard/documents', '/dashboard/deals', hasAttachmentAccess ? '/dashboard/attachments' : '', `/dashboard/search`]}>
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/documents/:documentId">
        <Layout>
          <DocumentPage />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/deals/:dealId">
        <Layout>
          <DealPage />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/config/access_control">{hasCCIAccess && <CciAccessControlPage />}</ProtectedRoute>
      <ProtectedRoute path={['/config/:tab', '/config']}>
        {hasCCIAccess && (
          <Layout>
            <CCI_Main />
          </Layout>
        )}
      </ProtectedRoute>
      <ProtectedRoute path="/downloads/:download_artifact_uuid/:file_name">
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>
      <ProtectedRoute path="/preview/:previewId">
        <Layout>
          <Dashboard />
        </Layout>
      </ProtectedRoute>

      {/* Only accessible if current user is a super admin */}
      <AdminOnlyRoute path="/admin">
        <Admin />
      </AdminOnlyRoute>

      {/* Accessible regardless of session */}
      <Route exact path="/contact-us">
        <Layout>
          <ContactUs />
        </Layout>
      </Route>
      <Route path="*">
        <Layout>
          <NotFoundPage />
        </Layout>
      </Route>
    </Switch>
  )
}
