import { useAnnotationTagsetQuery } from '../../graphql/codegen/hooks'
import React, { useMemo } from 'react'
import SelectInput from '../SelectInput'

export default function DataPointSelector({ documentId, onChange }: any) {
  const { data, loading } = useAnnotationTagsetQuery({
    context: { queryName: `DataPointSelector.tsx useAnnotationTagset` },
    variables: { documentId }
  })

  const options = useMemo(() => {
    if (!data?.annotation_tagset) return []

    return data.annotation_tagset.map(tag => {
      return { label: tag?.display_name, value: tag?.internal_name }
    })
  }, [data])

  return (
    <div style={{ width: 300 }}>
      <SelectInput isLoading={loading} onChange={onChange} options={options} />
    </div>
  )
}
