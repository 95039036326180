import { ValueEditorProps } from 'react-querybuilder'
import DatePicker from '../DatePicker'
import React, { SyntheticEvent } from 'react'
import SelectInput from '../SelectInput'
import css from './style.module.scss'

type BaseObjectType = { id: string }
type UserOption = BaseObjectType & { user_name: string }
type GenericOption = BaseObjectType & { name: string }
type QueryDropDownOption = string | GenericOption | UserOption

export default function valueEditor({ field, fieldData, handleOnChange, operator, value }: ValueEditorProps) {
  if (fieldData?.filter_type === 'select' && fieldData.data_type !== 'MULTI_SELECT_DROP_DOWN' && operator === '=') {
    return (
      <span className="fieldSelector">
        <SelectInput
          onChange={handleOnChange}
          options={fieldData?.valueOptions?.map((opt: string) => {
            return { isDropDown: true, value: opt, label: opt }
          })}
          placeholder="select"
          value={value}
        />
      </span>
    )
  }

  if ((fieldData?.data_type === 'DROP_DOWN' || fieldData?.data_type === 'MULTI_SELECT_DROP_DOWN') && ['in', 'notIn'].includes(operator)) {
    return (
      <span className="fieldSelector">
        <SelectInput
          isMulti
          onChange={handleOnChange}
          options={fieldData?.valueOptions?.map((opt: string) => {
            return { isMultiSelectDropDown: true, value: opt, label: opt }
          })}
          placeholder="select"
          value={value}
        />
      </span>
    )
  }

  switch (operator) {
    case 'contains':
    case 'doesNotContain':
      return (
        <input
          key={field}
          onChange={e => {
            e.preventDefault()
            handleOnChange(e.currentTarget.value)
          }}
          type="text"
          value={value}
        />
      )

    case 'in':
    case 'notIn':
      return (
        <span className="fieldSelector">
          <SelectInput
            isMulti
            onChange={handleOnChange}
            options={fieldData?.valueOptions.map((opt: QueryDropDownOption) =>
              typeof opt === 'string'
                ? { value: opt, label: opt }
                : typeof opt === 'object'
                ? { ...opt, value: opt.id, label: 'name' in opt ? opt.name : opt.user_name }
                : undefined
            )}
            placeholder="select"
            value={value}
          />
        </span>
      )

    case '<':
    case '<=':
    case '=':
    case '>=':
    case '>':
    case '!=':
      if (fieldData?.filter_type === 'date') {
        return (
          <DatePicker
            onChange={datestring => {
              handleOnChange(datestring)
            }}
            selected={value}
          />
        )
      }
      return (
        <input
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            handleOnChange(e.currentTarget.value)
          }}
          type={fieldData?.filter_type === 'string' ? 'text' : 'number'}
          value={value}
        />
      )

    case 'between':
    case 'notBetween':
      if (fieldData?.filter_type === 'double') {
        const [firstVal, secondVal] = value || ['', '']
        return (
          <div className={css.betweenValues} key="between-num">
            <input
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                handleOnChange([e.currentTarget.value, secondVal])
              }}
              type="number"
              value={firstVal}
            />
            <span>and</span>
            <input
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                handleOnChange([firstVal, e.currentTarget.value])
              }}
              type="number"
              value={secondVal}
            />
          </div>
        )
      } else if (fieldData?.filter_type === 'date') {
        const [firstVal, secondVal] = value || ['', '']
        return (
          <div className={css.betweenValues} key="between-date">
            <DatePicker
              onChange={datestring => {
                handleOnChange([datestring, secondVal])
              }}
              selected={firstVal}
            />
            <span>and</span>
            <DatePicker
              onChange={datestring => {
                handleOnChange([firstVal, datestring])
              }}
              selected={secondVal}
            />
          </div>
        )
      }
      return null

    case 'null':
    case 'notNull':
    default:
      return null
  }
}
