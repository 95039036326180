import { AiOutlineTable } from 'react-icons/ai'
import { DataTableContext } from '../DocumentPageWrapper'
import Button from '../Button'
import React, { useContext } from 'react'
import type { DataPoint } from '../../graphql/codegen/schemas'

export default function DataTableIcon({ dataPoint }: { dataPoint: DataPoint }) {
  const { setActiveTableId } = useContext(DataTableContext)

  return (
    <Button
      icon={<AiOutlineTable />}
      onClick={() => {
        setActiveTableId(dataPoint.resolved_data_points?.edges?.[0]?.node?.id || dataPoint.id)
      }}
      style={{ marginLeft: 8 }}
    />
  )
}
