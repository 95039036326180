import { CurrentRuntimeEnvironment } from '../utils/envUtils'

export const API_ROOT = CurrentRuntimeEnvironment.REACT_APP_API_ROOT
// Kibana auth redirect is proxied by nginx
export const ANALYTICS_URL = CurrentRuntimeEnvironment.REACT_APP_ANALYTICS_URL
// Okta
const OKTA_URL = CurrentRuntimeEnvironment.REACT_APP_OKTA_URL
const OKTA_CLIENT_ID = CurrentRuntimeEnvironment.REACT_APP_OKTA_CLIENT_ID
export const OKTA_LOGIN_REDIRECT_PATH = '/login/callback'
const OKTA_LOGOUT_REDIRECT_PATH = '/logout'

export const OKTA_CONFIG = {
  pkce: false,
  url: OKTA_URL,
  issuer: `${OKTA_URL}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}${OKTA_LOGIN_REDIRECT_PATH}`,
  postLogoutRedirectUri: `${window.location.origin}${OKTA_LOGOUT_REDIRECT_PATH}`,
  scopes: ['openid', 'profile', 'email'],

  // Override the default authorize and userinfo URLs
  authorizeUrl: `${OKTA_URL}/oauth2/default/v1/authorize`,
  tokenUrl: `${OKTA_URL}/oauth2/default/v1/token`,
  userinfoUrl: `${OKTA_URL}/oauth2/default/v1/userinfo`,
  responseType: 'id_token',
  responseMode: 'fragment',

  tokenManager: { storage: 'localStorage', autoRenew: true, expireEarlySeconds: 120 }
}
