import { ChevronLeft, ChevronRight } from 'react-feather'
import React, { useEffect } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

export default function Pagination({
  currentPage,
  dummyDataActive,
  jumpToPage,
  nextPage,
  pageCount,
  pageLength,
  prevPage,
  rowCount,
  setPageLength
}: {
  currentPage?: number
  dummyDataActive?: boolean
  jumpToPage?: (p: number) => void
  nextPage?: () => void
  pageCount?: number
  pageLength?: number
  prevPage?: () => void
  rowCount?: number
  setPageLength?: (pl: number) => void
}) {
  const pages = Array.from(new Array(pageCount)).map((_, index) => ++index)
  const pageNumbers = getPageNumbers(pages, currentPage)

  const pageLengths = [10, 25, 50]
  const pageLengthOptions = pageLengths.filter((_, index) => {
    return !pageLengths[index - 1] || (rowCount || 0) > pageLengths[index - 1]
  })

  const handlePageLength = (newPageLength: number) => {
    setPageLength && setPageLength(newPageLength)
    localStorage.setItem('table_pageLength_preference', newPageLength.toString())
  }

  useEffect(() => {
    if (setPageLength && rowCount && !dummyDataActive) {
      if (!localStorage.getItem('table_pageLength_preference')) {
        setPageLength(10)
      } else if (pageLengthOptions?.includes(Number(localStorage.getItem('table_pageLength_preference')))) {
        setPageLength(Number(localStorage.getItem('table_pageLength_preference'))) // use user's preference if available
      } else if (pageLengthOptions?.length > 0) {
        setPageLength(Math.max(...pageLengthOptions))
      } // set to the next highest length
      else {
        setPageLength(10)
      }
    }
    // eslint-disable-next-line
  }, [pageLengthOptions])

  if (!currentPage || !pageCount || !rowCount) {
    return null
  }

  return (
    <div className={css.pagination}>
      {pageLengthOptions.length > 0 && (
        <div className={css.pageLengthInput}>
          <span>{`View`}</span>
          {pageLengthOptions.map((pl: number) => {
            return (
              <button
                className={clsx(pageLength === pl && css.selected)}
                key={String(pl)}
                onClick={() => {
                  handlePageLength(pl)
                }}
              >
                {String(Math.min(pl, rowCount || pl))}
              </button>
            )
          })}
          {!!rowCount && <span>{`of ${rowCount} results`}</span>}
        </div>
      )}
      <div className={css.pageNumberInput}>
        <button onClick={prevPage} style={{ visibility: currentPage > 1 ? 'visible' : 'hidden' }}>
          <ChevronLeft />
        </button>
        {jumpToPage &&
          pageNumbers.length > 1 &&
          pageNumbers.map(n => {
            return (
              <button className={clsx(currentPage === n && css.selected)} key={n} onClick={() => jumpToPage(n)}>
                {n}
              </button>
            )
          })}
        <button onClick={nextPage} style={{ visibility: currentPage < pageCount ? 'visible' : 'hidden' }}>
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}

const getPageNumbers = (pages: number[], currentPage: number = 1, sliceLength: number = 5): number[] => {
  if (pages.length <= sliceLength) {
    return pages.slice()
  }
  // Ensure an even number for the sides as we’re looking to have a odd center in the slice
  const half = Math.floor(sliceLength / 2)
  // Convert to 0 based
  const index = --currentPage
  let start = index - half
  let end = index + half
  // Offset start when necessary
  if (start < 0) {
    end += 0 - start
    start = 0
  }
  // Offset end when necessary
  const lastIndex = pages.length - 1
  if (end > lastIndex) {
    start -= end - lastIndex
    end = lastIndex
  }
  // End must be inclusive
  return pages.slice(start, ++end)
}
