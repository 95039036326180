import { useLocation } from 'react-router-dom'
import useIsRapid7LegalTeam from './useIsRapid7LegalTeam'
import useUserAccess, { useIsAnnotator } from './useUserAccess'

export default function usePrevQueryType() {
  const location = useLocation()
  const isAnnotator = useIsAnnotator()
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })

  const getPreviousQueryType = () => {
    if (isAnnotator) {
      if (location?.pathname?.includes('/dashboard/deals')) {
        return 'PREVIOUS_DEAL_QUERY'
      } else if (location?.pathname?.includes('/dashboard/documents')) {
        return 'PREVIOUS_DOCUMENT_QUERY'
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return 'PREVIOUS_ATTACHMENT_QUERY'
      }
    } else if (isRapid7LegalTeam) {
      if (location?.pathname?.includes('/dashboard/deals')) {
        return 'PREVIOUS_DEAL_QUERY'
      } else if (location?.pathname === '/') {
        return 'PREVIOUS_DOCUMENT_QUERY'
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return 'PREVIOUS_ATTACHMENT_QUERY'
      }
    } else {
      if (location?.pathname === '/') {
        return hasDealAccess ? 'PREVIOUS_DEAL_QUERY' : 'PREVIOUS_DOCUMENT_QUERY'
      } else if (location?.pathname?.includes('/dashboard/documents')) {
        return 'PREVIOUS_DOCUMENT_QUERY'
      } else if (location?.pathname?.includes('/dashboard/attachments')) {
        return 'PREVIOUS_ATTACHMENT_QUERY'
      } else {
        return ''
      }
    }
  }

  const prevQueryType = getPreviousQueryType()

  return prevQueryType || null
}
