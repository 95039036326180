import { ChevronDown } from 'react-feather'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { formatKey } from '../../../../../../utils/stringUtils'
import CCI_LeftPanel_ChecklistCard from './CCI_LeftPanel_ChecklistCard'
import Collapse, { Panel } from 'rc-collapse'
import DraggableWrapper from './DraggableWrapper'
import React, { useEffect, useState } from 'react'
import useUserAccess from '../../../../../../hooks/useUserAccess'

export default function SortablePanel({
  activeKeys,
  disableCollapse,
  id,
  isReorderingEnabled = true,
  listItems,
  setActiveKeys
}: {
  activeKeys: any
  disableCollapse: boolean
  id: any
  isReorderingEnabled?: boolean
  listItems: any
  setActiveKeys: any
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [cardIds, setCardIds] = useState<any>([])
  const isReorderingAllowed = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'REORDER' })
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled: isOpen || !isReorderingAllowed || !isReorderingEnabled })

  const style = { transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined, transition: transition || '' }

  const handleChange = (e: any) => {
    if (!disableCollapse) {
      if (e?.includes(formatKey(id))) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
      setActiveKeys(e)
    }
  }

  useEffect(() => {
    setCardIds(
      listItems?.map((dataPointField: any) => {
        return dataPointField?.id
      }) || []
    )
    // eslint-disable-next-line
    }, [listItems])

  if (!listItems) return null

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Collapse
        activeKey={activeKeys}
        expandIcon={({ isActive }: { isActive: boolean }) => <ChevronDown style={{ transform: `rotate(${isActive ? 180 : 0}deg)` }} />}
        onChange={handleChange}
      >
        <Panel
          header={
            <div className="groupHeader">
              <h3>{id}</h3>
            </div>
          }
          key={formatKey(id)}
        >
          <ul>
            <SortableContext id={id} items={cardIds} strategy={verticalListSortingStrategy}>
              {listItems?.map((dataPointField: any) => (
                <DraggableWrapper canReorderItems={isReorderingAllowed && isReorderingEnabled} id={dataPointField?.id} key={id + dataPointField?.id}>
                  <CCI_LeftPanel_ChecklistCard dataPointField={dataPointField} />
                </DraggableWrapper>
              ))}
            </SortableContext>
          </ul>
        </Panel>
      </Collapse>
    </div>
  )
}
