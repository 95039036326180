import { NavLink } from 'react-router-dom'
import { logout } from '../../utils/sessionApiUtils'
import MoreMenu from '../../components/MoreMenu'
import React from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo.png'
import useCurrentUser from '../../hooks/useCurrentUser'
import useUserAccess from '../../hooks/useUserAccess'

// constants

const IMAGES = require.context('../../assets/customer-logos/', false, /\.(png|jpe?g|svg)$/)

const LOGO_MAP = IMAGES.keys().reduce((previous: { [k: string]: any }, current) => {
  const companyName = current.match(/.\/(.+)\.(png|jpe?g|svg)$/)?.[1]

  return companyName ? { ...previous, [companyName]: IMAGES(current) } : previous
}, {})

// components

export const Navbar = ({ pathname }: { pathname: string }) => {
  const currentUser = useCurrentUser()
  const hasAnalyticsAccess = useUserAccess({ feature: 'Analytics', permission: 'READ' })
  const hasCCIAccess = useUserAccess({ feature: 'CCI', permission: 'READ' })
  const currentCustomerId = localStorage.getItem('cid')
  const currentCustomerName = currentUser?.customers?.edges?.find(customerEdge => customerEdge?.node?.id === currentCustomerId)?.node?.name
  const customerLogoKey = currentCustomerName && currentCustomerName.toLowerCase().split(' ').join('_')
  const customerLogo = customerLogoKey && LOGO_MAP[customerLogoKey]

  return (
    <>
      {currentUser?.is_super_admin && (
        <div className={css.superAdminHeader}>
          <span className={css.superAdminDescription}>
            Admin mode for customer: <span className={css.superAdminCustomerName}>{currentCustomerName}</span>
          </span>
        </div>
      )}
      <div className={css.nav}>
        <ul className={css.navMenu}>
          <li>
            <img alt="Klarity Logo" className={css.logo} src={logo} />
          </li>
          <li>
            {currentUser ? (
              <NavLink
                activeClassName={css.active}
                className={css.navLink}
                exact
                isActive={() => pathname === '/' || pathname.indexOf('/dash') === 0}
                to="/"
              >{`Dashboard`}</NavLink>
            ) : (
              <NavLink className={css.navLink} exact to="/login">{`Login`}</NavLink>
            )}
          </li>
          {hasAnalyticsAccess && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/analytics">
                Analytics
              </NavLink>
            </li>
          )}
          {hasCCIAccess && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/config/">{`Configuration`}</NavLink>
            </li>
          )}
          {currentUser?.is_super_admin && (
            <li>
              <NavLink activeClassName={css.active} className={css.navLink} to="/admin">
                Admin
              </NavLink>
            </li>
          )}
        </ul>
        <ul className={css.navMenu}>
          {currentUser && (
            <li>
              <MoreMenu label={currentUser.first_name} menuItems={[{ label: 'Log out', onClick: logout }]} showOptionsOnMouseHover />
            </li>
          )}
          {customerLogo && (
            <li>
              <img className={css.customerLogo} src={customerLogo} />
            </li>
          )}
        </ul>
      </div>
    </>
  )
}
