import { Search } from 'react-feather'
import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

interface InputProps {
  autoComplete?: string
  isDisabled?: boolean
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: string
  value?: string
  variant?: string
}

// components

const Input = ({ isDisabled = false, type = `text`, variant, ...rest }: InputProps) => {
  const isSearch = variant === `search`

  return (
    <div className={css.wrapper}>
      {isSearch && <Search className={css.icon} />}

      <input className={clsx(css.input, isSearch && css.hasIcon)} disabled={isDisabled} type={type} {...rest} />
    </div>
  )
}

export default Input
