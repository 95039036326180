import { AiOutlineHistory } from 'react-icons/ai'
import { DataPoint } from '../../../graphql/codegen/schemas'
import ChangeLogItem from './ChangeLogItem'
import CommentContainer from '../../../containers/CommentContainer'
import React from 'react'
import css from './style.module.scss'

export default function AuditSection({ dataPoint, dealIsFinalized }: { dataPoint: DataPoint; dealIsFinalized: boolean }) {
  const comments = dataPoint.comments || []
  const changeLog = dataPoint.change_log || []
  const sortedItems = [...changeLog, ...comments].sort((a, b) => {
    return new Date(a?.created_at).valueOf() - new Date(b?.created_at).valueOf()
  })

  return (
    <div className={css.auditSection}>
      <div className={css.header}>
        <AiOutlineHistory />
        <h4>{`History`}</h4>
      </div>
      <div className={css.auditTrail}>
        {sortedItems.length ? (
          sortedItems.map(item => {
            if (item?.__typename === 'Comment') {
              return (
                <CommentContainer
                  comment={item}
                  dataPointId={dataPoint.id}
                  dealIsFinalized={dealIsFinalized}
                  key={item?.id}
                  renderTopRow={(author: string, timeAgo: string) => {
                    return <span className={css.commentTopRow}>{`${author} commented ${timeAgo} ago`}</span>
                  }}
                />
              )
            } else if (item?.__typename === 'ChangeLog') {
              return <ChangeLogItem changeLogItem={item} key={item?.id} />
            } else {
              return null
            }
          })
        ) : (
          <div className={css.empty}>{`Nothing to see here...`}</div>
        )}
      </div>
    </div>
  )
}
