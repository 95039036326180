import 'tippy.js/dist/tippy.css'
import { DollarSign } from 'react-feather'
import React, { useCallback } from 'react'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from './style.module.scss'
import type { DataPoint } from '../../graphql/codegen/schemas'

export default function AccountingImpactButton({ dataPoint, dealIsFinalized, mutation }: { dataPoint: DataPoint; dealIsFinalized?: boolean; mutation: any }) {
  const { has_accounting_impact, id } = dataPoint

  const handleSave = useCallback(() => {
    let nextState
    if (has_accounting_impact === null) {
      nextState = true
    } else if (has_accounting_impact === true) {
      nextState = false
    }
    mutation({ variables: { dataPointId: id, accountingImpact: nextState } })
  }, [has_accounting_impact, id, mutation])

  return (
    <>
      {(dealIsFinalized ? has_accounting_impact || has_accounting_impact === false : true) && (
        <Tippy
          content={
            dealIsFinalized
              ? `This field ${has_accounting_impact ? 'has' : 'does not have'} accounting impact.`
              : 'Click once to indicate presence of accounting impact (red). Click again to indicate no accounting impact (green).'
          }
        >
          <DollarSign
            className={clsx(css.accountingImpactIcon, { [css.noImpact]: has_accounting_impact === false, [css.withImpact]: has_accounting_impact === true })}
            onClick={dealIsFinalized ? undefined : handleSave}
            style={dealIsFinalized ? { cursor: 'default' } : {}}
          />
        </Tippy>
      )}
    </>
  )
}
