import 'tippy.js/dist/tippy.css'
import { BiArrowToLeft } from 'react-icons/bi'
import { DataPoint } from '../../graphql/codegen/schemas'
import { useDataPointDetailQuery } from '../../graphql/codegen/hooks'
import { useHistory, useParams } from 'react-router-dom'
import AuditSection from './AuditSection'
import ChecklistSkeleton from '../DataPointPanel/ChecklistSkeleton'
import CommentEditor from './CommentEditor'
import DataPointCollisionForm from './DataPointCollisionForm'
import InputRow from '../DatapointField/InputRow'
import RSkeleton from 'react-loading-skeleton'
import React from 'react'
import Tippy from '@tippyjs/react'
import css from './style.module.scss'

export default function DataPointOverlay({ dealIsFinalized }: { dealIsFinalized: boolean }) {
  const history = useHistory()
  const { dataPointId, dealId, documentId } = useParams<{ dataPointId: string; dealId?: string; documentId?: string }>()
  const { data, loading } = useDataPointDetailQuery({ variables: { id: dataPointId }, context: { queryName: `DataPointOverlay.tsx useDataPointDetailQuery` } })
  const dataPoint = data?.data_points?.edges[0]?.node
  const { description, name } = dataPoint?.data_point_field || {}
  const hasChildren = dataPoint?.document_data_points?.edges.length

  return (
    <div className={css.overlay}>
      <div className={css.topBar}>
        <h3>{loading ? <RSkeleton width={300} /> : name}</h3>
        <span
          className={css.backLink}
          onClick={() => history.push(`${dealId ? `/deals/${dealId}` : `/documents/${documentId}`}/checklist${history.location.search}`)}
        >
          <Tippy content={<span className={css.tippyText}>{`Return to checklist`}</span>}>
            <div style={{ display: 'flex' }}>
              <BiArrowToLeft />
            </div>
          </Tippy>
        </span>
      </div>
      <div className={css.scrollBodyWrapper}>
        <div className={css.scrollBody}>
          {description && (
            <div className={css.description}>
              <p>{description}</p>
            </div>
          )}
          <div className={css.formSection}>
            {loading ? (
              <ChecklistSkeleton />
            ) : !dealIsFinalized && hasChildren ? (
              <DataPointCollisionForm dataPoint={dataPoint as DataPoint} dealIsFinalized={dealIsFinalized} />
            ) : (
              <div className={css.singleInputWrapper}>
                <InputRow dataPoint={dataPoint as DataPoint} dealIsFinalized={dealIsFinalized} />
              </div>
            )}
          </div>
          {!loading && <AuditSection dataPoint={dataPoint as DataPoint} dealIsFinalized={dealIsFinalized} />}
        </div>
      </div>
      {!dealIsFinalized && <CommentEditor />}
    </div>
  )
}
