import { useDealsLazyQuery, useStatesLazyQuery } from '../graphql/codegen/hooks'
import { useEffect, useState } from 'react'

// Fetch current state,
// if that state belongs to a linear workflow, present transition buttons
// if non_linear, present list of all states
export default function useDealTransitions(dealId?: string) {
  const [currentState, setCurrentState] = useState<any>(undefined)
  const [getDealData, { data: dealData, loading: dealDataLoading }] = useDealsLazyQuery({ context: { queryName: `useDealTransitions.ts useDealsLazyQuery` } })
  const [fetchStates, { data: stateData, loading: statesLoading }] = useStatesLazyQuery({
    context: { queryName: `useDealTransitions.tsx fetchStates` },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (dealId) {
      getDealData({ variables: { id: dealId } })
    }
    // eslint-disable-next-line
  }, [dealId])

  useEffect(() => {
    if (dealData) {
      setCurrentState(dealData?.deals?.edges?.[0]?.node?.state)
    }
  }, [dealData])

  useEffect(() => {
    if (currentState?.id) {
      fetchStates({ variables: { workflowId: currentState.workflow?.id } })
    }
    // eslint-disable-next-line
  }, [currentState]);

  return {
    currentState,
    isNonLinear: currentState?.workflow?.is_non_linear,
    loading: dealDataLoading || statesLoading,
    data: { states: stateData?.states }
  }
}
