import { Comment as CommentType } from '../../graphql/codegen/schemas'
import {
  CommentsDataDocument,
  DataPointsDocument,
  NotesTabCountDocument,
  useDeleteCommentMutation,
  useEditCommentTextMutation,
  useMarkCommentAsPermanentMutation,
  useMarkCommentAsResolvedMutation,
  usePinCommentMutation
} from '../../graphql/codegen/hooks'
import { FiCheckCircle, FiLock } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import Comment from '../../components/Comment'
import Loader from '../../components/Loader'
import MoreMenu from '../../components/MoreMenu'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../../components/WithTooltip'
import useCurrentUser from '../../hooks/useCurrentUser'
// import { formatTimeAgo } from '../../utils/datetimeUtils';
import css from './style.module.scss'

interface Props {
  [x: string]: any
  comment: CommentType
  dataPointId: string
  dealIsFinalized?: boolean
}

export default function CommentContainer({ comment, dataPointId, dealIsFinalized, ...rest }: Props) {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const { created_by, id, type } = comment
  const [isEditing, setIsEditing] = useState(false)
  const [menuItems, setMenuItems] = useState<any>([])
  const currentUser = useCurrentUser()
  const isMine = currentUser?.id === created_by?.id

  const refetchConfig = [
    { query: CommentsDataDocument, variables: { resourceId: dealId || documentId! } },
    { query: DataPointsDocument, variables: { id: dataPointId } },
    { query: NotesTabCountDocument, variables: { resourceId: dealId || documentId! } }
  ]

  const [deleteMutation, { loading: deleteMutationLoading }] = useDeleteCommentMutation({
    context: { queryName: `CommentContainer.tsx deleteMutation` },
    refetchQueries: refetchConfig,
    awaitRefetchQueries: true,
    variables: { commentId: id }
  })
  const [markPermanentMutation, { loading: permanentMutationLoading }] = useMarkCommentAsPermanentMutation({
    context: { queryName: `CommentContainer.tsx markPermanentMutation` },
    refetchQueries: refetchConfig,
    awaitRefetchQueries: true
  })
  const [markResolvedMutation, { loading: resolvedMutationLoading }] = useMarkCommentAsResolvedMutation({
    context: { queryName: `CommentContainer.tsx markResolvedMutation` },
    refetchQueries: refetchConfig,
    awaitRefetchQueries: true
  })
  const [editMutation, editState] = useEditCommentTextMutation({
    context: { queryName: `CommentContainer.tsx editMutation` },
    onCompleted: () => setIsEditing(false),
    refetchQueries: refetchConfig,
    awaitRefetchQueries: true
  })
  const [pinMutation, { loading: pinMutationLoading }] = usePinCommentMutation({
    context: { queryName: `CommentContainer.tsx pinMutation` },
    refetchQueries: refetchConfig,
    awaitRefetchQueries: true
  })

  const handleSave = (text: string) => {
    editMutation({ variables: { commentId: id, text } })
  }

  const getMenuItems = () => {
    const items: any = [
      { label: 'Resolve', onClick: () => markResolvedMutation({ variables: { commentId: id } }) },
      { label: 'Make Permanent', onClick: () => markPermanentMutation({ variables: { commentId: id } }) }
    ]
    if (!comment?.is_pinned) {
      items.push({ label: 'Pin', onClick: () => pinMutation({ variables: { commentId: id, pin_type: 'PIN' } }) })
    }
    if (comment?.is_pinned) {
      items.push({ label: 'Unpin', onClick: () => pinMutation({ variables: { commentId: id, pin_type: 'UNPIN' } }) })
    }
    if (isMine || type === 'SHARED') {
      items.push({ label: 'Edit', onClick: () => setIsEditing(true) })
      if (!comment.is_pinned) {
        items.push({ label: 'Delete', onClick: () => deleteMutation() })
      }
    }
    setMenuItems([...items])
  }

  // eslint-disable-next-line
  useEffect(() => { getMenuItems(); }, [comment])

  const showOptions = !dealIsFinalized && type !== 'RESOLVED' && type !== 'PERMANENT'

  const renderActions = () => {
    if (type && ['RESOLVED', 'PERMANENT'].includes(type)) {
      return (
        <WithTooltip content={`Marked as ${type.toLowerCase()}.`}>
          {/* <WithTooltip content={`Marked as ${type.toLowerCase()}${last_updated_by?.user_name && ` by ${last_updated_by.user_name}`} ${formatTimeAgo(last_updated_at)} ago`}> */}
          <div className={css.lockedIcon}>{type === 'RESOLVED' ? <FiCheckCircle /> : <FiLock />}</div>
        </WithTooltip>
      )
    }
    if (deleteMutationLoading || editState.loading || permanentMutationLoading || resolvedMutationLoading || pinMutationLoading) {
      return (
        <div>
          <Loader size="s" />
        </div>
      )
    }
    return showOptions && menuItems.length > 0 && <MoreMenu menuItems={menuItems} withIcon />
  }

  const props = { isEditing, setIsEditing, editState, handleSave, renderActions }

  return (
    <Comment
      comment={comment}
      id={comment.id}
      {...props}
      {...rest}
      author={isMine ? 'You' : comment.created_by?.first_name}
      date={comment.last_updated_at || comment.created_at}
      text={comment.text || ''}
    />
  )
}
