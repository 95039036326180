import type { Field } from 'react-querybuilder'

type FieldWithMeta = Field & { [k: string]: any; options: any[]; value: string }
type FieldObject = { [k: string]: FieldWithMeta }

// In the future the operator component in react-querybuilder can be overwritten to do this logic, but for now we can map the names from the backend to names that the querybuilder understands
export const OPERATOR_TYPES: { [k: string]: Field } = {
  contains: { name: 'contains', label: 'contains' },
  not_contains: { name: 'doesNotContain', label: 'does not contain' },
  is_null: { name: 'null', label: 'is null' },
  is_not_null: { name: 'notNull', label: 'is not null' },
  equal: { name: '=', label: '=' },
  not_equal: { name: '!=', label: 'does not equal' },
  less: { name: '<', label: '<' },
  less_or_equal: { name: '<=', label: '<=' },
  greater: { name: '>', label: '>' },
  greater_or_equal: { name: '>=', label: '>=' },
  between: { name: 'between', label: 'is between' },
  not_between: { name: 'notBetween', label: 'is not between' },
  in: { name: 'in', label: 'in' },
  not_in: { name: 'notIn', label: 'is not in' }
}

/**
 * One issue I've seen is that the id's that we are using to request fields are prefixed by ID__ or IDDATE__
 * This function takes a field and returns it's value as expected by mongodb
 */
export const formatDataPointId = (dataPoint: any) => {
  return (dataPoint?.data_type === 'DATE' ? 'IDDATE__' : 'ID__') + dataPoint?.data_point_field_id
}

function formatOperatorsForSelect(operators: string[]) {
  return operators?.map((o: string) => {
    if (!OPERATOR_TYPES[o] && process.env.NODE_ENV === 'development') {
      throw Error(`operator '${o}' is not supported`)
    }
    return OPERATOR_TYPES[o]
  })
}

/**
 * Takes backend response of nested fields, returns list that is formatted for use in the querybuilder and (eventually) the table as well
 * 'name' is what the querybuilder uses as key, 'label' is the display value
 * For non-datapoints, the keys are just the internal_name; for datapoints, it is ID__ + their mongo id; for date, it is IDDATE__ + mongo id.
 */
export function formatFieldList(data: any, excludedFields: any): FieldObject {
  const fields = Object.entries(
    data?.fetch_advanced_dashboard_fields || data?.fetch_advanced_deals_dashboard_fields || data?.fetch_advanced_attachments_dashboard_fields || {}
  ).reduce((acc: { [k: string]: any }, curr: any) => {
    const [key, value] = curr

    if (!value || excludedFields?.includes(key)) {
      return acc
    } // this skips the iteration if the key should be excluded

    // Datapoint fields
    if (key === 'data_points' || key === 'multi_select_data_points') {
      value?.forEach((dataPoint: any) => {
        // Prefixed for mongo query, see above
        const dataPointId = formatDataPointId(dataPoint)
        acc[dataPointId] = {
          default_table_column: dataPoint?.default_table_column,
          data_type: dataPoint?.data_type,
          filter_type: dataPoint?.filter_type,
          filter_allowed: dataPoint?.filter_allowed,
          operators: formatOperatorsForSelect(dataPoint?.operators),
          label: dataPoint?.name,
          name: dataPointId,
          value: dataPointId,
          valueOptions: dataPoint?.options,
          data_point_field_id: dataPoint?.data_point_field_id
        }
      })
    } else {
      // Non-datapoint fields
      const dataPointId = value?.internal_name
      acc[dataPointId] = {
        ...value,
        data_point_field_id: value?.data_point_field_id,
        label: value?.display_name,
        name: dataPointId,
        operators: formatOperatorsForSelect(value?.operators),
        value: dataPointId,
        valueOptions: value?.value
      }
    }

    return acc
  }, {})

  return fields
}
