import { FiAlertTriangle } from 'react-icons/fi'
import { HighlighterHighlight } from './utils'
import Button from '../Button'
import React, { useEffect, useMemo, useState } from 'react'
import Tippy from '@tippyjs/react'

const parseIdFromHash = () => document.location.hash.slice('#highlight-'.length)

export default function AnnotationMissingAlert({
  annotations,
  deleteAnnotation,
  loading
}: {
  annotations: HighlighterHighlight[]
  deleteAnnotation: ((annotId: string) => void) | null
  loading: boolean
}) {
  const [visible, setVisible] = useState(true)
  const show = () => setVisible(true)
  const toggle = () => setVisible(prev => !prev)

  const toAlert = useMemo(() => {
    return annotations.filter(a => a.labelType === 'MISSING_ANNO')
  }, [annotations])

  useEffect(() => {
    // open the alert tooltip if one of its annotations is selected
    const handleHashChange = () => {
      const ids = toAlert.map(annot => annot.id)
      const hashId = parseIdFromHash()
      if (ids.includes(hashId)) {
        show()
      }
    }

    handleHashChange() // handle on initial mount

    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [toAlert])

  const root = document.querySelector('#popper-root')

  return toAlert.length && root ? (
    <Tippy
      appendTo={root}
      content={
        <ul>
          {toAlert.map(missingAnnot => {
            return (
              <li key={missingAnnot.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 0' }}>
                <span>{missingAnnot.comment.text}</span>
                <Button
                  danger
                  loading={loading}
                  onClick={e => {
                    e.preventDefault()
                    deleteAnnotation && deleteAnnotation(missingAnnot.id)
                  }}
                  size="s"
                  style={{ marginLeft: '8px' }}
                >
                  {`Resolve`}
                </Button>
              </li>
            )
          })}
        </ul>
      }
      interactive
      placement="right"
      visible={visible}
    >
      <div onClick={toggle} style={{ position: 'absolute', top: '8px', left: 'px', color: '#fc3535' }}>
        <FiAlertTriangle />
      </div>
    </Tippy>
  ) : null
}
