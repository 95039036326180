import { Annotation } from '../graphql/codegen/schemas'
import { klarityAnnotationToHighlighterFormat } from '../components/PDFHighlighterDoc/utils'
import { useDocumentsQuery } from '../graphql/codegen/hooks'
import { useParams } from 'react-router'

export default () => {
  const { documentId } = useParams<{ documentId?: string }>()
  const { data: documentData } = useDocumentsQuery({
    skip: !documentId,
    variables: { documentId },
    context: { queryName: `useGetAnnotations.tsx useDocumentsQuery` }
  })

  const annotations = documentData?.documents?.edges?.[0]?.node?.annotations?.edges?.map(edge => {
    const annotation = klarityAnnotationToHighlighterFormat(edge?.node as Annotation)
    return annotation
  })

  return annotations
}
