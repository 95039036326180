import {
  DealAttachmentsDocument,
  DocumentAttachmentsDocument,
  useDeleteDealAttachmentMutation,
  useDeleteDocumentAttachmentMutation
} from '../../graphql/codegen/hooks'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import { useHistory, useParams } from 'react-router-dom'
import AttachmentToDocument from '../ModalOptions/AttachmentToDocument'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import MoreMenu from '../MoreMenu'
import React, { useCallback, useEffect, useState } from 'react'
import RenameFile from '../RenameFile'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'
import useUserAccess from '../../hooks/useUserAccess'

const PREVIEWABLE_EXT_REGEX = /^.*\.(jpg|jpeg|gif|png|pdf)$/

export default function Attachment({ attachment, dealIsFinalized }: { attachment: any; dealIsFinalized?: boolean }) {
  const [modalLoading, setModalLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const history = useHistory()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId: string }>()
  const prefix = dealId ? `/deals/${dealId}` : `/documents/${documentId}`
  const currentUser = useCurrentUser()
  const isMine = currentUser?.id === attachment.created_by.id
  const canPreview = attachment.has_pdf_preview || attachment.name.match(PREVIEWABLE_EXT_REGEX) // For now, only files that can render inside an <img/> tag are previewable
  const shouldConvert = /(\.pdf|\.docx)/i.test(attachment?.name) // Bool- Case insensitive regex checking for .pdf or .docx file type.
  const hasDownloadAccess = useUserAccess({ feature: 'Attachment', permission: 'DOWNLOAD' })

  const [deleteDealAttachmentMutation, { loading: deleteDealAttMutationLoading }] = useDeleteDealAttachmentMutation({
    context: { queryName: `Attachment.tsx deleteDealAttachmentMutation` },
    refetchQueries: [{ query: DealAttachmentsDocument, variables: { dealId }, context: { queryName: `Attachment.tsx DealAttachmentsDocument` } }]
  })

  const [deleteDocumentAttachmentMutation, { loading: deleteDocAttMutationLoading }] = useDeleteDocumentAttachmentMutation({
    context: { queryName: `Attachment.tsx deleteDocumentAttachmentMutation` },
    refetchQueries: [{ query: DocumentAttachmentsDocument, variables: { documentId }, context: { queryName: `Attachment.tsx DocumentAttachmentsDocument` } }]
  })

  const openModal = (menuOption: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setIsDisabled(false)
      setModalOption('')
    }
  }

  const handleSubmit = () => {
    if (modalOption === 'Delete Attachment') {
      handleDelete()
    }
    closeModal()
  }

  const handleDownload = useCallback(
    async (attachment: any) => {
      const { data } = await axiosClient.get(`${prefix}/attachments/${attachment.id}`, { responseType: 'blob' })
      const href = window.URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = attachment?.alias || attachment?.name
      document.body.appendChild(a)
      a.click()
    },
    [prefix]
  )

  const handleDelete = async () => {
    if (dealId) {
      deleteDealAttachmentMutation({ variables: { dealId, attachmentId: attachment.id } })
    } else if (documentId) {
      deleteDocumentAttachmentMutation({ variables: { documentId, attachmentId: attachment.id } })
    }
  }

  const getMenuItems = () => {
    const items: any = [
      canPreview && {
        label: 'Preview',
        onClick: () => {
          history.push(`${prefix}/attachments/${attachment.id}`)
        }
      },
      hasDownloadAccess && {
        label: 'Download',
        onClick: () => {
          handleDownload(attachment)
        }
      },
      !dealIsFinalized && { label: 'Rename Attachment', onClick: () => openModal('Rename Attachment') },
      !dealIsFinalized &&
        attachment?.document_id &&
        dealId &&
        shouldConvert && {
          label: 'Convert to Document',
          onClick: () => {
            openModal('Convert Attachment')
          }
        },
      !dealIsFinalized && {
        label: 'Delete',
        onClick: () => {
          openModal('Delete Attachment')
        }
      }
    ]
    return [...items]
  }

  const menuItems = getMenuItems()

  useEffect(() => {
    if (deleteDocAttMutationLoading || deleteDocAttMutationLoading) {
      setLoading(true)
      setLoadingMessage('Deleting attachment…')
    } else {
      setLoading(false)
      setLoadingMessage('')
    }
  }, [deleteDealAttMutationLoading, deleteDocAttMutationLoading])

  return (
    <li className={css.attachment}>
      <div className={css.attachmentName}>
        <span>{attachment?.alias || attachment?.name}</span>
        <MoreMenu loading={loading} menuItems={menuItems} placement="bottom" withIcon />
      </div>
      <span>uploaded by </span>
      <span>{isMine ? 'You' : attachment.created_by.first_name} </span>
      <span>{formatTimeAgo(attachment.created_at)} ago</span>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalOption}>
        {loading || (modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />)}
        <div className={css.modal}>
          {modalOption === 'Convert Attachment' && (
            <AttachmentToDocument
              alias={attachment?.alias}
              attachment={attachment}
              closeModal={closeModal}
              dealId={dealId}
              name={attachment?.name}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Rename Attachment' && (
            <RenameFile
              attachmentId={attachment?.id}
              closeModal={closeModal}
              dealId={dealId}
              documentId={documentId}
              fileAlias={attachment?.alias}
              fileName={attachment?.name}
              fileType={'Attachment'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
          {modalOption === 'Delete Attachment' && (
            <>
              <p>{`Are you sure you want to delete attachment: ${attachment?.alias || attachment.name}`}</p>
              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
                <Button disabled={isDisabled} onClick={handleSubmit} title={isDisabled ? 'Select an option' : ''}>{`Submit`}</Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </li>
  )
}
