import { DataPoint, MutateAnnotation } from '../../graphql/codegen/schemas'
import { DataPointsForGroupDocument, useCreateAnnotationMutation, useDeleteAnnotationMutation, useDocumentsQuery } from '../../graphql/codegen/hooks'
import { Highlight, PdfHighlighter as ReactPdfHighlighter } from '@klarity/pdf-highlighter'
import { parseIdFromHash, positionToAnnotationPositionInput, updateHash } from './utils'
import { useIsAnnotator, useIsSuperAnnotator } from '../../hooks/useUserAccess'
import { useKeyPressed } from '../../hooks/useKeyPressed'
import { useParams } from 'react-router-dom'
import AnnotationMissingAlert from './AnnotationMissingAlert'
import AnnotationRetryModal from './AnnotationRetryModal'
import HighlightPopup from './HighlightPopup'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import Tip from './Tip'
import useCurrentUser from '../../hooks/useCurrentUser'
import useGetOperatingSystem from '../../hooks/useGetOperatingSystem'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'

// hooks

const useShouldBlockAnnotation = (documentId: string) => {
  const isAnnotator = useIsAnnotator()
  const isSuperAnnotator = useIsSuperAnnotator()
  const currUser = useCurrentUser()
  const { data } = useDocumentsQuery({ variables: { documentId } })

  return data?.documents?.edges[0]?.node?.internal_status === 'COMPLETE'
    ? false
    : isAnnotator && !isSuperAnnotator && data?.documents?.edges[0]?.node?.internal_assignee?.id !== currUser?.id
}

// components

export const PDFHighlighterWrapper: FC<any> = ({
  dealIsFinalized,
  documentId,
  highlights,
  onDocumentReady,
  onPageChange,
  pdfDocumentProxy,
  scale,
  showAllTags
}) => {
  const { isPreAnnot } = useIsPreAnnot()
  const { dealId } = useParams<{ dealId?: string }>()
  const shouldBlockAnnotation = useShouldBlockAnnotation(documentId)
  const [retryModalContent, setRetryModalContent] = useState<null | { create_annotation: any; prevAnnotation: any; prevSelectedLabel: string | null }>(null)
  const prevAnnotationRef = useRef<{ prevAnnotation: null | MutateAnnotation; prevSelectedLabel: string | null }>({
    prevAnnotation: null,
    prevSelectedLabel: null
  })
  const scrollRef = useRef<(highlight: any) => void>()

  const scrollToHighlightFromHash = () => {
    const id = parseIdFromHash()
    if (id) scrollTo({ id })
  }

  const scrollTo = useCallback(
    highlight => {
      const found = highlights.find((h: any) => highlight.id === h.id)
      if (found && scrollRef.current) {
        // @ts-ignore
        return scrollRef?.current(found)
      }
    },
    [highlights]
  )

  useEffect(() => {
    if (scrollRef?.current) {
      scrollToHighlightFromHash()
      window.addEventListener('hashchange', scrollToHighlightFromHash)
    }
    return () => window.removeEventListener('hashchange', scrollToHighlightFromHash)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current])

  const [createAnnotationMutation] = useCreateAnnotationMutation({
    onCompleted: ({ create_annotation }) => {
      if (create_annotation?.__typename === 'CreateAnnotationRetry') {
        const { prevAnnotation, prevSelectedLabel } = prevAnnotationRef.current
        setRetryModalContent({ create_annotation, prevAnnotation, prevSelectedLabel })
      }
    },
    refetchQueries: response =>
      (response.data.create_annotation.data_points as DataPoint[]).map(dataPoint => ({
        query: DataPointsForGroupDocument,
        variables: { group: dataPoint.data_point_field?.group, resourceId: dealId || documentId }
      }))
  })

  const createAnnotation = (annotation: MutateAnnotation, selectedLabel: string) => {
    // Store the prev value for retries
    prevAnnotationRef.current.prevAnnotation = annotation
    prevAnnotationRef.current.prevSelectedLabel = selectedLabel
    return createAnnotationMutation({ variables: { documentId, annotation } })
  }

  const [deleteAnnotationMutation, { loading: deleteMutationLoading }] = useDeleteAnnotationMutation({
    onCompleted: () => history.pushState('', document.title, `${window.location.pathname}${window.location.search}`)
  })

  const deleteAnnotation = (annotationId: string) => {
    deleteAnnotationMutation({ variables: { documentId, annotationId } })
  }

  // Hide highlights when ctrl is pressed to enable stacking highlights
  const operatingSystem = useGetOperatingSystem()
  const shouldHideHighlights = useKeyPressed(event => (operatingSystem === 'Mac' ? event.ctrlKey : event.altKey))

  return (
    <>
      <AnnotationRetryModal
        documentId={documentId}
        isOpen={!!retryModalContent}
        modalContent={retryModalContent}
        onRequestClose={() => setRetryModalContent(null)}
      />
      <ReactPdfHighlighter
        highlightTransform={(highlight, isHighlightPopupDismissedViaClick, isScrolledTo) => {
          // For highlights that have misconfigured positions, eventually this will be removed as the docx to pdf highlighting improves
          if (highlight.labelType === 'MISSING_ANNO') {
            return null
          }
          const key = highlight.id || 'new-highlight' // highlights won't have id's until they are created on the backend, but there will only ever be one of these at a time

          return (
            <Highlight
              highlight={highlight}
              isScrolledTo={!isHighlightPopupDismissedViaClick && isScrolledTo}
              key={key}
              onClick={e => {
                e.preventDefault()
                updateHash(highlight)
                scrollTo(highlight)
              }}
              renderPopup={highlight =>
                !isHighlightPopupDismissedViaClick && (
                  <HighlightPopup
                    deleteAnnotation={shouldBlockAnnotation || isPreAnnot || dealIsFinalized ? null : deleteAnnotation}
                    highlight={highlight}
                    isScrolledTo={isScrolledTo}
                  />
                )
              }
              showAllTags={showAllTags}
            />
          )
        }}
        highlights={!shouldHideHighlights && highlights ? highlights : []}
        onDocumentReady={onDocumentReady}
        onPageChange={onPageChange}
        onScrollChange={() => history.pushState('', document.title, `${window.location.pathname}${window.location.search}`)}
        onSelectionFinished={(position, content, hideTipAndSelection, transformSelection) => {
          if (shouldBlockAnnotation || isPreAnnot || dealIsFinalized) return null
          return (
            <Tip
              documentId={documentId}
              onConfirm={(selectedTag: { label: string; value: string }) => {
                const internal_name = selectedTag.value
                const positions = [positionToAnnotationPositionInput(position, content)]
                const annotation = { internal_name, positions }
                createAnnotation(annotation, selectedTag.label).then(hideTipAndSelection)
              }}
              onOpen={transformSelection}
            />
          )
        }}
        pdfDocument={pdfDocumentProxy}
        pdfScaleValue={scale}
        renderAnnotationMissingAlert={(annos: any) => (
          <AnnotationMissingAlert annotations={annos} deleteAnnotation={dealIsFinalized ? null : deleteAnnotation} loading={deleteMutationLoading} />
        )}
        scrollRef={triggerScrollTo => {
          scrollRef.current = triggerScrollTo
          scrollToHighlightFromHash()
        }}
      />
    </>
  )
}
