import { CCI_RightPanel_ChecklistTab_Main } from './RightPanel_components/ChecklistTab/CCI_RightPanel_ChecklistTab_Main'
import { Route, Switch } from 'react-router-dom'
import { useCciMainContext } from '../../CCI_Main'
import CCI_RightPanel_IntegrationsTab_Main from './RightPanel_components/IntegrationsTab/CCI_RightPanel_IntegrationsTab_Main'
import CCI_RightPanel_UsersTab_Main from './RightPanel_components/UsersTab/CCI_RightPanel_UsersTab_Main'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'
import useUserAccess from '../../../../hooks/useUserAccess'

export default function CCI_RightPanel_Content() {
  const { tab } = useCciMainContext()
  const [defaultTab, setDefaultTab] = useState<any>(undefined)
  const hasAdminAccess = useUserAccess({ feature: 'CCI', permission: 'ADMIN' })
  const hasChecklistTabAccess = useUserAccess({ feature: 'CCI', permission: 'CHECKLIST_TAB' })
  const hasIntegrationsTabAccess = useUserAccess({ feature: 'CCI', permission: 'INTEGRATIONS_TAB' })
  const hasRolesTabAccess = useUserAccess({ feature: 'CCI', permission: 'ROLES_TAB' })
  const hasUsersTabAccess = useUserAccess({ feature: 'CCI', permission: 'USERS_TAB' })

  // sets the default tab based off of the current user's permission levels. Goes through alphabetically until finding a tab they have access to.
  const getDefaultTab = () => {
    if (hasAdminAccess) {
      setDefaultTab(<CCI_RightPanel_ChecklistTab_Main />)
    } else if (hasChecklistTabAccess) {
      setDefaultTab(<CCI_RightPanel_ChecklistTab_Main />)
    } else if (hasIntegrationsTabAccess) {
      setDefaultTab(<></>)
    } else if (hasRolesTabAccess) {
      setDefaultTab(<></>)
    } else if (hasUsersTabAccess) {
      setDefaultTab(<CCI_RightPanel_UsersTab_Main />)
    } else {
      setDefaultTab(undefined)
    }
  }

  useEffect(() => {
    if (!tab) {
      getDefaultTab()
    }
  }, [tab]) // eslint-disable-line

  return (
    <div className={css.card}>
      <div className={css.initial}>
        <Switch>
          <Route exact path="/config/checklist">
            {(hasAdminAccess || hasChecklistTabAccess) && <CCI_RightPanel_ChecklistTab_Main />}
          </Route>

          <Route exact path="/config/integrations">
            {(hasAdminAccess || hasIntegrationsTabAccess) && <CCI_RightPanel_IntegrationsTab_Main />}
          </Route>

          <Route exact path="/config/roles">
            {(hasAdminAccess || hasRolesTabAccess) && <p>Not added yet</p>}
          </Route>

          <Route exact path="/config/users">
            {(hasAdminAccess || hasUsersTabAccess) && <CCI_RightPanel_UsersTab_Main />}
          </Route>

          <Route path="*">{defaultTab || <p>Error setting default tab</p>}</Route>
        </Switch>
      </div>
    </div>
  )
}
