import { OKTA_CONFIG } from './apiUtils'
import { OktaAuth } from '@okta/okta-auth-js'
import { logout } from './sessionApiUtils'

const oktaClient = new OktaAuth(OKTA_CONFIG)
let logoutRedirectTimeout = <any>null

// Okta tokenManager documentation: https://github.com/okta/okta-auth-js#tokenmanageronevent-callback-context

oktaClient.tokenManager.on('renewed', async () => {
  if (process.env.NODE_ENV !== 'production') {
    const date = new Date()
    console.info(`Okta renewed firing at: ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}. Cancelling expired setTimeout`)
  }
  clearTimeout(logoutRedirectTimeout) // this prevents the expired token redirect from firing
})

oktaClient.tokenManager.on('expired', () => {
  if (window.location.pathname !== '/login') {
    if (process.env.NODE_ENV !== 'production') {
      const date = new Date()
      console.info(`Okta token expired at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}. Starting 30 second timeout before redirecting`)
    }
    // if this timeout is not cleared by the renewed function firing:
    // the user will be sent to the Okta logout page, then redirect to /logout afterward to clear local auth values, and finally redirect to /login.
    logoutRedirectTimeout = setTimeout(() => {
      handleLogout()
    }, 30000)
  }
})

oktaClient.tokenManager.on('error', async () => {
  console.error('Error renewing token. Logging out…')
  clearTimeout(logoutRedirectTimeout)
  handleLogout()
})

const handleLogout = async () => {
  try {
    await oktaClient.tokenManager.clear()
    logout()
  } catch (err) {
    console.error('oktaClient.ts handleLogout error:', err)
    logout()
  }
}

export default oktaClient
