import axiosClient from '../utils/axiosClient'
import type { DocumentFormat } from '../components/DocumentPanel/DocumentPanel'

export const getDocument = async (documentId: string, type: DocumentFormat = 'ORIGINAL_PDF') => {
  const response = await axiosClient.get(`/document/${documentId}?type=${type}`)
  return response.data.data
}

export const downloadDocument = async (documentId: string, documentName: string) => {
  const {
    data: { data }
  } = await axiosClient.get(`/document/${documentId}?type=ORIGINAL_PDF`)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = data
  // documentName is alias if present- passed into toolbar as alias || name
  a.download = documentName.split('.')[0] + '.pdf' // TODO: return name w/ response. this is built on the assumption that all document names end with .docx
  document.body.appendChild(a)
  a.click()
}

export const saveDocument = async (documentId: string, version_name?: string | null) => {
  try {
    // @ts-ignore
    await window.editor.saveDocument()
  } catch (error) {
    console.error(error)
  }

  return await axiosClient.post(`/document/${documentId}`, { version_name })
}
