import { useCciMainContext } from '../../../CCI_Main'
import { useDeleteUserMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function DeleteUser({ closeModal, userId, userName }: { closeModal?: any; userId?: string; userName?: string }) {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [deleteUser, { error: deleteUserError, loading: deleteUserLoading }] = useDeleteUserMutation({
    context: { queryName: `DeleteUser.tsx deleteUser` },
    onCompleted: () => {
      setModalSuccess('User successfully deleted.')
    }
  })

  const handleDelete = () => {
    if (userId) {
      deleteUser({ variables: { user_id: userId } })
    } else {
      setModalError('User id is missing')
    }
  }

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (deleteUserLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Deleting User…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [deleteUserLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(deleteUserError)}, [deleteUserError])

  return (
    <>
      {userName && <h5 style={{ textAlign: 'center' }}>{userName}</h5>}
      <p style={{ textAlign: 'center', marginTop: '8px' }}>{`Are you sure you want to delete this user?`}</p>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={!userId ? 'User Id is missing' : isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={!userId || isDisabled} onClick={handleDelete}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
