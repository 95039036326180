import * as pdfjs from '../../../../pdfjs-dist-local'
import { Annotation } from '../../graphql/codegen/schemas'
import { DocumentProps } from '../DocumentPanel/DocumentPanel'
import { PDFHighlighterWrapper } from './PDFHighlighterWrapper'
import { getDocument } from '../../utils/documentApiUtils'
import { klarityAnnotationToHighlighterFormat } from './utils'
import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PdfjsWorker from '../../../../pdfjs-dist-local/build/pdf.worker.entry'
import React, { useEffect, useRef, useState } from 'react'

// @ts-ignore
pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorker

export default function PDFHighlighterDoc({
  dealIsFinalized,
  documentFormat,
  documentId,
  onDocumentReady,
  onPageChange,
  scale,
  showAllTags,
  showHighlights
}: DocumentProps & {
  dealIsFinalized: boolean
  onDocumentReady: (pdfDocument: any) => void
  onPageChange: (pageNumber: number) => void
  scale: number | 'auto'
  showAllTags: boolean
  showHighlights: boolean
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [pdfDocumentProxy, setPdfDocumentProxy] = useState<any>(null)

  useEffect(() => {
    const setDocument = async () => {
      if (documentId) {
        try {
          // Convert the document from base64 encoding to a bytestring for pdfjs
          const base64EncodedDocument = await getDocument(documentId, documentFormat)
          const data = atob(base64EncodedDocument.split('base64,')[1])
          const doc = await pdfjs.getDocument({ data }).promise
          setPdfDocumentProxy(doc)
        } catch (error) {
          console.error(error)
        }
      }
    }

    setPdfDocumentProxy(null)
    setDocument()
  }, [documentId, documentFormat])

  const { data: documentData } = useDocumentsQuery({ variables: { documentId }, context: { queryName: `PDFHighlighterDoc.tsx useDocumentsQuery` } })

  const highlights = documentData?.documents?.edges?.[0]?.node?.annotations?.edges?.map(edge => {
    const highlight = klarityAnnotationToHighlighterFormat(edge?.node as Annotation)
    return highlight
  })

  return (
    <div ref={wrapperRef} style={{ height: '100%', overflow: 'hidden', maxWidth: '100%' }}>
      {(!pdfDocumentProxy || !highlights) && (
        <div>
          <DocumentPlaceholder />
        </div>
      )}
      {pdfDocumentProxy && highlights && (
        <PDFHighlighterWrapper
          dealIsFinalized={dealIsFinalized}
          documentId={documentId}
          highlights={showHighlights ? highlights : []}
          onDocumentReady={onDocumentReady}
          onPageChange={onPageChange}
          pdfDocumentProxy={pdfDocumentProxy}
          scale={scale}
          showAllTags={showAllTags}
        />
      )}
    </div>
  )
}
