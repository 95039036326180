import { BsPersonFill } from 'react-icons/bs'
import React, { useCallback } from 'react'
import css from './style.module.scss'

export default function Source({ external_source, source }: any) {
  const formatSource = useCallback(() => {
    switch (external_source) {
      case 'MANUAL':
        return <BsPersonFill style={{ width: '22px', height: '22px', margin: '-1px 0 0 8px' }} />
      case null:
        return null
      default:
        return <img alt={`${external_source?.toLowerCase()} logo`} src={require(`../../../public/s/${external_source}.png`)} />
    }
  }, [external_source])

  return (
    <>
      {source === 'MANUAL' ? (
        <BsPersonFill style={{ width: '22px', height: '22px', margin: '-1px 0 0 8px' }} />
      ) : (
        <span className={css.sourceWrapper}>{formatSource()}</span>
      )}
    </>
  )
}
