import { Box, Button } from '@mui/material'
import { SearchBar } from '../../../../../components/DataPointPanel/SearchBar'
import { formatKey } from '../../../../../utils/stringUtils'
import { useCciLeftPanelContext } from '../CCI_LeftPanel'
import { useCciMainContext } from '../../../CCI_Main'
import React, { useEffect, useState } from 'react'
import css from '../style.module.scss'

export default function CCI_LeftPanel_Controls() {
  const { selectedItem, setActiveComponent, setSelectedItem, tab } = useCciMainContext()
  const { documentTypesField, searchTerm, searchableData, setActiveKeys, setSearchTerm, tabTitleArray } = useCciLeftPanelContext()
  const [searchPlaceholder, setSearchPlaceholder] = useState('Search…')

  useEffect(() => {
    if (searchTerm && (!tab || tab === 'checklist')) {
      setActiveKeys(generateActiveKeys)
    }
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (tab) {
      case undefined:
      case 'checklist':
        setSearchPlaceholder('Search checklist items…')
        break
      case 'roles':
        setSearchPlaceholder('Search roles…')
        break
      case 'users':
        setSearchPlaceholder('Search users…')
        break
      default:
        setSearchPlaceholder('Search…')
    }
  }, [tab])

  const generateActiveKeys = () => {
    if (!searchableData) return []

    switch (tab) {
      case undefined:
      case 'checklist':
        return searchableData.map((item: any) => formatKey(item))
      case 'integrations':
        return searchableData.map((item: any) => formatKey(item.node.id))
      case 'roles':
        return searchableData.map((item: any) => formatKey(item.id))
      case 'users':
        return searchableData.map((item: any) => formatKey(item.user_name))
      default:
        return []
    }
  }

  return (
    <>
      {tabTitleArray && (
        <div className={css.header}>
          <div className={css.topRow}>
            <ul>
              {tabTitleArray.map((tabTitle, index) => {
                if (tabTitle === 'Document Types') {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setActiveComponent('Edit Document Types')
                        setSelectedItem(documentTypesField)
                      }}
                    >
                      <p className={css.tabTitle} style={selectedItem?.id === documentTypesField?.id ? {} : { borderBottom: 'none', cursor: 'pointer' }}>
                        {tabTitle}
                      </p>
                    </li>
                  )
                }

                if (tabTitle === 'Checklist') {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setActiveComponent('')
                        setSelectedItem('')
                      }}
                    >
                      <p className={css.tabTitle} style={selectedItem?.id === documentTypesField?.id ? { borderBottom: 'none', cursor: 'pointer' } : {}}>
                        {tabTitle}
                      </p>
                    </li>
                  )
                }

                return (
                  <li key={index}>
                    <p className={css.tabTitle}>{tabTitle}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}

      {searchableData && (
        <>
          <SearchBar placeholder={searchPlaceholder} setFilter={setSearchTerm} value={searchTerm} />

          <Box display="flex" gap={1} justifyContent="flex-end" sx={{ pb: 2, px: 2 }}>
            <Button color="tertiary" onClick={() => setActiveKeys(generateActiveKeys)} size="small" variant="outlined">
              Expand all
            </Button>

            <Button color="secondary" onClick={() => setActiveKeys([])} size="small" variant="outlined">
              Collapse all
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
