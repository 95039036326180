import { useCciMainContext } from '../../CCI_Main'
import CCI_LeftPanel_Content from './LeftPanel_components/CCI_LeftPanel_Content'
import CCI_LeftPanel_Controls from './LeftPanel_components/CCI_LeftPanel_Controls'
import CCI_LeftPanel_Footer from './CCI_LeftPanel_Footer'
import React, { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

// types

interface CciLeftPanelContextInterface {
  activeKeys: any
  documentTypesField: any
  searchTerm: string
  searchableData: any
  setActiveKeys: Dispatch<SetStateAction<any>>
  setDocumentTypesField: any
  setSearchTerm: Dispatch<SetStateAction<string>>
  setSearchableData: Dispatch<SetStateAction<any>>
  tabTitleArray: Array<string> | undefined
}

// context

const CciLeftPanelContext = createContext<CciLeftPanelContextInterface | null>(null)

// hooks

export const useCciLeftPanelContext = () => {
  const context = useContext(CciLeftPanelContext)

  if (context === null) {
    throw new Error()
  }

  return context
}

// components

export const CCI_LeftPanel: FC = () => {
  const { setSelectedItem, tab } = useCciMainContext()
  const [tabTitleArray, setTabTitleArray] = useState<Array<string> | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [searchableData, setSearchableData] = useState<any>('')
  const [documentTypesField, setDocumentTypesField] = useState<any>('')

  useEffect(() => {
    setActiveKeys([])
    setSearchTerm('')
    setSelectedItem('')

    switch (tab) {
      case undefined:
      case 'checklist':
        setTabTitleArray(documentTypesField ? ['Checklist', 'Document Types'] : ['Checklist'])
        break
      case 'integrations':
        setTabTitleArray(['Integrations'])
        break
      case 'roles':
        setTabTitleArray(['Roles'])
        break
      case 'users':
        setTabTitleArray(['Users'])
        break
      default:
        setTabTitleArray(undefined)
    }
  }, [documentTypesField, setSelectedItem, tab])

  const context = useMemo(
    () => ({
      activeKeys,
      documentTypesField,
      searchableData,
      searchTerm,
      setActiveKeys,
      setDocumentTypesField,
      setSearchableData,
      setSearchTerm,
      tabTitleArray
    }),
    [activeKeys, documentTypesField, searchTerm, searchableData, tabTitleArray]
  )

  return (
    <div className={css.panel}>
      <CciLeftPanelContext.Provider value={context}>
        <CCI_LeftPanel_Controls />

        <div className={css.inner}>
          <CCI_LeftPanel_Content />
        </div>

        <CCI_LeftPanel_Footer />
      </CciLeftPanelContext.Provider>
    </div>
  )
}
