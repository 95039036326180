import { getFieldTypeLabel } from '../../../../../../../utils/cci'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

export default function FieldTypeInput({
  currentItemId,
  currentValue,
  handleData,
  selectedValue
}: {
  currentItemId?: string
  currentValue?: string
  handleData: any
  selectedValue?: string
}) {
  const [field_type_options, setField_type_options] = useState<any>([])

  const optionsList = [
    { value: 'TEXT', label: 'TEXT' },
    { value: 'FLOAT', label: 'NUMBER (DECIMAL)' },
    { value: 'NUMBER', label: 'NUMBER (NON-DECIMAL)' },
    { value: 'BOOLEAN', label: 'BOOLEAN' },
    { value: 'DROP_DOWN', label: 'DROPDOWN' },
    { value: 'MULTI_SELECT_DROP_DOWN', label: 'MULTI_SELECT_DROPDOWN' },
    { value: 'DATE', label: 'DATE' },
    { value: 'DURATION', label: 'DURATION' },
    { value: 'PERCENTAGE', label: 'PERCENTAGE' },
    { value: 'CURRENCY', label: 'CURRENCY' },
    { value: 'CURRENCY_TYPE', label: 'CURRENCY_TYPE' },
    { value: 'DATA_TABLE', label: 'DATA_TABLE' },
    { value: 'GEO_COUNTRY', label: 'GEO_COUNTRY' },
    { value: 'GEO_STATE', label: 'GEO_STATE' },
    { value: 'GEO_CITY', label: 'GEO_CITY' }
  ]

  const handleChange = (item: any) => {
    if (item?.value !== 'DATE' && currentValue === 'DATE') {
      handleData('value_format', '@@_DELETE_THIS_ITEM')
    } else {
      handleData('value_format', null)
    }
    if (!item?.value || item?.value === currentValue) {
      handleData('field_type', null)
    } else {
      handleData('field_type', item?.value)
    }
    handleData('default_value', null)
  }

  useEffect(() => {
    // below removes the currently selected item from the list of options
    setField_type_options(
      optionsList.filter((item: any) => {
        return selectedValue ? item.value !== selectedValue : item.value !== currentValue
      })
    )
    // eslint-disable-next-line
    }, [currentItemId, currentValue, selectedValue])

  useEffect(() => {
    handleData('field_type', null)
    handleData('value_format', null)
    handleData('default_value', null)
    // eslint-disable-next-line
    }, [currentItemId, currentValue])

  return (
    <>
      {/* Select a new field type from dropdown */}
      <div className={css.inputWrapper}>
        <h4>{`Field Type:`}</h4>
        <SelectInput
          isClearable
          onChange={(item: any) => {
            handleChange(item)
          }}
          options={field_type_options}
          placeholder={getFieldTypeLabel(selectedValue, currentValue)}
          value={selectedValue}
        />
      </div>
    </>
  )
}
