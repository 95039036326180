import { DealNoteContainer, DocumentNoteContainer } from '../../containers/NoteContainers'
import { Link, useHistory, useParams } from 'react-router-dom'
import { MutationResult } from '@apollo/client'
import CommentBox from '../CommentBox'
import CommentContainer from '../../containers/CommentContainer'
import React from 'react'
import Skeleton from './Skeleton'
import clsx from 'clsx'
import css from './style.module.scss'
import useUserAccess from '../../hooks/useUserAccess'

export default function NoteSection({
  commentState,
  comments,
  dealIsFinalized,
  handleCreate,
  loading
}: {
  commentState?: MutationResult<any>
  comments?: any
  dealIsFinalized?: boolean
  handleCreate?: (c: any) => void
  handleDelete?: (c: any) => void
  loading: boolean
}) {
  const history = useHistory()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const isEmpty = !loading && comments && !comments.length
  const hasCollisionAccess = useUserAccess({ feature: 'Collision', permission: 'READ' })

  return (
    <div className={css.commentSection}>
      <div className={css.commentListWrapper} style={dealIsFinalized ? { height: '100%' } : {}}>
        {/* height 100% here is to adjust for create comment not being shown */}
        <div className={clsx(css.commentList, isEmpty && css.empty)}>
          {!loading && comments ? (
            isEmpty ? (
              <div>{`Nothing to see here...`}</div>
            ) : (
              comments.map((comment: any) => {
                if (comment.__typename === 'Comment') {
                  const Wrapper = hasCollisionAccess ? Link : 'span'
                  return (
                    <CommentContainer
                      comment={comment}
                      dataPointId={comment.parentDataPoint.id}
                      dealIsFinalized={dealIsFinalized}
                      key={comment.id}
                      renderTopRow={(author: string, timeAgo: string) => (
                        <div className={css.noteHeader}>
                          <span className={css.author}>{author}</span>
                          <span>{` commented on `}</span>
                          {/* @ts-ignore */}
                          <Wrapper
                            className={css.parentDataPointName}
                            to={`${dealId ? `/deals/${dealId}` : `/documents/${documentId}`}/checklist/${comment.parentDataPoint.id + history.location.search}`}
                          >
                            {comment.parentDataPoint.data_point_field.name}
                          </Wrapper>
                          <span className={css.time}>{`${timeAgo} ago`}</span>
                        </div>
                      )}
                    />
                  )
                } else {
                  const Note = dealId ? DealNoteContainer : DocumentNoteContainer
                  return (
                    <Note
                      dealIsFinalized={dealIsFinalized || false}
                      key={comment.id}
                      note={comment}
                      renderTopRow={(author: string, timeAgo: string) => (
                        <div className={css.noteHeader}>
                          <span className={css.author}>{author}</span>
                          <span>{` left a note `}</span>
                          <span className={css.time}>{`${timeAgo} ago`}</span>
                        </div>
                      )}
                    />
                  )
                }
              })
            )
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      {handleCreate && (
        <div className={css.bottomRow}>
          <CommentBox commentState={commentState} handleCreate={handleCreate} notesMode />
        </div>
      )}
    </div>
  )
}
