import { Check, Edit, Paperclip } from 'react-feather'
import { ChecklistTab } from './ChecklistTab'
import { ChecklistTabAnnotatorFooter } from './ChecklistTabAnnotatorFooter'
import { DealAttachmentsTabContainer, DocumentAttachmentsTabContainer } from './AttachmentsTab'
import { NavLink, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useIsAnnotator } from '../../hooks/useUserAccess'
import { useIsAssignedToMe } from '../../hooks/useIsAssignedToMe'
import DataPointOverlay from '../DataPointOverlay'
import DealNotesTab from './DealNotesTab'
import DocumentNotesTab from './DocumentNotesTab'
import React, { useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

interface DataPointPanelProps {
  attachmentCount: number
  dealId?: string
  dealIsFinalized?: boolean
  documentId?: string
  noteCount: number
  showDataPointDetailOverlay: boolean
}

// components

const DataPointPanel = ({ attachmentCount, dealId, dealIsFinalized, documentId, noteCount, showDataPointDetailOverlay }: DataPointPanelProps) => {
  const isAnnotator = useIsAnnotator()
  const isAssignedToMe = useIsAssignedToMe(documentId)
  const history = useHistory()
  const { pathname } = useLocation()
  const { path, url } = useRouteMatch()
  const [activeTab, setActiveTab] = useState('default')

  const checklistPaths = [path, `${path}/checklist`, `${path}/checklist/:dataPointId`]

  return (
    <div className={clsx(css.panel, activeTab && css[activeTab])}>
      <div className={css.header}>
        <div className={css.topRow}>
          <ul>
            <li>
              <NavLink
                activeClassName={css.active}
                isActive={() => !pathname.includes('notes') && !pathname.includes('attachments') && !pathname.includes('history')}
                to={`${url}/checklist${history.location.search}`}
              >
                <Check />

                {`${isAnnotator ? 'Customer ' : ''}Checklist`}
              </NavLink>
            </li>

            {!isAnnotator && (
              <>
                <li>
                  <NavLink activeClassName={css.active} to={`${url}/notes${history.location.search}`}>
                    <Edit />
                    Notes{!!noteCount && <div className={css.badge}>{noteCount}</div>}
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName={css.active} to={`${url}/attachments${history.location.search}`}>
                    <Paperclip />
                    {`Attachments`}
                    {!!attachmentCount && <div className={css.badge}>{attachmentCount}</div>}
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <Switch>
        <Route path={[path, `${path}/:activeTab`]}>
          <Route path={`${path}/attachments`}>
            {dealId ? <DealAttachmentsTabContainer dealIsFinalized={dealIsFinalized} /> : <DocumentAttachmentsTabContainer />}
          </Route>

          <Route path={`${path}/notes`}>
            {dealId ? (
              <DealNotesTab dealIsFinalized={dealIsFinalized || false} setActiveTab={setActiveTab} />
            ) : (
              <DocumentNotesTab setActiveTab={setActiveTab} />
            )}
          </Route>

          <Route path={`${path}/checklist/:dataPointId`}>{showDataPointDetailOverlay && <DataPointOverlay dealIsFinalized={dealIsFinalized || false} />}</Route>

          <Route exact path={checklistPaths}>
            <ChecklistTab
              dealId={dealId}
              dealIsFinalized={dealIsFinalized || false}
              documentId={documentId}
              showDataPointDetailOverlay={showDataPointDetailOverlay}
            />

            {isAssignedToMe && <ChecklistTabAnnotatorFooter />}
          </Route>
        </Route>
      </Switch>
    </div>
  )
}

export default DataPointPanel
