import { DataPoint } from '../../../graphql/codegen/schemas'
import { getKeyByValue } from '../../../utils/objectUtils'
import { useDataPointFieldsByInternalNameLazyQuery, useDeleteAnnotationsByInternalNameMutation } from '../../../graphql/codegen/hooks'
import { useParams } from 'react-router'
import Button from '../../Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function DeleteOSMDDOption({
  annotationCount,
  closeModal,
  dataPoint,
  removedOption,
  setModalError,
  setModalLoading,
  setModalLoadingMessage,
  setModalSuccess
}: {
  annotationCount?: number
  closeModal: any
  dataPoint: DataPoint
  removedOption: string
  setModalError: any
  setModalLoading: any
  setModalLoadingMessage: any
  setModalSuccess: any
}) {
  const { documentId } = useParams<{ documentId: string }>()
  const [neutralTag, setNeutralTag] = useState<any>(undefined)
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState('')

  const [getDPFsByNeutralTag, { data: neutralTagDPFs, error: neutralTagDPFsError, loading: neutralTagDPFsLoading }] = useDataPointFieldsByInternalNameLazyQuery(
    {
      context: { queryName: 'DeleteOSMDDOption.tsx getDPFsByNeutralTag' }
    }
  )
  const [deleteAnnotationsByNeutralTag, { error: deleteAnnotationsError, loading: deleteAnnotationsLoading }] = useDeleteAnnotationsByInternalNameMutation({
    context: { queryName: 'DeleteOSMDDOption.tsx deleteAnnotationsByNeutralTag' },
    onCompleted: () => {
      setModalLoadingMessage('')
      setModalSuccess(`Successfully deleted the annotations and removed the option.`)
    }
  })

  const handleDelete = () => {
    const extractionLogicMapping = dataPoint?.data_point_field?.extraction_logic
      ? JSON.parse(dataPoint?.data_point_field?.extraction_logic)?.mapping
      : undefined
    const neutralTag = getKeyByValue(extractionLogicMapping, removedOption)
    if ((dataPoint?.document?.id || documentId) && neutralTag) {
      setModalLoadingMessage('Deleting Annotations…')
      deleteAnnotationsByNeutralTag({ variables: { document_id: dataPoint?.document?.id || documentId, internal_name: neutralTag } })
    }
  }

  useEffect(() => {
    const extractionLogicMapping = dataPoint?.data_point_field?.extraction_logic
      ? JSON.parse(dataPoint?.data_point_field?.extraction_logic)?.mapping
      : undefined
    const neutralTag = getKeyByValue(extractionLogicMapping, removedOption)
    setNeutralTag(neutralTag)
    if (neutralTag) {
      getDPFsByNeutralTag({ variables: { internal_name: neutralTag } })
    }
    // eslint-disable-next-line
    }, [removedOption])

  useEffect(() => {
    if (neutralTagDPFsLoading || deleteAnnotationsLoading) {
      setModalLoading(true)
    } else {
      setModalLoading(false)
    }
    // eslint-disable-next-line
    }, [neutralTagDPFsLoading, deleteAnnotationsLoading])

  useEffect(() => {
    if (!neutralTag) {
      setIsDisabled(true)
      setDisabledMessage('Error: Neutral Tag is missing')
    } else if (!dataPoint?.document?.id && !documentId) {
      setIsDisabled(true)
      setDisabledMessage('Error: Document ID is missing')
    } else if (!confirmed) {
      setIsDisabled(true)
      setDisabledMessage('Please type Confirm into the box to proceed')
    } else {
      setIsDisabled(false)
      setDisabledMessage('')
    }
    // eslint-disable-next-line
    }, [neutralTag, dataPoint, documentId, confirmed])

  useEffect(() => {
    if (neutralTagDPFsError) {
      setModalError(neutralTagDPFsError)
    } else if (deleteAnnotationsError) {
      setModalError(deleteAnnotationsError)
    }
    // eslint-disable-next-line
    }, [neutralTagDPFsError, deleteAnnotationsError])

  return (
    <>
      {!neutralTagDPFsLoading && neutralTagDPFs?.document_data_point_fields_by_internal_name?.length && (
        <div className={css.deleteOSMDDParent}>
          <h4>{`Remove Option`}</h4>
          <h5>{`Field: ${dataPoint?.data_point_field?.name}`}</h5>
          <h5>{`Option: ${removedOption}`}</h5>
          <p style={{ marginTop: '8px' }}>{`This option is tied to ${annotationCount} annotations.`}</p>
          {neutralTagDPFs?.document_data_point_fields_by_internal_name?.length === 1 ? (
            <p>{`These annotations must be deleted to remove the option.`}</p>
          ) : (
            <>
              <p>{`These annotations are also tied to the following fields:`}</p>
              <div className={css.itemContainer}>
                {neutralTagDPFs?.document_data_point_fields_by_internal_name
                  ?.filter((item: any) => {
                    return item?.name !== dataPoint?.data_point_field?.name
                  })
                  ?.map((item: any, index: number) => {
                    return <p key={`${item?.name}${index}`}>{item?.name}</p>
                  })}
              </div>
              <p>{`These annotations must be deleted to remove the option and will be deleted from all fields.`}</p>
            </>
          )}
          <TypeToConfirm setConfirmed={setConfirmed} />
          <div className={css.modalButtonRow}>
            <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
            <WithTooltip content={disabledMessage}>
              <Button disabled={isDisabled} onClick={handleDelete}>{`Remove Option`}</Button>
            </WithTooltip>
          </div>
        </div>
      )}
    </>
  )
}
