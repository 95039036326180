import useCurrentUser from './useCurrentUser'

// Hook for hardcoded features for this task: https://app.asana.com/0/0/1200501099177444/f
export default function useIsRapid7LegalTeam() {
  const currentUser = useCurrentUser()
  const poc_role_id = 'Um9sZTo2MDgwNDZlYWNjMzc5YTNmN2NlY2YzMGY='
  const prod_role_id_1 = 'Um9sZTo2MGRjNjBiMzFiZGQxYTM3MzJlMjRlNjA=' // "Legal"
  const prod_role_id_2 = 'Um9sZTo2MjM4MWFhM2MxNTA5Njc1NDNkODg3YWQ=' // "Legal (Viewer)""

  for (const r of currentUser?.roles?.edges || []) {
    if (r?.node?.id === poc_role_id || r?.node?.id === prod_role_id_1 || r?.node?.id === prod_role_id_2) {
      return true
    }
  }

  return false
}

// export function useGetRapid7Query ({withPrevQuery}:{withPrevQuery: boolean}) {
//   const currentUser = useCurrentUser();
//   const poc_role_id = 'Um9sZTo2MDgwNDZlYWNjMzc5YTNmN2NlY2YzMGY=';
//   const prod_role_id = 'Um9sZTo2MGRjNjBiMzFiZGQxYTM3MzJlMjRlNjA=';
//   const poc_query = "/?cols=ID__RGF0YVBvaW50RmllbGQ6NjBlM2Y3MjZjMDVhOGM4MDI2NGNkZTk5,internal_status&q=Lrules%21%255BAID__RGF0YVBvaW50RmllbGQ6NjBlM2Y3MjZjMDVhOGM4MDI2NGNkZTk5K%2A7-78Quote-Quote8H-H8SoW-SoW.%252CAinternal_statusKL1%279-9.UcombinJand%27~not%21false%29%2ALisMultiSelectDropDown%21true~1%27-%27~label%21%27.%27%29UoperJnotIn%27%291value%217Purchase%2520Order8%27%29%252C%2A9PROCESSINGALfield%21%27HInvoiceJator%21%27K%27~1%255BL%28%27U%255D~%2501ULKJHA9871.-%2A_";
//   const prod_query = "/?cols=ID__RGF0YVBvaW50RmllbGQ6NjBkYzVlNDhjMDVhOGM4MDI2NGNkYjk1,internal_status&q=Lrules%21%255BAID__RGF0YVBvaW50RmllbGQ6NjBkYzVlNDhjMDVhOGM4MDI2NGNkYjk1K%2A7-78Quote-Quote8H-H8SoW-SoW.%252CAinternal_statusKL3%279-9.TcombinJand%27~not%21false%29%2ALisMultiSelectDropDown%21true~3%27-%27~label%21%27.%27%29ToperJnotIn%27%293value%217Purchase%2520Order8%27%29%252C%2A9PROCESSINGALfield%21%27HInvoiceJator%21%27K%27~3%255BL%28%27T%255D~%2501TLKJHA9873.-%2A_";
//   const prevQuery = localStorage.getItem('PREVIOUS_DOCUMENT_QUERY');

//   for (const r of currentUser?.roles?.edges || []) {
//     if (r?.node?.id === poc_role_id){ return (withPrevQuery && prevQuery) ? `/${prevQuery}` : poc_query; }
//     else if (r?.node?.id === prod_role_id){ return (withPrevQuery && prevQuery) ? `/${prevQuery}` : prod_query; }
//   }
//   return false;
// }
