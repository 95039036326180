import * as queryString from 'query-string'
import { AiOutlineFileSearch } from 'react-icons/ai'
import { useHistory, useRouteMatch } from 'react-router-dom'
import React, { SyntheticEvent, useState } from 'react'
import SaveProgressIcon from './SaveProgressIcon'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

export default function ScrollIcon({ annotations, documentId, pageIds }: { annotations?: any; documentId?: string; pageIds?: any }) {
  const [currentAnnotationIndex, setCurrentAnnotationIndex] = useState<any>('initial')

  const generateToolTipContent = () => {
    if (annotations?.length > 0) {
      return <p>{`Jump to annotation${annotations.length === 1 ? '' : ` ${getNextAnnotationIndex()}/${annotations.length}`}`}</p>
    } else if (pageIds?.length > 0) {
      return pageIds ? <p>{`Jump to page ${pageIds[getNextAnnotationIndex()]}`}</p> : null
    } else {
      return null
    }
  }

  const getNextAnnotationIndex = () => {
    if (annotations?.length > 0) {
      if (currentAnnotationIndex === 'initial') {
        setCurrentAnnotationIndex(1)
        return 1
      } else if (currentAnnotationIndex + 1 > annotations?.length) {
        return 1
      } else {
        return currentAnnotationIndex + 1
      }
    } else if (pageIds?.length > 0) {
      if (currentAnnotationIndex === 'initial') {
        setCurrentAnnotationIndex(0)
        return 0
      } else if (currentAnnotationIndex + 1 > pageIds?.length) {
        return 0
      } else {
        return currentAnnotationIndex + 1
      }
    } else {
      return 0
    } // should never happen, added for typescript
  }

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [tooltipContent, setTooltipContent] = useState(generateToolTipContent())
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const { url } = useRouteMatch()
  const history = useHistory()

  const highlightItems = annotations || pageIds
  if (!highlightItems) return null // this should never happen, but prevents typescript errors

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation() // stop event bubbling before it gets to input
    const nextIndex = activeIndex === null ? 0 : (activeIndex + 1) % highlightItems.length

    if (annotations?.length > 0) {
      // This changes the url to the correct document before changing the highlight hash to the current annotation.
      const documentTab = url.includes('deals') ? annotations[nextIndex]?.documentId : ''
      const search = queryString.stringify({ ...(documentTab ? { documentTab } : {}) })
      history.push({ search })
      document.location.hash = `#highlight-${annotations[nextIndex]?.id}`
    } else {
      const documentTab = url.includes('deals') ? documentId : ''
      const page = pageIds?.length ? pageIds[nextIndex] : ''
      const search = queryString.stringify({ ...(documentTab ? { documentTab } : {}), ...(page ? { page } : {}) })
      history.push({ search })
    }
    setActiveIndex(nextIndex)
    setTooltipContent(generateToolTipContent())
    setCurrentAnnotationIndex(getNextAnnotationIndex())
    setShowLoadingSpinner(true)
    setTimeout(() => {
      setShowLoadingSpinner(false)
    }, 1000)
  }

  return (
    <>
      {showLoadingSpinner ? (
        <SaveProgressIcon error={false} loading={showLoadingSpinner} success={false} />
      ) : (
        <WithTooltip content={tooltipContent}>
          <span className={css.scrollIcon} onClick={handleClick}>
            <AiOutlineFileSearch />
            <div className={css.badge}>{highlightItems.length > 99 ? '99+' : highlightItems.length}</div>
          </span>
        </WithTooltip>
      )}
    </>
  )
}
