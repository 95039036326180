import { API_ROOT } from '../../utils/apiUtils'
import { FiUploadCloud } from 'react-icons/fi'
import { ModalContext } from '../../app'
import { useHistory } from 'react-router-dom'
import BulkUpload from './BulkUpload'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Lottie from 'lottie-react'
import ManualUpload from './ManualUpload'
import Modal from '../Modal'
import RadioButton from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useContext, useEffect, useState } from 'react'
import animationData from '../../assets/lottie/document-scan.json'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useUserAccess from '../../hooks/useUserAccess'

export default function UploadModal() {
  const { openModal, openPreview, setModalError } = useContext(ModalContext)
  const hasBulkUploadAccess = useUserAccess({ feature: 'ManualDocument', permission: 'BULK' })
  const [isOpen, setIsOpen] = useState(false)
  const [componentLoading, setComponentLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [requiredCount, setRequiredCount] = useState<number>(0)
  const [requiredValues, setRequiredValues] = useState([])
  const [optionalValues, setOptionalValues] = useState([])
  const [document, setDocument] = useState<File>()
  const [modalOption, setModalOption] = useState(hasBulkUploadAccess ? 'Select Upload Type' : 'Manual Upload')
  const [confirmClose, setConfirmClose] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [disabledMessage, setDisabledMessage] = useState<any>()
  const [dealData, setDealData] = useState<any>('')
  const [validationErrors, setValidationErrors] = useState<any>([])
  const [clearCounterPartyValue, setClearCounterPartyValue] = useState<boolean>(false)
  const [clearDealValue, setClearDealValue] = useState<boolean>(false)
  const history = useHistory()
  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })
  useEffect(() => {
    // validator useEffect
    if (modalOption === 'Manual Upload') {
      const message = []
      let shouldEnable = true
      if (componentLoading) {
        shouldEnable = false
        message.push('Component loading')
      }
      if (!document || !dealData.document_level || !dealData?.document_type?.id) {
        shouldEnable = false
        message.push('Missing document data')
      }
      if (requiredCount > 0 && requiredValues?.length !== requiredCount) {
        shouldEnable = false
        message.push('Missing required values')
      }
      if (shouldEnable) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
      setDisabledMessage([...message])
    }
  }, [componentLoading, requiredCount, requiredValues, modalOption, document, dealData?.document_type?.id, dealData?.document_level])

  const handleClose = () => {
    if (confirmClose) {
      setConfirmClose(false)
    } else {
      setConfirmClose(true)
    }
  }

  const openLocalModal = () => {
    setIsOpen(true)
  }

  const closeLocalModal = (redirectToDocumentsTab?: boolean) => {
    wipeForm()
    setModalOption(hasBulkUploadAccess ? 'Select Upload Type' : 'Manual Upload')
    setIsOpen(false)
    if (redirectToDocumentsTab && hasDealAccess) {
      history.push('/dashboard/documents')
    }
  }

  const wipeForm = () => {
    setIsDisabled(true)
    setConfirmClose(false)
    setRequiredCount(0)
    setRequiredValues([])
    setOptionalValues([])
    setDocument(undefined)
    setDealData('')
    setClearCounterPartyValue(true)
    setClearDealValue(true)
  }

  const handleSubmit = async () => {
    if (!confirmClose) {
      if (modalOption === 'Manual Upload') {
        setLoading(true)
        setLoadingMessage('Uploading Document…')
        await handleManualUpload()
      }
    } else {
      closeLocalModal()
    }
  }

  const handleValidationErrors = (errors: any) => {
    try {
      const parsed = JSON.parse(errors)
      // console.info('parsed:', parsed)
      // console.info('parsed:', parsed?.['1__document'])
      if (parsed?.['1__document'].length > 0) {
        setValidationErrors([...parsed?.['1__document']])
      }
    } catch (err) {
      console.info('UploadModal handleErrors error:', err)
    }
  }

  const handleError = (err: any) => {
    if (err === 'Document and document_type.id must be present') {
      setModalError({ title: 'Error: Missing required data', message: err })
      openModal()
    } else if (err === 'Counterparty name or ID is required') {
      setModalError({ title: 'Error: Missing required data', message: err })
      openModal()
    } else {
      if (err?.response?.data?.link) {
        const documentId = err.response.data.link.split('/documents/')[1] || undefined
        setModalError({
          title: 'Error uploading Document:',
          messageNode: (
            <>
              <p>{err.response.data.message}</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', maxWidth: '80%', margin: '32px auto 0 auto' }}>
                <Button variant="tertiary">
                  <a href={err.response.data.link}>{`Open Document`}</a>
                </Button>
                <Button
                  onClick={() => {
                    openPreview(documentId, true, 'Duplicate Document')
                  }}
                  variant="tertiary"
                >{`Preview Document`}</Button>
              </div>
            </>
          )
        })
      } else {
        setModalError({
          title: 'Error uploading Document:',
          message: err?.response?.data?.message || err || 'An unknown error occurred. Please contact us for assistance.'
        })
      }
      openModal()
    }
  }

  const handleManualUpload = () => {
    if (document === undefined || dealData?.document_type?.id === undefined) {
      handleError('Document and document_type.id must be present')
    } else if (!dealData?.counterparty_id && !dealData?.counterparty_name) {
      handleError('Counterparty name or ID is required')
    } else {
      let data_point_values = {}
      requiredValues.forEach((item: any) => {
        data_point_values = { ...data_point_values, ...item }
      })
      optionalValues.forEach((item: any) => {
        data_point_values = { ...data_point_values, ...item }
      })

      const formData = new FormData()
      formData.append('1__document', document)
      formData.append('1__document_type_id', dealData?.document_type?.id)
      formData.append('1__document_type_name', dealData?.document_type?.name)
      formData.append('1__data_points', JSON.stringify(data_point_values))
      if (dealData?.counterparty_id) {
        formData.append('1__counter_party_id', dealData.counterparty_id)
      }
      if (dealData?.counterparty_name) {
        formData.append('1__counter_party_name', dealData.counterparty_name)
      }
      if (dealData?.deal_id) {
        formData.append('1__deal_id', dealData.deal_id)
      }
      if (dealData?.deal_name) {
        formData.append('1__deal_name', dealData.deal_name)
      }

      // console.info('data_point_values', data_point_values);
      // formData.forEach((item, index)=>{
      //   console.info(`formData[${index}]:`, item)
      // });

      return axiosClient
        .post(`${API_ROOT}/document/manual_upload`, formData)
        .then(() => {
          setLoading(false)
          setLoadingMessage('')
          setModalOption('Success!')
        })
        .catch(err => {
          console.error('ManualUpload upload error:', err?.response?.data?.message || err)
          if (err?.response?.data?.status === 'validation-error') {
            handleValidationErrors(err?.response?.data?.message)
          } else {
            handleError(err)
          }
          setLoading(false)
          setLoadingMessage('')
        })
    }
  }

  const handleRequestClose = () => {
    if (modalOption === 'Success!') {
      closeLocalModal()
    } else if (modalOption === 'Select Upload Type' || modalOption === 'Bulk Upload') {
      if (!componentLoading) {
        closeLocalModal()
      }
    } else if (!loading) {
      setConfirmClose(true)
    }
  }

  return (
    <>
      <Button onClick={openLocalModal} style={{ marginLeft: 12 }}>
        <FiUploadCloud />
        <span style={{ marginLeft: 8 }}>Upload</span>
      </Button>
      <Modal isOpen={isOpen} onRequestClose={handleRequestClose} title={confirmClose ? 'Confirm Close' : modalOption}>
        <div className={css.modal}>
          {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} />}
          {confirmClose ? (
            <p>{`Are you sure you want to close? Inputted data will be lost.`}</p>
          ) : (
            <>
              {modalOption === 'Select Upload Type' && <SelectUploadType closeModal={closeLocalModal} setModalOption={setModalOption} />}
              {modalOption === 'Manual Upload' && (
                <ManualUpload
                  clearCounterPartyValue={clearCounterPartyValue}
                  clearDealValue={clearDealValue}
                  componentLoading={componentLoading}
                  dealData={dealData}
                  document={document}
                  optionalValues={optionalValues}
                  requiredValues={requiredValues}
                  setClearCounterPartyValue={setClearCounterPartyValue}
                  setClearDealValue={setClearDealValue}
                  setComponentLoading={setComponentLoading}
                  setDealData={setDealData}
                  setDocument={setDocument}
                  setOptionalValues={setOptionalValues}
                  setRequiredCount={setRequiredCount}
                  setRequiredValues={setRequiredValues}
                  validationErrors={validationErrors}
                  wipeForm={wipeForm}
                />
              )}
              {modalOption === 'Bulk Upload' && <BulkUpload closeModal={closeLocalModal} setComponentLoading={setComponentLoading} />}
              {modalOption === 'Success!' && <Success />}
            </>
          )}
          {modalOption !== 'Select Upload Type' && modalOption !== 'Bulk Upload' && (
            <div className={css.modalButtonRow}>
              {modalOption === 'Success!' ? (
                <Button
                  onClick={() => {
                    const REDIRECT_TO_DOCUMENTS = true
                    closeLocalModal(REDIRECT_TO_DOCUMENTS)
                  }}
                  variant={'secondary'}
                >
                  {hasDealAccess ? 'Go to documents' : 'Close'}
                </Button>
              ) : (
                <>
                  <Button onClick={handleClose} variant={'secondary'}>
                    Cancel
                  </Button>
                  <Button disabled={confirmClose ? false : isDisabled} onClick={handleSubmit} title={confirmClose ? '' : isDisabled ? disabledMessage : ''}>
                    {confirmClose ? 'Close' : 'Submit'}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

function Success() {
  return (
    <div className={css.success}>
      <Lottie animationData={animationData} style={{ height: 100 }} />
      <p>{`Your document was successfully uploaded.`}</p>
      <p>{`It will be available when finished processing.`}</p>
    </div>
  )
}

function SelectUploadType({ closeModal, setModalOption }: { closeModal?: any; setModalOption?: any }) {
  const [bulkUpload, setBulkUpload] = useState(false)

  const handleChange = (e: any) => {
    const actualValue = e?.target?.value === 'true'
    setBulkUpload(actualValue)
  }

  const handleSubmit = () => {
    setModalOption(bulkUpload ? 'Bulk Upload' : 'Manual Upload')
  }

  return (
    <>
      <div className={css.radioRow}>
        <h4>{`How many documents are you uploading?`}</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'bulkUpload_false'}
            isSelected={!bulkUpload}
            label={'One'}
            name={'bulkUpload'}
            style={{ marginRight: '16px' }}
            value={false}
          />
          <RadioButton handleChange={handleChange} id={'bulkUpload_true'} isSelected={!!bulkUpload} label={'Multiple'} name={'bulkUpload'} value={true} />
        </div>
      </div>
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <Button onClick={handleSubmit}>{'Next'}</Button>
      </div>
    </>
  )
}
