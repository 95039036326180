import { useCciNeutralTagsLazyQuery } from '../../../../../../../graphql/codegen/hooks'
import InlineQueryMenu from '../../../../../../../components/InlineQueryMenu'
import React, { useEffect } from 'react'

export default function SelectOrCreateNeutralTag({ clearSelectedValue, neutralTag, setClearSelectedValue, setNeutralTag }: any) {
  const [getCciNeutralTags, { data: neutralTags, loading: neutralTagsLoading }] = useCciNeutralTagsLazyQuery({
    context: { queryName: 'NeutralTagInput.tsx getCciNeutralTags' },
    fetchPolicy: 'network-only'
    // this fetch policy makes sure we will always have all current neutral tags.
    // always firing should have minimal impact- will only fire when creating an internal field or if tech team opens edit neutral tag
  })

  useEffect(() => {
    getCciNeutralTags()
    // eslint-disable-next-line
    }, [])

  const handleChange = (label: string, value: string) => {
    setNeutralTag({ label, value })
  }

  return (
    <InlineQueryMenu
      clearSelectedValue={clearSelectedValue}
      existingData={neutralTag}
      handleChange={handleChange}
      loading={neutralTagsLoading}
      queryData={neutralTags}
      queryFunction={getCciNeutralTags}
      queryItemName={'neutral tags'}
      queryName={'CCI Neutral Tags'}
      queryNoResultsMessage={'No neutral tags found.'}
      queryPlaceholder={'Select an existing or create a new Neutral Tag'}
      setClearSelectedValue={setClearSelectedValue}
    />
  )
}
