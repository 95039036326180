import { RiPushpin2Line } from 'react-icons/ri'
import { formatCommentText } from '../../utils/commentUtils'
import { formatTimeAgo } from '../../utils/datetimeUtils'
import { useUsersQuery } from '../../graphql/codegen/hooks'
import CommentBox from '../CommentBox'
import DOMPurify from 'dompurify'
import React, { ReactNode } from 'react'
import Skeleton from 'react-loading-skeleton'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

interface Props {
  author?: any
  comment?: any
  date: string
  editState?: any
  handleSave?: (x: any) => void
  id: string
  isDisabled?: boolean
  isEditing?: boolean
  renderActions?: (commentId: string) => ReactNode
  renderTopRow?: (author?: string, timeAgo?: string) => ReactNode
  setIsEditing?: (x: boolean) => void
  text: string
}

export default function Comment({
  author,
  comment,
  date,
  editState,
  handleSave,
  id,
  isDisabled,
  isEditing,
  renderActions,
  renderTopRow,
  setIsEditing,
  text
}: Props) {
  const { data, loading: usersLoading } = useUsersQuery({ context: { queryName: `Comment.tsx useUsersQuery` } })
  const users = data?.users?.map((node: any) => ({ id: node.id, display: node.user_name }))
  const currentUser = useCurrentUser()

  const _renderTopRow = () => {
    const timeAgo = date && formatTimeAgo(date)
    if (renderTopRow) {
      return renderTopRow(author, timeAgo)
    }
    const isMine = currentUser?.id === comment?.created_by?.id

    let pinnedBy = ''
    let timeAgoMessage = ''
    let contentMessage = ''

    if (comment?.is_pinned) {
      pinnedBy = isMine ? 'You' : comment?.pinned_by?.first_name || false
      timeAgoMessage = comment?.pinned_at ? `${formatTimeAgo(comment?.pinned_at)} ago` : ''
      contentMessage = pinnedBy ? `Pinned by ${pinnedBy} ${timeAgoMessage}` : `Pinned ${timeAgoMessage}`
    }

    return (
      <>
        {comment?.is_pinned && (
          <WithTooltip content={contentMessage}>
            <div className={css.icon}>
              <RiPushpin2Line />
            </div>
          </WithTooltip>
        )}
        <div>{`${author} ${timeAgo && `${timeAgo} ago`}`}</div>
      </>
    )
  }

  const _renderActions = () => {
    if (renderActions && !isEditing) {
      return renderActions(id)
    } else {
      return null
    }
  }

  const _renderCommentBody = () => {
    if (usersLoading) {
      return <Skeleton />
    }
    if (!users) {
      return null
    }
    let sanitizedLines = ''
    try {
      // All comment text is escaped before save on the backend, but we use DOMPurify to be safe.
      const sanitizedCommentText = DOMPurify.sanitize(formatCommentText(text as string, users))
      sanitizedLines = sanitizedCommentText.split(/\n/).join('<br/>')
    } catch (error) {
      console.error(error)
    }

    if (isEditing && handleSave) {
      return (
        <CommentBox autofocus commentState={editState} handleCancel={() => setIsEditing && setIsEditing(false)} handleCreate={handleSave} initialValue={text} />
      )
    }
    return <div className={css.commentText} dangerouslySetInnerHTML={{ __html: sanitizedLines }} />
  }

  return (
    <div className={clsx(css.comment, isDisabled && css.disabled)}>
      <div className={css.topRow}>
        {_renderTopRow()}
        <div className={css.commentActions}>{_renderActions()}</div>
      </div>
      {_renderCommentBody()}
    </div>
  )
}
