import * as queryString from 'query-string'
import { Column } from '../reducers/dashboardQueryReducer'
import { RuleGroupType } from 'react-querybuilder'
import { encodeQuery, generateColumnsToSave } from '../utils/dashboardQueryUtils'
import { useHistory } from 'react-router-dom'
import AdvancedSearch from '../components/AdvancedSearch'
import React, { Dispatch, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'
import useAttachmentDashboardFields from '../hooks/useAttachmentDashboardFields'
import useDashboardQString from '../hooks/useDashboardQString'

export default function AttachmentSearchContainer({
  clearAll,
  filteredCount,
  selectedColumns,
  setClearAll,
  totalCount
}: {
  clearAll: boolean
  filteredCount: number
  selectedColumns: Column[]
  setClearAll: Dispatch<SetStateAction<boolean>>
  totalCount: number
}) {
  const history = useHistory()
  const { fields, loading } = useAttachmentDashboardFields()
  const { queryBuilderQuery } = useDashboardQString()

  function handleSearch(q?: RuleGroupType | null) {
    if (!q) {
      return history.push('/dashboard/attachments')
    }
    // This func takes the currently selected cols and adds any columns relevant to the search and then formats them to be stored in the query string
    const cols = generateColumnsToSave(q, selectedColumns)
    history.push({ search: queryString.stringify({ cols, q: encodeQuery(q) }, { arrayFormat: 'comma' }) })
  }

  if (loading) return <Skeleton />

  return (
    <AdvancedSearch
      clearAll={clearAll}
      fields={fields}
      filteredCount={filteredCount}
      handleSearch={handleSearch}
      query={queryBuilderQuery}
      recordType="attachment"
      selectedColumns={selectedColumns}
      setClearAll={setClearAll}
      totalCount={totalCount}
    />
  )
}
