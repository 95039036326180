import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import React, { memo } from 'react'
import css from './style.module.scss'

const DocumentField = ({ field, title }: { field: any; title: string }) => {
  return (
    <>
      {' '}
      <span className={css.documentPayloadTitle}>{title}</span>
      {field}{' '}
    </>
  )
}

const DocumentPayload = memo(({ documentId }: { documentId: string }) => {
  const { data, loading } = useDocumentsQuery({ variables: { documentId }, context: { queryName: `DocumentPayload.tsx useDocumentsQuery` } })
  const document = data?.documents?.edges?.[0]?.node

  const SHOULD_SHOW_DOCUMENT_METADATA = !loading && document?.integration_type === 'MANUAL'

  if (SHOULD_SHOW_DOCUMENT_METADATA) {
    return (
      <div className={css.documentPayloadContainer}>
        <p>{document?.integration_type ? <DocumentField field={document?.integration_type} title="Document source: " /> : null} </p>
        <p>{document?.created_by?.user_name ? <DocumentField field={document?.created_by?.user_name} title="Created by: " /> : null} </p>
      </div>
    )
  }
  return null
})

export default DocumentPayload
