import { Link, useHistory } from 'react-router-dom'
import { useDealsAndDocumentsWithAttachmentLazyQuery } from '../../../graphql/codegen/hooks'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

export default function AttachedTo({
  attachmentDocId,
  closeModal,
  fileName,
  setModalLoading
}: {
  attachmentDocId?: string
  closeModal: any
  fileName?: string
  setModalLoading: any
}) {
  const history = useHistory()
  const [error, setError] = useState('')
  const [deals, setDeals] = useState([])
  const [documents, setDocuments] = useState([])

  const [getAttachedTo, { loading: attachedToLoading }] = useDealsAndDocumentsWithAttachmentLazyQuery({
    context: { queryName: `AttachedTo.tsx getAttachedTo` },
    onCompleted: data => {
      // @ts-ignore
      if (data?.deals_and_documents_with_attachment?.deals?.length > 0) {
        // @ts-ignore
        setDeals([...data?.deals_and_documents_with_attachment?.deals])
      } // @ts-ignore
      if (data?.deals_and_documents_with_attachment?.documents?.length > 0) {
        // @ts-ignore
        setDocuments([...data?.deals_and_documents_with_attachment?.documents])
      }
    }
  })

  useEffect(() => {
    if (attachmentDocId) {
      getAttachedTo({ variables: { attachment_document_id: attachmentDocId } })
    } else {
      setError(`Error loading data: Attachment's document id is missing`)
    }
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (attachedToLoading) {
      setModalLoading(true)
    } else {
      setModalLoading(false)
    }
    // eslint-disable-next-line
    }, [])

  return (
    <>
      <ComponentLoadingOverlay loading={attachedToLoading} message={''} />
      {!attachedToLoading && (
        <>
          {fileName && <h4>{fileName}</h4>}
          {error && <p>{error}</p>}
          {deals?.length > 0 || documents?.length > 0 ? (
            <>
              <p>{`This file is attached to the following items:`}</p>
              {deals?.length > 0 && (
                <>
                  <p>{`Deals:`}</p>
                  <div className={css.itemContainer}>
                    {deals?.map((item: any, index: number) => {
                      return (
                        <WithTooltip content={'Open Deal'} key={`${item?.id}${index}`}>
                          <div
                            className={css.menuItem}
                            onClick={() => {
                              history.push(`/deals/${item?.id}/attachments`)
                            }}
                          >
                            <Link to={`/deals/${item?.id}/attachments`}>{item?.name}</Link>
                          </div>
                        </WithTooltip>
                      )
                    })}
                  </div>
                </>
              )}
              {documents?.length > 0 && (
                <>
                  <p>{`Documents:`}</p>
                  <div className={css.itemContainer}>
                    {documents?.map((item: any, index: number) => {
                      return (
                        <WithTooltip content={'Open Document'} key={`${item?.id}${index}`}>
                          <div
                            className={css.menuItem}
                            onClick={() => {
                              history.push(`/documents/${item?.id}/attachments`)
                            }}
                          >
                            <Link to={`/documents/${item?.id}/attachments`}>{item?.name}</Link>
                          </div>
                        </WithTooltip>
                      )
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <p>{`This file is not attached to any deals or documents.`}</p>
          )}
          <div className={css.modalButtonRow}>
            <Button onClick={closeModal} variant={'secondary'}>{`Close`}</Button>
          </div>
        </>
      )}
    </>
  )
}
