import React, { SyntheticEvent, useState } from 'react'
import type { DataPointField } from '../../../graphql/codegen/schemas'
// import DataTableIcon from './DataTableIcon';
import DateInput from './DateInput'
import Dropdown from './Dropdown'
import FreeText from './FreeText'
import Percentage from './Percentage'
// TODO: Refactor and use same component that's being used in GenericDataPointInput.tsx
import NumberInput from './NumberInput'

export default function UploadInputs({
  dataPointField,
  handleChange,
  isError,
  value,
  valueType
}: {
  dataPointField: DataPointField
  handleChange: any
  isError: boolean
  value: any
  valueType: string
}) {
  const [isFocused, setFocused] = useState<boolean>(false)
  const { field_type } = dataPointField || {}

  switch (field_type) {
    // case 'DATA_TABLE':
    //   return <DataTableIcon dataPoint={dataPoint} />;

    case 'TEXT':
    case 'DURATION':
    case 'CURRENCY':
    case 'CURRENCY_TYPE':
    case 'GEO_CITY':
    case 'GEO_STATE':
    case 'GEO_COUNTRY':
    case 'ADDRESS':
      return (
        <FreeText
          isError={isError}
          isFocused={isFocused}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => handleChange(valueType, dataPointField.id, e.currentTarget.value)}
          placeholder={dataPointField?.name}
          setFocused={setFocused}
          value={value}
        />
      )

    case 'DROP_DOWN':
    case 'BOOLEAN':
      return (
        <Dropdown
          data_point_field={dataPointField}
          isError={isError}
          isFocused={isFocused}
          onChange={v => {
            handleChange(valueType, dataPointField.id, v?.label || '')
          }}
          setFocused={setFocused}
          value={value ? { value: value.toLowerCase().replace(' ', '_'), label: value } : null}
        />
      )

    case 'MULTI_SELECT_DROP_DOWN':
      return (
        <Dropdown
          data_point_field={dataPointField}
          isError={isError}
          isFocused={isFocused}
          isMulti
          onChange={(v: any) => {
            const mappedValues = v?.map((v: any) => v.label) || []
            // @ts-ignore
            const duplicatesRemoved = [...new Set(mappedValues)]
            handleChange(valueType, dataPointField.id, '', duplicatesRemoved)
          }}
          setFocused={setFocused}
          value={value?.length > 0 ? value?.map((v: any) => ({ label: v, value: v })) : ''}
        />
      )

    case 'PERCENTAGE':
      return (
        <Percentage
          isError={isError}
          isFocused={isFocused}
          name={dataPointField?.name}
          onChange={e => handleChange(valueType, dataPointField.id, e.currentTarget.value)}
          setFocused={setFocused}
          value={value}
        />
      )

    case 'FLOAT':
    case 'NUMBER':
      return (
        <NumberInput
          decimalScale={field_type === 'FLOAT' ? 3 : 0}
          isError={isError}
          onChange={(e: SyntheticEvent<HTMLInputElement>) => handleChange(valueType, dataPointField.id, e.currentTarget.value)}
          placeholder={dataPointField?.name}
          value={value}
        />
      )

    case 'DATE':
      return (
        <DateInput
          displayFormat={dataPointField?.value_format}
          isError={isError}
          onChange={v => {
            setFocused(false)
            handleChange(valueType, dataPointField.id, v || '')
          }}
          setFocused={setFocused}
          value={value}
        />
      )

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.info(`Unsupported field_type: ${field_type}`)
      }
      return null
  }
}
