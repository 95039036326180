import { useCciMainContext } from '../../../../CCI_Main'
import Button from '../../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import RoleInput from './RoleInput'
import TextInput from '../CCI_Inputs/TextInput'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'

export default function EditUser({ roleOptions }: { roleOptions?: any }) {
  const { activeComponent, openModal, selectedItem, tab } = useCciMainContext()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)

  // wipe values on tab or component change
  useEffect(() => {
    setValues(undefined)
    // eslint-disable-next-line
    }, [selectedItem, tab, activeComponent])

  const handleData = (type: string, value: any) => {
    // console.info('handleData firing, type:', type, 'value:', value)
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`EditUser handleData error: Must provide a valid option`)
          return
        case 'options':
          return setValues((prev: any) => {
            return { ...prev, options: [...value] }
          })
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(selectedItem, values, setIsDisabled, setDisabledMessage)
    // eslint-disable-next-line
    }, [values])

  return (
    <>
      {selectedItem ? (
        <>
          <div>
            <h3 style={{ textAlign: 'center', marginBottom: '24px' }}>{`Edit User`}</h3>
            <TextInput
              displayName={'First Name:'}
              handleData={handleData}
              placeholder={selectedItem?.first_name || `Enter user's first name`}
              value={values?.first_name}
              variableName={'first_name'}
            />
            <TextInput
              displayName={'Last Name:'}
              handleData={handleData}
              placeholder={selectedItem?.last_name || `Enter user's last name`}
              value={values?.last_name}
              variableName={'last_name'}
            />
            <RoleInput
              currentValues={selectedItem?.roles?.edges?.map((edge: any) => {
                return { value: edge?.node?.id, label: edge?.node?.name }
              })}
              handleData={handleData}
              options={roleOptions}
            />
          </div>
          <div className={css.modalButtonRow}>
            {/* <Button onClick={()=>{console.info('values', values)}} variant={'secondary'}>Console log values</Button> */}
            <WithTooltip content={disabledMessage}>
              <Button
                disabled={isDisabled}
                onClick={() => {
                  openModal({ menuOption: 'Review User Edits', content: { userId: selectedItem?.id, userName: selectedItem?.user_name, values } })
                }}
              >
                {`Review Changes`}
              </Button>
            </WithTooltip>
          </div>
        </>
      ) : (
        <p style={{ textAlign: 'center' }}>{`Select a user from the left panel to edit`}</p>
      )}
    </>
  )
}

const validateInputs = (selectedItem?: any, values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []
  if (!values || (Object.keys(values).length === 0 && values.constructor === Object)) {
    errors.push('No changes have been made')
  }
  if (values?.first_name && values?.first_name === selectedItem?.first_name) {
    errors.push(`Cannot set first name to the current value`)
  }
  if (values?.last_name && values?.last_name === selectedItem?.last_name) {
    errors.push(`Cannot set last name to the current value`)
  }
  const noRolesAdded = !values?.roles_to_add?.length || values?.roles_to_add?.length < 1
  const allCurrentRolesRemoved = values?.roles_to_remove?.length > 0 && values?.roles_to_remove?.length === selectedItem?.roles?.edges?.length
  if (noRolesAdded && allCurrentRolesRemoved) {
    errors.push(`At least one role is required`)
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}
