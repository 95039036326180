import { Link } from 'react-router-dom'
import { fetchIDP } from '../../utils/sessionApiUtils'
import { useLocation } from 'react-router'
import Button from '../../components/Button'
import Input from '../../components/Input'
import React, { SyntheticEvent, useState } from 'react'
import css from './style.module.scss'
import logo from '../../assets/logo-full-black.png'
import oktaClient from '../../utils/oktaClient'

export default function Login() {
  const [{ email, error, loading }, setState] = useState<{ email: string; error?: any; loading: boolean }>({ loading: false, error: null, email: '' })
  const location = useLocation<any>()

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!email) {
      return setState(prev => ({ ...prev, loading: false, error: 'Please enter your email' }))
    }
    setState(prev => ({ ...prev, loading: true }))
    if (location?.state?.from?.pathname) {
      window.localStorage.setItem('from', location?.state?.from?.pathname)
    }

    // idp will be an empty string for internal users
    try {
      const idp = await fetchIDP(email)
      const options = idp ? { idp } : null
      oktaClient.token.getWithRedirect(options)
    } catch (e: any) {
      const defaultErrorMessage = (
        <>
          An error has occurred. Please double-check your credentials and <Link to="/contact-us">contact us</Link> if the problem persists.
        </>
      )
      const error = e?.response?.data?.error || defaultErrorMessage
      return setState(prev => ({ ...prev, loading: false, error }))
    }
  }

  return (
    <div className={css.loginPage}>
      <form className={css.loginForm} onSubmit={handleSubmit}>
        <div className={css.logoContainer}>
          <img alt="Klarity Logo" src={logo} />
        </div>
        <Input
          autoComplete="true"
          name="email"
          onChange={e => {
            setState({ email: e.target.value, loading: false, error: false })
          }}
          placeholder="Email"
          type="email"
          value={email}
        />
        <div>
          <Button disabled={loading || error} htmlType="submit">{`Log In`}</Button>
          {error && (
            <div className={css.error}>
              <p>{error}</p>
            </div>
          )}
          <Link to="/contact-us">
            <Button disabled={loading || error} variant="secondary">{`Sign up`}</Button>
          </Link>
        </div>
      </form>
    </div>
  )
}
