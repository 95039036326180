import * as queryString from 'query-string'
import { Column } from '../../../reducers/dashboardQueryReducer'
import { DashboardQueriesDocument, useCreateDashboardQueryMutation } from '../../../graphql/codegen/hooks'
import { FiSave } from 'react-icons/fi'
import { Formik } from 'formik'
import { encodeQuery, generateColumnsToSave } from '../../../utils/dashboardQueryUtils'
import { useLocation } from 'react-router-dom'
import Button from '../../Button'
import Input from '../../Input'
import Modal from '../../Modal'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import Toggle from '../../Toggle'
import WithTooltip from '../../WithTooltip'
import css from '../style.module.scss'

export default function SavedQueryModal({
  isDealsDashboard,
  selectedColumns,
  tempQuery
}: {
  isDealsDashboard: any
  selectedColumns: Column[]
  tempQuery: any
}) {
  const [isOpen, setIsOpen] = useState(false)
  const closeIt = () => setIsOpen(false)
  const [formattedTempQuery, setFormattedTempQuery] = useState<string | undefined>(undefined)

  useEffect(() => {
    // This func takes the currently selected cols and adds any columns relevant to the search and then formats them to be stored in the query string
    const cols = generateColumnsToSave(tempQuery, selectedColumns)
    const tempQueryFormatted = queryString.stringify({ cols, q: encodeQuery(tempQuery) }, { arrayFormat: 'comma' })
    setFormattedTempQuery(tempQueryFormatted)
  }, [tempQuery, selectedColumns])

  return (
    <>
      <WithTooltip content="Save search rules">
        <Button icon={<FiSave />} onClick={() => setIsOpen(true)} />
      </WithTooltip>
      <Modal isOpen={isOpen} onRequestClose={closeIt} title="Save Query">
        <CreateQueryModalContent closeModal={closeIt} formattedTempQuery={formattedTempQuery} isDealsDashboard={isDealsDashboard} />
      </Modal>
    </>
  )
}

function CreateQueryModalContent({
  closeModal,
  formattedTempQuery,
  isDealsDashboard
}: {
  closeModal: () => void
  formattedTempQuery?: string
  isDealsDashboard: boolean
}) {
  const location = useLocation()
  const isAttachmentsDashboard = location?.pathname?.includes('/dashboard/attachments') || false
  const [createDashboardQuery, { error: submissionError, loading }] = useCreateDashboardQueryMutation({
    context: { queryName: 'SavedQueryModal.ts useCreateDashboardQueryMutation' },
    onCompleted: closeModal,
    refetchQueries: [
      {
        query: DashboardQueriesDocument,
        variables: { dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT' },
        context: { queryName: 'SavedQueryModal.tsx DashboardQueriesDocument' }
      }
    ],
    awaitRefetchQueries: true
  })

  return (
    // NOTE: isDefault is expected by the api, to be implemented on the frontend in the future
    <Formik
      initialValues={{ name: '', isShared: true, isDefault: false }}
      onSubmit={values => {
        createDashboardQuery({
          variables: {
            ...values,
            queryString: formattedTempQuery || '',
            dashboard: isAttachmentsDashboard ? 'ATTACHMENT' : isDealsDashboard ? 'DEAL' : 'DOCUMENT'
          }
        })
      }}
      validate={values => {
        const errors: { [k: string]: string } = {}
        if (!values.name) {
          errors.name = 'Required'
        }
        return errors
      }}
    >
      {({ errors, handleSubmit, setFieldValue, touched, values }) => {
        return (
          <form className={css.saveQueryModalContent} onSubmit={handleSubmit}>
            <label>
              <h4>{`Query Name:`}</h4>
              <Input
                onChange={(e: SyntheticEvent<HTMLInputElement>) => setFieldValue('name', e.currentTarget.value)}
                placeholder="Give this query a unique name"
                value={values.name}
              />
              {errors.name && touched.name && <p className="error">{errors.name}</p>}
            </label>
            <div className={css.row}>
              <Toggle checked={values.isShared} onChange={(e: SyntheticEvent<HTMLInputElement>) => setFieldValue('isShared', e.currentTarget.checked)} />
              <div>
                <h4>{`Share query`}</h4>
                <p>{`Make this query available to others within your organization.`}</p>
              </div>
            </div>
            <Button loading={loading}>{`Save`}</Button>
            {submissionError && <p className="error">{`Something went wrong`}</p>}
          </form>
        )
      }}
    </Formik>
  )
}
