import { useCciMainContext } from '../../../../CCI_Main'
import { useCciRolesLazyQuery } from '../../../../../../graphql/codegen/hooks'
import ComponentLoadingOverlay from '../../../../../../components/ComponentLoadingOverlay'
import CreateUser from './CreateUser'
import EditUser from './EditUser'
import React, { useEffect, useState } from 'react'

export default function CCI_RightPanel_UsersTab_Main() {
  const { activeComponent } = useCciMainContext()
  const [roleOptions, setRoleOptions] = useState<any>(undefined)

  const [getRoles, { data: roleData, loading: rolesLoading }] = useCciRolesLazyQuery({ context: { queryName: 'CCI_RightPanel_UsersTab_Main.tsx getRoles' } })

  useEffect(() => {
    getRoles()
  }, [getRoles])

  useEffect(() => {
    const roles = roleData?.cci_roles?.map(item => {
      return { value: item?.id, label: item?.name }
    })
    setRoleOptions(roles && roles?.length > 0 ? [...roles] : undefined)
  }, [roleData])

  return (
    <>
      <ComponentLoadingOverlay loading={rolesLoading} />
      {activeComponent === '' && <EditUser roleOptions={roleOptions} />}
      {activeComponent === 'Create User' && <CreateUser roleOptions={roleOptions} />}
      {activeComponent === 'Edit User' && <EditUser roleOptions={roleOptions} />}
    </>
  )
}
