// FIXME: removing tschecking for automated upload demo in order to use CreatableSelect and Select interchangeably
// @ts-nocheck
import CreatableSelect from 'react-select/creatable'
import React from 'react'
import Select from 'react-select'

export default function SelectInput({ creatable, isError, ...rest }: { [x: string]: any; creatable?: boolean; isError?: any }) {
  const Component = creatable ? CreatableSelect : Select

  return (
    <Component
      {...rest}
      isFocused
      styles={{
        control: provided => {
          return { ...provided, borderColor: isError ? 'red' : provided.borderColor }
        },
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1
          const transition = 'opacity 300ms'
          return { ...provided, opacity, transition, padding: 0 }
        },
        multiValue: provided => {
          return { ...provided, maxWidth: '300px' }
        },
        placeholder: provided => {
          return { ...provided, color: '#57575b', fontFamily: 'Open Sans' }
        },
        menuPortal: provided => ({ ...provided, zIndex: 999999999 })
      }}
    />
  )
}
