import { useDocumentsLazyQuery, useStatesLazyQuery } from '../graphql/codegen/hooks'
import { useEffect, useState } from 'react'

// Fetch current state,
// if that state belongs to a linear workflow, present transition buttons
// if non_linear, present list of all states
export default function useDocumentTransitions(documentId?: string) {
  const [currentState, setCurrentState] = useState<any>(undefined)
  const [getDocumentData, { data: documentData, loading: documentDataLoading }] = useDocumentsLazyQuery({
    context: { queryName: `useDocumentTransitions.ts useDocumentsQuery` }
  })
  const [fetchStates, { data: stateData, loading: statesLoading }] = useStatesLazyQuery({
    context: { queryName: `useDocumentTransitions.tsx fetchStates` },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (documentId) {
      getDocumentData({ variables: { documentId } })
    }
    // eslint-disable-next-line
  }, [documentId])

  useEffect(() => {
    if (documentData) {
      setCurrentState(documentData?.documents?.edges?.[0]?.node?.state)
    }
  }, [documentData])

  useEffect(() => {
    if (currentState?.id) {
      fetchStates({ variables: { workflowId: currentState.workflow?.id } })
    }
    // eslint-disable-next-line
  }, [currentState]);

  return {
    currentState,
    isNonLinear: currentState?.workflow?.is_non_linear,
    loading: documentDataLoading || statesLoading,
    data: { states: stateData?.states }
  }
}
