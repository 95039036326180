import { Box, Container, ScopedCssBaseline } from '@mui/material'
import React, { ReactNode, StrictMode } from 'react'
import TabSelector from '../../pages/CCI/components/RightPanel/TabSelector/TabSelector'

// types

interface InnerLayoutProps {
  children: ReactNode
  tab?: string
}

// components

export const InnerLayout = ({ children, tab }: InnerLayoutProps) => (
  <>
    <StrictMode>
      <ScopedCssBaseline>
        <Box height="calc(100vh - 131.5px)" overflow="scroll" sx={{ backgroundColor: '#f3f4f8' }}>
          <Container maxWidth={false}>{children}</Container>
        </Box>
      </ScopedCssBaseline>
    </StrictMode>

    <TabSelector isNew tab={tab} />
  </>
)
