import { CurrentRuntimeEnvironment } from './envUtils'

// @ts-nocheck

const _paq = window._paq || []
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(['setDocumentTitle', document.domain + '/' + document.title])
_paq.push(['trackPageView'])
_paq.push(['enableLinkTracking'])
;(function () {
  const u = CurrentRuntimeEnvironment.REACT_APP_MATOMO_URL
  _paq.push(['setTrackerUrl', u + 'matomo.php'])
  _paq.push(['setSiteId', '3'])
  const d = document
  const g = d.createElement('script')
  const s = d.getElementsByTagName('script')[0]
  g.type = 'text/javascript'
  g.async = true
  g.defer = true
  g.src = u + 'matomo.js'
  s.parentNode.insertBefore(g, s)
})()
