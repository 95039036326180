import React, { useEffect, useState } from 'react'
import SelectInput from '../SelectInput'
import useDealTransitions from '../../hooks/useDealTransitions'
import useDocumentTransitions from '../../hooks/useDocumentTransitions'

export default function StatusSelector({ dealId, documentId, onChange }: { dealId?: string; documentId?: string; onChange: (arg: any) => void }) {
  const [stateOptions, setStateOptions] = useState(undefined)

  const { data: dealTransitionData, loading: dealTransitionsLoading } = useDealTransitions(dealId)
  const { data: documentTransitionData, loading: documentTransitionsLoading } = useDocumentTransitions(documentId)

  useEffect(() => {
    if (!stateOptions) {
      if (dealTransitionData?.states) {
        generateStateOptions(dealTransitionData?.states?.edges)
      } else if (documentTransitionData?.states) {
        generateStateOptions(documentTransitionData?.states?.edges)
      }
    }
  }, [dealTransitionData, documentTransitionData, stateOptions])

  const generateStateOptions = (stateEdges: any) => {
    setStateOptions(
      stateEdges
        ?.forEach((stateEdge: any) => {
          if (stateEdge?.node?.name === 'Processing') return
          const { id, name } = stateEdge?.node || {}
          return { label: name, value: id }
        })
        .filter(Boolean)
    )
  }

  return <SelectInput isLoading={dealTransitionsLoading || documentTransitionsLoading} onChange={onChange} options={stateOptions} placeholder="Select status" />
}
