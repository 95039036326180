import { MutationResult } from '@apollo/client'
import CommentBox from '../CommentBox'
import CommentContainer from '../../containers/CommentContainer'
import React from 'react'
import Skeleton from './Skeleton'
import clsx from 'clsx'
import css from './style.module.scss'

export default function CommentSection({
  commentState,
  comments,
  dataPointId,
  dealIsFinalized,
  handleCreate,
  loading
}: {
  commentState?: MutationResult<any>
  comments?: any
  dataPointId: string
  dealIsFinalized: boolean
  handleCreate?: (c: any) => void
  loading?: boolean
}) {
  const isEmpty = !loading && (!comments || (comments && !comments.length))

  return (
    <div className={css.commentSection}>
      <div className={css.commentListWrapper}>
        <div className={clsx(isEmpty && css.empty)}>
          {!loading ? (
            isEmpty ? (
              <div>{`Nothing to see here...`}</div>
            ) : (
              comments.map((comment: any) => {
                return <CommentContainer comment={comment} dataPointId={dataPointId} dealIsFinalized={dealIsFinalized} key={comment.id} />
              })
            )
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      {handleCreate && (
        <div className={css.bottomRow}>
          <CommentBox commentState={commentState} handleCreate={handleCreate} />
        </div>
      )}
    </div>
  )
}
