import { FiChevronDown, FiChevronUp, FiTrash2, FiXSquare } from 'react-icons/fi'
import { useCciChecklistContext } from '../CCI_RightPanel_ChecklistTab_Main'
import Button from '../../../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import Skeleton from 'react-loading-skeleton'
import css from './style.module.scss'

export default function CollisionResolutionInput({ collisionType, handleData }: { collisionType?: string; handleData: any }) {
  const { docTypesLoading, documentTypes } = useCciChecklistContext()
  const [selectedItems, setSelectedItems] = useState<Array<string>>([])

  const collisionOptions = [
    { value: 'UNION', label: 'UNION' },
    { value: 'EQUIVALENCE', label: 'EQUIVALENCE' },
    { value: 'CASCADE', label: 'CASCADE' }
  ]

  const handleCollision = (item: any) => {
    handleData('collision_type', item?.value)
  }

  const handleResolution = (index: number, value?: string, isNew?: boolean) => {
    const localArray: any[] = [...selectedItems]

    if (value === '@@_DELETE_THIS_ITEM') {
      localArray.splice(index, 1)
    } else if (isNew) {
      if (value) {
        localArray.push(value)
        setSelectedItems([...localArray])
      }
    } else {
      localArray[index] = value
      setSelectedItems([...localArray])
    }

    setSelectedItems([...localArray])
    handleData('resolution_strategy', localArray)
  }

  useEffect(() => {
    handleData('resolution_strategy', null)
    setSelectedItems([])
    // eslint-disable-next-line
    }, [collisionType])

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{`Collision Type:`}</h4>
        <SelectInput
          isClearable
          onChange={handleCollision}
          options={collisionOptions}
          placeholder={collisionType || 'Select Collision Type'}
          value={collisionType || ''}
        />
      </div>
      {collisionType === 'CASCADE' && (
        <>
          <h4>{`Resolution Strategy:`}</h4>
          {docTypesLoading ? (
            <Skeleton count={1} height={37} />
          ) : (
            <>
              {documentTypes?.length ? (
                selectedItems?.length ? (
                  <>
                    {selectedItems?.map((option: any, index: number) => {
                      return (
                        <OptionInput
                          documentTypes={documentTypes}
                          handleChange={handleResolution}
                          handleData={handleData}
                          index={index}
                          key={`${option}${index}`}
                          selectedItems={selectedItems}
                          value={option ? { value: option, label: option } : ''}
                        />
                      )
                    })}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <p>{`Error: No Document Types found`}</p>
              )}
              <OptionInput
                documentTypes={documentTypes}
                handleChange={handleResolution}
                index={9999}
                isNew
                placeholder={'Add item…'}
                selectedItems={selectedItems}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

function OptionInput({
  documentTypes,
  handleChange,
  handleData,
  index,
  isNew,
  placeholder,
  selectedItems,
  value
}: {
  documentTypes?: any
  handleChange?: any
  handleData?: any
  index: number
  isNew?: boolean
  placeholder?: string
  selectedItems?: any
  value?: any
}) {
  const [filteredDocTypeOptions, setFilteredDocTypeOptions] = useState<any>([])

  useEffect(() => {
    let filteredOptions = []
    if (!selectedItems) {
      filteredOptions = [...documentTypes]
    } else {
      filteredOptions = documentTypes?.filter((docType: any) => {
        if (selectedItems?.includes(docType?.label)) {
          return false
        } else {
          return true
        }
      })
    }
    setFilteredDocTypeOptions(
      filteredOptions?.map((option: any) => {
        return { value: option?.label, label: option?.label }
      })
    )
  }, [selectedItems, documentTypes])

  return (
    <>
      {value?.value !== '@@_DELETE_THIS_ITEM' && value !== placeholder && (
        <div className={css.inputWrapper} style={isNew ? { marginLeft: '131px' } : {}}>
          <div className={css.textInput}>
            {!isNew && <ReorderButtons handleData={handleData} index={index} selectedItems={selectedItems} />}
            <div style={{ flexGrow: 1 }}>
              <SelectInput
                isClearable
                onChange={(e: any) => {
                  handleChange(index, e?.value, isNew)
                }}
                options={filteredDocTypeOptions}
                placeholder={'Select Document Type'}
                value={value || ''}
              />
            </div>
          </div>
          {!isNew && placeholder && value && (
            <Button
              className={css.cciInput}
              icon={<FiXSquare />}
              onClick={() => {
                handleChange(index, null, false)
              }}
            />
          )}
          {(!isNew || !placeholder) && (
            <Button
              icon={<FiTrash2 color="#fc3535" />}
              onClick={() => {
                handleChange(index, '@@_DELETE_THIS_ITEM', false)
              }}
              style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
            />
          )}
        </div>
      )}
    </>
  )
}

// need to account for @@deletethisitem items
function ReorderButtons({ handleData, index, selectedItems }: { handleData?: any; index: number; selectedItems?: any }) {
  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      handleData('resolution_strategy', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== selectedItems?.length - 1) {
      handleData('resolution_strategy', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = selectedItems[fromIndex]
    selectedItems.splice(fromIndex, 1)
    selectedItems.splice(toIndex, 0, element)
    return [...selectedItems]
  }

  return (
    <div style={{ display: 'flex', marginRight: '16px', width: '115px' }}>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('up')
        }}
        style={{ visibility: index === 0 ? 'hidden' : 'unset' }}
        title="Move option up"
      >
        <FiChevronUp />
      </div>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('down')
        }}
        style={{ visibility: index === selectedItems?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </div>
    </div>
  )
}

// Union: Deal data point will display all document data points.

// Equivalence: All document data points in a deal should equal the same value. If one value is not the same, an error will be thrown.

// Cascade: Deal data point value will be chosen from the resolution strategy order's data point values. If resolution strategy is empty, then the resolution strategy is the order of the document types in the deal config.
