import { IconButton, Tooltip } from '@mui/material'
import { Info } from 'react-feather'
import { getFieldTypeLabel } from '../../../../../../utils/cci'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import DOMPurify from 'dompurify'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import Source from '../../../../../../components/DatapointField/Source'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from '../../style.module.scss'
import useUserAccess from '../../../../../../hooks/useUserAccess'

export default function CCI_LeftPanel_ChecklistCard({ dataPointField }: { dataPointField: any }) {
  const { openModal, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()
  const { documentTypesField } = useCciLeftPanelContext()
  const { description, external_mapping, field_type, id, inheritance_logic, internal_mapping, name, options } = dataPointField
  const canViewNeutralTags = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'CREATE_FIELD' })
  const canDeleteFields = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'DELETE_FIELD' })

  const createDescriptionHTML = (description?: string) => {
    if (!description) {
      return { __html: DOMPurify.sanitize('') }
    } else {
      return { __html: DOMPurify.sanitize(description) }
    }
  }

  return (
    <Tooltip followCursor title={dataPointField.is_deleted ? 'Pending removal…' : ''}>
      <div
        className={clsx(css.field, selectedItem?.id === dataPointField?.id && css.isFieldActive)}
        onClick={() => {
          if (!dataPointField.is_deleted) {
            setActiveComponent(id === documentTypesField?.id ? 'Edit Document Types' : 'Edit Field')
            setSelectedItem(dataPointField)
          }
        }}
      >
        <div className={clsx(css.labelRow, css.noSelect)}>
          <div className={css.label}>
            <span>{name}</span>
            {description && (
              <Tippy
                className={clsx(description?.length > 500 && css.description)}
                content={<div dangerouslySetInnerHTML={createDescriptionHTML(description)} />}
              >
                <Info />
              </Tippy>
            )}
            <Source {...dataPointField} />
          </div>
          {canDeleteFields && !internal_mapping?.includes('document_type') && (
            <ul className={css.actions}>
              <li style={{ ...(dataPointField.is_deleted && { cursor: 'default' }) }}>
                <Tooltip arrow title="Delete">
                  <IconButton
                    disabled={dataPointField.is_deleted}
                    onClick={event => {
                      event.stopPropagation()

                      openModal({ menuOption: 'Delete Field', content: { dataPointID: id, dataPointName: name } })

                      setActiveComponent('Delete Field')
                      setSelectedItem(dataPointField)
                    }}
                  >
                    <DeleteIcon color={dataPointField.is_deleted ? 'disabled' : undefined} />
                  </IconButton>
                </Tooltip>
              </li>
            </ul>
          )}
        </div>
        <div className={clsx(css.textRow, css.noSelect)}>
          <h5>{`Field Type:`}</h5>
          <h6>{getFieldTypeLabel(field_type)}</h6>
        </div>
        {options?.length > 0 && (
          <div className={clsx(css.textRow, css.noSelect)}>
            <h5>{`Options:`}</h5>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {options?.map((item: string, index: number) => {
                return <h6 key={name + item}>{`${item}${index !== options?.length - 1 ? ',' : ''}`}</h6>
              })}
            </div>
          </div>
        )}
        {JSON.parse(inheritance_logic)?.default_value && (
          <div className={clsx(css.textRow, css.noSelect)}>
            <h5>{`Default Value:`}</h5>
            <h6>{JSON.parse(inheritance_logic)?.default_value}</h6>
          </div>
        )}
        {canViewNeutralTags && external_mapping?.length > 0 && (
          <div className={clsx(css.textRow, css.noSelect)} style={{ minWidth: '75px' }}>
            <h5>{`Neutral Tag${external_mapping?.length > 1 ? 's' : ''}:`}</h5>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {external_mapping?.map((tag: string, index: number) => {
                return <h6 key={name + tag}>{`${tag}${index !== external_mapping?.length - 1 ? ',' : ''}`}</h6>
              })}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  )
}

// Example Data

// collision_type: null
// default_field_on_dashboard: true
// description: ""
// display_accounting_impact: false
// display_comment_field: true
// display_if_empty: true
// display_on_document: false
// external_source: "SALESFORCE"
// field_type: "TEXT"
// group: "Step 1: Identify the contract"
// group_priority: 2
// id: "RGF0YVBvaW50RmllbGQ6NWZlNDUyMGFmOGM2MjRhYjJiMGY5OGRm"
// inheritance_logic: "{\"resolution_strategy\": [\"\"]}"
// internal_mapping: []
// name: "Reseller Name"
// options: []
// priority: 4
// source: "EXTERNAL"
// value_format: ""
