import { FiChevronDown, FiChevronUp, FiTrash2, FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { KeyboardEvent, useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import SelectOrCreateNeutralTag from '../EditInputs/SelectOrCreateNeutralTag'
import css from './style.module.scss'

export default function FieldOptions({
  defaultValue,
  fieldType,
  handleData,
  tagType,
  valueFormat
}: {
  defaultValue?: string
  fieldType?: string
  handleData: any
  tagType?: string
  valueFormat?: string
}) {
  const [optionsArray, setOptionsArray] = useState<Array<any>>([])
  const optionsList = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN', 'DATE']
  const dropdownTypes = ['DROP_DOWN', 'MULTI_SELECT_DROP_DOWN']
  const isDropdown = fieldType && dropdownTypes?.includes(fieldType)
  const valueFormatOptions = [
    { value: 'MMMM/DD/YYYY', label: 'MMMM/DD/YYYY' },
    { value: 'MMMM DD, YYYY', label: 'MMMM DD, YYYY' },
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
    { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
    { value: 'DD MMMM, YYYY', label: 'DD MMMM, YYYY' }
  ]

  // @ts-ignore
  const handleChange = (value?: string, index: number, isNew?: boolean) => {
    const localArray: any[] = [...optionsArray]

    if (!isNew && localArray[index].option === defaultValue) {
      if (value === '@@_DELETE_THIS_ITEM') {
        handleData('default_value', null)
      } else {
        handleData('default_value', value)
      }
    }

    if (value === '@@_DELETE_THIS_ITEM') {
      localArray.splice(index, 1)
    } else if (isNew) {
      localArray.push({ option: value })
    } else {
      localArray[index].option = value
    }

    setOptionsArray([...localArray])
    handleData('options', localArray)
  }

  const handleDateFormat = (item: any) => {
    handleData('value_format', item?.value)
  }

  const handleNeutralTag = (index: number, value?: string) => {
    const localArray: any[] = [...optionsArray]
    localArray[index].neutral_tag = value
    setOptionsArray([...localArray])
    setOptionsArray([...localArray])
    handleData('options', localArray)
  }

  // wipe initial array on fieldType change
  useEffect(() => {
    handleData('options', null)
    handleData('value_format', null)
    // eslint-disable-next-line
    }, [fieldType])

  return (
    <>
      {/* Choose options for dropdown/multi dropdown, value_format for dates, and default values */}
      {fieldType && optionsList?.includes(fieldType) && (
        <>
          {isDropdown && (
            <>
              <h4>{`Field Options:`}</h4>
              {optionsArray?.length ? (
                optionsArray?.map((option: any, index: number) => {
                  return (
                    <div className={css.inputWrapper} key={`${option}${index}`} style={{ flexWrap: 'wrap' }}>
                      <OptionInput
                        handleChange={handleChange}
                        handleData={handleData}
                        index={index}
                        optionsArray={optionsArray}
                        placeholder={option?.option}
                        value={option?.option || ''}
                      />
                      {tagType === 'option_level' && <OptionNeutralTagInput handleNeutralTag={handleNeutralTag} index={index} />}
                    </div>
                  )
                })
              ) : (
                <></>
              )}
              <OptionInput handleChange={handleChange} index={9999} isNew placeholder={'Add item…'} />
            </>
          )}

          {/* if type = date select value format */}
          {fieldType === 'DATE' && (
            <>
              <div className={css.inputWrapper}>
                <h4>{`Value Format:`}</h4>
                <SelectInput
                  isClearable
                  onChange={handleDateFormat}
                  options={valueFormatOptions}
                  placeholder={valueFormat || 'Select Date Format'}
                  value={valueFormat || ''}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

function OptionInput({
  handleChange,
  handleData,
  index,
  isNew,
  optionsArray,
  placeholder,
  value
}: {
  handleChange?: any
  handleData?: any
  index: number
  isNew?: boolean
  optionsArray?: any
  placeholder?: string
  value?: string
}) {
  const [isFocused, setFocused] = useState(false)
  const [addItemValue, setAddItemValue] = useState('')
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  useEffect(() => {
    if (!isFocused && addItemValue) {
      handleChange(addItemValue || null, index, isNew)
      setAddItemValue('')
    } else {
      setAddItemValue(value || '')
    }
    // eslint-disable-next-line
    }, [isFocused])

  return (
    <>
      <div className={isNew ? css.optionInputWrapper : css.inputWrapper} style={isNew ? { marginLeft: '131px' } : { margin: '0' }}>
        <div className={css.textInput}>
          {!isNew && <ReorderButtons handleData={handleData} index={index} optionsArray={optionsArray} />}
          <FreeText
            isFocused={isFocused}
            onChange={(e: any) => {
              setAddItemValue(e?.target?.value)
            }}
            onKeyDown={handleKeyDown}
            placeholder={isNew ? 'Add item…' : placeholder}
            setFocused={setFocused}
            value={isFocused ? addItemValue || '' : value || ''}
          />
        </div>
        {(!isNew || !placeholder) && (
          <Button
            icon={<FiTrash2 color="#fc3535" />}
            onClick={() => {
              handleChange('@@_DELETE_THIS_ITEM', index)
            }}
            style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
          />
        )}
      </div>
    </>
  )
}

function OptionNeutralTagInput({ handleNeutralTag, index }: { handleNeutralTag: any; index: number }) {
  const [value, setValue] = useState<any>(null)
  const [clearSelectedValue, setClearSelectedValue] = useState(false)

  const handleChange = ({ label }: { label: string }) => {
    setValue(label)
  }

  useEffect(() => {
    handleNeutralTag(index, value || null)
    // eslint-disable-next-line
    }, [value])

  return (
    <>
      <div className={css.inputWrapper} style={{ marginBottom: '0' }}>
        <h4 style={{ marginLeft: '131px' }}>{`Neutral Tag:`}</h4>
        <SelectOrCreateNeutralTag clearSelectedValue={clearSelectedValue} setClearSelectedValue={setClearSelectedValue} setNeutralTag={handleChange} />
        {value && (
          <Button
            className={css.cciButton}
            icon={<FiXSquare />}
            onClick={() => {
              setValue(null)
              setClearSelectedValue(true)
            }}
          />
        )}
      </div>
    </>
  )
}

function ReorderButtons({ handleData, index, optionsArray }: { handleData?: any; index: number; optionsArray?: any }) {
  const handleClick = (direction: string) => {
    if (direction === 'up' && index !== 0) {
      handleData('options', reorderArray(index, index - 1))
    } else if (direction === 'down' && index !== optionsArray?.length - 1) {
      handleData('options', reorderArray(index, index + 1))
    }
  }

  const reorderArray = (fromIndex: number, toIndex: number) => {
    const element = optionsArray[fromIndex]
    optionsArray.splice(fromIndex, 1)
    optionsArray.splice(toIndex, 0, element)
    return [...optionsArray]
  }

  return (
    <div style={{ display: 'flex', width: '115px', marginRight: '16px' }}>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('up')
        }}
        style={{ visibility: index === 0 ? 'hidden' : 'unset' }}
        title="Move option up"
      >
        <FiChevronUp />
      </div>
      <div
        className={css.toolbarButton}
        onClick={() => {
          handleClick('down')
        }}
        style={{ visibility: index === optionsArray?.length - 1 ? 'hidden' : 'unset' }}
        title="Move option down"
      >
        <FiChevronDown />
      </div>
    </div>
  )
}

// NOTES:

// for dropdown/multi: list of freetext inputs for each option array. This calls handleChange which formats the inputs and then updates handleData
// empty input at the bottom that adds a new item to the newOptions array
// newOptions array is cleaned up on submit in edit deal- if any newOption has a value or was deleted it will fill in nulls with currentItems. @@_DELETE_THIS_ITEM fields will not be sent
// if any value is changed the entire new options array is sent to the backend in order to replace old values.

// have a way to delete an option row- trashcan button to the right of X
// throw error on submit if type has required options but is missing. update this to show errors
