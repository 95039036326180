export function formatKey(str?: string) {
  if (!str) {
    return ''
  }
  return `${str?.toLowerCase().split(/ |-/).join('_')}`
}

// Take an escaped html string, render as a string. Helpful for converting back from escaped text from the backend when you know for sure it will not be unsafely rendered in the dom
export function htmlDecode(escapedHTMLString: string) {
  const doc = new DOMParser().parseFromString(escapedHTMLString, 'text/html')
  return doc.documentElement.textContent
}

export function hideFileExtension(fileName: string) {
  if (!fileName) {
    return ''
  } // this is to support formatting aliases which can be null
  const split = fileName.split('.')

  // quick/hacky way to return anything before the first ., will not work for things that allow .s in name
  if (split.length === 2) {
    return split[0]
  } else {
    return fileName
  } // do nothing if more than one period is found
}

export function validateEmail(email: string) {
  if (!email) {
    return false
  } else
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!bytes || bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const compareArrayOfStrings = (array1: string[], array2: string[]) => {
  if (array1?.length !== array2?.length) {
    return false
  } else {
    return array1?.every((value, index) => {
      return value === array2[index]
    })
  }
}

export const array1ContainsAllOfArray2 = (array1: string[], array2: string[]) => {
  if (!array2.length) {
    return true
  } else if (array1.length < array2.length) {
    return false
  } else {
    return array2.every(item => {
      return array1.includes(item)
    })
  }
}
