import { FiTrash2, FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

export default function NameInput({ currentValue, handleData, newValue }: { currentValue?: string; handleData: any; newValue?: string }) {
  const [isFocused, setFocused] = useState(false)
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{`Description:`}</h4>
        <FreeText
          isFocused={isFocused}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => handleData('description', e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          placeholder={newValue === '@@_DELETE_THIS_ITEM' ? 'Enter a description for the field' : currentValue || 'Enter a description for the field'}
          setFocused={setFocused}
          value={newValue === '@@_DELETE_THIS_ITEM' ? '' : newValue || ''}
        />
        {newValue && newValue !== '@@_DELETE_THIS_ITEM' && (
          <Button
            className={css.cciInput}
            icon={<FiXSquare />}
            onClick={() => {
              handleData('description', null)
            }}
          />
        )}
        {currentValue && newValue !== '@@_DELETE_THIS_ITEM' && (
          <Button
            icon={<FiTrash2 color="#fc3535" />}
            onClick={() => {
              handleData('description', '@@_DELETE_THIS_ITEM')
            }}
            style={{ margin: '-1px 0 0 8px', borderRadius: '4px' }}
          />
        )}
      </div>
    </>
  )
}
