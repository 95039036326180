import { useCciMainContext } from '../../../CCI_Main'
import { useChangeNeutralTagMutation, useDataPointFieldsByInternalNameLazyQuery } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import RadioButton from '../../RightPanel/RightPanel_components/ChecklistTab/SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import SelectOrCreateNeutralTag from '../../RightPanel/RightPanel_components/ChecklistTab/EditInputs/SelectOrCreateNeutralTag'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function EditNeutralTag() {
  const { modalContent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } = useCciMainContext()
  const [DPFsByNeutralTag, setDPFsByNeutralTag] = useState<any>(undefined)
  const [neutralTag, setNeutralTag] = useState<{ label: string; value: string } | undefined>(undefined)

  const [getDPFsByNeutralTag, { data: neutralTags, error: neutralTagDPFsError, loading: neutralTagDPFsLoading }] = useDataPointFieldsByInternalNameLazyQuery({
    context: { queryName: 'EditNeutralTag.tsx getDPFsByNeutralTag' },
    onCompleted: data => {
      setDPFsByNeutralTag(data?.document_data_point_fields_by_internal_name)
    }
  })

  const [changeNeutralTag, { error: changeNeutralTagError, loading: changeNeutralTagLoading }] = useChangeNeutralTagMutation({
    context: { queryName: 'EditNeutralTag.tsx changeNeutralTag' },
    onCompleted: response => {
      setSelectedItem(response.change_neutral_tag?.data_point_fields?.[0])

      setModalSuccess('Neutral tag successfully updated.')
    }
  })

  useEffect(() => {
    if (modalContent?.oldTag) {
      getDPFsByNeutralTag({ variables: { internal_name: modalContent.oldTag } })
    }
    // eslint-disable-next-line
    }, [modalContent?.oldTag])

  useEffect(() => {
    if (neutralTagDPFsLoading) {
      setModalLoading(true)
      setModalLoadingMessage('')
    } else if (changeNeutralTagLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Updating Neutral Tag…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
  }, [neutralTags, neutralTagDPFsLoading, changeNeutralTagLoading, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    if (neutralTagDPFsError) {
      setMessageTitle('Error loading neutral tags:')
      setModalError(neutralTagDPFsError)
    } else if (changeNeutralTagError) {
      setMessageTitle('Error updating neutral tag:')
      setModalError(changeNeutralTagError)
    }
  }, [neutralTagDPFsError, changeNeutralTagError, setModalError, setMessageTitle])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <h4 style={{ marginRight: '8px' }}>{`Field Name:`}</h4>
        <p>{modalContent?.fieldName}</p>
      </div>
      <div style={{ display: 'flex', marginBottom: DPFsByNeutralTag?.length === 1 ? '16px' : '' }}>
        <h4 style={{ marginRight: '8px' }}>{`Current Neutral Tag:`}</h4>
        <p>{modalContent?.oldTag}</p>
      </div>
      {DPFsByNeutralTag?.length === 1 ? (
        <OnlyOneFieldFound
          changeNeutralTag={changeNeutralTag}
          fieldId={modalContent?.fieldId}
          neutralTag={neutralTag}
          oldTag={modalContent?.oldTag}
          setNeutralTag={setNeutralTag}
        />
      ) : DPFsByNeutralTag?.length > 1 ? (
        <MultipleFieldsFound
          DPFsByNeutralTag={DPFsByNeutralTag}
          changeNeutralTag={changeNeutralTag}
          fieldId={modalContent?.fieldId}
          fieldName={modalContent?.fieldName}
          neutralTag={neutralTag}
          oldTag={modalContent?.oldTag}
          setNeutralTag={setNeutralTag}
        />
      ) : null}
    </>
  )
}

function OnlyOneFieldFound({ changeNeutralTag, fieldId, neutralTag, oldTag, setNeutralTag }: any) {
  return (
    <>
      <h5 style={{ marginBottom: '6px' }}>{`Select new Neutral Tag:`}</h5>
      <SelectOrCreateNeutralTag setNeutralTag={setNeutralTag} />
      {neutralTag && neutralTag !== oldTag}
      <ConfirmNeutralTagEdits changeNeutralTag={changeNeutralTag} fieldId={fieldId} neutralTag={neutralTag} oldTag={oldTag} updateAnnotations={true} />
    </>
  )
}

function MultipleFieldsFound({ DPFsByNeutralTag, changeNeutralTag, fieldId, fieldName, neutralTag, oldTag, setNeutralTag }: any) {
  const [updateOtherFields, setUpdateOtherFields] = useState<boolean | undefined>(undefined)
  const [updateAnnotations, setUpdateAnnotations] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (updateOtherFields === true) {
      setUpdateAnnotations(true)
    } // this prevents annotations from not being tied to any checklist items
    else {
      setUpdateAnnotations(undefined)
    }
  }, [updateOtherFields])

  return (
    <>
      {DPFsByNeutralTag?.length < 10 ? (
        <>
          <h5 style={{ margin: '12px 0' }}>{`These fields are also bound to the current neutral tag:`}</h5>
          {DPFsByNeutralTag?.map((tag: any, index: number) => {
            if (tag?.name === fieldName) {
              return null
            } else {
              return <p key={`${tag?.name}${index}`}>{tag?.name}</p>
            }
          })}
        </>
      ) : (
        <p>{`${DPFsByNeutralTag?.length} other fields are bound to this neutral tag.`}</p>
      )}
      <UpdateOtherFieldsInput setUpdateOtherFields={setUpdateOtherFields} updateOtherFields={updateOtherFields} />
      {updateOtherFields === false && <UpdateAnnotationsInput setUpdateAnnotations={setUpdateAnnotations} updateAnnotations={updateAnnotations} />}
      {updateOtherFields !== undefined && updateAnnotations !== undefined && (
        <>
          <h5 style={{ marginBottom: '6px' }}>{`Select new Neutral Tag:`}</h5>
          <SelectOrCreateNeutralTag setNeutralTag={setNeutralTag} />
        </>
      )}
      <ConfirmNeutralTagEdits
        changeNeutralTag={changeNeutralTag}
        fieldId={fieldId}
        neutralTag={neutralTag}
        oldTag={oldTag}
        updateAnnotations={updateAnnotations}
        updateOtherFields={updateOtherFields}
      />
    </>
  )
}

function UpdateOtherFieldsInput({ setUpdateOtherFields, updateOtherFields }: any) {
  return (
    <div className={css.radioRow} style={{ width: '100%', margin: updateOtherFields === true ? '16px auto' : '8px auto 0 auto' }}>
      <p>{`Update other fields?`}</p>
      <div>
        <WithTooltip
          content={
            <>
              {'Other fields bound to this tag will also be set to the new tag.'}
              <div>{`All annotations using this tag will also be updated.`}</div>
            </>
          }
        >
          <RadioButton
            handleChange={() => {
              setUpdateOtherFields(true)
            }}
            id={'updateOtherFields_true'}
            isSelected={!!updateOtherFields}
            label={'Yes'}
            name={'updateOtherFields'}
            style={{ marginRight: '16px' }}
            value={true}
          />
        </WithTooltip>
        <WithTooltip
          content={
            <>
              {'Other fields bound to this tag will not be updated.'}
              <div>{`Annotations using this tag may optionally be updated to the new tag.`}</div>
            </>
          }
        >
          <RadioButton
            handleChange={() => {
              setUpdateOtherFields(false)
            }}
            id={'updateOtherFields_false'}
            isSelected={updateOtherFields === false}
            label={'No'}
            name={'updateOtherFields'}
            value={'false'}
          />
        </WithTooltip>
      </div>
    </div>
  )
}

function UpdateAnnotationsInput({ setUpdateAnnotations, updateAnnotations }: any) {
  return (
    <div className={css.radioRow} style={{ width: '100%', margin: '0 auto 16px auto' }}>
      <p>{`Update annotations?`}</p>
      <div>
        <WithTooltip content={'Annotations bound to the current neutral tag will be updated to the new tag.'}>
          <RadioButton
            handleChange={() => {
              setUpdateAnnotations(true)
            }}
            id={'updateAnnotations_true'}
            isSelected={!!updateAnnotations}
            label={'Yes'}
            name={'updateAnnotations'}
            style={{ marginRight: '16px' }}
            value={true}
          />
        </WithTooltip>
        <WithTooltip content={'Current annotations will not be affected.'}>
          <RadioButton
            handleChange={() => {
              setUpdateAnnotations(false)
            }}
            id={'updateAnnotations_false'}
            isSelected={updateAnnotations === false}
            label={'No'}
            name={'updateAnnotations'}
            value={'false'}
          />
        </WithTooltip>
      </div>
    </div>
  )
}

function ConfirmNeutralTagEdits({
  changeNeutralTag,
  fieldId,
  neutralTag,
  oldTag,
  updateAnnotations,
  updateOtherFields
}: {
  changeNeutralTag: any
  fieldId: string
  neutralTag: { label: string; value: string } | undefined
  oldTag?: string
  updateAnnotations?: boolean
  updateOtherFields?: boolean
}) {
  const { closeModal } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)

  const handleTagChange = () => {
    changeNeutralTag({
      variables: {
        data_point_field_id: fieldId,
        new_neutral_tag: neutralTag?.value,
        old_neutral_tag: oldTag || undefined,
        change_other_fields: updateOtherFields || false,
        change_annotations: updateAnnotations || false
      }
    })
  }

  return (
    <>
      {neutralTag && <TypeToConfirm setConfirmed={setConfirmed} />}
      <div className={css.editNeutralTagButtonRow}>
        <Button onClick={closeModal} variant="tertiary">{`Cancel`}</Button>
        <WithTooltip
          content={
            !neutralTag ? 'No tag selected' : neutralTag?.label === oldTag ? 'Cannot select the current tag' : !confirmed ? 'Type Confirm to proceed' : ''
          }
        >
          <Button
            disabled={!neutralTag || neutralTag?.label === oldTag || !confirmed}
            onClick={handleTagChange}
            variant="primary"
          >{`Update Neutral Tag`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
