import { DataPoint } from '../../graphql/codegen/schemas'
import { DataPointDetailDocument, useResolveCollisionMutation } from '../../graphql/codegen/hooks'
import { NavLink, useRouteMatch } from 'react-router-dom'
import { RiFileCopy2Line } from 'react-icons/ri'
import { areEqualSets, formatResolutionMessage } from './collisionUtils'
import Alert from '../Alert'
import Button from '../Button'
import InputRow from '../DatapointField/InputRow'
import React, { SyntheticEvent, useState } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'
import usePromptOnUnmount from '../../hooks/usePromptOnUnmount'

export default function DataPointCollisionForm({
  dataPoint,
  dealIsFinalized,
  isSummary
}: {
  dataPoint: DataPoint
  dealIsFinalized: boolean
  isSummary?: boolean
}) {
  const defaultResolvedIds = new Set(
    dataPoint?.resolved_data_points?.edges.map(dp => {
      return String(dp?.node?.id)
    }) || []
  )
  const resolutionMessage = formatResolutionMessage(dataPoint)
  const { url } = useRouteMatch()
  const [selected, setSelected] = useState(defaultResolvedIds)

  /**
   * Form validation depends on dataPoint.data_point_field.collision_type
   *  CASCADE & EQUIVALENCE: only one value is allowed
   *  UNION: any combination
   */
  function handleChange(e: SyntheticEvent<HTMLInputElement>) {
    const { checked, value } = e.currentTarget
    if (dataPoint.data_point_field?.collision_type !== 'UNION') {
      setSelected(new Set([value]))
    }

    setSelected(prevSelected => {
      // Copy the set so we don't mutate the old one
      // @ts-ignore
      const toMutate = new Set([...prevSelected])
      if (checked) {
        return toMutate.add(value)
      }
      toMutate.delete(value)
      return toMutate
    })
  }

  const [resolveCollisionMutation, { error, loading }] = useResolveCollisionMutation({
    refetchQueries: [{ query: DataPointDetailDocument, variables: { id: dataPoint.id } }]
  })

  async function handleSubmit() {
    try {
      // @ts-ignore
      await resolveCollisionMutation({ variables: { dataPointId: dataPoint.id, resolvedDataPointIds: [...selected] } })
    } catch (error) {
      console.error(error)
    }
  }

  function handleCancel() {
    setSelected(defaultResolvedIds)
  }

  const isChange = !areEqualSets(new Set(defaultResolvedIds || []), selected)
  usePromptOnUnmount(isChange, 'Are you sure you want to leave without resolving these changes?')

  return (
    <div className={clsx(css.collisionForm, isChange && css.isChange, isSummary && css.summary)}>
      {isSummary || (
        <div className={css.header}>
          <RiFileCopy2Line />
          <h4>{`Extracted values`}</h4>
        </div>
      )}
      {isSummary || (resolutionMessage && <Alert alertText={resolutionMessage} type={dataPoint.is_collision_resolved === false ? 'warning' : 'info'} />)}
      <div className={css.documentDataPoints}>
        {dataPoint?.document_data_points?.edges.map((dp: any) => {
          if (dp?.node?.document?.external_document === true) {
            let src
            const source = dp.node.document?.document_type?.name.toUpperCase()
            if (source && source !== 'MANUAL') {
              try {
                src = require(`../../../public/s/${source}.png`)
              } catch (error) {
                console.error(error)
              }
            }

            return (
              <div className={css.occurrence} key={dp.node.id}>
                <div className={css.checkboxColumn}>
                  <input checked={selected.has(dp.node.id)} onChange={handleChange} type="checkbox" value={dp.node.id} />
                </div>
                <div className={css.inputColumn}>
                  <span style={{ color: '#bdbec2', fontSize: 10, marginLeft: 8, display: 'flex', alignItems: 'center' }}>
                    {src && <img alt={`${source?.toLowerCase()} logo`} src={src} style={{ maxHeight: 20, maxWidth: 40, marginRight: 8 }} />}
                    {dp.node.document?.document_type?.name} {dp.node.document?.alias || dp.node.document?.name}
                  </span>
                  <InputRow dataPoint={dp.node} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
                </div>
              </div>
            )
          }

          return (
            dp?.node && (
              <div className={css.occurrence} key={dp.node.id}>
                <div className={css.checkboxColumn}>
                  <input checked={selected.has(dp.node.id)} onChange={handleChange} type="checkbox" value={dp.node.id} />
                </div>
                <div className={css.inputColumn}>
                  <NavLink className={css.documentTitle} to={`${url}?documentTab=${dp.node.document?.id}`}>
                    {dp.node.document?.document_type?.name}: {dp.node.document?.alias || dp.node.document?.name}
                  </NavLink>
                  <InputRow dataPoint={dp?.node as any} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
                </div>
              </div>
            )
          )
        })}
      </div>
      {!!error && <p className="error">{`Something went wrong.`}</p>}
      <div className={css.buttons}>
        <Button className={css.resolveButton} disabled={!isChange} loading={loading} onClick={handleSubmit}>{`Resolve`}</Button>
        <Button className={css.resolveButton} disabled={!isChange} onClick={handleCancel} variant="secondary">{`Cancel`}</Button>
      </div>
    </div>
  )
}
