import React, { SyntheticEvent, useEffect, useRef } from 'react'
import css from './style.module.scss'

function Percentage({
  isError,
  isFocused,
  name,
  onChange,
  onKeyDown,
  setFocused,
  value
}: {
  isError?: boolean
  isFocused?: boolean
  name?: string
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void
  onKeyDown?: (e: any) => void
  setFocused?: (arg: boolean) => void
  value: string
}) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.focus()
    }
  }, [isFocused])

  return (
    <div>
      <input
        className={css.input}
        onBlur={() => (setFocused ? setFocused(false) : null)}
        onChange={onChange}
        onFocus={() => (setFocused ? setFocused(true) : null)}
        onKeyDown={onKeyDown}
        placeholder={name}
        ref={ref}
        style={{ border: `${isError ? '1px solid red' : ''}` }}
        type="number"
        value={value}
      />
    </div>
  )
}

export default Percentage
