import 'tippy.js/dist/tippy.css'
import {
  DealAttachmentsDocument,
  DealsDocument,
  useDeleteDocumentsFromDealMutation,
  useDocumentTypesQuery,
  useMarkDocumentAsReviewedMutation,
  useMoveDocumentToDealAttachmentMutation,
  useUpdateDocumentTypeMutation
} from '../../graphql/codegen/hooks'
import { DeleteDocumentsFromDealMutation } from '../../graphql/codegen/operations'
import { FiCheck, FiGlobe, FiPlus } from 'react-icons/fi'
import { Link, useHistory, useParams } from 'react-router-dom'
import { MdOutlineDocumentScanner } from 'react-icons/md'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import ConvertDocumentLevel from '../ModalOptions/ConvertDocumentLevel'
import DeleteDocument from '../ModalOptions/DeleteDocument'
import Modal from '../Modal'
import MoreMenu from '../MoreMenu'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import RenameFile from '../RenameFile'
import SelectInput from '../SelectInput'
import Tippy from '@tippyjs/react'
import WithTooltip from '../WithTooltip'
import clsx from 'clsx'
import css from './style.module.scss'
import useSummaryDataPoints from '../../hooks/useSummaryDataPoints'
import useUserAccess from '../../hooks/useUserAccess'

export default function DocumentSelector({ currentDocumentId, dealCounterparty, dealIsFinalized, documents, hasDealUpdateAccess, setActiveDocumentId }: any) {
  const { dealId } = useParams<{ dealId: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<null | {
    currentDocumentType?: string | undefined
    documentAlias?: string
    documentId: string
    documentName: string
    documentTypeId?: string | null
    isAccountLevel?: boolean
  }>(null)
  const history = useHistory()
  const summaryData = useSummaryDataPoints(dealId)
  // const canRenameDocument = useUserAccess({feature: 'Document', permission: 'RENAME'});
  const canDeleteDocument = useUserAccess({ feature: 'Document', permission: 'DELETE' })
  const hasAccountLevelDocumentAccess = useUserAccess({ feature: 'Document', permission: 'ACCOUNT_LEVEL' })
  const isKlarityEmployee = useIsKlarityEmployee()

  const openModal = (menuOption: string, documentId: string, documentName: string, documentAlias?: string, currentType?: string, isAccountLevel?: boolean) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ documentId, documentName, documentAlias, currentDocumentType: currentType, isAccountLevel })
    if (menuOption === 'Update Document Type') {
      setIsDisabled(true)
    }
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setIsDisabled(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(null)
      setLoadingMessage('')
    }
  }

  const getMenuOptions = ({ alias, document_type, id, is_account_level, is_reviewed, isForeignDocument, name }: any) => {
    const modalOptions: any = [
      { label: `Mark as ${is_reviewed ? 'Unreviewed' : 'Reviewed'}`, onClick: () => markAsReviewed(id, !is_reviewed) },
      { label: 'Update Document Type', onClick: () => openModal('Update Document Type', id, name, alias, document_type?.name) },
      { label: 'Rename Document', onClick: () => openModal('Rename Document', id, name, alias, document_type?.name) },
      !is_account_level && { label: 'Convert Document to Attachment', onClick: () => openModal('Convert to Attachment', id, name, alias, document_type?.name) },
      hasAccountLevelDocumentAccess &&
        is_account_level &&
        !isForeignDocument && {
          label: 'Remove Document from Customer Level',
          onClick: () => openModal('Change Document Level', id, name, alias, document_type?.name, is_account_level)
        },
      hasAccountLevelDocumentAccess &&
        !is_account_level &&
        !isForeignDocument && {
          label: 'Convert Document to Customer Level',
          onClick: () => openModal('Change Document Level', id, name, alias, document_type?.name, is_account_level)
        },
      { label: 'Remove Document from Deal', onClick: () => openModal('Remove Document', id, name, alias, document_type?.name) },
      canDeleteDocument && { label: 'Delete Document', onClick: () => openModal('Delete Document', id, name, alias, document_type?.name) }
    ]
    return [...modalOptions]
  }

  // switch to call correct function/args of modal submit button
  const handleSubmit = () => {
    if (modalOption === 'Update Document Type') {
      if (!modalContent?.documentTypeId) return
      const { documentId, documentTypeId } = modalContent
      updateDocType({ variables: { documentId, documentTypeId } })
      setLoadingMessage('Updating document type…')
    } else if (modalOption === 'Convert to Attachment') {
      if (modalContent?.documentId) {
        moveDocumentToDealAttachmentMutation({ variables: { dealId, documentId: modalContent?.documentId } })
        setLoadingMessage('Converting to attachment…')
      }
    } else if (modalOption === 'Remove Document') {
      if (modalContent?.documentId) {
        deleteDocumentsFromDealMutation({ variables: { dealId, documentIds: [modalContent.documentId] } })
        setLoadingMessage('Removing document…')
      }
    }
  }

  const handleSelect = (value: string | null) => {
    if (modalOption === 'Update Document Type') {
      setModalContent(prev => prev && { ...prev, documentTypeId: value })
      if (value) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }

  const [deleteDocumentsFromDealMutation, { loading: mutationLoading }] = useDeleteDocumentsFromDealMutation({
    context: { queryName: `DocumentSelector.tsx deleteDocumentsFromDealMutation` },
    refetchQueries: ['deals'],
    awaitRefetchQueries: true,
    onCompleted: (res: DeleteDocumentsFromDealMutation) => {
      const newDocumentId = res?.edit_deal?.deal?.documents?.edges[0]?.node?.id
      history.push(`${history.location.pathname}?documentTab=${newDocumentId}`)
    }
  })

  const [moveDocumentToDealAttachmentMutation, { loading: mutationLoading2 }] = useMoveDocumentToDealAttachmentMutation({
    context: { queryName: `DocumentSelector.tsx moveDocumentToDealAttachmentMutation` },
    refetchQueries: [
      'deals',
      { query: DealAttachmentsDocument, variables: { dealId }, context: { queryName: `DocumentSelector.tsx DealAttachmentsDocument` } }
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      history.push(history.location.pathname)
    }
  })

  const [updateDocType, { loading: updateDocTypeLoading }] = useUpdateDocumentTypeMutation({
    context: { queryName: `DocumentSelector.tsx updateDocType` },
    onCompleted: () => setModalContent(null),
    refetchQueries: [{ query: DealsDocument, variables: { d: dealId }, context: { queryName: `DocumentSelector.tsx DealsDocument` } }],
    awaitRefetchQueries: true
  })

  const [markAsReviewedMutation] = useMarkDocumentAsReviewedMutation({ context: { queryName: `DocumentSelector.tsx markAsReviewedMutation` } })
  const markAsReviewed = async (documentId: string, isReviewed: boolean) => {
    markAsReviewedMutation({ variables: { documentId, isReviewed } })
  }

  // Sort documents so reviewed items are last
  const sortedDocuments =
    documents &&
    [...documents].sort((a, b) => {
      const [aReview, bReview] = [a?.is_reviewed, b?.is_reviewed]
      return aReview === bReview ? 0 : aReview ? 1 : -1
    })

  useEffect(() => {
    if (mutationLoading || mutationLoading2) {
      setModalLoading(true)
    } else {
      setModalLoading(false)
      if (isOpen) {
        closeModal()
      }
    }
    // eslint-disable-next-line
  }, [mutationLoading, mutationLoading2])

  return (
    <>
      <div className={css.documentSelector}>
        <div className={css.inner}>
          {hasDealUpdateAccess && !dealIsFinalized && <UploadDocumentButton currentDocumentId={currentDocumentId} history={history} />}
          <div className={css.horizontalTabList}>
            {sortedDocuments?.map((doc: any, idx: any) => {
              if (!doc || doc.external_document === true) {
                return null
              }
              const { alias, counter_party, document_type, id, is_counter_party_level, is_reviewed, name } = doc
              const isActive = id === currentDocumentId
              const is_account_level = !!is_counter_party_level
              const isForeignDocument = counter_party?.id ? counter_party?.id !== dealCounterparty?.id : false
              const menuItems = getMenuOptions({ id, name, alias, document_type, is_reviewed, is_account_level, isForeignDocument })
              const uploadedBy = doc?.created_by?.user_name?.includes('klaritylaw.com')
                ? isKlarityEmployee
                  ? doc?.created_by?.user_name
                  : 'Klarity'
                : doc?.created_by?.user_name
              const tooltipContent = (
                <>
                  {alias || name}
                  {doc?.integration_type && <div>{`Source: ${doc?.integration_type}`}</div>}
                  {doc?.integration_type === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
                </>
              )

              return (
                <ScrollingTabLink isActive={isActive} key={idx + id}>
                  <Link
                    className={clsx(css.item, { [css.active]: isActive, [css.isReviewed]: is_reviewed })}
                    style={{ border: 'none' }}
                    to={`${history.location.pathname}?documentTab=${id}`}
                  >
                    <div className={css.documentSelectorContainer}>{doc?.is_legacy && <span className={css.legacyDocumentHighlight} />}</div>
                    {is_account_level && !isForeignDocument && (
                      <Tippy content="Customer Level Document">
                        <div className={css.accountLevelTooltip}>
                          <FiGlobe />
                        </div>
                      </Tippy>
                    )}
                    {isForeignDocument && (
                      <Tippy
                        content={
                          <>
                            {`This document was added from the customer:`}
                            <div>{counter_party?.name}</div>
                          </>
                        }
                      >
                        {/* The div breaks the tooltip text to a new line */}
                        <div className={css.accountLevelTooltip}>
                          <MdOutlineDocumentScanner style={{ width: '22px', height: '22px' }} />
                        </div>
                      </Tippy>
                    )}
                    <WithTooltip content={tooltipContent}>
                      <span style={{ marginRight: '8px' }}>{document_type?.name}</span>
                    </WithTooltip>
                    {is_reviewed && (
                      <WithTooltip content="Reviewed">
                        <FiCheck className={css.check} />
                      </WithTooltip>
                    )}
                    {!dealIsFinalized && hasDealUpdateAccess && <MoreMenu menuItems={menuItems} placement="top-end" withIcon />}
                  </Link>
                </ScrollingTabLink>
              )
            })}
          </div>
          {summaryData && (
            <div className={clsx(css.item, { [css.active]: currentDocumentId === 'summary' })} onClick={() => setActiveDocumentId('summary')}>{`Summary`}</div>
          )}
        </div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={modalOption === 'Delete Document' ? undefined : closeModal} title={modalTitle}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}
        <div className={css.modal}>
          {modalOption === 'Rename Document' ? (
            <RenameFile
              closeModal={closeModal}
              documentId={modalContent?.documentId}
              fileAlias={modalContent?.documentAlias}
              fileName={modalContent?.documentName}
              fileType={'Document'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          ) : modalOption === 'Delete Document' ? (
            <DeleteDocument
              closeModal={closeModal}
              deal_id={dealId}
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              document_id={modalContent?.documentId}
              setLoadingMessage={setLoadingMessage}
              setModalLoading={setModalLoading}
            />
          ) : modalOption === 'Change Document Level' ? ( // @ts-ignore
            <ConvertDocumentLevel
              closeModal={closeModal}
              deal_id={dealId}
              // @ts-ignore
              documentName={modalContent?.documentAlias || modalContent?.documentName}
              // @ts-ignore
              document_id={modalContent?.documentId}
              isAccountLevel={modalContent?.isAccountLevel}
              setLoadingMessage={setLoadingMessage}
              setModalTitle={setModalTitle}
            />
          ) : (
            <>
              <h5 style={{ textAlign: 'center' }}>{modalContent?.documentAlias || modalContent?.documentName}</h5>
              <p>{`${modalOption === 'Update Document Type' ? 'Current' : 'Document'} Type: ${modalContent?.currentDocumentType || 'Unknown'}`}</p>
              {modalOption === 'Update Document Type' && (
                <DocTypeSelector
                  onChange={(v: any) => {
                    handleSelect(v?.value)
                  }}
                />
              )}
              {modalOption === 'Convert to Attachment' && (
                <>
                  <p>{`Are you sure you want to convert this document to an attachment?`}</p>
                  <p>{`Previously resolved checklist values may be reset.`}</p>
                </>
              )}
              {modalOption === 'Remove Document' && (
                <>
                  <p>{`Are you sure you want to remove this document from this deal?`}</p>
                  <p>{`Previously resolved checklist values may be reset.`}</p>
                </>
              )}

              <div className={css.modalButtonRow}>
                <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
                <Button
                  disabled={isDisabled || updateDocTypeLoading}
                  loading={updateDocTypeLoading}
                  onClick={handleSubmit}
                  title={isDisabled ? 'Select an option' : ''}
                >{`Submit`}</Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

function ScrollingTabLink({ children, isActive }: { children: ReactNode; isActive: boolean }) {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView()
    }
  }, [isActive])
  return (
    <div className={clsx(css.scrollingTab, { [css.scrollingTabActive]: isActive })} ref={ref}>
      {children}
    </div>
  )
}

const DocTypeSelector = ({ isError, onChange }: { isError?: boolean; onChange: any }) => {
  const { data: docTypesData, loading: docTypesLoading } = useDocumentTypesQuery()
  const docTypeOptions = docTypesData?.document_types?.edges?.map(edge => {
    const { id, name } = edge?.node || {}
    return { label: name, value: id }
  })

  return (
    <SelectInput isClearable isError={isError} isLoading={docTypesLoading} onChange={onChange} options={docTypeOptions} placeholder="Select document type" />
  )
}

function UploadDocumentButton({ currentDocumentId, history }: { currentDocumentId?: string; history?: any }) {
  return (
    <div className={clsx(css.item, css.addItem, { [css.active]: currentDocumentId === 'upload' })} style={{ border: 'none', paddingRight: '16px' }}>
      <WithTooltip content="Add documents to this deal">
        <FiPlus onClick={() => history.push(`${history.location.pathname}?documentTab=upload`)} style={{ width: '22px', height: '22px' }} />
      </WithTooltip>
    </div>
  )
}
