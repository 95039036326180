import 'tippy.js/dist/tippy.css'
import { AlertTriangle, ChevronRight, Info } from 'react-feather'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom'
import { ManualReviewCheckboxes } from './ManualReviewCheckboxes'
import { MutationResult } from '@apollo/client'
import { countTruthyValues } from '../../utils/dataPointUtils'
import { isEmpty } from 'lodash'
import {
  useDataPointDetailQuery,
  useEditDataPointAccountingImpactMutation,
  useEditDataPointManualReviewMutation,
  useTransitionAuditsQuery
} from '../../graphql/codegen/hooks'
import { useIsAnnotator } from '../../hooks/useUserAccess'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import AccountingImpactButton from './AccountingImpactButton'
import CommentButton from './CommentButton'
import DOMPurify from 'dompurify'
import DataPointCollisionForm from '../DataPointOverlay/DataPointCollisionForm'
import DataPointUpdateFailure from './DataPointUpdateFailure'
import InputRow from './InputRow'
import PinnedComments from '../PinnedComments'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Source from './Source'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from './style.module.scss'
import useQString from '../../hooks/useQString'
import type { CustomState, DataPoint, DataPointEdge, DataPointField, Maybe } from '../../graphql/codegen/schemas'

export default function DatapointField({
  dataPoint,
  dealIsFinalized,
  selectedAnnotationId,
  showDataPointDetailOverlay
}: {
  dataPoint: DataPoint
  dealIsFinalized: boolean
  selectedAnnotationId?: string | null
  showDataPointDetailOverlay?: boolean
}) {
  const [isFocused, setFocused] = useState(false)
  const [accountingImpactMutation, accountingImpactMutationState] = useEditDataPointAccountingImpactMutation()
  const [manualReviewMutation, manualReviewMutationState] = useEditDataPointManualReviewMutation()
  const history = useHistory()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const isAnnotator = useIsAnnotator()
  const isKlarityEmployee = useIsKlarityEmployee()
  const needsResolution = dataPoint.is_collision_resolved === false
  const isRequiredForPushIntegration = dataPoint.data_point_field?.required_for_push_integration

  const isChildAnnotationSelected = useMemo(() => {
    let isSelected = false
    for (const annotEdge of dataPoint.annotations || []) {
      for (const annot of annotEdge?.annotations || []) {
        if (annot?.id === selectedAnnotationId) {
          isSelected = true
          break
        }
      }
    }
    if (!isSelected && dataPoint.document_data_points?.edges) {
      for (const docDataPoint of dataPoint.document_data_points.edges || []) {
        for (const annotEdge of docDataPoint?.node?.annotations || []) {
          for (const annot of annotEdge?.annotations || []) {
            if (annot?.id === selectedAnnotationId) {
              isSelected = true
              break
            }
          }
        }
      }
    }
    return isSelected
  }, [dataPoint, selectedAnnotationId])

  const ref = useRef<any>()
  useEffect(() => {
    if (isChildAnnotationSelected && ref.current) {
      // TODO: investigate using onAnimationEnd (or something similar) with the collapse animation
      setTimeout(() => ref?.current?.scrollIntoView({ block: 'center' }), 250)
    }
  }, [isChildAnnotationSelected])

  return (
    <li
      className={clsx(
        css.field,
        isFocused && css.focused,
        needsResolution && css.withCollision,
        isChildAnnotationSelected && css.isSelected,
        isRequiredForPushIntegration && css.isRequiredForPushIntegration
      )}
      id={isChildAnnotationSelected ? 'checklistItem' : ''}
      ref={ref}
    >
      <LabelAndActions
        accountingImpactMutation={accountingImpactMutation}
        dataPoint={dataPoint}
        dealId={dealId}
        dealIsFinalized={dealIsFinalized}
        documentId={documentId}
        history={history}
        isAnnotator={isAnnotator}
        manualReviewMutation={manualReviewMutation}
        needsResolution={needsResolution}
        showDataPointDetailOverlay={showDataPointDetailOverlay}
      />
      {isAnnotator && <NeutralTagRow data_point_field={dataPoint?.data_point_field} />}
      {(isAnnotator || isKlarityEmployee) && dataPoint?.annotator_needs_review && <DataPointUpdateFailure dataPoint={dataPoint} />}
      <ChildDataPoints
        accountingImpactMutationState={accountingImpactMutationState}
        dataPoint={dataPoint}
        dealId={dealId}
        dealIsFinalized={dealIsFinalized}
        history={history}
        isAnnotator={isAnnotator}
        isFocused={isFocused}
        isKlarityEmployee={isKlarityEmployee}
        manualReviewMutationState={manualReviewMutationState}
        needsResolution={needsResolution}
        setFocused={setFocused}
        showDataPointDetailOverlay={showDataPointDetailOverlay}
      />
    </li>
  )
}

function LabelAndActions({
  accountingImpactMutation,
  dataPoint,
  dealId,
  dealIsFinalized,
  documentId,
  history,
  isAnnotator,
  manualReviewMutation,
  needsResolution,
  showDataPointDetailOverlay
}: {
  accountingImpactMutation: any
  dataPoint: DataPoint
  dealId?: string
  dealIsFinalized: boolean
  documentId?: string
  history: any
  isAnnotator: boolean
  manualReviewMutation: any
  needsResolution: boolean
  showDataPointDetailOverlay?: boolean
}) {
  const { data: transitionAuditsData } = useTransitionAuditsQuery({ variables: { dealOrDocumentId: dealId || documentId! } })

  const { data_point_field } = dataPoint
  const { description, display_accounting_impact, display_comment_field, display_manual_review } = data_point_field || {}
  const states = transitionAuditsData?.transition_audit?.states
    ? (transitionAuditsData.transition_audit.states as CustomState[]).filter(state => !state.initial && !state.final)
    : null

  const sanitizeHTML = (description?: string) => {
    if (!description) {
      return { __html: DOMPurify.sanitize('') }
    } else {
      return { __html: DOMPurify.sanitize(description) }
    }
  }

  return (
    <>
      <div className={css.labelRow}>
        <div className={css.label}>
          <span>{dataPoint.data_point_field?.name}</span>
          {/* @ts-ignore */}
          {description && (
            <Tippy className={clsx(description?.length > 500 && css.description)} content={<div dangerouslySetInnerHTML={sanitizeHTML(description)} />}>
              <Info />
            </Tippy>
          )}
          <Source {...data_point_field} />
        </div>
        {!isAnnotator && (
          <ul className={css.actions}>
            {display_manual_review && !isAnnotator && states && (
              <li>
                <ManualReviewCheckboxes dataPoint={dataPoint} dealIsFinalized={dealIsFinalized} mutation={manualReviewMutation} states={states} />
              </li>
            )}
            {display_accounting_impact && (
              <li>
                <AccountingImpactButton dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} mutation={accountingImpactMutation} />
              </li>
            )}
            {display_comment_field && (
              <li>
                <CommentButton dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} />
              </li>
            )}
            {showDataPointDetailOverlay && (
              <DetailOverlayButton
                dataPointId={dataPoint.id}
                dealId={dealId}
                documentId={documentId}
                needsResolution={needsResolution}
                searchString={history.location.search}
              />
            )}
          </ul>
        )}
      </div>
    </>
  )
}

function DetailOverlayButton({
  dataPointId,
  dealId,
  documentId,
  needsResolution,
  searchString
}: {
  dataPointId: string
  dealId?: string
  documentId?: string
  needsResolution: boolean
  searchString?: string
}) {
  return (
    <li>
      <Link to={`${dealId ? `/deals/${dealId}` : `/documents/${documentId}`}/checklist/${dataPointId}${searchString}`}>
        {needsResolution && <AlertTriangle className={css.alertIcon} />}
        <ChevronRight className={css.chevronRight} />
      </Link>
    </li>
  )
}

function NeutralTagRow({ data_point_field }: { data_point_field: Maybe<DataPointField> | undefined }) {
  return (
    <>
      <div style={{ padding: '0 8px', marginBottom: 16, fontSize: 12, lineHeight: 1.6 }}>
        <span style={{ color: '#f0f' }}>{`Neutral tag(s): `}</span>
        <span style={{ textTransform: 'capitalize', fontWeight: 800 }}>
          {data_point_field?.internal_mapping
            ?.map((k: any) => k?.split('_').join(' '))
            .filter(Boolean)
            .join(', ')}
        </span>
      </div>
    </>
  )
}

function ChildDataPoints({
  accountingImpactMutationState,
  dataPoint,
  dealId,
  dealIsFinalized,
  history,
  isAnnotator,
  isFocused,
  isKlarityEmployee,
  manualReviewMutationState,
  needsResolution,
  setFocused,
  showDataPointDetailOverlay
}: {
  accountingImpactMutationState: MutationResult
  dataPoint: DataPoint
  dealId?: string
  dealIsFinalized: boolean
  history: any
  isAnnotator: boolean
  isFocused: boolean
  isKlarityEmployee: boolean
  manualReviewMutationState: MutationResult
  needsResolution: boolean
  setFocused: any
  showDataPointDetailOverlay?: boolean
}) {
  const isDataTable = dataPoint.data_point_field?.field_type === 'DATA_TABLE'
  const { data: dataPointDetailData } = useDataPointDetailQuery({ skip: !isDataTable, variables: { id: dataPoint.id } })
  const dataPointDetail = dataPointDetailData?.data_points?.edges[0]?.node as DataPoint
  const dataPointDetailHasChildren = !isEmpty(dataPointDetail?.document_data_points?.edges)
  const documentDataPoints = dataPoint.document_data_points?.edges
  const resolvedDataPoints = dataPoint.resolved_data_points?.edges
  const toResolveCount = countTruthyValues(dataPoint)
  const valueCount = countTruthyValues(dataPoint)
  return (
    <>
      {dealIsFinalized ? (
        <InputRow
          accountingImpactMutationState={accountingImpactMutationState}
          dataPoint={dataPoint}
          dealIsFinalized={dealIsFinalized}
          isFocused={isFocused}
          manualReviewMutationState={manualReviewMutationState}
          setFocused={setFocused}
        />
      ) : showDataPointDetailOverlay ? (
        needsResolution ? (
          <AlertLink
            dataPointId={dataPoint.id}
            dealId={dealId}
            message={`${toResolveCount ? `${String(toResolveCount)} conflicting` : 'No'} values`}
            type="warning"
          />
        ) : resolvedDataPoints?.length ? (
          <InputList
            dataPoints={resolvedDataPoints}
            dealIsFinalized={dealIsFinalized}
            history={history}
            isAnnotator={isAnnotator}
            isKlarityEmployee={isKlarityEmployee}
          />
        ) : documentDataPoints?.length ? (
          <AlertLink
            dataPointId={dataPoint.id}
            dealId={dealId}
            message={`${valueCount ? `${String(valueCount)}` : 'No'} value${valueCount === 1 ? '' : 's'} detected in ${documentDataPoints.length} documents`}
            type="info"
          />
        ) : (
          <InputRow
            accountingImpactMutationState={accountingImpactMutationState}
            dataPoint={dataPoint}
            dealIsFinalized={dealIsFinalized}
            isFocused={isFocused}
            manualReviewMutationState={manualReviewMutationState}
            setFocused={setFocused}
          />
        )
      ) : documentDataPoints?.length ? (
        <InputList
          dataPoints={documentDataPoints}
          dealIsFinalized={dealIsFinalized}
          history={history}
          isAnnotator={isAnnotator}
          isKlarityEmployee={isKlarityEmployee}
        />
      ) : (
        <InputRow
          accountingImpactMutationState={accountingImpactMutationState}
          dataPoint={dataPoint}
          dealIsFinalized={dealIsFinalized}
          isFocused={isFocused}
          manualReviewMutationState={manualReviewMutationState}
          setFocused={setFocused}
        />
      )}
      <PinnedComments dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} />
      {!dealIsFinalized && dataPointDetailHasChildren && (
        <DataPointCollisionForm dataPoint={dataPointDetail} dealIsFinalized={dealIsFinalized} isSummary key={JSON.stringify(dataPointDetail)} />
      )}
    </>
  )
}

// if collision is resolved, show resolved data points OR a count of how many document data points there are (in case resolved is empty)
// otherwise show display_value of the deal-level data point
function InputList({
  dataPoints,
  dealIsFinalized,
  history,
  isAnnotator,
  isKlarityEmployee
}: {
  dataPoints: Maybe<DataPointEdge>[]
  dealIsFinalized: boolean
  history: any
  isAnnotator: boolean
  isKlarityEmployee: boolean
}) {
  const { documentTab } = useQString()
  return (
    <>
      {dataPoints.map((dp, index) => {
        if (!dp?.node) return null
        const isDataTable = dp.node.data_point_field?.field_type === 'DATA_TABLE'
        return (
          <div key={`${dp.node.id},${index}`}>
            {(isAnnotator || isKlarityEmployee) && dp?.node?.annotator_needs_review && <DataPointUpdateFailure dataPoint={dp?.node} />}

            {isDataTable || (
              <>
                <NavLink
                  isActive={() => documentTab === dp.node?.document?.id}
                  style={{ color: '#bdbec2', fontSize: 10, marginLeft: '8px' }}
                  to={`${history.location.pathname}?documentTab=${dp.node.document?.id}`}
                >
                  {dp.node.document?.document_type?.name}: {dp?.node?.document?.alias || dp.node.document?.name}
                </NavLink>

                <InputRow dataPoint={dp.node} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

function AlertLink({ dataPointId, dealId, message, type }: { dataPointId: string; dealId?: string; message: string; type: 'info' | 'warning' }) {
  return (
    <Link className={clsx(css.collisionAlert, css[type])} to={`/deals/${dealId}/checklist/${dataPointId}`}>
      {type === 'info' ? <Info /> : <AlertTriangle />}
      {message}
    </Link>
  )
}
