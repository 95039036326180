import { useDealsQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import DataPointPanelContainer from '../../containers/DealDataPointPanelContainer'
import DealPageHeader from './DealPageHeader'
import DocumentPageWrapper from '../../components/DocumentPageWrapper'
import ErrorPage from '../ErrorPage'
import Loader from '../../components/Loader'
import React from 'react'

export default function DealPage() {
  const { dealId } = useParams<{ dealId: string }>()
  const { data, error, loading } = useDealsQuery({ context: { queryName: `DealPage.tsx useDealsQuery` }, variables: { id: dealId } })
  const deal = data?.deals?.edges?.[0]?.node
  const { alias, assignee, counter_party, created_at, documents, name, state } = deal || {}
  const documentNodes = documents?.edges?.map(docEdge => docEdge?.node)
  const withoutDocument = !loading && !documentNodes

  if (loading) return <Loader />

  if (error?.message) return <ErrorPage message={`Deal not found.\n\n${error.message}`} />

  if (!deal) return <ErrorPage message="You do not have access to this deal." />

  return (
    <DocumentPageWrapper
      counterparty={counter_party}
      dataPointPanel={<DataPointPanelContainer dealIsFinalized={state?.final || false} />}
      dealIsFinalized={state?.final || false}
      documents={documentNodes || []}
      headerComponent={
        <DealPageHeader
          alias={alias || undefined}
          assigneeTo={assignee?.user_name}
          counter_party={counter_party}
          created_at={created_at}
          dealIsFinalized={state?.final || false}
          name={name}
          state={state}
        />
      }
      loading={loading}
      name={alias || name}
      withDocumentSelector={!withoutDocument}
    />
  )
}
