import { FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import RadioButton from '../SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import SelectOrCreateNeutralTag from '../EditInputs/SelectOrCreateNeutralTag'
import css from './style.module.scss'

export default function NeutralTagInput({ handleData, values }: { handleData: any; values?: any }) {
  const [value, setValue] = useState<any>(null)
  const [clearSelectedValue, setClearSelectedValue] = useState(false)
  const [isFieldLevel, setIsFieldLevel] = useState<boolean>(true)

  const handleFieldLevel = (e: any) => {
    const actualValue = e?.target?.value === 'true'
    setIsFieldLevel(actualValue)
    handleData('tag_type', actualValue === true ? 'field_level' : 'option_level')
    if (actualValue === false) {
      setValue(null)
    }
  }

  useEffect(() => {
    handleData('neutral_tag', value)
    // eslint-disable-next-line
    }, [value])

  const handleChange = ({ label }: { label: string }) => {
    setValue(label)
  }

  return (
    <>
      {values?.field_type === 'MULTI_SELECT_DROP_DOWN' ? (
        <>
          <div className={css.radioRow}>
            <h4>{`Neutral Tag:`}</h4>
            <div>
              <RadioButton
                handleChange={handleFieldLevel}
                id={'isFieldLevel_true'}
                isSelected={!!isFieldLevel}
                label={'Field Level'}
                name={'isFieldLevel'}
                style={{ marginRight: '16px' }}
                value={true}
              />
              <RadioButton
                handleChange={handleFieldLevel}
                id={'isFieldLevel_false'}
                isSelected={!isFieldLevel}
                label={'Option Level'}
                name={'isFieldLevel'}
                value={false}
              />
            </div>
          </div>
          {isFieldLevel && (
            <div className={css.inputWrapper} style={{ marginLeft: '131px' }}>
              <SelectOrCreateNeutralTag clearSelectedValue={clearSelectedValue} setClearSelectedValue={setClearSelectedValue} setNeutralTag={handleChange} />
              {value && (
                <Button
                  className={css.cciButton}
                  icon={<FiXSquare />}
                  onClick={() => {
                    setValue(null)
                    setClearSelectedValue(true)
                  }}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={css.inputWrapper}>
            <h4>{`Neutral Tag:`}</h4>
            <SelectOrCreateNeutralTag clearSelectedValue={clearSelectedValue} setClearSelectedValue={setClearSelectedValue} setNeutralTag={handleChange} />
            {value && (
              <Button
                className={css.cciButton}
                icon={<FiXSquare />}
                onClick={() => {
                  setValue(null)
                  setClearSelectedValue(true)
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

// if MSDD show radio button row for field level or option level
// on field change keep values?.neutral_tag value and default to option level
// if changing field level wipe both- clear values?.neutral_tag and map through options to delete option.neutral_tag if options have length
// if option level render this input under each (or duplicate this and call it OptionNeutralTagInput) to avoid mixed conditionals
