import RadioButton from '../SharedInputs/RadioButton/RadioButton'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'

declare type CurrentValues = {
  currentDefaultFieldOnDashboard: boolean
  currentDisplayAccountingImpact: boolean
  currentDisplayIfEmpty: boolean
  currentDisplayOnDocument: boolean
  currentItemId?: string
  handleData: any
}

export default function BooleanInputs({
  currentDefaultFieldOnDashboard,
  currentDisplayAccountingImpact,
  currentDisplayIfEmpty,
  currentDisplayOnDocument,
  currentItemId,
  handleData
}: CurrentValues) {
  const [displayOnDocument, setDisplayOnDocument] = useState<boolean>(currentDisplayOnDocument)
  const [displayIfEmpty, setDisplayIfEmpty] = useState<boolean>(currentDisplayIfEmpty)
  const [displayAccountingImpact, setDisplayAccountingImpact] = useState<boolean>(currentDisplayAccountingImpact)
  const [defaultFieldOnDashboard, setDefaultFieldOnDashboard] = useState<boolean>(currentDefaultFieldOnDashboard)

  useEffect(() => {
    setDisplayOnDocument(currentDisplayOnDocument)
    setDisplayIfEmpty(currentDisplayIfEmpty)
    setDisplayAccountingImpact(currentDisplayAccountingImpact)
    setDefaultFieldOnDashboard(currentDefaultFieldOnDashboard)
    // eslint=disable-next-line
  }, [currentItemId, currentDisplayOnDocument, currentDisplayIfEmpty, currentDisplayAccountingImpact, currentDefaultFieldOnDashboard])

  const handleChange = (e: any) => {
    const actualValue = e?.target?.value === 'true'
    switch (e?.target?.name) {
      case 'displayIfEmpty':
        setDisplayIfEmpty(actualValue)
        handleData('display_if_empty', actualValue)
        break
      case 'displayOnDocument':
        setDisplayOnDocument(actualValue)
        handleData('display_on_document', actualValue)
        break
      case 'displayAccountingImpact':
        setDisplayAccountingImpact(actualValue)
        handleData('display_accounting_impact', actualValue)
        break
      case 'defaultFieldOnDashboard':
        setDefaultFieldOnDashboard(actualValue)
        handleData('default_field_on_dashboard', actualValue)
        break
      default:
        console.error(`BooleanInputs handleChange error: Unsupported button name: ${e?.target?.name}`)
    }
  }

  return (
    <>
      <div className={css.radioRow} style={{ marginTop: '16px' }}>
        <h4>{`Display on checklist if not found:`}</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'displayIfEmpty_true'}
            isSelected={!!displayIfEmpty}
            label={'True'}
            name={'displayIfEmpty'}
            style={{ marginRight: '16px' }}
            value={true}
          />
          <RadioButton
            handleChange={handleChange}
            id={'displayIfEmpty_false'}
            isSelected={!displayIfEmpty}
            label={'False'}
            name={'displayIfEmpty'}
            value={false}
          />
        </div>
      </div>
      <div className={css.radioRow}>
        <h4>{`Show Annotations:`}</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'displayOnDocument_true'}
            isSelected={!!displayOnDocument}
            label={'True'}
            name={'displayOnDocument'}
            style={{ marginRight: '16px' }}
            value={true}
          />
          <RadioButton
            handleChange={handleChange}
            id={'displayOnDocument_false'}
            isSelected={!displayOnDocument}
            label={'False'}
            name={'displayOnDocument'}
            value={false}
          />
        </div>
      </div>
      <div className={css.radioRow}>
        <h4>{`Display Accounting Impact Button:`}</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'displayAccountingImpact_true'}
            isSelected={!!displayAccountingImpact}
            label={'True'}
            name={'displayAccountingImpact'}
            style={{ marginRight: '16px' }}
            value={true}
          />
          <RadioButton
            handleChange={handleChange}
            id={'displayAccountingImpact_false'}
            isSelected={!displayAccountingImpact}
            label={'False'}
            name={'displayAccountingImpact'}
            value={false}
          />
        </div>
      </div>
      <div className={css.radioRow}>
        <h4>{`Display as default column in the dashboard:`}</h4>
        <div>
          <RadioButton
            handleChange={handleChange}
            id={'defaultFieldOnDashboard_true'}
            isSelected={!!defaultFieldOnDashboard}
            label={'True'}
            name={'defaultFieldOnDashboard'}
            style={{ marginRight: '16px' }}
            value={true}
          />
          <RadioButton
            handleChange={handleChange}
            id={'defaultFieldOnDashboard_false'}
            isSelected={!defaultFieldOnDashboard}
            label={'False'}
            name={'defaultFieldOnDashboard'}
            value={false}
          />
        </div>
      </div>
    </>
  )
}

// display_if_not_found = graphene.Boolean()
// display_comment_on_document = graphene.Boolean()
// display_accounting_impact = graphene.Boolean()
// default_field_on_dashboard = graphene.Boolean()

// console.info('currentDisplayOnDocument', currentDisplayOnDocument)
// console.info('currentDisplayCommentField', currentDisplayCommentField)
// console.info('currentDisplayAccountingImpact', currentDisplayAccountingImpact)
// console.info('currentDefaultFieldOnDashboard', currentDefaultFieldOnDashboard)

// console.info('displayOnDocument', displayOnDocument)
// console.info('displayCommentField', displayCommentField)
// console.info('displayAccountingImpact', displayAccountingImpact)
// console.info('defaultFieldOnDashboard', defaultFieldOnDashboard)
