import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

export default function SourceInput({ handleData, hasFullAccess }: { handleData: any; hasFullAccess: boolean }) {
  const [source, setSource] = useState<any>('')
  const [sourceOptions, setSourceOptions] = useState<any>([])

  const sourceList = [
    { value: 'INTERNAL', label: 'INTERNAL' },
    { value: 'MANUAL', label: 'MANUAL' },
    { value: 'SALESFORCE', label: 'SALESFORCE' },
    { value: 'IRONCLAD', label: 'IRONCLAD' },
    { value: 'SPRINGCM', label: 'SPRINGCM' },
    { value: 'NETSUITE', label: 'NETSUITE' },
    { value: 'CALCULATION', label: 'CALCULATION' },
    { value: 'PEGA', label: 'PEGA' },
    { value: 'SAP', label: 'SAP' }
  ]

  const handleSource = (value?: string) => {
    handleData('source', value)
    setSource(value)
  }

  useEffect(() => {
    // below removes the currently selected item from the list of options
    setSourceOptions(
      sourceList.filter((item: any) => {
        return item.value !== source
      })
    )
    // eslint-disable-next-line
    }, [source])

  useEffect(() => {
    if (!hasFullAccess) {
      handleData('source', 'MANUAL')
    }
    // eslint-disable-next-line
    }, [hasFullAccess])

  return (
    <>
      {hasFullAccess ? (
        <div className={css.inputWrapper}>
          <h4>{`Source:`}</h4>
          <SelectInput
            isClearable
            onChange={(item: any) => {
              handleSource(item?.value)
            }}
            options={sourceOptions}
            placeholder={source || 'Select Source'}
            value={source}
          />
        </div>
      ) : (
        <div className={css.inputWrapper}>
          <h4>{`Source:`}</h4>
          <p style={{ flexGrow: 1, textAlign: 'right' }}>{'Manual'}</p>
        </div>
      )}
    </>
  )
}
