import { FieldTypeLabels, FieldTypes, getFieldTypeLabel } from '../../../../../../../utils/cci'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

export default function FieldTypeInput({ handleData, value }: { handleData: any; value?: string }) {
  const [field_type_options, setField_type_options] = useState<any>([])

  const optionsList = [
    { value: 'TEXT', label: 'TEXT' },
    { value: FieldTypes.FLOAT, label: FieldTypeLabels.FLOAT },
    { value: FieldTypes.NUMBER, label: FieldTypeLabels.NUMBER },
    { value: 'BOOLEAN', label: 'BOOLEAN' },
    { value: 'DROP_DOWN', label: 'DROPDOWN' },
    { value: 'MULTI_SELECT_DROP_DOWN', label: 'MULTI_SELECT_DROPDOWN' },
    { value: 'DATE', label: 'DATE' },
    { value: 'DURATION', label: 'DURATION' },
    { value: 'PERCENTAGE', label: 'PERCENTAGE' },
    { value: 'CURRENCY', label: 'CURRENCY' },
    { value: 'CURRENCY_TYPE', label: 'CURRENCY_TYPE' },
    { value: 'DATA_TABLE', label: 'DATA_TABLE' },
    { value: 'GEO_COUNTRY', label: 'GEO_COUNTRY' },
    { value: 'GEO_STATE', label: 'GEO_STATE' },
    { value: 'GEO_CITY', label: 'GEO_CITY' }
  ]

  useEffect(() => {
    // below removes the currently selected item from the list of options
    setField_type_options(
      optionsList.filter((item: any) => {
        return item.value !== value
      })
    )
    // eslint-disable-next-line
    }, [value])

  useEffect(() => {
    handleData('default_value', null)
    handleData('value_format', null)
    // eslint-disable-next-line
    }, [value])

  return (
    <>
      {/* Select a new field type from dropdown */}
      <div className={css.inputWrapper}>
        <h4>{`Field Type:`}</h4>
        <SelectInput
          isClearable
          onChange={(item: any) => {
            handleData('field_type', item?.value)
          }}
          options={field_type_options}
          placeholder={getFieldTypeLabel(value)}
          value={value}
        />
      </div>
    </>
  )
}
