import { format, formatDistance } from 'date-fns'

/**
 * Convert ISO datestring to X time ago...
 * If no timezone designator, assume UTC and append '+00:00'
 */
export function formatTimeAgo(datestring: string) {
  if (!datestring) {
    return ''
  }
  if (!datestring.includes('+')) {
    datestring += '+00:00'
  }

  const datetime = new Date(datestring)
  const now = new Date()
  return formatDistance(datetime, now)
}

export function formatDate(datestring: string) {
  if (!datestring) {
    return ''
  }
  const datetime = new Date(datestring)
  return format(datetime, 'MMM d, yyyy')
}
