import { AlertTriangle, Info } from 'react-feather'
import React from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

interface Props {
  alertText: string
  type?: 'info' | 'warning' | 'danger'
}

export default function Alert({ alertText, type = 'info' }: Props) {
  const Icon = type === 'info' ? Info : AlertTriangle

  return (
    <div className={clsx(css.alert, css[type])}>
      <div>
        <Icon />
      </div>
      <div>
        <p>{alertText}</p>
      </div>
    </div>
  )
}
