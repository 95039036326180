import { API_ROOT } from './apiUtils'
import { CurrentUserDocument } from '../graphql/codegen/hooks'
import { clearPrevQueriesAndColumns } from '../pages/Admin/Admin'
import { print } from 'graphql'
import axios from 'axios'
import axiosClient from './axiosClient'
import oktaClient from '../utils/oktaClient'

// fetch identity provider from revrec backend (empty string for internal Klarity users)
export const fetchIDP = async (userEmail: string): Promise<string> => {
  const { data } = await axios.post(`${API_ROOT}/idp`, { user_name: userEmail })

  return data.idp_id
}

// The okta client automatically refreshes/validates tokens, so we use it for storage instead of plain localstorage
export const getAccessToken = async () => {
  return (await oktaClient.tokenManager.get('idToken'))?.value
}

// For bootstrapping before we have the apolloclient set up
export const getCurrentUserInApolloFormat = async () => {
  const token = await getAccessToken()
  if (!token) return null

  const res = await axiosClient.post('/graphql', { query: print(CurrentUserDocument) })

  return res.data.data.current_user
}

export const logout = async () => {
  localStorage.removeItem('cid') // Remove selected customer id
  if (localStorage.getItem('isAnnotator')) {
    clearPrevQueriesAndColumns()
    localStorage.removeItem('isAnnotator')
  }

  // oktaClient.signOut(); redirects the user to the Okta signout page, then back to the postLogoutRedirectUri (/logout, which will clear local values)
  try {
    await oktaClient.signOut()
  } catch (error) {
    console.error(error)
  }
}
