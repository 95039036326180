import { Page } from 'react-pdf'
import DocumentPlaceholder from '../DocumentPlaceholder'
import React from 'react'
import css from './style.module.scss'

interface Props {
  data: { searchText: string; width: number }
  index: number
  style: any
}

function PageRenderer({ data: { width }, index, style }: Props) {
  const pageNumber = index + 1

  return (
    <div style={style}>
      <Page
        className={css.page}
        key={`page_${pageNumber}`}
        loading={<DocumentPlaceholder style={{ width }} />}
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        width={width}
      />
    </div>
  )
}

export default PageRenderer
