import { useCciMainContext } from '../../CCI_Main'
import Button from '../../../../components/Button'
import React, { useEffect, useState } from 'react'
import css from './style.module.scss'
import useUserAccess from '../../../../hooks/useUserAccess'

export default function Footer() {
  const { leftpanelLoading, selectedItem, setActiveComponent, tab } = useCciMainContext()
  const [footerButton, setFooterButton] = useState<any>(undefined)
  const hasChecklistCreateAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'CREATE_FIELD' })
  const hasLimitedChecklistCreateAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'CREATE_FIELD_LIMITED' })

  const generateFooterButton = (tab: string | undefined) => {
    if (!tab) {
      tab = 'checklist'
    }
    switch (tab) {
      case 'checklist':
        if (hasChecklistCreateAccess || hasLimitedChecklistCreateAccess) {
          createFooterButton('Create Field', 'Create Checklist Field')
        } else {
          setFooterButton(undefined)
        }
        break
      case 'integrations':
        createFooterButton('Create Integration', 'Create Integration')
        break
      case 'roles':
        createFooterButton('Placeholder- not added yet', 'Placeholder- not added yet')
        break
      case 'users':
        createFooterButton('Create User', 'Create User')
        break
    }
  }

  const createFooterButton = (activeComponent: string, buttonText: string) => {
    setFooterButton(
      <div className={css.footer} style={{ padding: '0 20px' }}>
        <Button onClick={() => setActiveComponent(activeComponent)}>{buttonText}</Button>
      </div>
    )
  }

  // eslint-disable-next-line
    useEffect(() => { generateFooterButton(tab); }, [tab])

  return <>{leftpanelLoading ? null : tab === 'integrations' ? (selectedItem ? null : footerButton) : footerButton}</>
}
