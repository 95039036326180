import React, { CSSProperties } from 'react'
import clsx from 'clsx'
// import logo from '../../assets/logo-full-black.png';
import css from './style.module.scss'

export default function Loader({
  // isFullPage,
  size,
  variant,
  ...rest
}: {
  isFullPage?: boolean
  size?: 's' | 'm' | 'l'
  style?: CSSProperties
  variant?: 'light' | 'dark'
}) {
  return (
    <div className={css.wrapper} {...rest}>
      <div className={clsx(css[size || 'm'], variant === 'light' && css.light)} />
      {/* {isFullPage && (
        <div className={css.fullPageContent}>
          <img src={logo} alt="" />
        </div>
      )} */}
    </div>
  )
}
