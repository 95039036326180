import { AiOutlineLink } from 'react-icons/ai'
import { EditDataPointAccountingImpactMutation, EditDataPointManualReviewMutation } from '../../graphql/codegen/operations'
import { MutationResult } from '@apollo/client'
import { useEditDataPointValueMutation } from '../../graphql/codegen/hooks'
import Button from '../Button'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import DatapointInput from '../DatapointInput'
import DeleteOSMDDOption from '../ModalOptions/DeleteOSMDDOption'
import MessageModal from '../ModalOptions/MessageModal'
import Modal from '../Modal'
import React, { useEffect, useRef, useState } from 'react'
import SaveProgressIcon from './SaveProgressIcon'
import ScrollIcon from './ScrollIcon'
import css from './style.module.scss'
import type { DataPoint } from '../../graphql/codegen/schemas'

export default function InputRow({
  accountingImpactMutationState,
  dataPoint,
  dealIsFinalized,
  isFocused,
  manualReviewMutationState,
  setFocused
}: {
  accountingImpactMutationState?: MutationResult<EditDataPointAccountingImpactMutation>
  dataPoint: DataPoint
  dealIsFinalized: boolean
  isFocused?: boolean
  manualReviewMutationState?: MutationResult<EditDataPointManualReviewMutation>
  setFocused?: (x: boolean) => void
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [removedOption, setRemovedOption] = useState('')
  const [annotationCount, setAnnotationCount] = useState<any>(undefined)
  const [modalTitle, setModalTitle] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalError, setModalError] = useState<any>('')
  const [modalSuccess, setModalSuccess] = useState<any>('')

  const [value, setValue] = useState<string>(() => {
    const { display_value, value_iso_date } = dataPoint
    return value_iso_date || display_value || ''
  })
  const [hyperlink, setHyperlink] = useState<string | null>(null)
  const [isOptionMulti, setIsOptionMulti] = useState(false)
  const [multiLength, setMultiLength] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const [annotations, setAnnotations] = useState<any>(undefined)
  const [pageIds, setPageIds] = useState<any>(undefined)
  const [inputRowHeight, setInputRowHeight] = useState('')
  const inputRowRef = useRef<any>()

  useEffect(() => {
    setInputRowHeight(inputRowRef?.current?.clientHeight)
    const multiLength = dataPoint.value_list?.length || 0
    if (multiLength < 2) {
      setIsExpanded(false)
    }
  }, [inputRowRef, isExpanded, dataPoint.value_list])

  useEffect(() => {
    setHyperlink(dataPoint.hyperlink_url || null)
    setValue(dataPoint.value_iso_date || dataPoint.display_value || '')
    setAnnotations(
      dataPoint?.annotations
        ?.map((annotation: any) => {
          // If annotations are present from multiple documents they would be two separate objects with an annotation array and a document object.
          // This is adding the correct documentId inside each annotation object, then flattening all annotations to a single array of objects.
          // .flat squishes everything into one array. If there were nested arrays it would remove only one level
          return annotation?.annotations?.map((innerAnnot: any) => {
            return { ...innerAnnot, documentId: annotation?.document?.id }
          })
        })
        .flat()
    )

    // [...new Set()] removes duplicates. pageIds had dupes in it, which resulted in extra jump buttons for the same pageID
    // @ts-ignore
    setPageIds([...new Set(dataPoint.hyperlink?.filter(Boolean))])

    const isMulti = dataPoint?.data_point_field?.field_type === 'MULTI_SELECT_DROP_DOWN'
    setMultiLength(dataPoint.value_list?.length || 0)
    setIsOptionMulti(isMulti && dataPoint?.data_point_field?.extraction_logic ? !!JSON.parse(dataPoint?.data_point_field?.extraction_logic)?.mapping : false)
  }, [dataPoint])

  const [editDataPointValueMutation, valueMutationState] = useEditDataPointValueMutation({
    onError: e => {
      console.error(e)
      setValue(dataPoint.value_iso_date || dataPoint.display_value || '') // make sure that field displays current value on backend
    }
  })

  const openModal = (removedOption: string, annotationCount: number) => {
    setRemovedOption(removedOption)
    setModalTitle('')
    setAnnotationCount(annotationCount)
    setIsOpen(true)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setModalLoading(false)
      setModalError('')
      setModalSuccess('')
      setIsOpen(false)
    }
  }

  const handleHyperlink = () => {
    const linkedWindow = window.open(hyperlink!, '_blank', 'noopener,noreferrer') // noopener,noreferrer needed to prevent new tab from being able to access this window. security vulnerability
    if (linkedWindow) linkedWindow.opener = null // further prevent new window from being able to access this window (opener is this window)
  }

  return (
    <>
      <div className={css.inputRow}>
        <span className={css.inputWrapper} ref={inputRowRef}>
          <DatapointInput
            annotations={annotations}
            dataPoint={dataPoint}
            dealIsFinalized={dealIsFinalized}
            editDataPointValueMutation={editDataPointValueMutation}
            isExpanded={isExpanded}
            isFocused={isFocused}
            isOptionMulti={isOptionMulti}
            openModal={openModal}
            setFocused={setFocused}
            setValue={setValue}
            value={value}
          />

          {!!hyperlink && <AiOutlineLink className={css.linkIcon} onClick={handleHyperlink} />}
        </span>

        {isOptionMulti && multiLength > 1 && annotations?.length ? (
          <OptionMultiSelectInput
            annotations={annotations}
            dataPoint={dataPoint}
            error={valueMutationState.error}
            inputRowHeight={inputRowHeight}
            isExpanded={isExpanded}
            loading={!!(valueMutationState.loading || accountingImpactMutationState?.loading || manualReviewMutationState?.loading)}
            pageIds={pageIds}
            setIsExpanded={setIsExpanded}
            success={!!(valueMutationState.data || accountingImpactMutationState?.data || manualReviewMutationState?.data)}
          />
        ) : (
          <SaveIconAndJumpButton
            annotations={annotations}
            dataPoint={dataPoint}
            error={valueMutationState.error}
            loading={!!(valueMutationState.loading || accountingImpactMutationState?.loading || manualReviewMutationState?.loading)}
            pageIds={pageIds}
            success={!!(valueMutationState.data || accountingImpactMutationState?.data || manualReviewMutationState?.data)}
          />
        )}
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        title={modalTitle}
      >
        <ComponentLoadingOverlay loading={modalLoading || !removedOption} message={modalLoadingMessage} />
        {modalSuccess ? (
          <MessageModal closeModal={closeModal} message={modalSuccess} messageTitle={''} />
        ) : modalError ? (
          <MessageModal closeModal={closeModal} message={modalError} messageTitle={''} />
        ) : (
          <DeleteOSMDDOption
            annotationCount={annotationCount}
            closeModal={closeModal}
            dataPoint={dataPoint}
            removedOption={removedOption}
            setModalError={setModalError}
            setModalLoading={setModalLoading}
            setModalLoadingMessage={setModalLoadingMessage}
            setModalSuccess={setModalSuccess}
          />
        )}
      </Modal>
    </>
  )
}

function OptionMultiSelectInput({
  annotations,
  dataPoint,
  error,
  inputRowHeight,
  isExpanded,
  loading,
  pageIds,
  setIsExpanded,
  success
}: {
  annotations: any
  dataPoint: DataPoint
  error?: any
  inputRowHeight?: any
  isExpanded: boolean
  loading: boolean
  pageIds: any
  setIsExpanded?: any
  success: boolean
}) {
  return (
    <>
      {loading ? (
        <SaveProgressIcon error={error} loading={loading} success={success} />
      ) : (
        <div className={css.multiControls} style={{ height: inputRowHeight }}>
          {isExpanded ? (
            <>
              {dataPoint?.value_list?.map((item, index) => {
                const itemAnnotations = annotations.filter((annotation: any) => {
                  return annotation?.label_name === item
                })
                return (
                  <ul className={css.actions} key={`${item}${index}`} style={{ justifyContent: 'flex-end' }}>
                    <li style={{ paddingLeft: '4px', cursor: annotations?.length || pageIds?.length ? 'pointer' : 'default' }}>
                      {itemAnnotations.length > 0 && (
                        <ScrollIcon annotations={itemAnnotations} documentId={annotations[0]?.document?.id || dataPoint.document?.id} />
                      )}
                    </li>
                  </ul>
                )
              })}
              <Button
                onClick={() => {
                  setIsExpanded(!isExpanded)
                }}
                size={'s'}
                variant="secondary"
              >{`Collapse`}</Button>
            </>
          ) : (
            <Button
              onClick={() => {
                setIsExpanded(!isExpanded)
              }}
              size={'s'}
              variant="secondary"
            >{`Expand`}</Button>
          )}
        </div>
      )}
    </>
  )
}

function SaveIconAndJumpButton({
  annotations,
  dataPoint,
  error,
  loading,
  pageIds,
  success
}: {
  annotations: any
  dataPoint: DataPoint
  error?: any
  loading: boolean
  pageIds: any
  success: boolean
}) {
  return (
    <>
      <SaveProgressIcon error={error} loading={loading} success={success} />
      <JumpButton annotations={annotations} dataPoint={dataPoint} pageIds={pageIds} />
    </>
  )
}

function JumpButton({ annotations, dataPoint, pageIds }: { annotations: any; dataPoint: DataPoint; pageIds: any }) {
  return (
    <>
      {
        annotations?.length ? (
          <ScrollIcon annotations={annotations} documentId={annotations[0]?.document?.id || dataPoint.document?.id} />
        ) : pageIds?.length ? (
          <ScrollIcon documentId={dataPoint.document?.id} pageIds={pageIds} />
        ) : (
          <span style={{ width: 24 }} />
        ) // Placeholder to keep everything else aligned
      }
    </>
  )
}
