import { useDocumentsQuery } from '../graphql/codegen/hooks'
import { useIsAnnotator } from './useUserAccess'
import { useParams } from 'react-router'

export default () => {
  const isAnnot = useIsAnnotator()
  const { documentId } = useParams<{ documentId?: string }>()
  const { data: docData, loading } = useDocumentsQuery({
    context: { queryName: `useIsPreAnnot.ts useDocumentsQuery` },
    variables: { documentId },
    skip: !isAnnot || !documentId
  })

  const isPreAnnot = loading ? null : Boolean(isAnnot && docData?.documents?.edges[0]?.node?.internal_state?.name === 'Pre-Annotation')

  return { isPreAnnot, loading }
}
