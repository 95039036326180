import { useIsAnnotator } from '../../hooks/useUserAccess'
import { useUsersQuery } from '../../graphql/codegen/hooks'
import React from 'react'
import SelectInput from '../SelectInput'

export default function UserSelector({ onChange, withNegativeOption }: { onChange: (arg: any) => void; withNegativeOption?: boolean }) {
  const internal = useIsAnnotator()
  const { data, loading } = useUsersQuery({ variables: { internal }, context: { queryName: `UserSelector.tsx useUsersQuery` } })

  let userOptions = data?.users?.map((node: any) => {
    const { id, user_name } = node || {}
    return { label: user_name, value: id }
  })

  if (userOptions && withNegativeOption) {
    userOptions = [...userOptions, { label: 'Unassign', value: null }]
  }

  return <SelectInput isLoading={loading} onChange={onChange} options={userOptions} placeholder="Select user" />
}
