import { formatFieldList } from '../utils/fieldUtils'
import { useAdvancedDashboardFieldsQuery } from '../graphql/codegen/hooks'
import useGetExcludedFields from './useGetExcludedFields'

export default () => {
  const { data, loading } = useAdvancedDashboardFieldsQuery({ context: { queryName: `useDocumentDashboardFields.ts useAdvancedDashboardFieldsQuery` } })
  const fieldsToExclude = useGetExcludedFields('Documents', 'Search')

  return { fields: data && formatFieldList(data, fieldsToExclude), loading }
}
