import { Check } from 'react-feather'
import { ModalContext } from '../../app'
import { StateEdge } from '../../graphql/codegen/schemas'
import { useParams } from 'react-router-dom'
import { useSetDealAssigneeMutation, useSetDealStateMutation, useSetDocumentAssigneeMutation, useSetDocumentStateMutation } from '../../graphql/codegen/hooks'
import AssigneeModal from './FooterAssigneeModal'
import Button from '../Button'
import MoreMenu from '../MoreMenu'
import React, { useContext, useState } from 'react'
import SelectInput from '../SelectInput'
import css from './style.module.scss'
import useDealTransitions from '../../hooks/useDealTransitions'
import useDocumentTransitions from '../../hooks/useDocumentTransitions'
import useIsRapid7LegalTeam from '../../hooks/useIsRapid7LegalTeam'
import useUserAccess from '../../hooks/useUserAccess'

interface Props {
  currentState?: any
  dealIsFinalized?: boolean
  handleClick: (nextStateId: string) => void
  isNonLinear?: boolean | null
  loading: boolean
  stateEdges?: any
}

function Footer({ currentState, dealIsFinalized, handleClick, isNonLinear, loading, stateEdges }: Props) {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false)
  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })
  const isRapid7LegalTeam = useIsRapid7LegalTeam()

  const [setDealAssignee, { loading: setDealAssigneeLoading }] = useSetDealAssigneeMutation({
    context: { queryName: `Footer.tsx setDealAssignee` },
    onCompleted: () => setAssigneeModalOpen(false)
  })
  const [setDocumentAssignee, { loading: setDocumentAssigneeLoading }] = useSetDocumentAssigneeMutation({
    context: { queryName: `Footer.tsx setDocumentAssignee` },
    onCompleted: () => setAssigneeModalOpen(false)
  })

  const handleSave = (assigneeId: string) => {
    if (dealId) {
      setDealAssignee({ variables: { dealId, assigneeId } })
    } else if (documentId) {
      setDocumentAssignee({ variables: { documentId, assigneeId } })
    }
  }

  if (isNonLinear) {
    const opts = stateEdges
      ?.map((e: any) => {
        if (e.node.name === 'Processing') return null
        return { label: e.node.name, value: e.node.id }
      })
      .filter(Boolean)

    const active = opts?.filter((o: any) => o.value === currentState.id)

    return (
      <div className={css.footer}>
        <div className={css.stateOptionsContainer}>
          <SelectInput
            isDisabled={loading}
            isLoading={loading}
            menuPlacement="auto"
            onChange={(o: any) => handleClick(o.value)}
            options={opts}
            value={active}
          />
        </div>
      </div>
    )
  }
  if (!stateEdges) return null

  const states = stateEdges.filter((edge: StateEdge) => edge.node?.name !== 'Processing')
  const currIdx = states.findIndex(({ node }: any) => node.id === currentState.id)
  const next = !states[currIdx]?.node?.final && states[currIdx + 1]?.node
  const availableStates = states.slice(0, currIdx).concat(states.slice(currIdx + 2))

  // Take states that users should be able to jump to, format as menu items
  const menuItems = availableStates.map((edge: StateEdge) => {
    const { final, id, name } = edge.node || {}

    if (!id || !name) return null

    if (states[currIdx]?.node?.final && final) return null

    return { label: `Move to ${name}`, onClick: () => handleClick(id) }
  })

  // Add the option to change assignee as long as this customer has assignee access on that entity (for example deal-level customers can assign on deal page, document-level customers on document page)
  if ((!dealIsFinalized && hasDealAccess) || ((!hasDealAccess || isRapid7LegalTeam) && documentId)) {
    menuItems.push({ label: 'Update assignee', onClick: () => setAssigneeModalOpen(true) })
  }

  return (
    <div className={css.footer} style={{ paddingLeft: '20px' }}>
      {next ? (
        <Button disabled={loading} loading={loading} onClick={() => handleClick(next.id)}>{`Move to ${next.name}`}</Button>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <Check style={{ color: '#59d129', marginRight: 8 }} />
          <span>{`Review complete.`}</span>
        </div>
      )}
      <MoreMenu menuItems={menuItems} style={{ marginLeft: 8, visibility: loading ? 'hidden' : undefined }} withIcon />
      <AssigneeModal
        handleSave={handleSave}
        isOpen={assigneeModalOpen}
        loading={setDealAssigneeLoading || setDocumentAssigneeLoading}
        setIsOpen={setAssigneeModalOpen}
      />
    </div>
  )
}

export function DocumentChecklistFooterContainer() {
  const { documentId } = useParams<{ documentId: string }>()
  const { currentState, data, isNonLinear, loading } = useDocumentTransitions(documentId)

  const [setDocumentState, { loading: setDocumentStateLoading }] = useSetDocumentStateMutation({
    context: { queryName: `Footer.tsx setDocumentState` },
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true
  })

  return (
    <Footer
      currentState={currentState}
      handleClick={stateId => setDocumentState({ variables: { documentId, stateId } })}
      isNonLinear={isNonLinear}
      loading={loading || setDocumentStateLoading}
      stateEdges={data?.states?.edges}
    />
  )
}

export function DealChecklistFooterContainer({ dealIsFinalized }: { dealIsFinalized: boolean }) {
  const { openModal, setModalError } = useContext(ModalContext)
  const { dealId } = useParams<{ dealId: string }>()
  const { currentState, data, isNonLinear, loading } = useDealTransitions(dealId)

  const [setDealState, { loading: setDealStateLoading }] = useSetDealStateMutation({
    context: { queryName: `Footer.tsx setDealState` },
    onError: error => {
      setModalError({ title: 'Set Deal State Error:', message: error?.message })

      openModal()
    },
    refetchQueries: ['latestStateAudits'],
    awaitRefetchQueries: true
  })

  return (
    <Footer
      currentState={currentState}
      dealIsFinalized={dealIsFinalized}
      handleClick={async stateId => {
        if (await confirm('Are you sure you want to change this document’s status?')) {
          setDealState({ variables: { dealId, stateId } })
        }
      }}
      isNonLinear={isNonLinear}
      loading={loading || setDealStateLoading}
      stateEdges={data?.states?.edges}
    />
  )
}
