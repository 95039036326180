import { Mention, MentionsInput } from 'react-mentions'
import { useUsersQuery } from '../../graphql/codegen/hooks'
import type { MutationResult } from '@apollo/client'
// import { useIsKlarityEmployee } from '../../hooks/useCurrentUser';
import { formatMentions } from '../../utils/commentUtils'
import { htmlDecode } from '../../utils/stringUtils'
import Button from '../Button'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import css from './style.module.scss'

export default function CommentBox({
  autofocus,
  commentState,
  handleCancel,
  handleCreate,
  initialValue,
  notesMode
}: {
  autofocus?: boolean
  commentState?: MutationResult<any>
  handleCancel?: (c: any) => void
  handleCreate: (c: any) => void
  initialValue?: string | null
  notesMode?: boolean
}) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState(() => {
    // NOTE: comment text is being escaped on the backend by flask.escape. This function 'unescapes' those characters because our editor will render this safely as a string.
    const unescapedString = initialValue && htmlDecode(initialValue)
    return unescapedString || ''
  })
  // below lines will allow internal users to see other internal users, there are other places for comments this needs to be fixed also like reading the mentions text
  // const isKlarityEmployee = useIsKlarityEmployee();
  // const { data } = useUsersQuery({context: {queryName: `CommentBox.tsx useUsersQuery`}, variables: {internal: isKlarityEmployee}});
  const { data } = useUsersQuery({ context: { queryName: `CommentBox.tsx useUsersQuery` } })
  const users = data?.users?.map((node: any) => ({ id: node.id, display: node.user_name }))

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus()
    }
  }, [autofocus])

  const getData = useCallback(
    (filter: string) => {
      return users?.filter((user: any) => user.display.toLowerCase().includes(filter.toLowerCase()))
    },
    [users]
  )

  return (
    <div className={css.commentBoxWrapper}>
      <MentionsInput
        className="mentions"
        classNames={css}
        inputRef={inputRef}
        onChange={(e: { target: { value: string } }) => {
          setValue(e.target.value)
        }}
        onKeyDown={e => {
          if (e.key === 'Enter' && e.metaKey && !e.ctrlKey && value.length) {
            setValue('')
            handleCreate(formatMentions(value))
          }
        }}
        placeholder={`Add a ${notesMode ? 'note' : 'comment'}, or use '@' to tag others`}
        value={value}
      >
        <Mention className={css.mention} data={getData} trigger="@" />
      </MentionsInput>
      <div style={{ display: 'flex' }}>
        <Button
          disabled={!value}
          loading={commentState?.loading}
          onClick={() => {
            setValue('')
            handleCreate(formatMentions(value))
          }}
        >
          {notesMode ? 'Add Note' : 'Comment'}
        </Button>
        {handleCancel && <Button disabled={commentState?.loading} onClick={handleCancel} variant="secondary">{`Cancel`}</Button>}
      </div>
    </div>
  )
}
