export function getKeyByValue(object: any, value?: string) {
  if (!object || !value) {
    return null
  } // @ts-ignore
  else {
    return Object.keys(object).find(key => object[key] === value)
  }
}

// add isObject, hasKeys, and mapKeys(maybe) functions
