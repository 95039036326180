import { formatFieldList } from '../utils/fieldUtils'
import { useAdvancedAttachmentsDashboardFieldsQuery } from '../graphql/codegen/hooks'
import useGetExcludedFields from './useGetExcludedFields'

export default () => {
  const { data, loading } = useAdvancedAttachmentsDashboardFieldsQuery({
    context: { queryName: `useAttachmentDashboardFields.ts useAdvancedAttachmentsDashboardFieldsQuery` }
  })
  const fieldsToExclude = useGetExcludedFields('Attachments', 'Search')

  return { fields: data && formatFieldList(data, fieldsToExclude), loading }
}
