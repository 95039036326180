import { DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { ModalContext } from '../../app'
import { formatDate } from '../../utils/datetimeUtils'
import { generateDashboardColumns } from '../../utils/dashboardColumnUtils'
import { useAdvancedAttachmentsDashboardLazyQuery, useAttachmentCountLazyQuery } from '../../graphql/codegen/hooks'
import { useHistory } from 'react-router-dom'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import AttachedTo from '../ModalOptions/AttachedTo'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import MoreMenu from '../MoreMenu'
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import RenameFile from '../RenameFile'
import Table from '../Table'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useTablePagination from '../../hooks/useTablePagination'
import useUserAccess from '../../hooks/useUserAccess'

const PREVIEWABLE_EXT_REGEX = /^.*\.(jpg|jpeg|gif|png|pdf)$/

export default function AttachmentList({
  dispatch,
  setClearAll,
  setFilteredCount,
  setTotalCount
}: {
  dispatch: Dispatch<DocumentQueryAction>
  setClearAll: Dispatch<SetStateAction<boolean>>
  setFilteredCount: Dispatch<SetStateAction<number>>
  setTotalCount: Dispatch<SetStateAction<number>>
}) {
  const isKlarityEmployee = useIsKlarityEmployee()
  const history = useHistory()
  const [rowCount, setRowCount] = useState(10)
  const { offset, pageLength, ...paginationControls } = useTablePagination({ total: rowCount })
  const { gqlFilters, queryBuilderQuery, sortConfig } = useDashboardQString()
  const hasDownloadAccess = useUserAccess({ feature: 'Attachment', permission: 'DOWNLOAD' })

  const [advancedDashboardLazyQuery, { data: dataAdvanced, loading: loadingAdvanced }] = useAdvancedAttachmentsDashboardLazyQuery({
    context: { queryName: `AttachmentList.tsx useAdvancedAttachmentsDashboardQuery` },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
    // these fetch policies force the first query of the page to be from the network, then to prefer the cache afterwards.
    // this is needed in order to have the search re-fire when the search button is pressed.
  })
  const [attachmentCountLazyQuery, { data: dataCount, loading: loadingCount }] = useAttachmentCountLazyQuery({
    variables: { ...gqlFilters },
    context: { queryName: `AttachmentList.tsx useAttachmentCountQuery` },
    fetchPolicy: 'network-only'
  })
  const loading = loadingAdvanced || loadingCount

  useEffect(() => {
    if (pageLength) {
      advancedDashboardLazyQuery({ variables: { ...gqlFilters, size: pageLength, offset } })
    }
    // eslint-disable-next-line
  }, [pageLength, gqlFilters, offset])

  useEffect(() => {
    // ONLY recount if the search changed!
    attachmentCountLazyQuery({ variables: gqlFilters })
  }, [gqlFilters, attachmentCountLazyQuery])

  useEffect(() => {
    // ONLY recount if the search changed!
    if (dataCount && dataCount.fetch_advanced_attachments_dashboard_count) {
      setFilteredCount(dataCount.fetch_advanced_attachments_dashboard_count.total_number_of_filtered_documents!)
      setTotalCount(dataCount.fetch_advanced_attachments_dashboard_count.total_number_of_documents!)
    } else {
      setFilteredCount(0)
      setTotalCount(0)
    }
  }, [dataCount, setFilteredCount, setTotalCount])

  const totalFiltered = dataCount?.fetch_advanced_attachments_dashboard_count?.total_number_of_filtered_documents
  useEffect(() => {
    setRowCount(totalFiltered || 0)
  }, [totalFiltered])

  const tableData = useMemo(() => {
    if (!dataAdvanced) return []

    const dashboardData = dataAdvanced.fetch_advanced_attachments_dashboard?.dashboard_data
    const normalized = dashboardData?.map((row: any) => {
      return { ...row, created_at: row.created_at, counterPartyId: JSON.parse(row?.counter_party?.value)?.id }
    })
    return normalized // this is an object with keys that map to the 'accessor' passed to columns below
  }, [dataAdvanced])

  // #region Modal state/functions
  const { openPreview } = useContext(ModalContext)
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState<{
    attachmentAlias?: string
    attachmentId: string
    attachmentName: string
    counterpartyId?: string
    documentTypeId?: string
    isAccountLevel?: boolean
  }>()

  const openModal = (menuOption: string, attachmentId: string, attachmentName: string, attachmentAlias?: string, counterpartyId?: string) => {
    setIsOpen(true)
    setModalOption(menuOption)
    setModalTitle(menuOption)
    setModalContent({ attachmentId, attachmentName, attachmentAlias, counterpartyId })
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
      setModalTitle('')
      setModalContent(undefined)
      setModalLoading(true)
      setLoadingMessage('')
    }
  }
  // #endregion

  const handleDownload = useCallback(async ({ id, name }: any) => {
    const { data } = await axiosClient.get(`attachments/${id}`, { responseType: 'blob' })
    const href = window.URL.createObjectURL(data)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = href
    a.download = name
    document.body.appendChild(a)
    a.click()
  }, [])

  const getModalOptions = ({ attachmentAlias, attachmentId, attachmentName, counterPartyId }: any) => {
    // For now, only files that can render inside an <img/> tag are previewable
    const canPreview = attachmentName?.match(PREVIEWABLE_EXT_REGEX) || attachmentAlias?.match(PREVIEWABLE_EXT_REGEX)

    const modalOptions: any = [
      canPreview && { label: 'Preview', onClick: () => openPreview(attachmentId, false, attachmentAlias || attachmentName) },
      { label: 'View Attached To', onClick: () => openModal('Attached To', attachmentId, attachmentName, attachmentAlias, counterPartyId) },
      hasDownloadAccess && {
        label: 'Download',
        onClick: () => {
          handleDownload({ id: attachmentId, name: attachmentAlias || attachmentName })
        }
      },
      { label: 'Rename Attachment', onClick: () => openModal('Rename Attachment', attachmentId, attachmentName, attachmentAlias, counterPartyId) }
    ]
    return [...modalOptions]
  }

  // these columns will always be shown and cannot be reordered.
  const staticColumns = [
    ...generateDashboardColumns(
      history,
      queryBuilderQuery,
      sortConfig,
      [],
      [
        {
          headerName: 'Attachment',
          accessor: 'document',
          sortStringName: 'document_name',
          Cell: ({ row, value }: any) => {
            if (!value) return null
            const attachmentId = value?.id
            const attachmentName = value?.value_original || value?.value
            const attachmentAlias = value?.value_alias
            const counterPartyId = row?.values?.counter_party?.value && JSON.parse(row?.values?.counter_party?.value)?.id
            const menuItems = getModalOptions({ attachmentId, attachmentName, attachmentAlias, counterPartyId })
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: '-16px' }}
                title={attachmentAlias ? `Original Name: ${attachmentName}` : attachmentName}
              >
                <MoreMenu isFixed menuItems={menuItems} offset={[8, 8]} placement="auto" withIcon />
                {attachmentName?.match(PREVIEWABLE_EXT_REGEX) || attachmentAlias?.match(PREVIEWABLE_EXT_REGEX) ? (
                  <a onClick={() => openPreview(attachmentId, false, attachmentAlias || attachmentName)}>{attachmentAlias || attachmentName}</a>
                ) : hasDownloadAccess ? (
                  <a onClick={() => handleDownload({ id: attachmentId, name: attachmentAlias || attachmentName })}>{attachmentAlias || attachmentName}</a>
                ) : (
                  <>{attachmentAlias || attachmentName}</>
                )}
              </div>
            )
          }
        },
        {
          headerName: 'Customer',
          accessor: 'counter_party',
          sortStringName: 'counter_party_name',
          Cell: ({ value }: any) => {
            // eslint-disable-next-line
            const parsedCounterParty = value?.value && JSON.parse(value?.value);
            const counterPartyName = parsedCounterParty?.name === 'UNASSIGNED' ? '' : parsedCounterParty?.name
            return counterPartyName || null
          }
        },
        {
          headerName: 'Integration Type',
          accessor: 'integration_type',
          Cell: ({ row, value }: { row: any; value: any }) => {
            /* eslint-disable react/prop-types */ // this allows for block ignoring of eslint. It's freaking out over nothing here.
            if (!value || !value?.value || !row) {
              return null
            } else {
              const userName = row?.original?.created_by?.value?.user_name
              const uploadDate = row?.original?.created_at ? formatDate(row?.original?.created_at?.value) : undefined
              const uploadedBy = userName?.includes('klaritylaw.com') ? (isKlarityEmployee ? userName : 'Klarity') : userName
              const tooltipContent = (
                <>
                  {value?.value === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
                  {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
                </>
              )
              return <WithTooltip content={tooltipContent}>{value?.value}</WithTooltip>
            }
            /* eslint-disable react/prop-types */
          }
        },
        { headerName: 'Upload Date', accessor: 'created_at' }
      ]
    )
  ]

  return (
    <>
      <Table
        {...paginationControls}
        columns={[...staticColumns]}
        dispatch={dispatch}
        dummyDataActive={loading || !dataCount}
        loading={loading || !dataCount}
        pageLength={pageLength}
        rowCount={rowCount}
        setClearAll={setClearAll}
        tableData={tableData}
      />

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalTitle}>
        <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />
        <div className={css.modal}>
          {modalOption === 'Attached To' && (
            <AttachedTo
              attachmentDocId={modalContent?.attachmentId}
              closeModal={closeModal}
              fileName={modalContent?.attachmentAlias || modalContent?.attachmentName}
              setModalLoading={setModalLoading}
            />
          )}
          {modalOption === 'Rename Attachment' && (
            <RenameFile
              closeModal={closeModal}
              documentId={modalContent?.attachmentId}
              fileAlias={modalContent?.attachmentAlias}
              fileName={modalContent?.attachmentName}
              fileType={'Attachment'}
              loading={modalLoading}
              setLoading={setModalLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
        </div>
      </Modal>
    </>
  )
}
