import { AdvancedDashboardDocument, useCreateAdditionalReviewMutation } from '../../graphql/codegen/hooks'
import { getAssignedToMeFilters } from '../../hooks/useAssignedToMe'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router'
import Button from '../Button'
import React, { useState } from 'react'
import UserSelector from '../UserSelector'
import useCurrentUser from '../../hooks/useCurrentUser'

export default function AdditionalReviewPrompt() {
  const history = useHistory()
  const currentUser = useCurrentUser()
  const { documentId } = useParams<{ documentId: string }>()
  const [assigneeId, setAssigneeId] = useState<string | null>(null)

  const [addReviewer, { loading }] = useCreateAdditionalReviewMutation({
    context: { queryName: `AdditionalReviewModal.tsx addReviewer` },
    refetchQueries: [
      {
        query: AdvancedDashboardDocument,
        variables: getAssignedToMeFilters({ currentUser }),
        context: { queryName: `AdditionalReviewModal.tsx AdvancedDashboardDocument` }
      }
    ],
    onCompleted: () => {
      toast.success(`Successfully added reviewer.`, { autoClose: 5000 })
      history.push('/')
    }
  })

  return (
    <div>
      <h2>Request Additional Review</h2>
      <div style={{ marginBottom: 16 }}>Please select an assignee</div>
      <UserSelector
        onChange={({ value }) => {
          setAssigneeId(value)
        }}
      />
      <Button
        disabled={!assigneeId}
        loading={loading}
        onClick={() =>
          assigneeId &&
          addReviewer({
            variables: {
              documentId,
              assigneeId
            }
          })
        }
        style={{ width: '100%', marginTop: 16 }}
      >
        Submit
      </Button>
    </div>
  )
}
