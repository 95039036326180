import AddExistingDocument from './AddExistingDocument'
import Button from '../../Button'
import ComponentLoadingOverlay from '../../ComponentLoadingOverlay'
import React, { useState } from 'react'
import UploadNewDocument from './UploadNewDocument'
import css from '../style.module.scss'

export default function UploadTab({
  counterparty,
  dealIsFinalized,
  dealName,
  hasDealUpdateAccess,
  parentRef
}: {
  counterparty?: any
  dealIsFinalized: boolean
  dealName?: string
  hasDealUpdateAccess: boolean
  parentRef?: any
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const [formType, setFormType] = useState<string>('Initial')

  return (
    <>
      {hasDealUpdateAccess && !dealIsFinalized ? (
        <div className={css.card}>
          {loading && <ComponentLoadingOverlay loading={loading} message={loadingMessage} style={{ backgroundColor: 'rgba(255,255,255,.6' }} />}
          {formType === 'Initial' ? (
            <div className={css.initial}>
              <h3>{`Add documents to ${dealName}`}</h3>
              <div>
                <Button onClick={() => setFormType('Add Existing')}>{`Add existing document`}</Button>
                <Button onClick={() => setFormType('Upload New')}>{`Upload new document`}</Button>
              </div>
            </div>
          ) : formType === 'Add Existing' ? (
            <AddExistingDocument
              counterpartyId={counterparty?.id}
              dealName={dealName}
              parentRef={parentRef}
              setFormType={setFormType}
              setLoading={setLoading}
              setLoadingMessage={setLoadingMessage}
            />
          ) : (
            <UploadNewDocument
              counterparty={counterparty}
              dealName={dealName}
              parentRef={parentRef}
              setFormType={setFormType}
              setLoading={setLoading}
              setLoadingMessage={setLoadingMessage}
            />
          )}
        </div>
      ) : (
        <div className={css.card} style={{ padding: '32px 0' }}>
          <h4 style={{ textAlign: 'center' }}>{`This deal does not have any documents.`}</h4>
        </div>
      )}
    </>
  )
}
