import { useCciMainContext } from '../../../../CCI_Main'
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import css from './style.module.scss'

export default function RoleInput({ currentValues, handleData, options }: { currentValues?: any; handleData: any; options?: any }) {
  const { activeComponent, selectedItem, tab } = useCciMainContext()
  const ref = useRef<HTMLElement>()
  const [isFocused, setFocused] = useState(false)
  const [values, setValues] = useState(currentValues?.length > 0 ? [...currentValues] : [])

  const handleChange = (values?: any) => {
    if (activeComponent === 'Create User') {
      handleData('role_ids', values?.length > 0 ? [...values] : null)
    } else if (activeComponent === 'Edit User') {
      // this is filtering the currently selected options in the MSDD (values) to show only items that are not inside the original array of values (currentValues)
      // the currentValues?.filter function is filtering current values by the current index of the values array, if it finds a match it will add that match to the addedOptions array
      const addedOptions =
        values?.filter((value: any) => {
          return (
            currentValues?.filter((cv: any) => {
              return cv?.value === value?.value
            })?.length === 0
          )
        }) || []
      const removedOptions =
        values === null
          ? // if values is null but currentValues has length then all options have been removed
            currentValues?.length > 0
            ? [...currentValues]
            : null
          : // filter logic is the same addedOptions, but the arrays are flipped to look for things that are in current values but not in values
            currentValues?.filter((cv: any) => {
              return (
                values?.filter((value: any) => {
                  return value?.value === cv?.value
                })?.length === 0
              )
            }) || []

      handleData('roles_to_add', addedOptions?.length > 0 ? [...addedOptions] : null)
      handleData('roles_to_remove', removedOptions?.length > 0 ? [...removedOptions] : null)
    }
    setValues(values?.length > 0 ? [...values] : [])
  }

  useEffect(() => {
    if (isFocused) {
      ref.current?.focus()
    }
  }, [isFocused])

  // reset values on tab/component/selectedItem change
  useEffect(() => {
    setValues(currentValues?.length > 0 ? [...currentValues] : [])
    // eslint-disable-next-line
    }, [selectedItem, tab, activeComponent])

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{`Roles:`}</h4>
        {/* @ts-ignore  */}
        <Select
          isMulti
          menuPlacement="auto"
          menuPortalTarget={document.body}
          onBlur={() => setFocused(false)}
          onChange={(values: any) => {
            handleChange(values)
          }}
          onFocus={() => setFocused(true)}
          openMenuOnFocus
          options={options}
          placeholder={`Select user's Roles`}
          // @ts-ignore
          ref={ref}
          styles={{
            control: provided => {
              return { ...provided, fontSize: 14, borderRadius: 4, borderColor: '#bdbec2', boxShadow: 'none' }
            },
            option: provided => {
              return { ...provided, fontSize: 14 }
            },
            container: provided => {
              return { ...provided, padding: 0 }
            },
            multiValue: provided => {
              return { ...provided, maxWidth: '300px' }
            },
            placeholder: provided => {
              return { ...provided, color: '#e0e0e0' }
            },
            dropdownIndicator: () => {
              return { display: 'none' }
            },
            indicatorSeparator: () => {
              return { display: 'none' }
            },
            clearIndicator: (provided, { isFocused }) => {
              return { ...provided, visibility: isFocused ? 'visible' : 'hidden' }
            },
            menuPortal: provided => {
              return { ...provided, zIndex: 999999999 }
            }
          }}
          theme={theme => {
            return { ...theme, borderRadius: 2, colors: { ...theme.colors, primary: '#0067ff' } }
          }}
          value={values}
        />
      </div>
    </>
  )
}
