import { Box, Button, IconButton, InputAdornment, List, ListItem, TextField, Typography } from '@mui/material'
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, DropResult, Droppable } from 'react-beautiful-dnd'
import { isEmpty, isEqual, isUndefined } from 'lodash'
import { useCciDocumentTypesQuery } from '../../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../../CCI_Main'
import { useFormik } from 'formik'
import ComponentLoadingOverlay from '../../../../../../components/ComponentLoadingOverlay'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import useUserAccess from '../../../../../../hooks/useUserAccess'

// types

interface AddDocumentTypeProps {
  documentTypeList: DocumentType[]
  setDocumentTypeList: Dispatch<SetStateAction<DocumentType[]>>
}

export interface DocumentType {
  id?: string
  isNew?: boolean
  name: string
}

interface DisplayErrorProps {
  error?: string
}

interface DocumentTypesProps {
  documentTypeList: DocumentType[]
  setDocumentTypeList: Dispatch<SetStateAction<DocumentType[]>>
}

interface Errors {
  name?: string
}

interface ExistingDocumentTypeProps {
  documentType: DocumentType
  documentTypeList: DocumentType[]
  dragHandleProps?: DraggableProvidedDragHandleProps
  index: number
  setDocumentTypeList: Dispatch<SetStateAction<DocumentType[]>>
}

interface Values {
  name: string
}

// functions

const getInputProps = (dragHandleProps?: DraggableProvidedDragHandleProps) => ({
  autoComplete: 'off',
  endAdornment: Boolean(dragHandleProps) && (
    <InputAdornment {...dragHandleProps} position="end" tabIndex={-1}>
      <DragHandleIcon />
    </InputAdornment>
  ),
  sx: { background: '#fff', fontSize: 14 }
})

const reorder = (documentTypeList: DocumentType[], startIndex: number, endIndex?: number) => {
  const result = Array.from(documentTypeList)

  const [removed] = result.splice(startIndex, 1)

  if (!isUndefined(endIndex)) {
    result.splice(endIndex, 0, removed)
  }

  return result
}

// components

const AddDocumentType: FC<AddDocumentTypeProps> = ({ documentTypeList, setDocumentTypeList }) => {
  const { errors, getFieldProps, handleSubmit, isValid, setFieldValue, values } = useFormik({
    initialValues: { name: '' },

    onSubmit: ({ name }: Values) => {
      setDocumentTypeList(previous => [...previous, { id: String(Math.random()), isNew: true, name }])

      setFieldValue('name', '')
    },

    validate: ({ name }: Values) => {
      const errors: Errors = {}

      if (documentTypeList.find(documentType => documentType.name === name.trim())) {
        errors.name = 'Must be unique.'
      }

      return errors
    },

    validateOnBlur: false
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" flexGrow={1} sx={{ mt: -0.5, mx: 7 }}>
        <Box display="flex" gap={1} sx={{ width: '100%' }}>
          <TextField {...getFieldProps('name')} InputProps={getInputProps()} placeholder="Add item…" size="small" sx={{ width: '100%' }} />

          <Button disabled={!(values.name.trim() && isValid)} type="submit" variant="contained">
            Add
          </Button>
        </Box>

        <DisplayError error={errors.name} />
      </Box>
    </form>
  )
}

const DisplayError: FC<DisplayErrorProps> = ({ error }) => (
  <>
    {error && (
      <Box ml={1.75} my={1} sx={{ color: 'error.main', fontSize: 14 }}>
        {error}
      </Box>
    )}
  </>
)

export const EditDocumentTypes: FC = () => {
  const { data: initialDocumentTypesData } = useCciDocumentTypesQuery()
  const { openModal } = useCciMainContext()
  const [documentTypeList, setDocumentTypeList] = useState<DocumentType[]>([])
  const [initialDocumentTypeList, setInitialDocumentTypeList] = useState<DocumentType[]>([])
  const [isDisabled, setIsDisabled] = useState(true)
  const hasEditDocumentTypesAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'EDIT_DOCUMENT_TYPES' })

  useEffect(() => setIsDisabled(isEqual(documentTypeList, initialDocumentTypeList)), [documentTypeList, initialDocumentTypeList, isDisabled])

  useEffect(() => {
    if (initialDocumentTypesData) {
      const list: DocumentType[] = initialDocumentTypesData.cci_document_types!.map(option => ({ id: option!.id, name: option!.name }))

      setDocumentTypeList(list)
      setInitialDocumentTypeList([...list])
    }
  }, [initialDocumentTypesData])

  if (!initialDocumentTypesData) return <ComponentLoadingOverlay loading />

  if (isEmpty(initialDocumentTypeList)) return <Typography>No document types found.</Typography>

  return (
    <>
      <Typography mt={1.5} textAlign="center" variant="h3">
        Edit Document Types
      </Typography>

      <Typography mt={1.5} textAlign="center">
        Document type order determines the default resolution strategy.
      </Typography>

      <Typography mb={1} textAlign="center">
        This can be overridden for specific fields if needed.
      </Typography>

      {hasEditDocumentTypesAccess ? (
        <>
          <DocumentTypes documentTypeList={documentTypeList} setDocumentTypeList={setDocumentTypeList} />

          <Box mb={1} mt={3} textAlign="center">
            <Button
              disabled={isDisabled}
              onClick={() =>
                openModal({
                  menuOption: 'Review Document Type Edits',
                  content: {
                    documentTypeList: documentTypeList.map(({ id, isNew, name }) => ({ ...(!isNew && { id }), name })) as DocumentType[],
                    initialDocumentTypeList
                  }
                })
              }
              variant="contained"
            >
              Review Changes
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography textAlign="center">At this time, changes to document types can only be made by Klarity.</Typography>

          <Typography mb={1} textAlign="center">
            Please reach out to us for assistance.
          </Typography>

          <List sx={{ mt: 2 }}>
            {initialDocumentTypeList?.map(({ name }) => (
              <ListItem key={name}>{name}</ListItem>
            ))}
          </List>
        </>
      )}
    </>
  )
}

const DocumentTypes: FC<DocumentTypesProps> = ({ documentTypeList, setDocumentTypeList }) => {
  const handleDelete = (index: number) => setDocumentTypeList(previous => previous.filter((_, localIndex) => localIndex !== index))

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    setDocumentTypeList(documentTypeList => reorder(documentTypeList, result.source.index, result.destination?.index))
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {({ droppableProps, innerRef, placeholder }) => (
          <>
            <form>
              <List {...droppableProps} ref={innerRef} sx={{ ml: 5 }}>
                {documentTypeList.map((documentType, index) => (
                  <Draggable draggableId={documentType.id!} index={index} key={documentType.id}>
                    {({ dragHandleProps, draggableProps, innerRef }) => (
                      <ListItem {...draggableProps} ref={innerRef} style={draggableProps.style} sx={{ gap: 0.5, py: 0.5 }}>
                        <ExistingDocumentType
                          documentType={documentType}
                          documentTypeList={documentTypeList}
                          dragHandleProps={dragHandleProps}
                          index={index}
                          setDocumentTypeList={setDocumentTypeList}
                        />

                        <IconButton color="error" onClick={() => handleDelete(index)} sx={{ ...(!documentType.isNew && { visibility: 'hidden' }) }}>
                          <HighlightOffIcon fontSize="small" />
                        </IconButton>
                      </ListItem>
                    )}
                  </Draggable>
                ))}

                {placeholder}
              </List>
            </form>

            <AddDocumentType documentTypeList={documentTypeList} setDocumentTypeList={setDocumentTypeList} />
          </>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const ExistingDocumentType: FC<ExistingDocumentTypeProps> = ({ documentType, documentTypeList, dragHandleProps, index, setDocumentTypeList }) => {
  const { errors, getFieldProps } = useFormik({
    initialValues: { name: documentType.name || '' },

    onSubmit: () => undefined,

    validate: (values: Values) => {
      const errors: Errors = {}

      if (documentTypeList.find(({ name }, localIndex) => name === values.name.trim() && index !== localIndex)) {
        errors.name = 'Must be unique.'
      } else {
        const index = documentTypeList.findIndex(({ id }) => id === documentType?.id)

        setDocumentTypeList(previous => [...previous.slice(0, index), { ...previous[index], name: values.name.trim() }, ...previous.slice(index + 1)])
      }

      return errors
    },

    validateOnBlur: false
  })

  return (
    <>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <TextField {...getFieldProps('name')} InputProps={getInputProps(dragHandleProps)} size="small" sx={{ width: '100%' }} />

        <DisplayError error={errors.name} />
      </Box>
    </>
  )
}
