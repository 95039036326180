import { ModalContext } from '.'
import { NAVATTIC_USER_ACCEPT_LIST, loadNavatticScript } from '../utils/navatticUtils'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIsAnnotator } from '../hooks/useUserAccess'
import useCurrentUser from '../hooks/useCurrentUser'

// This component is to move app level utility useEffects outside of the main file for clarity
export default function AppSetupUtils() {
  const { closeModal, openModal } = useContext(ModalContext)
  const history = useHistory()
  const currentUser = useCurrentUser()
  const isAnnotator = useIsAnnotator()
  const [isStorageListenerLoaded, setIsStorageListenerLoaded] = useState(false)

  // eslint-disable-next-line
    useEffect(() => { removeIntercomAndGoogleCookies(); }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && isAnnotator) {
      require('../utils/matomo')
    }
    // Append navattic script just for approved users
    if (currentUser?.user_name && NAVATTIC_USER_ACCEPT_LIST.includes(currentUser.user_name)) {
      loadNavatticScript()
    }

    // this block sets up current customer tracking for the wrong customer modal and the ability to force sign out of all tabs on logout/token expiration
    if (currentUser && !isStorageListenerLoaded) {
      const currentCustomerName = localStorage.getItem('customerName')
      window.addEventListener('storage', e => {
        let currentName = currentCustomerName
        if (e.key === 'okta-token-storage' && e.newValue === null) {
          // this is used to log out of all tabs when the okta-token-storage value has been cleared.
          history.push('/login') // redirect to /login to not needlessly clear values/sign out of Okta again and potentially cause infinite looping back to /logout
        } else if (e.key === 'customerName' && isAnnotator) {
          if (!currentName) {
            currentName = e.newValue
          } else if (currentName === e.newValue) {
            closeModal()
          } else {
            openModal(`WrongCustomer`, `Wrong Customer`, { customerName: currentName })
          }
        }
      })
      setIsStorageListenerLoaded(true)
    }
    // eslint-disable-next-line
    }, [currentUser, isAnnotator]);

  return null
}

const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}
const removeIntercomAndGoogleCookies = () => {
  const cookiesToRemove = ['intercom-id', 'intercom-session', '1P_JAR', '_ga', '_gid']
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const cookieName = ca[i].split('=')[0]
    for (let c = 0; c < cookiesToRemove.length; c++) {
      if (cookieName?.includes(cookiesToRemove[c])) {
        deleteCookie(cookieName)
        break
      }
    }
  }
}
