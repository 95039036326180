import { FiXSquare } from 'react-icons/fi'
import Button from '../../../../../../../components/Button'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { KeyboardEvent, SyntheticEvent, useState } from 'react'
import css from './style.module.scss'

export default function NameInput({ handleData, value }: { handleData: any; value?: string }) {
  const [isFocused, setFocused] = useState(false)
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    // @ts-ignore
    if (isFocused && e?.key === 'Enter') {
      setFocused(false)
      // @ts-ignore
      e?.target?.blur()
    }
  }

  return (
    <>
      <div className={css.inputWrapper}>
        <h4>{`Name:`}</h4>
        <FreeText
          isFocused={isFocused}
          onChange={(e: SyntheticEvent<HTMLTextAreaElement>) => {
            handleData('name', e.currentTarget.value)
          }}
          onKeyDown={handleKeyDown}
          placeholder={value || `Enter a name for the field`}
          setFocused={setFocused}
          value={value || ''}
        />
        {value && (
          <Button
            className={css.cciButton}
            icon={<FiXSquare />}
            onClick={() => {
              handleData('name', null)
            }}
          />
        )}
      </div>
    </>
  )
}
