import { useCciMainContext } from '../../../CCI_Main'
import { useDeleteIntegrationMutation } from '../../../../../graphql/codegen/hooks'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function DeleteIntegration({ closeModal }: { closeModal?: any }) {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [deleteIntegrationMigration, { error: deleteIntegrationError, loading: deleteIntegrationLoading }] = useDeleteIntegrationMutation({
    context: { queryName: `DeleteIntegration.tsx deleteIntegrationMigration` },
    onCompleted: () => {
      setModalSuccess('Integration successfully deleted.')
    }
  })

  const handleDelete = () => {
    deleteIntegrationMigration()
  }

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (deleteIntegrationLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Deleting Integration…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [deleteIntegrationLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(deleteIntegrationError)}, [deleteIntegrationError])

  return (
    <>
      <p style={{ textAlign: 'center', marginTop: '8px' }}>{`Are you sure you want to delete this integration?`}</p>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleDelete}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
