import { NavLink, Route, Switch, useLocation } from 'react-router-dom'
import DealsTab from '../../pages/Dashboard/DealsTab'
import DocumentsTab from '../../pages/Dashboard/DocumentsTab'
import React, { FC } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

interface TabInterface {
  component: FC
  path: string
  title: string
}
interface IProps {
  hasDealAccess: boolean
  tabs: TabInterface[]
}

export default function Tabs({ hasDealAccess, tabs }: IProps) {
  const location = useLocation()

  return (
    <section className={css.main}>
      <ul className={css.tabRow}>
        {tabs.map(({ path, title }, idx: number) => {
          return (
            <li key={`tab-link-${idx}`}>
              <NavLink
                activeClassName={css.active}
                className={clsx({
                  // Manually adding active styling for the proper tab link when at /downloads or /preview
                  [css.active]:
                    (location.pathname.includes('downloads') || location.pathname.includes('preview')) && title === (hasDealAccess ? 'Deals' : 'Documents')
                })}
                exact
                to={path}
              >
                {title}
              </NavLink>
            </li>
          )
        })}
      </ul>
      <div className={css.tabBody}>
        <Switch>
          {tabs.map(({ component, path }, idx: number) => {
            return <Route component={component} exact key={`tab-route-${idx}`} path={path} />
          })}
          <Route component={hasDealAccess ? DealsTab : DocumentsTab} path={'/downloads'} />;
          <Route component={hasDealAccess ? DealsTab : DocumentsTab} path={'/preview'} />;
        </Switch>
      </div>
    </section>
  )
}
