import 'tippy.js/dist/tippy.css'
import { EditDataPointManualReviewMutation } from '../../graphql/codegen/operations'
import { MutationFunctionOptions } from '@apollo/client'
import React, { useCallback } from 'react'
import Tippy from '@tippyjs/react'
import css from './style.module.scss'
import type { CustomState, DataPoint, Exact } from '../../graphql/codegen/schemas'

// types

interface ManualReviewCheckboxProps {
  dealIsFinalized: boolean
  handleChange: () => void
  isReviewed: boolean
  priority: number
  state: CustomState | null
}

interface ManualReviewCheckboxesProps {
  dataPoint: DataPoint
  dealIsFinalized: boolean
  mutation: (options: MutationFunctionOptions<EditDataPointManualReviewMutation, Exact<{ dataPointId: string; isReviewed: boolean; priority: number }>>) => void
  states: CustomState[]
}

// components

const ManualReviewCheckbox = ({ dealIsFinalized, handleChange, isReviewed, priority, state }: ManualReviewCheckboxProps) => {
  const content = dealIsFinalized
    ? `This field has${isReviewed ? `` : ` not`} been manually reviewed.`
    : priority === state?.priority
    ? `Check this box after manual review.`
    : `Disabled${state ? ` while in L${state.priority! - 1}` : ``}.`

  return (
    <Tippy content={content}>
      <label className={css.manualReviewCheckboxLabel}>
        {`L${priority - 1}`}

        <input
          checked={isReviewed}
          className={css.manualReviewCheckbox}
          disabled={dealIsFinalized || priority !== state?.priority}
          onChange={handleChange}
          type="checkbox"
        />
      </label>
    </Tippy>
  )
}

export const ManualReviewCheckboxes = ({ dataPoint, dealIsFinalized, mutation, states }: ManualReviewCheckboxesProps) => {
  const state = dealIsFinalized ? null : states.find(state => state.active)!

  // @ts-ignore
  const { id, [`is_reviewed_${state?.priority - 1}`]: isReviewed } = dataPoint

  const handleChange = useCallback(() => {
    if (state) {
      mutation({ variables: { dataPointId: id, isReviewed: !isReviewed, priority: state.priority! } })
    }
  }, [id, isReviewed, mutation, state])

  return (
    <>
      {states.map(({ priority }) => {
        // @ts-ignore
        const isReviewed = dataPoint[`is_reviewed_${priority - 1}`]

        return (
          <ManualReviewCheckbox
            dealIsFinalized={dealIsFinalized}
            handleChange={handleChange}
            isReviewed={isReviewed}
            key={priority!}
            priority={priority!}
            state={state}
          />
        )
      })}
    </>
  )
}
