import useIsRapid7LegalTeam from './useIsRapid7LegalTeam'
import useUserAccess, { useIsAnnotator } from './useUserAccess'

export default (dashboardTab: 'Deals' | 'Documents' | 'Attachments', component: 'Search' | 'FieldSelector') => {
  const isAnnotator = useIsAnnotator()
  const hasDealAccess = useUserAccess({ feature: 'DealDashboard', permission: 'READ' })
  const isRapid7LegalTeam = useIsRapid7LegalTeam()
  const fieldsToExclude = ['__typename'] // __typename is graphql metadata returned in the query result, not a field so should always be excluded.

  if (isAnnotator) {
    fieldsToExclude.push('assignee', 'all_assignees')
    if (component === 'FieldSelector') {
      fieldsToExclude.push('internal_state', 'internal_assignee')
    }
  } else {
    fieldsToExclude.push('internal_state', 'internal_assignee')
  }

  if (component === 'FieldSelector') {
    // allow searching of these columns, only exclude from the field selector list
    // static columns. document is actually combined_name, which is always included in the table as Deal/Document/Attachment. counter_party shows up as Customer.
    fieldsToExclude.push('document', 'counter_party')
  }

  if (dashboardTab === 'Documents') {
    // hide these columns from selection for users without relevant permissions
    if (hasDealAccess && !isRapid7LegalTeam) {
      fieldsToExclude.push('status', 'assignee', 'all_assignees')
    }
  }
  // Attachments tab currently does not need exclude filters because all four of its columns are static, so the FieldSelectionModal is not shown.

  return [...fieldsToExclude]
}
