import 'tippy.js/dist/tippy.css'
import React, { ReactElement } from 'react'
import Tippy from '@tippyjs/react'

// HOC for tooltip with optional text
export default function WithTooltip({
  children,
  content,
  tippyClass,
  ...rest
}: {
  [x: string]: any
  children: ReactElement
  content?: any
  tippyClass?: string
}) {
  return content ? (
    <Tippy className={tippyClass || ''} content={content} {...rest}>
      <div {...rest}>{children}</div>
    </Tippy>
  ) : (
    children
  )
}
