import { groupDataPointFields } from '../../../../../../utils/dataPointUtils'
import { useCciDealDataPointFieldsQuery } from '../../../../../../graphql/codegen/hooks'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import CCI_Checklist_DND from './CCI_Checklist_DND'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import React, { useEffect, useMemo } from 'react'

export default function CCI_LeftPanel_ChecklistList() {
  const { setContentData, setLeftpanelLoading, tab } = useCciMainContext()
  const { searchTerm, setDocumentTypesField, setSearchableData } = useCciLeftPanelContext()
  const { data: checklistData, loading: isChecklistLoading } = useCciDealDataPointFieldsQuery({
    context: { queryName: 'CCI_LeftPanel_ChecklistList.tsx getChecklist' }
  })

  useEffect(() => {
    if (checklistData) {
      const documentTypesField = checklistData?.cci_deal_data_point_fields?.filter(field => {
        const mappingMatch =
          field?.internal_mapping?.filter((tag: any) => {
            return tag === 'document_type'
          }) || []
        return mappingMatch?.length > 0 || false
      })
      // @ts-ignore
      setDocumentTypesField((documentTypesField?.length > 0 && documentTypesField[0]) || undefined)
    } else {
      setDocumentTypesField(undefined)
    }
    // eslint-disable-next-line
  }, [checklistData])

  const groups = useMemo(() => groupDataPointFields(checklistData?.cci_deal_data_point_fields), [checklistData])

  const sortedNames = useMemo(() => {
    return (
      groups &&
      Object.keys(groups).sort((a: string, b: string) => {
        const p1 = groups[a][0]?.group_priority
        const p2 = groups[b][0]?.group_priority
        if (p1 && p2 && p1 > p2) return 1
        return -1
      })
    )
  }, [groups])

  // this handles resetting contentData from cached values (and for initial load for this component) when switching tabs
  useEffect(() => {
    if (!tab || tab === 'checklist') {
      setContentData(sortedNames || '')

      setSearchableData(sortedNames || '')
    }
  }, [setContentData, setSearchableData, sortedNames, tab])

  useEffect(() => {
    if (!checklistData || isChecklistLoading) {
      setLeftpanelLoading(true)
    } else {
      setLeftpanelLoading(false)
    }
  }, [checklistData, isChecklistLoading, setLeftpanelLoading])

  if (isChecklistLoading) return <ChecklistSkeleton />

  if (searchTerm) {
    return (
      <CCI_Checklist_DND
        groups={groupDataPointFields(checklistData?.cci_deal_data_point_fields?.filter(item => item?.name.toLowerCase().includes(searchTerm.toLowerCase())))}
        isReorderingEnabled={false}
        sortedNames={sortedNames}
      />
    )
  }

  return <CCI_Checklist_DND groups={groups} sortedNames={sortedNames} />
}
