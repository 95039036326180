import { Note as NoteType } from '../graphql/codegen/schemas'
import { NotesDataDocument, NotesTabCountDocument, useDeleteDealNoteMutation, useDeleteDocumentNoteMutation } from '../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import Comment from '../components/Comment'
import Loader from '../components/Loader'
import MoreMenu from '../components/MoreMenu'
import React from 'react'
import useCurrentUser from '../hooks/useCurrentUser'

// [x: string]: any; is rest
interface Props {
  [x: string]: any
  dealIsFinalized: boolean
  note: NoteType
}

export function DealNoteContainer({ dealIsFinalized, note, ...rest }: Props) {
  const { dealId } = useParams<{ dealId: string }>()
  const { created_by, id } = note
  const currentUser = useCurrentUser()
  const isMine = currentUser?.id === created_by?.id

  const [deleteMutation, { loading: deleteMutationLoading }] = useDeleteDealNoteMutation({
    refetchQueries: [
      { query: NotesDataDocument, variables: { resourceId: dealId } },
      { query: NotesTabCountDocument, variables: { resourceId: dealId } }
    ],
    awaitRefetchQueries: true,
    variables: { dealId, noteId: id }
  })

  const renderActions = () => {
    if (!dealIsFinalized && isMine) {
      if (deleteMutationLoading) {
        return (
          <div>
            <Loader size="s" />
          </div>
        )
      }
      return (
        <MoreMenu
          menuItems={[
            {
              label: 'Delete',
              onClick: () => {
                deleteMutation()
              }
            }
          ]}
          withIcon
        />
      )
    } else {
      return null
    }
  }

  // ...rest is for renderTopRow
  return (
    <Comment
      author={isMine ? 'You' : note.created_by?.first_name}
      date={note.created_at}
      id={note.id}
      renderActions={renderActions}
      text={note.text}
      {...rest}
    />
  )
}

export function DocumentNoteContainer({ note, ...rest }: Props) {
  const { documentId } = useParams<{ documentId: string }>()
  const { created_by, id } = note
  const currentUser = useCurrentUser()
  const isMine = currentUser?.id === created_by?.id

  const [deleteMutation, { loading: deleteMutationLoading }] = useDeleteDocumentNoteMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: NotesDataDocument, variables: { resourceId: documentId } },
      { query: NotesTabCountDocument, variables: { resourceId: documentId } }
    ],
    variables: { documentId, noteId: id }
  })

  const renderActions = () => {
    if (isMine) {
      if (deleteMutationLoading) {
        return (
          <div>
            <Loader size="s" />
          </div>
        )
      }
      return (
        <MoreMenu
          menuItems={[
            {
              label: 'Delete',
              onClick: () => {
                deleteMutation()
              }
            }
          ]}
          withIcon
        />
      )
    } else {
      return null
    }
  }

  // ...rest is for renderTopRow
  return (
    <Comment
      author={isMine ? 'You' : note.created_by?.first_name}
      date={note.created_at}
      id={note.id}
      renderActions={renderActions}
      text={note.text!}
      {...rest}
    />
  )
}
