import { ModalContext } from '../../../app'
import React, { useContext } from 'react'
import css from './style.module.scss'

export default function WrongCustomer() {
  const { modalContent } = useContext(ModalContext)

  return (
    <>
      <div className={css.wrongCustomerParent}>
        <p>{`Please sign back into ${modalContent?.customerName} to make changes to this page.`}</p>
      </div>
    </>
  )
}
