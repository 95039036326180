export const NAVATTIC_USER_ACCEPT_LIST = [
  'neil.mclean@navattic.com',
  'chris.hoyle@navattic.com',
  'nischal-demo@klaritylaw.com',
  'charlie-demo@klaritylaw.com',
  'venumadhav-demo@klaritylaw.com'
]

export function loadNavatticScript() {
  const script = document.createElement('script')
  script.src = 'https://js.navattic.com/navattic.js'

  script.onload = function () {
    window.flow.init('e6R1fqWxRAf9UWh940nT')
  }

  script.onerror = function () {
    console.error('Error loading navattic script tag')
  }

  if (document.body) {
    document.body.appendChild(script)
  }
}
