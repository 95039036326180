import { Box, Typography } from '@mui/material'
import { useCciMainContext } from '../../../../CCI_Main'
import BooleanInputs from './EditInputs/BooleanInputs'
import Button from '../../../../../../components/Button'
import CollisionResolutionInput from './EditInputs/CollisionResolutionInput'
import DefaultValueInput from './EditInputs/DefaultValueInput'
import DescriptionInput from './EditInputs/DescriptionInput'
import FieldOptions from './EditInputs/FieldOptions'
import FieldTypeInput from './EditInputs/FieldTypeInput'
import NameInput from './EditInputs/NameInput'
import NeutralTagRow from './EditInputs/NeutralTagRow'
import React, { FC, useEffect, useState } from 'react'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'
import useUserAccess from '../../../../../../hooks/useUserAccess'

// types

interface EditFieldProps {
  hasFullAccess: boolean
}

interface GroupProps {
  name: string
}

// functions

const validateInputs = (selectedItem?: any, values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []
  if (!values || (Object.keys(values).length === 0 && values.constructor === Object)) {
    errors.push('No changes have been made')
  }

  // If source is INTERNAL must have collision type
  if (selectedItem?.source === 'INTERNAL' && !selectedItem?.collision_type && !values?.collision_type) {
    errors.push(`Collision Type is required for INTERNAL Sources`)
  }

  // If dropdown or multi dropdown must have an option
  if (
    (selectedItem?.field_type === 'DROP_DOWN' ||
      selectedItem?.field_type === 'MULTI_SELECT_DROP_DOWN' ||
      values?.field_type === 'DROP_DOWN' ||
      values?.field_type === 'MULTI_SELECT_DROP_DOWN') &&
    (!selectedItem?.options?.length || selectedItem?.options?.length < 1) &&
    (!values?.options?.length || values?.options?.length < 1)
  ) {
    errors.push(`Dropdown and Multi-Dropdowns must have at least one option`)
  }

  // If field_type is DATE must have a value format
  if ((selectedItem?.field_type === 'DATE' || values?.field_type === 'DATE') && !selectedItem?.value_format && !values?.value_format) {
    errors.push(`Value Format is required when Field Type is DATE`)
  }

  // no duplicate options
  if (selectedItem?.options?.length || values?.options?.length) {
    const formattedValues =
      values?.options?.map((item: any) => {
        return item?.newValue || item?.currentValue
      }) || []
    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === '@@_DELETE_THIS_VALUE') {
          return false
        }
        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const newValueDuplicates = formattedValues?.filter((newValue?: any) => {
          if (item === newValue) {
            return true
          } else {
            return false
          }
        })
        if (newValueDuplicates?.length > 1) {
          return true
        }
        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const currentValueDuplicates =
          selectedItem?.options?.filter((currentValue?: any, currentValueIndex?: any) => {
            if (!currentValue) {
              return false
            } // cant be duplicate if null
            // ignore currentValue if there is a new value for it, otherwise return true if there is a match
            else if (currentValue && !formattedValues[currentValueIndex] && item === currentValue) {
              return true
            } else {
              return false
            }
          }) || []
        if (currentValueDuplicates?.length > 1) {
          return true
        } else {
          return false
        }
      }) || []
    if (duplicateTags.length > 0) {
      errors.push(`Options must be unique`)
    }
  }

  // new OMSDD options must have a neutral tag
  if (selectedItem?.option_to_neutral_tag_mapping !== null) {
    // only OMSDDs can/must have this field
    values?.options?.forEach((option: any) => {
      if (!option.currentValue && option?.newValue && !option?.neutralTag) {
        errors.push(`Neutral tag is missing for option: ${option.newValue}`)
      }
    })
  }

  if (errors.length > 0) {
    setIsDisabled(true)
    setDisabledMessage(
      <>
        {`Required Values are missing:`}
        {errors.map((error?: string) => {
          return <div key={error}>{error}</div>
        })}
      </>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage('')
  }
}

// components

export const EditField: FC<EditFieldProps> = ({ hasFullAccess }) => {
  const { activeComponent, openModal, selectedItem } = useCciMainContext()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState('')
  const hasEditNeutralTagAccess = useUserAccess({ feature: 'CCI_Checklist_Tab', permission: 'EDIT_NEUTRAL_TAG' })

  useEffect(() => {
    setValues(undefined)
  }, [selectedItem?.id])

  const handleData = (type: string, value: any) => {
    // console.info('handleData firing, type:', type, 'value:', value)
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`EditField handleData error: Must provide a valid option`)
          return
        case 'deleted_options':
          return setValues((prev: any) => {
            return { ...prev, deleted_options: [...value] }
          })
        case 'options':
          return setValues((prev: any) => {
            return { ...prev, options: [...value] }
          })
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(selectedItem, values, setIsDisabled, setDisabledMessage)
  }, [selectedItem, values])

  return (
    <>
      {selectedItem ? (
        <>
          <div className={css.editField}>
            <Typography align="center" sx={{ mb: 1 }} variant="h2">
              {selectedItem.name}
            </Typography>

            <Typography align="center" sx={{ mb: 3 }} variant="h3">
              {activeComponent === 'Delete Field' ? 'Delete' : 'Edit'} Field
            </Typography>

            <NameInput currentValue={selectedItem?.name || ''} handleData={handleData} newValue={values?.name} />

            <DescriptionInput currentValue={selectedItem?.description || ''} handleData={handleData} newValue={values?.description} />

            <Group name={selectedItem.group} />

            {hasFullAccess && (
              <>
                {hasEditNeutralTagAccess && selectedItem?.source === 'INTERNAL' && !selectedItem?.option_to_neutral_tag_mapping && <NeutralTagRow />}
                <FieldTypeInput currentValue={selectedItem?.field_type || ''} handleData={handleData} selectedValue={values?.field_type || ''} />
                <FieldOptions
                  currentDefaultValue={selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''}
                  currentFieldType={selectedItem?.field_type || ''}
                  currentItemId={selectedItem?.id}
                  currentOptions={selectedItem?.options}
                  currentValueFormat={selectedItem?.value_format}
                  handleData={handleData}
                  newDefaultValue={values?.default_value || ''}
                  newFieldType={values?.field_type || ''}
                  newValueFormat={values?.value_format}
                />
                <DefaultValueInput
                  currentFieldType={selectedItem?.field_type || ''}
                  currentItemId={selectedItem?.id}
                  currentOptions={selectedItem?.options || undefined}
                  currentValue={selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.default_value : ''}
                  handleData={handleData}
                  newFieldType={values?.field_type || ''}
                  newOptions={values?.options || undefined}
                  newValue={values?.default_value || ''}
                  valueFormat={values?.value_format || selectedItem?.value_format || undefined}
                />
                {(selectedItem?.source === 'INTERNAL' || values?.source === 'INTERNAL') && (
                  <CollisionResolutionInput
                    currentCollisionType={selectedItem?.collision_type}
                    currentResolutionStrategy={selectedItem ? JSON.parse(selectedItem?.inheritance_logic)?.resolution_strategy : ''}
                    handleData={handleData}
                    newCollisionType={values?.collision_type}
                  />
                )}
              </>
            )}
            <BooleanInputs
              currentDefaultFieldOnDashboard={selectedItem?.default_field_on_dashboard}
              currentDisplayAccountingImpact={selectedItem?.display_accounting_impact}
              currentDisplayIfEmpty={selectedItem?.display_if_empty}
              currentDisplayOnDocument={selectedItem?.display_on_document}
              currentItemId={selectedItem?.id}
              handleData={handleData}
            />
          </div>
          <div className={css.modalButtonRow}>
            {/* <Button onClick={()=>{console.info('values', values)}} variant={'secondary'}>Console log values</Button> */}
            <WithTooltip content={disabledMessage}>
              <Button
                disabled={isDisabled}
                onClick={() => {
                  openModal({ menuOption: 'Review Edits', content: { dataPointID: selectedItem?.id, dataPointName: selectedItem?.name, values } })
                }}
              >
                {`Review changes`}
              </Button>
            </WithTooltip>
          </div>
        </>
      ) : (
        <p style={{ textAlign: 'center' }}>{`Select an item from the checklist to edit`}</p>
      )}
    </>
  )
}

const Group: FC<GroupProps> = ({ name }) => (
  <Box alignItems="baseline" display="flex" marginBottom={1.5}>
    <Typography sx={{ width: 131 }} variant="h4">
      Group:
    </Typography>

    <Typography>{name}</Typography>
  </Box>
)
