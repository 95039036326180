import { FallbackProps, ErrorBoundary as RErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import Button from '../Button'
import React, { PropsWithChildren } from 'react'

function Fallback({ error }: FallbackProps) {
  console.error(error)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, maxWidth: 800, textAlign: 'center', margin: 'auto' }}>
      <h1>
        <span aria-label="Embarrassed monkey" role="img">
          🙈
        </span>
        {`Well, this is embarrassing.`}
      </h1>
      <p>{`Apologies for the inconvenience - it appears that something has gone wrong on our end.`}</p>
      <p>{`Please contact us so we can make sure it doesn't happen again.`}</p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Button>
          <Link style={{ color: '#fff' }} to="/contact-us">{`Contact us`}</Link>
        </Button>
        {/* Set this manually to reset react state, query params */}
        <Button onClick={() => (window.location.href = '/')} variant="secondary">{`Go home`}</Button>
      </div>
    </div>
  )
}

export default function ErrorBoundary({ children }: PropsWithChildren<any>) {
  return <RErrorBoundary FallbackComponent={Fallback}>{children}</RErrorBoundary>
}
