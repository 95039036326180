import { CciUsersDocument, useCreateUserMutation } from '../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../CCI_Main'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function ReviewNewUser({ closeModal, values }: { closeModal?: any; dataPointName?: string; values?: any }) {
  const { setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess } = useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [createUserMutation, { error: createUserError, loading: createUserLoading }] = useCreateUserMutation({
    context: { queryName: `ReviewNewUser.tsx createUserMutation` },
    onCompleted: () => setModalSuccess('User successfully created.'),
    refetchQueries: [{ query: CciUsersDocument }]
  })

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (createUserLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating User…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [createUserLoading])

  // eslint-disable-next-line
    useEffect(()=>{setModalError(createUserError)}, [createUserError])

  const handleSubmit = () => {
    const localValues = { ...values }
    const role_ids = formatRoleIds()
    delete localValues.role_ids
    // console.info('submitted values:', {role_ids, ...localValues})
    createUserMutation({ variables: { role_ids, ...localValues } })
  }

  const formatRoleIds = () => {
    if (!values?.role_ids?.length) {
      return undefined
    }
    return (
      values?.role_ids?.map((role: any) => {
        return role?.value
      }) || undefined
    )
  }

  return (
    <>
      {values?.user_primary_email && (
        <div className={css.reviewDiv}>
          <h4>{`Email:`}</h4>
          <p>{values?.user_primary_email}</p>
        </div>
      )}
      {values?.first_name && (
        <div className={css.reviewDiv}>
          <h4>{`First Name:`}</h4>
          <p>{values?.first_name}</p>
        </div>
      )}
      {values?.last_name && (
        <div className={css.reviewDiv}>
          <h4>{`Last Name:`}</h4>
          <p>{values?.last_name}</p>
        </div>
      )}
      {values?.role_ids && (
        <div className={css.reviewDiv}>
          <h4>{`Roles:`}</h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {values?.role_ids?.map((item: any, index: number) => {
              return (
                <div className={css.reviewOptions} key={item?.label + index}>
                  <p style={{ width: '100%' }}>{item?.label}</p>
                </div>
              )
            })}
          </div>
        </div>
      )}

      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={isDisabled} onClick={handleSubmit}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
