import { DocumentQueryAction } from '../../reducers/dashboardQueryReducer'
import { getStickyColumnWidths } from '../../hooks/useStickyColumnWidths'
import { insertColumn, removeEmptyObjects } from './utils'
import { useColumnOrder, useFlexLayout, useResizeColumns, useRowSelect, useTable } from 'react-table'
import { useLocation } from 'react-router-dom'
import ColumnHeader from './ColumnHeader'
import EmptyState from './EmptyState'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import Pagination from './Pagination'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import css from './style.module.scss'

interface Props {
  columns: any
  dispatch?: Dispatch<DocumentQueryAction>
  dummyDataActive?: boolean
  loading?: boolean
  pageLength?: number
  rowCount?: number
  selectionActionsTemplate?: any
  setClearAll?: Dispatch<SetStateAction<boolean>>
  tableData: any
  variant?: 'table' | 'inbox'
}

function Table({
  columns,
  dispatch,
  dummyDataActive,
  loading,
  pageLength,
  rowCount,
  selectionActionsTemplate,
  setClearAll,
  tableData,
  variant,
  ...pageLengthControls
}: Props) {
  const { pathname } = useLocation()

  const tableColumns = useMemo(() => {
    const persistentColumnWidths = getStickyColumnWidths(pathname)
    return columns.map((c: any) => ({ ...c, width: persistentColumnWidths[c.accessor] || 200, Cell: loading ? <Skeleton /> : c.Cell }))
  }, [columns, loading, pathname])

  const formattedTableData = useMemo(() => {
    // Empty rows for skeleton loader
    if (loading) return Array(10).fill({})
    return tableData
  }, [tableData, loading])

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows, selectedFlatRows, visibleColumns } = useTable(
    { columns: tableColumns, data: formattedTableData },
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    hooks => {
      if (selectionActionsTemplate) {
        hooks.visibleColumns.push(columns => [
          // Column for selection
          {
            id: 'selection',
            // eslint-disable-next-line react/display-name
            Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => {
              return <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} loading={loading} />
            },
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: { row: any }) => {
              return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} loading={loading} />
            },
            width: 50,
            minWidth: 50,
            disableResizing: true
          },
          ...columns
        ])
      }
    }
  )

  const [activeDragColumnIndices, setActiveDragColumnIndices] = useState<number[]>([])
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const visibleColumnIds = visibleColumns.map(c => c.id)

  const handleColumnSwap = () => {
    const swapped = insertColumn(visibleColumnIds, activeDragColumnIndices)
    try {
      dispatch && dispatch({ type: 'REORDER_TEMPORARY_COLUMNS', newColumnOrderById: swapped })
    } catch (error) {
      console.error('Table.tsx handleColumnSwap error:', error)
    }
  }

  const isTableDataEmpty = (array: Array<any>) => {
    if (!removeEmptyObjects(array).length) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isTableDataEmpty(tableData) && !loading) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }, [tableData, loading])

  return (
    <div className={clsx(css.wrapperWithBottomRow, isEmpty && css.isEmpty)}>
      <div className={css.wrapper}>
        {selectionActionsTemplate && (
          <div className={clsx(css.selectionOverlay, selectedFlatRows.length && css.visible)}>{selectionActionsTemplate({ selectedFlatRows })}</div>
        )}
        <div className={clsx(css.table, selectionActionsTemplate && css.selectable, isEmpty && css.isEmpty)} {...getTableProps()}>
          {headerGroups.map(headerGroup => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps()
            return (
              <div key={key} {...rest} className={css.tr}>
                {headerGroup.headers.map((column, idx) => {
                  const { key, ...rest } = column.getHeaderProps()
                  return (
                    <div key={key} {...rest} className={css.th}>
                      <ColumnHeader
                        {...column}
                        handleColumnSwap={handleColumnSwap}
                        hasCheckboxRow={!!selectionActionsTemplate}
                        idx={idx}
                        loading={loading}
                        setActiveDragColumnIdxs={setActiveDragColumnIndices}
                      >
                        {column.render('Header')}
                      </ColumnHeader>
                      {!loading && column.getResizerProps && (
                        <div {...column.getResizerProps()} className={clsx(css.resizer, column.isResizing && css.isResizing)} />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
          <div {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...rest } = row.getRowProps()
              return (
                <div key={key} {...rest} className={css.tr}>
                  {row.cells.map(cell => {
                    const { key, ...rest } = cell.getCellProps()
                    return (
                      <div key={key} {...rest} className={css.td}>
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Pagination pageLength={pageLength} {...pageLengthControls} dummyDataActive={dummyDataActive} rowCount={rowCount} />

      {isEmpty && <EmptyState setClearAll={setClearAll} variant={variant} />}
    </div>
  )
}

export default Table
